import React from 'react';
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks'
//import { affordability, getAllUrlParams, lowRisk, age, Potential_Benefit, shuffle } from './calculations';
//import { random, randomAccount, randomCellNumber, randomStreet } from './randomData.js'
import { VictoryContainer, VictoryChart, VictoryLabel, VictoryTheme, VictoryScatter, VictoryAxis } from 'victory'
//import I18n, { setLocale } from './strings/i18n'

const MokuPoint = ({ x, y, datum, riskType, riskColour }) => {
  // change this to have a red Moku
  const image =  datum._y > 620 ? "😄" : "🥵"
  const textWidth = riskType ? (riskType.length * 10) / 2 : 0
  console.log('textWidth', textWidth)
  return (
    <g >
    <text x={x-15} y={y+13} fontSize={30}>{image} </text>
    <text x={x-textWidth} y={y+35} fontSize={20}>{riskType}</text>
    </g>
  );
  }

  const DrawChart = ({compuScore, riskType, riskColour, afford}) => {
    return (
      <center>
        <div style={{width:"50%",background: riskColour, opacity: 1}}>
          <VictoryChart domain={{ x: [-2,2 ], y: [450, 750] }} width={300} height={300} theme={VictoryTheme.material}>
            <VictoryLabel angle={-90} x={20} y={200} textAnchor={"middle"} text={"Credit Rating ⟶"} />
            <VictoryLabel x={200} y={280} textAnchor={"middle"} text={"Affordability ⟶"} />
            <VictoryAxis crossAxis
              offsetY={150}
              standalone={false}
              theme={VictoryTheme.material}
              style={{
                grid: { stroke: "#969696" },
                tickLabels: { display: "none" }
              }}/>
            <VictoryAxis dependentAxis crossAxis
              offsetX={150}
              standalone={false}
              theme={VictoryTheme.material}
              style={{
                grid: { stroke: "#969696" },
                tickLabels: { display: "none" }
              }}
              />
            <VictoryScatter
              size={14}
              dataComponent={<MokuPoint riskType={riskType} riskColour={riskColour} />}
              style={{ data: { fill: riskColour }, labels: { fill: riskColour , fontSize: 14} }}
              data={[{ x: afford, y: compuScore } ]}
            />
          </VictoryChart>
        </div>
    </center>
    )
  }

const CreditPicture = ({  chatState }) => {


  let {compuScore, riskType, riskColour, canAfford} = chatState
  if (!compuScore || compuScore < 450) { compuScore = 450 }

  console.log('credit picture values are ', compuScore, riskType, riskColour, canAfford)

  const afford =  canAfford ? 1 : -1

  return  <DrawChart compuScore={compuScore} riskType={riskType} riskColour={riskColour} afford={afford} />

}

export {CreditPicture, DrawChart}
