import React, { Fragment, useState} from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { ActivityIndicator, Button } from '../generic'
import { Row } from '../generic/grid'
import Select  from '../generic/Select'


const BANK_ACCOUNT_DETAILS = gql`
  fragment BankAccountDetails on BankAccount {
    id
    accountNo
    bankName
    clientId
  }
`

const GET_BANK_ACCOUNTS = gql`{
  myClient {
    id
    bankAccounts {
      ...BankAccountDetails
    }
  }
}
${BANK_ACCOUNT_DETAILS}`

const getBankAccountOptions = (bankAccounts) => {
  let banks = []
  bankAccounts.map(b => {
    if (b.bankName.indexOf('UNKNOWN') == -1 && b.accountNo.indexOf('UNKNOWN') == -1) {
    let bank = {text: b.bankName + ' ' + b.accountNo, value:b.id}
    banks.push(bank)}
    })

  const seen = new Set();
  const options = banks.filter(b => {
    const duplicate = Array.from(seen).find(s => s.text === b.text);
    seen.add({text: b.text, value:b.id});
    return !duplicate;
   }).sort((a, b) => (a.text > b.text) ? 1 : -1);

  options.push({text:'Add new bank account', value:"NEW"})

  //console.log('Select Bank lotions', options)

  return options
}

const findAccount = (accounts, bankId) => accounts.find(bank => bank.id === bankId )

function Layout({ bankAccounts, chatState, setChatState, gotoNext }) {
  const { bankAccount } = chatState
  
  const [bankId, setBankId] = useState(bankAccount.id)
  
  const [saved, setSaved] = useState(false)
  
  const options = getBankAccountOptions(bankAccounts)

  console.log('Select Bank layout called')

  return (
    <Fragment>
    {!saved ?
      <Fragment>
      Bank account for payment <br /><br />
      <Select
        value={bankId}
        noSelectionLabel="  "
        options={options}
        prefix="Account: "
        onChange={(v) => setBankId(v) }
      />
      { bankId !== "NEW" && <Row centerHorizontal>
        <Button action="true" disabled={!bankId || saved} onClick={() => { console.log('bank selected is ',  findAccount(bankAccounts, bankId)); setSaved(true); setChatState({ bankAccount: findAccount(bankAccounts, bankId)  }); gotoNext("paymentDue")  } } > Next</Button>
      </Row>}
      { bankId === "NEW" && <Row centerHorizontal>
        <Button action="true" disabled={!bankId || saved}  onClick={() => { setSaved(true); gotoNext("bankAccountInfo")  } }  >Next</Button>
      </Row>}
      </Fragment> :
      <div>
      Bank account for payment<br />
      <span style={{float:"right"}}>{`Bank Account: ${bankAccount.bankName} ${bankAccount.accountNo}`}</span>
      <br />
      </div>
      }
    </Fragment>)
}


function Banks({ chatState, setChatState, gotoNext }) {

  console.log('Select Bank', chatState)

  return (
    <Query query={GET_BANK_ACCOUNTS}>
        {({ loading, error, data }) => { 
          
          console.log('Select Bank loading', loading, error, data)

          if (loading) return <ActivityIndicator large="true"/>

          if (error) return `Error! ${error.message}`

          return <Layout bankAccounts={data.myClient.bankAccounts} chatState={chatState} setChatState={setChatState} gotoNext={gotoNext} />
          
        }}
    </Query>
  )
}

export default Banks