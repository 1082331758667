import React from 'react';
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks'
//import { affordability, getAllUrlParams, lowRisk, age, Potential_Benefit, shuffle } from './calculations';
//import { random, randomAccount, randomCellNumber, randomStreet } from './randomData.js'
import { VictoryLegend,VictoryContainer, VictoryChart, VictoryLabel, VictoryTheme, VictoryScatter, VictoryAxis } from 'victory'
//import I18n, { setLocale } from './strings/i18n'

const MokuPoint = ({ x, y, datum, riskType, riskColour}) => {
  // change this to have a red Moku
  const image =  datum._y > 620 ? "😄" : "🥵"
  const textWidth = riskType ? (riskType.length * 10) / 2 : 0
  console.log('textWidth and riskType ', textWidth, riskType)
  return (
    <g >
    <text x={x} y={y+13} fontSize={36}>{image} </text>
    <text x={x+40} y={y+8} fill={riskColour} fontSize={20}>{riskType}</text>
    </g>
  );
  }

  const DrawLine = ({compuScore, riskType, riskColour}) => {
    console.log('Drawline ',compuScore, riskType, riskColour)
    return (
      <center>
        <div style={{width: "50%"}} >
          <VictoryChart  width={300} height={300} domain={{ x:[-2, 2 ], y: [450, 750] }} theme={VictoryTheme.material}>
            <VictoryLabel  x={150} y={280} textAnchor={"middle"} text={"Credit Rating"}/>
            <VictoryAxis dependentAxis crossAxis
              standalone={false}
              offsetX={150}
              tickValues={[450, 610, 750]}
              style={{
                grid: { display:"none",stroke: "#969696" },
                tickLabels: { fontSize: 12 }
              }}
              />
            <VictoryScatter
              size={14}
              dataComponent={<MokuPoint riskType={riskType} riskColour={riskColour} />}
              style={{ data: { fill: riskColour }, labels: { fill: riskColour, fontSize: 14  }} }
              data={[{ x: 0, y: compuScore } ]}
            />
          </VictoryChart>
      </div>
    </center>
    )
  }

const CreditLine = ({ chatState, submitting, setSubmitting }) => {

  let {compuScore, riskType, riskColour} = chatState
  if (!compuScore ||compuScore < 450) compuScore = 450

  console.log('CreditLine compuScore & submitting is ', compuScore, submitting)

  if (!compuScore) {
    setSubmitting(true)
    return (<div />)
  }
  else {
    setSubmitting(false)
    return <DrawLine compuScore={compuScore} riskType={riskType} riskColour={riskColour}  />
  }
}

export {CreditLine, DrawLine}