import React, {useState, Fragment } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import { PageContainer, Container, BoxContainer, Title, DocLink, Iframe, MyModal } from './MyDocuments.style'
import { ActivityIndicator, Label, Button, Modal, Pdf } from '../../../components/generic'
import { Row, Col } from '../../../components/generic/grid'
import { renderLabel, renderDisplay } from '../../../components/generic/form'
import {API_URL, API_TOKEN} from '../../../config'
import {terms, policy}  from '../../../constants'
import moment from 'moment'

const isMobileDevice = () =>  {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

const createMarkup = (car) => {
  return "data:text/html;charset=utf-8," + escape(car);
}

const fetchCAR = async (id, product) => {
    const response = await fetch(`${API_URL}${product}/${id}/carHTML`, { headers: { 'Authorization': `JWT ${localStorage.getItem('accessToken') || API_TOKEN } ` } })
    const car = await response.text()
    return createMarkup(car)
}

const GET_PROFILE = gql`
{
  myClient {
    id
    firstNames
    surname
    idNumber
    acceptedTermsAt
    creditLife {
      id
      policyNumber
    }
    funeralPolicies {
      id
      policyNumber
      policyType
      createdAt
      coverAmount
      state
      currentPremiumTotal
    }
    creditRecord {
      fetchedAt
      pdf {
        id
        url
      }
    }
  }
}
`
const GET_TEMPLATE = gql`
  query ClientCheck($name: String!, $productType:String, $productId:String, $format:String) {
    getTemplate(name: $name, productType:$productType, productId:$productId, format:$format )
  }`


function MyDocuments(props) {
  const {history, client} = props
  const [spin, setSpin] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [carHTML, setCarHTML] = useState()
  const [whatDoc, setWhatDoc] = useState("Credit Report")
  const [template, setTemplate] = useState()
  const [pdf, setPdf] = useState()

  const { data , loading, error } = useQuery(GET_PROFILE)
  if (loading ) { return <ActivityIndicator large/> }
  if (error) { console.log(error); return "An error has occurred" }

  const { myClient } = data
  console.log('My documents are ', myClient)

  const {firstNames, surname, acceptedTermsAt, creditRecord, funeralPolicies, creditLife } = myClient

  console.log('funeralPolicies ', funeralPolicies)

  const fetchTemplate = ({name,  productType, productId, format = 'html' }) => {
    setSpin(true)
    client.query({ query: GET_TEMPLATE, variables: { name, productType, productId, format } }).then(({ data }) => {
        console.log('get_template ', data.getTemplate)
        if (format && format === 'pdf ') { setPdf(data.getPdf) }
        else setTemplate(data.getTemplate)
        setShowModal(true)
        setSpin(false)
    }).catch((error) => {
        setSpin(false)
        console.log('fetchTemplate error ', error);
  });
  }

  return (
    <PageContainer>
      <BoxContainer>
      <Container>
        <Row>
          <Col centerVertical><Title>All Your Documents</Title></Col>
        </Row>
      </Container>

      <br />
      <Row >
        <Col centerHorizontal>
          <DocLink action="true" disabled={spin} onClick={() => {setWhatDoc("Credit Report"); setShowModal(true) }} >Credit Report</DocLink>
        </Col>
      </Row>
      <Row >
        <Col centerHorizontal>
          <DocLink action="true" disabled={spin} onClick={() => {fetchTemplate({name:"CONST_TERMS"});setWhatDoc("Terms & Conditions") }} >Terms & Conditions</DocLink>
        </Col>
      </Row>

      {creditLife &&
      <Fragment>
        <Row centerVertical>
          <Col centerHorizontal>
              <DocLink action="true" disabled={spin} onClick={() => { fetchTemplate({ name: "CLI_POLICY_DOCUMENT", format: "pdf" }); setWhatDoc("Credit Life Policy Document") }}  >Credit Life Policy Document </DocLink>
          </Col>
        </Row>
        <Row centerVertical>
        <Col centerHorizontal>
          <DocLink action="true" disabled={spin} onClick={() => {fetchCAR(creditLife.id,"creditLife").then((car)=> {setCarHTML(car)}); setWhatDoc("Certificate of Advice") }} >Policy {creditLife.policyNumber}: Certificate of Advice </DocLink>
        </Col>
      </Row>
      </Fragment>}

        {funeralPolicies && funeralPolicies.length > 0 && <Col centerHorizontal>
          <DocLink action="true" disabled={spin} onClick={() => {fetchTemplate({name:"CONST_FUNERAL_DOCUMENT"});setWhatDoc("Terms & Conditions") }} >Funeral Policy Document</DocLink>
        </Col>}

      {funeralPolicies && funeralPolicies.length > 0 && funeralPolicies.map((policy) => (
        <Row centerVertical>
          <Col centerHorizontal>
            <DocLink action="true" disabled={spin} onClick={() => {fetchTemplate({name:"CONST_FUNERAL_SCHEDULE",productType:"FUNERAL", productId:policy.id,}); setWhatDoc("Funeral Policy Schedule") }} >Policy {policy.policyNumber}: Funeral Policy Schedule </DocLink>
          </Col>
        </Row>
        ))}
      <br />

      {showModal && <Modal
          title={whatDoc}
          width='100%'
          hideCancel
          hideOk
          backgroundColor= 'rgb(242, 246, 252)'
          visible={showModal}
          extraButtonsTop={true}
          onOk={() => setShowModal(false)}
          okText={"Close"}
          >
          <center><Button action="true"onClick={() => {setShowModal(false)} } >Close</Button></center>

          {spin && <ActivityIndicator /> }
          {whatDoc.includes("Credit") && console.log('pdf to display is ', whatDoc, creditRecord,pdf, )}
          
          {whatDoc.includes("Credit")
            ?  whatDoc.includes("Life Policy") ? <Pdf fillHeight file={pdf} showPdf={showModal}  setShowPdf={setShowModal} /> :
            <Pdf fillHeight fileUrl={creditRecord.pdf.url} showPdf={showModal}  setShowPdf={setShowModal} />
            : <div dangerouslySetInnerHTML={{__html: template }} />
          }

          {whatDoc.includes("Credit Life Policy")
            && <Pdf fillHeight file={pdf} showPdf={showModal}  setShowPdf={setShowModal} />
          }

          {whatDoc.includes("Terms") && <div style={{marginTop:14,fontWeight:"bold"}} >Accepted by {firstNames} {surname} on {moment(acceptedTermsAt).format("Do MMMM YYYY")}</div>}

          <center><Button action="true"onClick={() => {setShowModal(false)} } >Close</Button></center>
          </Modal>}
    </BoxContainer>
    <Button action="true"onClick={()=> window.location = "https://beta.meerkat.co.za/" } > Back</Button>
    
    <br />
  </PageContainer>
    )
  }

export default withApollo(MyDocuments)

//history.push(`/`)} > Back</Button>
//<Button action="true"onClick={()=> window.location = "https://beta.meerkat.co.za/" } > Back</Button>
