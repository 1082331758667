import React, { Component, useState, useReducer, Fragment } from 'react'
import { Moku, Center, ChatCards, LoginContainer } from '../../profile/RegisterForm/RegisterForm.style'
import {ActivityIndicator, Container, Icon, Button, ChatCard,  } from '../../generic'
import { renderLabel, renderInput, renderDisplay } from '../../generic/form'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo-hooks'
import { withRouter } from 'react-router-dom'
import { GraphQLFormProvider, FormFieldArray, FieldInput, FormField, FieldValue, FieldValues, SubmitButton } from 'react-form-helper';
import {RequiredDocuments, List} from '../RequiredDocuments'
import { Row, Col } from '../../generic/grid'
import { DocumentName } from '../RequiredDocuments/RequiredDocuments.style'

import moment from 'moment'

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const clientFriendlyStatus = (state) => {
  console.log('clientFriendlyStatus',state)
  if (state === "INITIATED") {
    // If not valid / document missing then "Requirements Outstanding"
    return "Needs More Information"
  }
  if (state === "VALIDATED") {
    return "Waiting For Manager Approval"
  }
  if (state === "CANCELLED") {
    return "Claim was Cancelled"
  }
  if (state === "COMPLETED") {
    return "Claim Was Paid"
  }
  if (state === "APPROVED_PENDING_PAYMENT") {
    return "Awaiting Payment"
  }
  if (state === "REJECTED") {
    return "Claim was rejected"
  }
  if (state === "PENDING_REJECTED") {
    return "Unikely To Be Approved"
  }
  if (state === "CLIENT_INFO_REQUIRED") {
    return "Waiting for client to submit documents"
  }
  return "Being Processed"
}

const claimInfo = gql`
  fragment ClaimInfo on Claim {
    id
    effectiveDate
    info {
      key
      value
    }
    documents {
      id
      description
      verified
      waived
      fileId
      documentId
    }
  }`

function ClaimHeader ({ claimInfo, displayOnly}) {
  const {client : clientData, effectiveDate, createdAt, claimantId, claimType, claimNumber, state} = claimInfo
  const {idNumber, firstNames, surname} = clientData
  console.log('Edits allowed ', displayOnly)

  return  (
      <Container title=''>
      <Row alignHorizontal="left" key={'claimStatus'}>
      <Col alignHorizontal="right" size='8'  ><DocumentName >Status :</DocumentName></Col>
      <Col   ></Col>
      <Col alignHorizontal="left" size='12'><DocumentName bold={true}>{clientFriendlyStatus(state)}</DocumentName></Col>
    </Row>
      {createdAt && (
        <Row alignHorizontal="left" key={'Createddate'}>
          <Col alignHorizontal="right" size='8'><DocumentName>Initiated :</DocumentName></Col>
          <Col ></Col>
          <Col alignHorizontal="left" size='12'><DocumentName  >{moment(createdAt).format("Do MMMM YYYY")}</DocumentName></Col>
        </Row>
      )}

      {claimantId && (
        <Row alignHorizontal="left" key={'claimantID'}>
          <Col alignHorizontal="right" size='8'><DocumentName> Claimant :</DocumentName></Col>
          <Col ></Col>
          <Col alignHorizontal="left" size='12' ><DocumentName  >{claimantId}</DocumentName></Col>
        </Row>
      )}
      {claimNumber && (
        <Row alignHorizontal="left" key={'claimnumber'}>
          <Col alignHorizontal="right" size='8'><DocumentName>Claim Number :</DocumentName></Col>
          <Col ></Col>
          <Col alignHorizontal="left" size='12' ><DocumentName  >{claimNumber}</DocumentName></Col>
        </Row>
      )}
      {idNumber && (
        <Fragment>
        <Row alignHorizontal="left" key={'insuredID'}>
          <Col alignHorizontal="right" size='8' ><DocumentName >Claim For :</DocumentName></Col>
          <Col ></Col>
          <Col alignHorizontal="left" size='12'><DocumentName >{idNumber} {!isMobileDevice() && `  ${surname}, ${firstNames}` }</DocumentName></Col>
        </Row>

      </Fragment>
      )}
      {claimType && (
        <Row alignHorizontal="left" key={'type'}>
          <Col alignHorizontal="right" size='8'  > <DocumentName >Type :</DocumentName></Col>
          <Col  ></Col>
          <Col alignHorizontal="left" size='12'> <DocumentName >{claimType}</DocumentName></Col>
        </Row>
        )}
        {effectiveDate && (
          <Row alignHorizontal="left" key={'EffectiveDate'}>
            <Col alignHorizontal="right" size='8'><DocumentName>Occured :</DocumentName></Col>
            <Col ></Col>
            <Col alignHorizontal="left" size='12'><DocumentName >{moment(effectiveDate).format("Do MMMM YYYY")}</DocumentName></Col>
          </Row>
        )}
    </Container>
    )
  }
//<FormField mode="display" name="effectiveDate" noLabel/>

const GET_PROFILE = gql`
{
  myClient {
    id
    firstNames
    surname
    idNumber
    photo {
      id
      url
    }
  }
}`

  function Layout({ apolloClient, changeFieldValue, history, data}) {
    const today = moment()
    const fileUploadSuccess = ""

    const [claimantID, setClaimantID] = useState(false)
    const [dateError, setDateError] = useState(false)
    const [infoFields, setInfoFields] = useState([])
    const [allDocuments, setAllDocuments] = useState([])
    const [activeDocument, setActiveDocument] = useState(null)
    const [doNothing, setDoNothing] = useState(null)
    const [displayOnly, setDisplayOnly] = useState(true)
    const [lastFileUploaded, setLastFileUploaded] = useState(false)

    const { data : myData, loading, error } = useQuery(GET_PROFILE)

    if (loading ) { return <ActivityIndicator /> }

    console.log('data & claimant ', claimantID, data.claim, myData)

    if (!claimantID ) {
      console.log('state is ', data.claim.state )
      setDisplayOnly(data.claim.state === 'INITIATED')

      if (myData.myClient) {
        setClaimantID(myData.myClient.idNumber)
      }
      else {
        setClaimantID(data.claim.claimant.idNumber)
      }
      setInfoFields(data.claim.info)
      setAllDocuments(data.claim.documents)
      setDisplayOnly(['cancelled', "rejected"].indexOf(data.claim.state.toLowerCase()) > -1 )
    }

     if (displayOnly) {
       return (
          <Center>
          <Moku small />
          <ChatCards>
            {myData.myClient && <ChatCard text={`Welcome back ${myData.myClient.firstNames}. `} noNext />}
             <ChatCard text={`This claim has been ${data.claim.state} because ${data.claim.cancelReason}.`} noNext />
            <ChatCard nextTitle="OK" handleNext={() => history.push('/claims')} />
        </ChatCards>
        </Center>  
       )
     }

     else return (
       
    <Center>
    <Moku small />
    
         {console.log('alldocuments are ', allDocuments, allDocuments.length)}

      <ChatCards>

      {myData.myClient && <ChatCard text={`Welcome back ${myData.myClient.firstNames}.`} noNext />}

      {myData.myClient &&  <ChatCard text={<ClaimHeader claimInfo={data.claim} displayOnly={displayOnly}/>  } noNext />}

      {infoFields && infoFields.length > 0 &&
        <ChatCard displayOnly={displayOnly} text="" noNext>
             {console.log('info fields are ', infoFields)}
             {infoFields && infoFields.map((field, index) => {
               console.log('display info fields ', field, index)
               return <FormField displayOnly key={field.key} type='text' label={field.key} name={`info.${field.key}`} />
             }
             )}
      </ChatCard>}

           {allDocuments && allDocuments.length > 0 && <ChatCard noNext text={<Fragment>
             <RequiredDocuments activeDocument={activeDocument} setActiveDocument={displayOnly ? setDoNothing : setActiveDocument} />
        <br />
        <center>
        <Row alignHorizontal="left" key={'iconMeaning'}>
          <Col></Col>
          <Col></Col>
          <Col><Icon size="xs" color="green" icon="thumbs-up" />good</Col>
          <Col><Icon size="xs" color="green" icon="user-clock" />checking</Col>
        </Row>
        </center>
        </Fragment>
        } />}

        <FieldValue  name={`documents[${activeDocument}].fileId`} renderField={doc => {
          const { input } = doc
          console.log('active doc is ', activeDocument, input, doc)
          if ((activeDocument || activeDocument === 0) && !input.value  ) {
            return  (
              <ChatCard
              text={<Fragment>
                <center><br/>Please upload: <b>{allDocuments[activeDocument].description}</b></center>
                <br />
              </Fragment>}
            fieldNames={[`documents[${activeDocument}].fileId` ]} nextOnValue={true} noNext={true} focus nextTitle={"Later" } handleNext={ (() => setActiveDocument(null))} />
          ) }
          else {
            return (
              ((activeDocument || activeDocument === 0 ) && !input.value)
              ? <ChatCard text={<Fragment><center><br/> <Icon size="4x" color="green" spin icon="spinner" /><br/>uploading</center></Fragment>} noNext />
              : (activeDocument || activeDocument === 0) && input.value
              ? <ChatCard text={
                <center>
                    <Button action="true"onClick={() =>  { console.log('file uploaded ',activeDocument,  input.value); setLastFileUploaded(input.value) ; setActiveDocument(null) } }>
                      Save File
                    </Button> 
                </center> } noNext  />
              : " "
              )}
            }} />

        {activeDocument == null && !displayOnly &&
        <center>
          <br/>
          <ChatCard noNext text={<SubmitButton renderSubmitButton={({ invalid, submit, hasChanges, submitting }) => (
            <Fragment>
            {hasChanges
            ? <Button action="true" disabled={invalid || submitting} onClick={() =>  { console.log('submit pressed'); submit()} }>Save & Exit</Button>
                   : <Button action="true" disabled={invalid} onClick={() => { history.push('/claims') }}>Done</Button>}
            </Fragment>
            )} />}/>
        </center>}

      </ChatCards>
    </Center>
    )
}

Layout = withRouter(Layout)

const UPDATE_CLAIM = gql`
  mutation UpdateClaim($id: String, $input: UpdateClaimWithDocumentsInput) {
  updateClaimWithDocuments(id: $id, input: $input) {
    ...ClaimInfo
  }
}
${claimInfo}
`

const validate = values => {
  const {info, documents} = values

  const errors = {}

  console.log('errors are ', errors)
  return errors
}

const warn = values => {

  const {info, documents} = values

  const errors = {}

  //if (!info || !info.causeOfDeath || info.causeOfDeath.length == 0 ) {
  //  if (!errors.info) { errors.info = {} }
  //  errors.info.causeOfDeath = 'Cause of death is required'
  //}

  if (!info  ) {
    if (!errors.info) { errors.info = {} }
    errors.info = 'All fields are required'
  }

  errors.documents = documents && documents.map((doc, index) => {
    {console.log('values for warnings ', doc )}
    if (doc) {
      if (doc.waived) {
        return {}
      }
      if (doc.verified) {
        return {}
      }
      if (doc.verified === false) {
        return {documentId: 'The document is invalid' }
      }
      if (doc.documentId || doc.fileId) {
      return {documentId: 'Please check the document' }
      }

      return { documentId: 'This document is required ' }
    }
  })

  console.log('warnings are ', errors)

  return errors
}

const fields = {
  effectiveDate: {
    type: "date",
    minDate:  moment().subtract(3,'months'),
    maxDate: moment(),
    required:true
  },
  createdAt:{
    strip:true
  },
  info:  {
    type: "keyvalue",
    config: {}
  },
  documents: {
    label: 'Documents',
    type: 'fieldarray',
    config: {
      description: {
        label: 'Description',
        type: 'text'
      },
      file: {
        strip:true,
      },
      fileId: {
        type: 'fileupload'
      },
      requirementId: {
        type: 'hidden'
      },
      requirement: {
        strip: true
      },
      'requirement.documentType': {
        label: 'Type',
        type: 'text',
        displayOnly: true
      }
    }
  }
}

function ClaimUpdated({ history }) {
  return (
        <Center>
          <Moku />
          <ChatCards>
            <ChatCard text={
              <center>Thanks so much for the update. Your claim changes have been logged and will be assessed. We will provide feedback using the details on file.
              </center>}
            nextTitle="Done" handleNext={() => history.push('/claims')} />
          </ChatCards>
          </Center>
  )
}
ClaimUpdated = withRouter(ClaimUpdated)


function ClaimFormEdit({ match}) {

  const [claimUpdated, setClaimUpdated] = useState(false)
  const { id, claimNumber } = match.params

  console.log('Edit claim for match params and id and claimType ', match, claimNumber)

  if (claimUpdated) {
    return <ClaimUpdated  />
  }

  return (
    <GraphQLFormProvider
      id={id}
      name="claim"
      mode="edit"
      fields={fields}
      validate={validate}
      warn={warn}
      initialValuesFromData={data => data.claim}
      renderInput={renderInput}
      InputFormLayout={Layout}
      renderLabel={renderLabel}
      renderDisplay={renderDisplay}
      query={ gql`query Claim($id: String!) {
        claim(id: $id) {
        id
        state
        cancelReason
        claimReason
        claimNumber
        claimType
        claimant {
          id
          idNumber
        }
        client {
          id
          idNumber
          firstNames
          surname
        }
        effectiveDate
        createdAt
        info {
          key
          value
        }
        documents {
          id
          description
          waived
          verified
          required
          fileId
          file {
            id
            url
            name
            contentType
          }
          requirementId
          requirement {
            id
            requirementId
            documentType
            description
            required
          }
        }
        }
        }`}
      updateMutation={UPDATE_CLAIM}
      mapInputVariables={(input) =>  {
        const { claimForIdNumber, claimForIdType, cancelReason, ...rest } = input
        console.log(' mapInputVariables claim edit', claimForIdNumber, claimForIdType, rest)
        return { input:rest }
      }}
      afterSubmit={() => { setClaimUpdated(true) }}
      keepDirtyOnReinitialize={true}
    />
  )
}


export default ClaimFormEdit