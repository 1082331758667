import React from 'react'
import { ButtonContainer } from './Button.style'
import ActivityIndicator from '../ActivityIndicator'

function Button({ children, action, warning, onClick, size, width, disabled, reverseColor, submitting }) {
  return (
    <ButtonContainer action={action} warning={warning} disabled={disabled} onClick={(!disabled && !submitting) ? onClick : undefined} size={size} width={width} reverseColor={reverseColor}>
      {submitting ? <ActivityIndicator small color={action="true"? "white" : "black"} /> : children}
    </ButtonContainer>
  )
}

export default Button
