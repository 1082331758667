import styled from 'styled-components'

const ButtonContainer = styled.div`
  display:flex
  flex-direction: column
  justify-content:center
  align-items: center
  width: 100%
`

  export {
    ButtonContainer
  }