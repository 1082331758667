import styled from 'styled-components'
import { googleSignIn, facebookSignIn, moku } from '../../../assets/images'
import { device } from '../../../device.js'

const Moku = styled.img.attrs({
  src: moku
})`
 height: ${props => props.small ? "10vh" : "150px"};
 margin: auto;
 margin-top: 14px;
 margin-bottom: 14px;
 ${props => props.hide && `display: none`}
`

const Center = styled.div`
  z-index: 1;
  display:flex;
  flex-direction: column;
  justify-content:center;
`

const Bottom = styled.div`
  position:absolute;
  bottom:1vh;
  margin:0 auto;
  width:400px;
`

const ChatCards = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
`

/*
${device.mobileS`
  width: 320px;
`}
${device.mobileM`
  width: 375px;
`}
${device.mobileL`
  width: 425px;
`}
${device.tablet`
  width: 768px;
`}

${device.laptop`
  width: 1024px;
`}
${device.laptopL`
  width: 1024px;
`}
${device.desktop`
  width: 1024px;
`};
*/

const FacebookLoginButton = styled.img.attrs({
  src: facebookSignIn
})`
  width: 218px;
`

const GoogleLoginButton = styled.img.attrs({
  src: googleSignIn
})`
  width: 200px;
`

const TermsLink = styled.a`
text-decoration: underline;
:active {
  color: blue;
}
:hover,
:focus {
  font-weight: bold;
}
:visited {
    color: green;
  }
`
const Footer = styled.div`
  position: absolute;
  text-align:center;
  min-height: 3vh;
  height: 3vh;
  padding-top:1vh;
  left: 0;
  bottom:0;
  opacity: 0.5;
  //background-image:linear-gradient(to bottom,rgba(38,126,127,0.5),0,rgba(38,126,127,0.5),32vh,#f8f8f8);
  width: 100%;
  color:${props => props.white ? "#229490" : "#FFF"}
  font-size:12px;
`

export {
  Moku,
  Center,
  ChatCards,
  FacebookLoginButton,
  GoogleLoginButton,
  TermsLink,
  Bottom,
  Footer
}