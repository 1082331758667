import React from 'react'
import styled from 'styled-components'
import { getBucketTypeProps, IconCircle, ProgressBar } from '../SavingsNeedItemRow/SavingsNeedItemRow.style'
import { Icon } from '../../generic'

const SavingsNeedIconCircle = styled(IconCircle)`
  margin-top: 7px;
  margin-bottom: 7px;
  width: 120px;
  height: 120px;
  border-radius: 60px;
`

const SavingsNeedIcon = ({ bucketType }) => (
  <SavingsNeedIconCircle bucketType={bucketType}>
    <Icon style={{ width: "80px", height: "80px" }} {...getBucketTypeProps(bucketType)} />
  </SavingsNeedIconCircle>
)

const NeedProgressBar = styled(ProgressBar)`
  max-width: 550px;
  width: 80%;
  background-color: white;
`

export {
  SavingsNeedIcon,
  NeedProgressBar
}