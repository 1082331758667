import React from 'react'
import moment from 'moment'
import { printCurrency } from '../../../lib/utils'
import { Label, Icon } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { MonthCircle, RowContainer, stateColor } from './EmployerContributionRow.style'

function EmployerContributionRow({ id, period, description, employees, totalAmount, amountPaid, submittedAt, onClick, topRow, payDay = 25 }) {
  const now = moment().valueOf()
  const payDate = moment(period+"-"+payDay).valueOf()
  const state = amountPaid >= totalAmount ? "PAID" : now > payDate ? "OVERDUE" : "PENDING"
  const defaultDescription = moment(period).format('MMMM YYYY Contribution')
  const month = moment(period).format('MMM').toUpperCase()

  return (
      <RowContainer topRow={topRow} onClick={() => onClick(id)}>
        <Col width="60px" centerHorizontal centerVertical>
          <Row>
            <MonthCircle>{month}</MonthCircle>
          </Row>
        </Col>
        <Col centerVertical>
          <div>
            <Row>{description || defaultDescription}</Row>
            <Row>{employees} Employees</Row>
          </div>
        </Col>
        <Col width="80px" centerVertical centerHorizontal><Label color={stateColor[state]}>{state}</Label></Col>
        <Col width="80px" centerVertical centerHorizontal>{printCurrency(totalAmount)}</Col>
        <Col width="20px" centerVertical centerHorizontal><Icon icon="chevron-right" size="2x" /></Col>
      </RowContainer>
  )
}

export default EmployerContributionRow
