import styled from 'styled-components'

const ShareButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  width: ${props => props.width}px;
`

const ShareButtonContainer = styled.div`
  flex: 1
`

export {
  ShareButtonsContainer,
  ShareButtonContainer
}
