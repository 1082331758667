import React, { Component} from 'react'
import { Switch, Route } from 'react-router-dom'
import EmployerContributions from './EmployerContributions'
import EmployerContributionDetails from './EmployerContributionDetails'
import ListEmployers from './ListEmployers'
import { RegisterEmployer } from '../../components/profile'

export default class Employer extends Component {
  render() {
    return (
      <Switch>
        <Route path='/employers' component={ListEmployers} />
        <Route path='/employer/contribution/:id' component={EmployerContributionDetails} />
        <Route path='/employer/new' component={RegisterEmployer} />
        <Route path='/employer/:id' component={EmployerContributions} />
        
      </Switch>
    )
  }
}