import styled from 'styled-components'
import { Row, Col } from '../../../components/generic/grid'

const isMobileDevice = () =>  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const PaddedCol = styled(Col)`
  padding: 7px;
`
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}


const stateColor = {
  "ACTIVE": "green",
  "PAID_UP": "green",
  "INFO_MISSING": "orange",
  "NOT_TAKEN_UP": "orange",
  "ON_RISK_GRACE": "orange",
  "FROZEN": "red",
  "LAPSED": "red",
  "CANCELLED":"red"
}

const Container = styled.div`
  margin:auto;
  border: 1px solid grey;
  padding:7px,
`

const BoxContainer = styled.div`
  min-width: ${props => isMobileDevice() ?  "320px" : "800px"};
  min-height:100px;
  margin:0 auto;
  border: 1px solid grey;
  padding:7px,
`

const PageContainer = styled.div`
  display: flex;
  margin-top:5%;
  flex-direction:column;
  align-items: center;
  min-width: ${props => isMobileDevice() ?  "320px" : "800px"};
`

const Title = styled.h1`
  font-size: 16px;
  line-height: 16px;
  padding-left: 7px;
`

const Header = styled.div`
  display:none
  padding: 7px;
  border-bottom: 1px solid grey;
`

const DocLink = styled.a`
text-decoration: underline;
color: blue;
:hover,
:focus {
  font-weight: bold;
}
`
const Iframe = styled.iframe`
height: 100vh;
padding:5px;
min-width: ${props => isMobileDevice() ?  "320px" : "800px" }
`
const MyModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  width: ${props => {
    switch (props.size) {
      case "lg":
        return "800";
      default:
        return "480";
    }
  }}px;
  margin: 40px auto;
`;


export {
  MyModal,
  PaddedCol,
  stateColor,
  PageContainer,
  Container,
  Header,
  Title,
  BoxContainer,
  DocLink,
  Iframe
}
