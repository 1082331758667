import React from 'react'
import styled from 'styled-components'

const SelectContainer = styled.div`
.rrs {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 7px;
  min-width:50%;
  min-height:50%;
}

.rrs *,
.rrs *:before,
.rrs *:after {
  box-sizing: border-box;
}

.rrs__button {
  color: #555;
  position: relative;
  cursor: pointer;
  line-height: 28px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid grey;
  outline: none;
}

.rrs__button--disabled {
  color: #999999;
  background: #f5f5f5;
  cursor: default;
}

.rrs__button:focus {
  outline: 0;
}

.rrs__button + .rrs__options {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  position: absolute;
  z-index: 2;
  border: 1px solid #999;
  border-radius: 14px;
  top: 35px;
  left: 7px;
  right: 7px;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.rrs--options-visible .rrs__options {
  height: auto;
  visibility: visible;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 230px;
}

/*
  Open in a modal when smaller potentially touch screen
*/

@media screen and (max-width: 768px) {
  .rrs {
    position: static;
  }

   .rrs.rrs--options-visible:after {
    content: '';
    cursor: pointer;
    position: fixed;
    animation: fadeIn 0.3s ease forwards;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

   .rrs--options-visible .rrs__options {
    max-height: initial;
    position: fixed;
    width: auto;
    left: 1rem;
    right: 1rem;
    top: 64px;
    bottom: 1rem;
    border: 0;
  }
}

 @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rrs__option {
  cursor: pointer;
  padding: 0.75rem 1rem;
  margin: 0;
}

.rrs__option * {
  pointer-events: none;
}

.rrs__option:focus {
  outline: 1px solid #e0e0e0;
}

.rrs__option:hover {
  background: ${props => props.theme.colors.primary}
  color: white
}

.rrs__option:active {
  background: #e1f5fe;
}

.rrs__option.rrs__option--next-selection {
  background: ${props => props.theme.colors.primary};
  color: white;
  font-weight: 600;
}

.rrs__option.rrs__option--selected {
}

.rrs__option.rrs__option--disabled {
  color: #999999;
  background: #f5f5f5;
  cursor: default;
}

.rrs__option.rrs__option--header {
  color: #666666;
  cursor: default;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  background: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
}

.rrs__label {
  padding: 0 2rem 0 1rem;
  display: inline-flex;
  height: 100%;
  width: 100%;
  max-width: 100%;
  font-size: inherit;
  background: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rrs__label * {
  pointer-events: none;
}

.rrs--options-visible .rrs__label,
.rrs__button:focus .rrs__label {
  outline: 0;
}

.rrs--options-visible .rrs__label {
  border-radius: 4px 4px 0 0;
}

.rrs--has-changed .rrs__label {
}

/*
  Multiselect overrides
*/

.rrs__multiselect-label {
  display: inline-flex;
  max-width: 100%;
  line-height: 1;
}

.rrs__multiselect-label__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rrs__multiselect-label__badge {
  border: 1px solid #ccc;
  padding: 1px 6px;
  margin: 0 0 0 4px;
  border-radius: 8px;
  color: #666;
  font-size: 11px;
  vertical-align: middle;
  display: inline-block;
}

/*
  Checkbox
*/

.rrs .checkbox {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  top: -1px;
  border: 1px solid #ccc;
  margin: 2px 8px 2px 0;
  border-radius: 4px;
}

.rrs__option.rrs__option--selected .checkbox {
  border: 1px solid  ${props => props.theme.colors.primary};
}

.rrs .checkbox-icon {
  fill: transparent;
  position: absolute;
  left: 1px;
  top: 1px;
}

.rrs__option.rrs__option--selected .checkbox-icon {
  fill:  ${props => props.theme.colors.primary};
}

/*
  Caret Icon
*/

.rrs .caret-icon {
  position: absolute;
  right: 1rem;
  top: 12px;
  fill: #333;
}

.rrs--options-visible .caret-icon {
  transform: rotate(180deg);
}
`;

const CaretIcon = () => (
  <svg
    className="caret-icon"
    x="0px"
    y="0px"
    width="11.848px"
    height="6.338px"
    viewBox="351.584 2118.292 11.848 6.338"
  >
    <g>
      <path d="M363.311,2118.414c-0.164-0.163-0.429-0.163-0.592,0l-5.205,5.216l-5.215-5.216c-0.163-0.163-0.429-0.163-0.592,0s-0.163,0.429,0,0.592l5.501,5.501c0.082,0.082,0.184,0.123,0.296,0.123c0.103,0,0.215-0.041,0.296-0.123l5.501-5.501C363.474,2118.843,363.474,2118.577,363.311,2118.414L363.311,2118.414z" />
    </g>
  </svg>
);


export {
  SelectContainer,
  CaretIcon
}