import React, {Fragment} from 'react'
import { GoogleButtonImage, MokuButtonImage} from '../../../assets/images'
import {MenuButton} from '../../generic'
import { API_URL, API_AUTH_ID } from '../../../config'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import { Link } from './LoginButton.style'

const GoogleButton = () => <MenuButton background={"#fff"} border={"#4285F4"} image={GoogleButtonImage} label="Continue with Google" />
const FaceBookButton = () => <MenuButton background={"#4267B2"} color={"#fff"} icon={faFacebook} label="Continue with Facebook" />
const MeerkatButton = ({onClick}) => <MenuButton background={"#267E7F"} image={MokuButtonImage} color={"#fff"} label="Register with Meerkat" onClick={onClick}/>

const authProviderButtons = {
  google: GoogleButton,
  facebook:FaceBookButton
}

// TODO: Add something to set the local storage location
const LoginButton = ({ register, authProvider, MyButtonComponent}) => {
  let ButtonComponent
  if (MyButtonComponent) {
    ButtonComponent = MyButtonComponent
  } else {
    ButtonComponent = authProviderButtons[authProvider] || GoogleButton
  }

  let loginUrl = `${API_URL}auth/${authProvider || "google"}?clientId=${API_AUTH_ID}&redirect=${window.location.origin}`

  if (register) {
    loginUrl += `&registerId=${localStorage.getItem('registerId')}`
  }
  return (
    <Link href={loginUrl}>
      <ButtonComponent />
    </Link>
  )
}


export {LoginButton, MeerkatButton}
