import React from 'react'
import { Icon } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { RowContainer, IconCircle, DebtBalance, AccountTypeText, InterestRateBadge } from './DebtItemRow.style'
import { printCurrency } from '../../../lib/utils'

const formatName = (text) => text.split(" ").map(t => t && t[0] && (t[0].toUpperCase() + t.substring(1).toLowerCase())).join(" ")

function DebtItemRow({ obligation, onClick, topRow }) {
  const { id, accountTypeDescription, currentBalance, clientCreditor, currentPayment, remainingTerm } = obligation
  const { rate, installment } = currentPayment || {}

  return (
      <RowContainer topRow={topRow} onClick={() => onClick && onClick(id)}>
        <Col width="52px" centerHorizontal centerVertical>
          <Row centerVertical>
            <IconCircle accountType={accountTypeDescription} />
          </Row>
        </Col>
        <Col centerVertical>
          <Row centerVertical><Col alignHorizontal={"left"} centerVertical>{formatName(clientCreditor.description)}</Col></Row>
          <Row centerVertical  alignHorizontal={"left"}><AccountTypeText>{accountTypeDescription}</AccountTypeText><InterestRateBadge rate={rate} /></Row>
        </Col>
        <Col width="100px" centerVertical alignHorizontal={"right"}>
          <Row size={2} centerVertical><DebtBalance large>{printCurrency(installment)}</DebtBalance></Row>
          {remainingTerm && remainingTerm > 0 && <Row size={1} centerVertical><DebtBalance small>{remainingTerm} mo</DebtBalance></Row>}
          <Row size={1} centerVertical><DebtBalance>{printCurrency(currentBalance)}</DebtBalance></Row>
        </Col>
      </RowContainer>
  )
}

export default DebtItemRow
