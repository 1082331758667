import styled from 'styled-components'
import { Row, Col } from '../../generic/grid'

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const PaddedCol = styled(Col)`
  padding: 7px;
`

const Iframe = styled.iframe`
height: 100vh;
padding:5px;
min-width: ${isMobileDevice() ? "320px" : "800px"}
`

const stateColor = {
  "ACTIVE": "green",
  "PAID_UP": "green",
  "INFO_MISSING": "orange",
  "NOT_TAKEN_UP": "orange",
  "ON_RISK_GRACE": "orange",
  "FROZEN": "red",
  "LAPSED": "red",
  "CANCELLED":"red"
}

const Container = styled.div`
  position: absolute;
  top:  "14vh";
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
`

const ListContainer = styled.div`
  display: flex;
  padding-top: 6px;
  padding-bottom: 8px;
  ${props => props.topRow ? "" : "border-top: 1px solid grey;"}
  justify-content: center
`

const Text = styled.span`
  color: ${props => props.theme.color};
  padding-left: 7px;
`

const State = styled.div`
  position: relative;
  margin: 7px;
`

const Title = styled.span`
  color: ${props => props.theme.color};
  padding-left: 7px;
  text-transform:uppercase;
  font-weight:bold;
`

export {
  PaddedCol,
  stateColor,
  ListContainer,
  Container,
  Text,
  Title,
  Iframe,
  State
}
