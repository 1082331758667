import React, { useState, Fragment } from 'react'
import { Moku, Center, ChatCards, LoginContainer, GoogleLoginButton } from '../RegisterForm/RegisterForm.style'
import { Button, Card, ChatCard } from '../../generic'
import { renderLabel, renderInput } from '../../generic/form'
import { API_URL, API_AUTH_ID } from '../../../config'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FieldInput, FormField, FieldValue, FieldValues, SubmitButton } from 'react-form-helper';
import { banks, payrollSystems, payrollDate } from '../../../constants'
import { isValidRsaIdNumber } from '../../../lib/validators'

const COMPLETE_REGISTRATION = gql`
  mutation RegisterEmployer($input: RegisterEmployerInput) {
    registerEmployerUser(input: $input)
  }
`

const SEND_OTP = gql`
  mutation SendOTP($cellNumber: String, $useExisting: Boolean) {
    sendOTP(cellNumber: $cellNumber, useExisting: $useExisting)
  }
`

const CONFIRM_OTP = gql`
  mutation RegistrationOTP($OTP: String) {
    confirmOTP(OTP: $OTP)
  }
`

const GET_SIMPLE_PAY_CLIENTS = gql`
  query GetSimplePayClients($token: String) {
    getSimplePayClients(token: $token) {
      id
      name
    }
  }
`

const isRegister = () => {
  const registerToken = localStorage.getItem('registerToken');
  return registerToken === "true"
}

// TODO: Remove inline styles and fix styling containers
function Layout({ apolloClient, changeFieldValue }) {
  const [hasEmployer, setHasEmployer] = useState(false)
  const [hasPayroll, setHasPayroll] = useState(false)
  const [hasPayrollInfo, setHasPayrollInfo] = useState(false)
  const [payrollSystem, setPayrollSystem] = useState("")
  const [hasCellNumber, setHasCellNumber] = useState(false)
  const [hasPayrollDate, setHasPayrollDate] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [hasBankDetails, setHasBankDetails] = useState(false)
  const [numInvalidOTP, setNumInvalidOTP] = useState(0)
  const [tcAccepted, setTCAccepted] = useState(null)
  const [simplePayClients, setSimplePayClients] = useState([])

  const doEmployerCheck = () => {
    setHasEmployer(true)
  }

  const sendOTP = (variables) => {
    apolloClient.mutate({ mutation: SEND_OTP, variables }).then(({ data }) => {
      setHasCellNumber(true);
    })
  }

  const confirmOTP = (variables) => {
    const OTP = variables[Object.keys(variables)[0]]
    apolloClient.mutate({ mutation: CONFIRM_OTP, variables: { OTP } }).then(({ data }) => {
      if (data.confirmOTP) {
        setIsVerified(true);
      } else {
        setNumInvalidOTP(numInvalidOTP + 1);
      }
    })
  }

  const onPayrollSystem = ({ payrollSystem }) => {
    setPayrollSystem(payrollSystem)
    setHasPayroll(true)
    if (payrollSystem !== "SIMPLE_PAY") {
      setHasPayrollInfo(true)
    }
  }

  // TODO: Deal with invalid token ...
  const setSimplePayToken = ({ simplePayToken }) => {
    apolloClient.query({ query: GET_SIMPLE_PAY_CLIENTS, variables: { token: simplePayToken } })
      .then(({ data }) => {
        if (data.getSimplePayClients.length > 1) {
          setSimplePayClients(data.getSimplePayClients)
        } else {
          changeFieldValue('simplePayId', data.getSimplePayClients[0].id)
          setHasEmployer(true)
        }
        setHasPayrollInfo(true)
      })
  }

  return (
    <Center>
        <Moku />
          <ChatCards>
            <ChatCard text="Hi - I am Moku. I am going to help you register as an employer in our customer portal. You will then be able to view and manage your contributions to your employers" noNext />
            <ChatCard text="What payroll system do you use (we can automatically synchronise with the deductions you have specified in SimplePay)" fieldNames={["payrollSystem"]} handleNext={onPayrollSystem} noNext={hasPayroll} />
            {hasPayroll && payrollSystem === "SIMPLE_PAY" && <ChatCard text="Please enter your API token for Simple Pay. We will store this securely and use it to synchorinise your data. Click here for instructions on how to retrieve the token" handleNext={setSimplePayToken} fieldNames={["simplePayToken"]} noNext={hasPayrollInfo} focus={hasPayroll && payrollSystem === "SIMPLE_PAY" && simplePayClients.length === 0} />}
            {simplePayClients.length > 1 && <ChatCard text="Please select which employer you would like to use" handleNext={() => setHasEmployer(true)} noNext={hasEmployer}>
              <FieldValue name="simplePayId" renderField={({ input }) => (
                <select onChange={input && input.onChange}>
                  {simplePayClients.map(client => <option value={client.id}>{client.name}</option>)}
                </select>
              )} />
            </ChatCard>}
            {hasPayrollInfo && payrollSystem !== "SIMPLE_PAY" && <ChatCard text="What is the name of your company?" fieldNames={["companyName"]} handleNext={() => setHasEmployer(true)} noNext={hasEmployer} />}
            {hasEmployer && <ChatCard text="When is your current pay day?" fieldNames={["payDate", "payDay"]} handleNext={() => setHasPayrollDate(true)} noNext={hasPayrollDate} /> }
            {hasPayrollDate && <ChatCard text="Thanks. What is your cell number?" fieldNames={["cellNumber"]} handleNext={sendOTP} noNext={hasCellNumber} focus={hasPayrollDate && !hasCellNumber} />}
            {hasCellNumber && <ChatCard text="I have sent an OTP to the number we have on record to confirm your identity, please provide to continue" fieldNames={["OTP[0]"]} handleNext={confirmOTP} noNext={isVerified} focus={numInvalidOTP === 0 && hasCellNumber} />}
            {numInvalidOTP > 0 && [...Array(numInvalidOTP > 3 ? 3 : numInvalidOTP).keys()].map(index => <ChatCard text="The OTP you provided was invalid, please try again. Check here to resend or here to change the number" fieldNames={[`OTP[${index + 1}]`]} handleNext={confirmOTP} focus={numInvalidOTP === (index + 1) && hasCellNumber} />)}
            {numInvalidOTP >= 3 && <ChatCard text="You have not been able to provide a valid OTP after 3 attempts - we are unable to continue with registration" />}
            {isVerified && <ChatCard text="What is your bank account details (We will not debit your account without a mandate)?" fieldNames={["bankAccount.bankName", "bankAccount.accountNo"]} labels handleNext={() => setHasBankDetails(true)} noNext={hasBankDetails} />}
            {hasBankDetails && <ChatCard text="Do you accept ours terms and conditions?" noNext>
              <FieldValue name="acceptedTerms" renderField={({ input: { onChange, value } }) => (
                (value !== true && value !== false) ?
                  <center>
                    <SubmitButton renderSubmitButton={({ submit }) => (
                      <Button onClick={() => { onChange(true); submit(); setTCAccepted(true) }} action>Yes</Button>
                    )} />
                    <Button action="true"onClick={() => { onChange(false); setTCAccepted(false) }}>No</Button>
                  </center>
                : value === true ? "Yes" : "No"
              )} />
            </ChatCard>}
            {hasBankDetails && tcAccepted === false && <ChatCard text="We will not be able to help you unless you accept the terms and conditions" noNext/>}
            {isRegister() && hasBankDetails && tcAccepted && <SubmitButton renderSubmitButton={({ submitting }) => (
              !submitting &&
                <ChatCard text="Thanks so much for your information. We are ready to finish setting up your account. Please signin with your Google account to complete registration and link your Google login to your Meerkat account" noNext>
                  <a href={`${API_URL}auth/google?clientId=${API_AUTH_ID}&redirect=${window.location.origin}&registerId=${localStorage.getItem('registerId')}`}>
                    <GoogleLoginButton  />
                  </a>
                </ChatCard>)} />}
          </ChatCards>
        </Center>
  )
}

const fields = {
  companyName: {
    type: "text",
  },
  payrollSystem: {
    type: "select",
    options: payrollSystems
  },
  payDate: {
    label: "When is payroll date?",
    type: "select",
    options: payrollDate
  },
  payDay: {
    type: "select",
    options: [...Array(31).keys()].map(i => i + 1)
  },
  simplePayToken: {
    type: "text"
  },
  simplePayId: {
    type: "text"
  },
  cellNumber: {
    type: "text"
  },
  OTP: {
    type: "fieldarray",
    arrayOfType: "text",
    strip: true
  },
  "bankAccount.bankName": {
    label: "Bank",
    type: "select",
    options: banks
  },
  "bankAccount.accountNo": {
    label: "Account Number",
    type: "text",
    validate: value => value && value.length > 0 ? null : "We need an account number"
  },
  acceptedTerms: {
    type: "boolean"
  }
}

function RegisterForm() {
  return (
    <GraphQLFormProvider
      name="register"
      mode="add"
      initialValues={{
        payrollSystem: "SIMPLE_PAY",
        payDate: "SET_DATE_MOVE_BACK",
        payDay: "25",
        bankAccount: {
          bankName: "Standard Bank"
        }
      }}
      fields={fields}
      addMutation={COMPLETE_REGISTRATION}
      afterSubmit={({ data }) => isRegister() && localStorage.setItem('registerId', data.registerEmployerUser)}
      renderInput={renderInput}
      InputFormLayout={Layout}
      renderLabel={renderLabel}
      keepInitialValues
    />
  )
}

export default RegisterForm