import styled from 'styled-components'

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const ListTitle = styled.h1`
  ${props => props.centerTitle && "text-align: center"}
  font-size: 16px;
  line-height: 16px;
  padding-left: 7px;
`

const ListHeader = styled.div`
  padding: 7px;
  border-bottom: 1px solid lightgrey;
`

const ListContainer = styled.div`
  display: flex;
  justify-self: center;
  flex-direction: column;
  ${props => props.noBackground ? "" : `
    background: white;
    border-radius: 5px;
  `}
  width: calc(100% - 14px);
  margin: auto
  max-width: 650px;
  margin-top: 7px;
  ${props => props.pad && "padding: 7px"}

`

export {
  ListTitle,
  ListHeader,
  ListContainer
}