import React from 'react'
import { TopBarContainer, TopBarLeft, TopBarRight, TopBarTitle } from './TopBar.style'
import { Icon } from '../../generic'

function TopBar({ onLeftPress, leftIcon = "bars", renderRight, onRightPress }) {
  return (
    <TopBarContainer>
      { 
        leftIcon && 
          <TopBarLeft>
            <Icon icon={leftIcon} color="white" size="2x" style={{ marginTop: 3, marginLeft: 3 }} onClick={onLeftPress} />
          </TopBarLeft>
      }
      <TopBarTitle />
      { 
        renderRight && 
          <TopBarRight onClick={onRightPress}>
            {renderRight()}
          </TopBarRight>
      }
    </TopBarContainer>
  )
}

export default TopBar
