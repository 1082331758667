import React from 'react'
import { Moku, Center, ChatCards } from './RegisterForm.style'
import gql from 'graphql-tag'
import { banks, idTypes } from '../../../constants'
import { isValidIdNumber, isValidRsaIdNumber, isValidEmail, isInteger, isDate, isRequired } from '../../../lib/validators'
import { TermsAndConditions, OTP, LoginButtons, RegisterButtons } from '../../shared'
import { ChatFlow } from '../../generic'
import {addMonths, getMonth, getYear} from 'date-fns'
import queryString from 'query-string'

const CLIENT_CHECK = gql`
  query ClientCheck($idNumber: String) {
    checkForClient(idNumber: $idNumber) {
      existingClient
      hasSavings
      employerContributions
      hasUser
      hasExistingCellNumber
      hasExistingEmail
      hasExistingBankAccount
      hasAcceptedTerms
      hasCreditLife,
      hasFuneralPolicy,
      isFuneralPolicyMember
      authProvider
      hasCreditRecord
    }
  }
`

const COMPLETE_REGISTRATION = gql`
  mutation RegisterClient($input: RegisterClientInput) {
    registerClientUser(input: $input)
  }
`

// Adapted from the solution here: http://geekswithblogs.net/willemf/archive/2005/10/30/58561.aspx

const doClientCheck = ({ apolloClient, values, chatState, setChatState, gotoNext, setNextSteps }) => {

  const { idType, idNumber } = values

  console.log('RegisterForm doClientCheck ', idNumber, idType, chatState)

  let nextSteps = ["cellPhone"]

  if (idType === 'rsa_id') {
   if (isValidRsaIdNumber(idNumber) === undefined) {
    console.log('id is south african and is valid ', isValidRsaIdNumber(idNumber))
    apolloClient.query({ query: CLIENT_CHECK, variables: { idNumber } }).then(({ data }) => {
      setChatState({ ...data.checkForClient })

      console.log('RegisterForm client check values are ', data.checkForClient)

      const { hasUser, hasSavings, hasCreditLife , hasFuneralPolicy , isFuneralPolicyMember } = data.checkForClient

      if (hasUser) {
        console.log('RegisterForm is a user of ours ', hasUser)
        nextSteps = ["alreadyRegistered"]
        }
      else {
        // this is a bit silly as it looks as though we always go to "employer" but I'll leave it here in case we wantto get cleverer.
        if (hasSavings) {
          console.log('User has savings already but not registered on portal ', hasSavings)
        }
        else if (hasCreditLife || hasFuneralPolicy || isFuneralPolicyMember) {
          console.log('RegisterForm has a product but first time on portal ', hasCreditLife , hasFuneralPolicy , isFuneralPolicyMember)
        }
        else {
         console.log("RegisterForm We going to the next step...")
        }
      }
      gotoNext(nextSteps)
    })
    } 
    else {
      nextSteps = ["idNumber"]
      gotoNext(nextSteps)
    }
  }
  else {
    console.log('Id Number is not valid ID ')
    gotoNext(nextSteps)
  }
}

const registerSteps = [
{
  name: 'start',
  text: "Welcome to Meerkat",
  nextSteps: ["idNumber"],
  nextAfterDelay:500,
  noNext:true
},
{
  name:"idType",
  text: 'What is your nationality?',
  fieldNames: ["idType"],
  nextSteps: ["idNumber"],
},
{
  name: "idNumber",
  text: "Please enter your Nationality and ID Number",
  fieldNames: ["idType", "idNumber"],
  handleNext: doClientCheck,
  handleNextIfSkipStep: true,
  // skipStep: ({ idNumber, hasCreditLife }) => (idNumber && hasCreditLife !== undefined),
  nextSteps: ["firstTime"],
},
{
  name: "firstTime",
  text: ({ hasCreditLife , hasFuneralPolicy }) => (`${hasFuneralPolicy ? "I see that you have a Meerkat Funeral policy." : hasCreditLife ? "I see that you have a Credit Life Policy." : ""} Since this is your first time here we will need to link your Google or Facebook credentials to speed up future log-ins.`),
  nextSteps: ["cellPhone"],
},
{
  name: "alreadyRegistered",
  text: "You already have a login to the Meerkat Customer Portal so you do not need to register",
  component: LoginButtons,
  noNext: true
},
{
  name: "employer",
  text: ({ existingClient, employerContributions, hasSavings }) => (
    existingClient ? (
      employerContributions ? "Thanks - I see that your employer contributes to your savings or you have a deduction set up with your employer to save with Meerkat. Please can you confirm your current employer"
      : hasSavings ? "Thanks - I see that you have savings with Meerkat. Once we have completed registration, you will be able to view and manage your savings. Who is your current employer?"
      : "Thanks - I see you have an existing product with Meerkat. Meerkat also offers an innovative Savings product. Who is your current employer"
    ) : "Who is your current Employer?"
  ),
  fieldNames: ["currentEmployer"],
  nextSteps: ["income"]
},
{
  name: "income",
  text: "What is your total monthly income?",
  fieldNames: ["income"],
  nextSteps: ["desiredSavings"]
},
{
  name: "desiredSavings",
  text: "How much would you like to save monthy (we suggest 3% of income)?",
  beforeStep: async ({ chatState, changeFieldValue }) => {
    const { income } = chatState
    let recommended = income && income > 0 ? parseFloat(income * 0.03) : 0
    console.log('desired recommended ', income, recommended)
    changeFieldValue('desiredSavings',Math.round(recommended))
  },
  fieldNames: ["desiredSavings"],
  nextSteps: ["payDay"]
},
{
  name: "payDay",
  text: "What day of the month are you paid?",
  fieldNames: ["payDay"],
  nextSteps: ["desiredStartDate"]
},
{
  name: "desiredStartDate",
  text: "When would you like to make your first payment?",
  beforeStep: async ({ chatState, changeFieldValue }) => {
    console.log('desired date chatState is ', chatState)
    const { payDay } = chatState
    const today = new Date()
    const month = getMonth(today)
    const year = getYear(today)
    let payDate = new Date(year,month,payDay)
    let recommended = addMonths(payDate,1)
    console.log('desired start date is ',recommended )
    changeFieldValue('desiredStartDate',recommended)
  },
  fieldNames: ["desiredStartDate"],
  nextSteps: ["cellPhone"]
},
{
  name: "cellPhone",
  text: "What is your cell number?",
  fieldNames: ["cellNumber"],
  skipStep: ({ hasExistingCellNumber }) => hasExistingCellNumber,
  nextSteps: ["OTP"]
},
{
  name: "OTP",
  component: OTP,
  noCard: true,
  nextSteps: ["email"]
},
{
  name: "email",
  text: "What is your email?",
  fieldNames: ["email"],
  skipStep: ({ hasExistingEmail }) => hasExistingEmail,
  nextSteps: ["acceptedTerms"]
},
{
  name: "bankAccount",
  text: "What are your bank account details (we will not debit your account without you signing a mandate)?",
  fieldNames: ["bankAccount.bankName", "bankAccount.accountNo"],
  skipStep: ({ hasExistingBankAccount }) => hasExistingBankAccount,
  labels: true,
  nextSteps: ["acceptedTerms"]
},
{
  name: "acceptedTerms",
  component: TermsAndConditions,
  skipStep: ({ hasAcceptedTerms }) => hasAcceptedTerms,
  noCard: true,
  nextSteps: ({hasAcceptedTerms, acceptedTerms}) => (acceptedTerms || hasAcceptedTerms) ? ["signUp"] : ["pleaseAcceptTerms"]
},
{
  name: "pleaseAcceptTerms",
  text: "I can't proceed unless you accept the terms",
  noNext: true,
  nextAfterDelay:1000,
  nextSteps: ["acceptedTerms"]
},
{
  name: "selfie",
  text: "To be able to verify your identity in future, please can you provide us with a selfie",
  skipStep: ({ hasSelfie }) => hasSelfie,
  fieldNames: ["selfieId"],
  nextSteps: ["register"],
  nextTitle: "Later",
  nextOnValue:true,
},
{
  name: "register",
  submitBeforeStep: true,
  text: "We will shortly send you an SMS with the details you need to set up an EFT. You can take this into your bank (or set up yourself via online banking). You'll be ready to start saving in no time at all.",
  nextSteps: ["signUp"],
},
{
  name: "signUp",
  submitBeforeStep: true,
  text: "Please sign in with your Google or Facebook account to complete registration. After that you will be redirected to your home page.",
  //beforeStep: async () => localStorage.setItem('route', "/savings"),
  component: RegisterButtons,
  noNext: true
}
];

const fields = {
  idNumber: {
    type: "text",
    validate: isValidIdNumber
  },
  idType: {
    type: "select",
    options: idTypes,
  },
  currentEmployer: {
    type: "text",
    query: gql`{
      employers
    }`,
    valuesFromData: data => data.employers
  },
  cellNumber: {
    type: "text"
  },
  OTP: {
    type: "fieldarray",
    arrayOfType: "text",
    strip: true
  },
  email: {
    type: "email",
    label:"Email *",
    validate: [isValidEmail, isRequired]
  },
  "bankAccount.bankName": {
    label: "Bank",
    type: "select",
    options: banks,
    defaultValue:banks[0],
    validate: value => value && value.length > 0 ? null : "Please click on a Bank (above)"
    },
  "bankAccount.accountNo": {
    label: "Account Number",
    type: "text",
    validate: value => value && value.length > 0 ? null : "We need an account number"
  },
  income :{
    type: "currency",
    validate: [isInteger, isRequired]
  },
  payDay :{
    type: "text",
    validate: value => value && isInteger && value > 0  && value < 32 ? null : "Please enter a valid day of the month",
    strip: true
  },
  desiredStartDate :{
    type: "date",
    validate: isDate,
    minDate:new Date()
  },
  desiredSavings :{
    type: "currency",
    validate: [isInteger, isRequired]
  },
  acceptedTerms: {
    type: "boolean"
  },
  selfieId: {
    type: "fileupload"
  }
}

function RegisterForm( {match, location }) {
  const { id } = match.params
  let clientId = id ? id : null
  console.log('RegisterForm client id', id, clientId)
  const { referredBy } = queryString.parse(location.search)

  return (
    <Center>
      <Moku  />
      <ChatCards>
        <ChatFlow
          name="register"
          mode="add"
          steps={registerSteps}
          fields={fields}
          addMutation={COMPLETE_REGISTRATION}
          initialValues={chatState => ({ referredBy, currentEmployer: chatState.currentEmployer, income: chatState.income, cellNumber: chatState.cellNumber, idType: chatState.idType || idTypes[0].value, idNumber: chatState.idNumber, payDay: chatState.payDay || "25"})}
          afterSubmit={({ data }) => { console.log('afterSubmit data=', data); localStorage.setItem('registerId', data.registerClientUser) }}
        />
      </ChatCards>
    </Center>
  )
}

export default RegisterForm