import React, { Fragment } from 'react'
import {LoginButton} from '../LoginButton'
import { Row, Col } from '../../generic/grid'

const RegisterButtons = () => (
  <Row centerVertical>
    <Col centerHorizontal>
      <LoginButton register authProvider="google" />
    </Col>
    <Col centerHorizontal>
      <LoginButton register authProvider="facebook" />
    </Col>
  </Row>
)

export default RegisterButtons
