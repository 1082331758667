import React, { Component } from 'react'
import { EmployerContributionInfo } from '../../../components/employer'

class EmployerContributionDetails extends Component {
  render() {
    const { id } = this.props.match.params
    return (
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", marginTop: "7px" }}>
        <EmployerContributionInfo id={id} />
      </div>
    )
  }
}

export default EmployerContributionDetails