import React, { Component } from 'react'
import TopBar from '../../layout/TopBar'
import { Moku, Center, LoginContainer, Footer, HrLine, HrText} from './Login.style'
import { StyledCardContainer } from '../../generic/Card/Card.style'
import {ButtonContainer} from '../../shared/LoginButtons/LoginButtons.style'
import { LoginButtons, MeerkatButton } from '../../shared'
import Mandate from '../../payments/Mandate'
import { API_URL, API_AUTH_ID, APP_DEFAULT_USER_ID } from '../../../config'
import jwtDecode from 'jwt-decode'

const isUserToken = (token) => {
  const decoded = jwtDecode(token) || {}
  console.log("DECODED IT IS: ", decoded)
  return decoded && decoded.userId !== APP_DEFAULT_USER_ID
}


function LoginRegister({ register, registerEmployer }) {
  return (
    <LoginContainer>
      <Center>
          <Moku />
          <StyledCardContainer>
              <LoginButtons url={register} />
              <HrLine >
                <HrText>New to Meerkat</HrText>
              </HrLine>

              <ButtonContainer>
                <MeerkatButton onClick={register} />
              </ButtonContainer>
            {/* <Button large="true"action="true"onClick={register}>Register For An Account</Button>}
            { <div style={{ height: 14 }} />
  <Button large="true"action="true"onClick={registerEmployer}>Register as an Employer</Button>*/}
          </StyledCardContainer>
      </Center>
      <Footer>
        © Copyright MyMeerkat (PTY) Ltd. All Rights Reserved. Meerkat is an authorized financial services provider (FSP 46535).
      </Footer>
    </LoginContainer>
  )
}

class Login extends Component {
  componentDidMount() {
    let accessToken = localStorage.getItem('accessToken')
    let registerToken = localStorage.getItem('registerToken');
    let routePath = localStorage.getItem('route');
    if (this.props.location && this.props.location.pathname === '/login/callback') {
      console.log('IT IS A CALLBACK')
      const temp = new URLSearchParams(this.props.location.search).get('token')
      const redirect = new URLSearchParams(this.props.location.search).get('redirect')

      if (redirect) {
        routePath = redirect
      }
      
      console.log(temp)
      localStorage.setItem('accessToken', temp)
      localStorage.setItem('registerToken', "false");
      localStorage.removeItem('registerId');
      accessToken = temp
      registerToken = "false"

      if (accessToken && !isUserToken(accessToken)) {
        this.props.history.push(routePath || '/');
        localStorage.setItem('route', "/");  
      }
    }
    if (accessToken && isUserToken(accessToken)) {
      this.props.loggedIn()
      this.props.history.push(routePath || '/');
      localStorage.setItem('route', "/");
    }
  }

  render() {
    return (
      <div>
        <TopBar leftIcon={null} />
        {/* <Mandate clientId={"999999999999"} funeralId={"999999999999"} firstNames={'guy'} surname={'platt'} idNumber={'ADD YOURS HERE'} amount={'2000'} bankAccount={{bank:"FNB", accountNo:"12345678"}}   /> */}
        <LoginRegister register={this.props.register} registerEmployer={this.props.registerEmployer} />
      </div>
    )
  }
}

Login.isUserToken = isUserToken

export default Login
