import React, { createRef, Fragment } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { ShareButtons } from '../../../components/shared'
import gql from 'graphql-tag'
import { ActivityIndicator, Button } from '../../../components/generic'
import { Row, Col } from '../../../components/generic/grid'
import moment from 'moment'
import { printCurrency } from '../../../lib/utils'

import { LINKS_API_URL } from '../../../config'

const DRAW_DAY = 7
const DRAW_TIME = "21:30"

const GET_LOTTERY_TICKETS = gql`{
  myClient {
    id
    firstNames
    surname
    referredClients {
      id
    }
    nextLotteryDrawTickets
    lotteryDrawTickets {
      id
      forDraw
      numberOfTickets
    }
    referralCode
  }
  lotterydraws {
    drawId
    winners {
      clientId
      prizeDescription
      amount
    }
  }
}`

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}




function Lottery() {
  const { data, loading, error } = useQuery(GET_LOTTERY_TICKETS)

  if (loading) { return <ActivityIndicator /> }
  if (error) { return "An error has occurred" }

  const url = `${LINKS_API_URL}/${data.myClient.referralCode || "abc123"}`

  const text = `${data.myClient.firstNames} ${data.myClient.surname} has invited you to start saving with Meerkat. Click to save and receive an entry into our monthly prize draw for every R100 you save (T’s & Cs apply)`
  const title = `${data.myClient.firstNames} ${data.myClient.surname} has sent you an invitiation`

  const nextDrawDate = moment().date(DRAW_DAY).add(1, 'month').format("YYYY-MM-DD")
  const lastDrawDate = moment().date(DRAW_DAY).format("YYYY-MM-DD")

  const numTickets = data.myClient.lotteryDrawTickets.filter(d => d.drawFor === moment(lastDrawDate).format('YYYY-MM')).length

  const prizesWon = []
  data.lotterydraws.forEach(draw => {
    draw.winners.forEach(winner => {
      if (winner.clientId === data.myClient.id) {
        prizesWon.push({
          drawId: draw.drawId,
          prizeDescription: winner.prizeDescription,
          amount: winner.amount
        })
      }
    })
  })

  let comingUpSoon = true
  if (moment(`${lastDrawDate} ${DRAW_TIME}`).valueOf() < moment().valueOf()) {
    comingUpSoon = false
  }

  return  (
    <div style={{display:"flex",textAlign: "center",justifyContent:"center", flexDirection:"column", alignItems:"center", marginLeft:14}}>
    {comingUpSoon ? <p>The draw for last month's savings will take place on {lastDrawDate} at {DRAW_TIME}. You have {numTickets} tickets</p> : <p>Our last prize draw took place on {lastDrawDate}</p>}
    <Button action>View Past Draw Results</Button>
    <p>You have {data.myClient.nextLotteryDrawTickets} entries into next months draw. This draw will be on {nextDrawDate}</p>
    <p>You get extra tickets when either you or a friend you have referred save during the month</p>
    <p>You have referred {data.myClient.referredClients.length} friends in the last 3 months</p>
    <p>Click buttons below to share your personal link with your friends,</p>
    <center><ShareButtons url={url} text={text} title={title}/></center>
    <p>Your link is: <b>{url}</b></p>
    <Button action onClick={() => { copyToClipboard(url); }}>Copy link to Clipboard</Button>
    {prizesWon.length > 0 &&
      <Fragment>
        <p>You have won the following prizes while you have been saving</p>
        <Col width="250px">
          {prizesWon.map(({ drawId, prizeDescription, amount }) => <Row key={drawId}><Col>{drawId}</Col><Col size={2}>{prizeDescription}</Col><Col>{printCurrency(amount, false)}</Col></Row>)}
        </Col>

      </Fragment>
    }
  </div>)
}

export default Lottery