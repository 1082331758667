import styled from 'styled-components'

const LabelContainer = styled.div`
  background-color: ${props => props.color}
  width: fit-content
  margin: auto
  border-radius: 16px
`

const LabelText = styled.span`
  padding: 14px
  line-height: 32px
  color: white
`

export {
  LabelContainer,
  LabelText
}