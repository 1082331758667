import React from 'react'
import { printCurrency, toDecimalPlaces } from '../../../lib/utils'
import moment from 'moment'
import { Icon } from '../../generic'

const renderDisplay = (type) => ({ input, ...props }) => {
  
  console.log('render display props', type, input && input.value)

  const myValue = input ? input.value || props.value : props.value
  // console.log('Render Display with value', myValue)
  if ((myValue === null || myValue === undefined) && type !== "fileupload") {
    return "-"
  }
  if (type === "currency") {
    return printCurrency(myValue)
  }
  if (type === "rate") {
    return myValue ? toDecimalPlaces(myValue,  2) + " %" : "-"
  }
  if (type === "date") {
    // console.log('render display date value ', myValue)
    return moment(myValue).format("Do MMMM YYYY")
  }
  if (type === "fileupload") {
    return <Icon size="1x" icon={myValue ? "check": "times"} color={myValue ? "green": "red"} />
  }
  return myValue
}

export default renderDisplay