import React, { Fragment } from 'react'
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import gql from 'graphql-tag'
// TODO: Combine the two
import { ListContainer, ListHeader, ListTitle, stateColor, PaddedCol } from './EmployerContributionInfo.style'
import { Label } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { renderLabel, renderDisplay } from '../../generic/form'
import { Container } from '../../generic'
import ClientAmountRow from '../ClientAmountRow'

const config = {
  description: {
    label: "Description",
    type: "text"
  },
  totalAmount: {
    label: "Total Contributions",
    type: "currency"
  },
  amountPaid: {
    label: "Paid Amount",
    type: "currency"
  },
  state: {
    type: "label"
  },
  contributions: {
    type: "fieldarray",
    arrayOfType: "object"
  },
  period: {
    label: "Period",
    type: "text"
  },
  unitDate: {
    label: "Date Allocated",
    type: "date"
  }
}

function Layout() {
  return (
    <Fragment>
      <Container title={
        <Row>
          <Col centerVertical>Contribution Information</Col>
          <Col width="150px" alignVertical="top" alignHorizontal="right">
            <div>
              <FieldValue name="state" renderField={({ value: state }) => <Label color={stateColor[state || "PENDING"]}>{state || "PENDING"}</Label>} />
            </div>
          </Col>
        </Row>
      }>
        <PaddedCol>
          <Row>
            <PaddedCol size={2}><FormField name="description" /></PaddedCol>
          </Row>
          <Row>
            <PaddedCol><FormField name="totalAmount" /></PaddedCol>
            <PaddedCol><FormField name="amountPaid" /></PaddedCol>
          </Row>
          <Row>
            <PaddedCol><FormField name="period" /></PaddedCol>
            <PaddedCol><FormField name="unitDate" /></PaddedCol>
          </Row>
        </PaddedCol>
      </Container>
      <Container title={<PaddedCol>Contributions To</PaddedCol>}>
        <FieldValue name="state" renderField={({ value: state }) => (
          <FieldValue name="contributions" renderField={({ input }) => (
            input && (input.value || []).map((c, index) => <ClientAmountRow showEdit={state !== "PAID"} topRow={index === 0} {...c} />)
          )} />
        )} />
      </Container>
    </Fragment>
  )
}

function EmployerContributionInfo({ id }) {
  return (
    <GraphQLFormProvider
      query={gql`query EmployerContributionInfo($id: String!) {
        employercontribution(id: $id) {
          id
          contributions {
						client {
              id
              idNumber
              firstNames
              surname
            }
            amount
          }
    			totalAmount
    			amountPaid
          unitDate
          period
          description
          state
        }
      }`}
      name="employercontribution"
      id={id}
      fields={config}
      mode="display"
      displayOnly
      DisplayFormLayout={Layout}
      renderLabel={renderLabel}
      renderDisplay={renderDisplay}
    />
  )
}

export default EmployerContributionInfo