import styled from 'styled-components'

const Box = styled.div`
  display:flex;
  flex-wrap:wrap;
  height: auto;
  flex-direction:column;
  padding: 14px 0 14px 14px;
  box-sizing: border-box;
  border: 1px solid #267E7F;
  border-radius: 14px;
  margin:0 auto;
  margin-bottom:7px;
`;

const Item = styled.div`
  display: flex;
  height: 24px;
  position: relative;
  font-size:smaller;
`;
const Title = styled.span`
  margin-bottom:7px;
`;
const RadioButtonLabel = styled.label`
  position: absolute;
  top: 0%;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  }
  &:hover ~ ${RadioButtonLabel}{
    background: #bebebe;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 5px;
      background: #eeeeee;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: #db7290;
    border: 1px solid #267E7F;
    Z-index: 3;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 5px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: #db7290;
    border: 1px solid white;
    Z-index: 4;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 5px;
    }
  }
`

export {
  Box,
  Item,
  RadioButton,
  RadioButtonLabel,
  Title
}