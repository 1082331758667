//
import React, { Fragment, useState } from 'react'
import FileInput from '../FileInput'
import axios from 'axios'
import { BarFillStripes, StripedBar, UploadContainer } from './FileUpload.style'
import CameraInput from '../CameraInput'
import { API_URL } from '../../../config'
import { Icon } from '../../../components/generic'
import styles from './progress.css'


const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
// TODO: Upload progress


function FileUpload({ input, prompt, renderFileInput }) {
  const [progress, setProgress] = useState("0%")

  const  uploadDocumentRequest = (e, v) => {

    if (e.target.files[0]) {
      let data = new FormData();
      const { name } = e.target.files[0]
      data.append('name', name);
      data.append('file', e.target.files[0])

      const config = {
        method: "POST",
        headers: { 'Authorization': `JWT ${localStorage.getItem('accessToken') || "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI1OWQyNjUzNmMzNzBjM2E2YjIzMjc5YjQiLCJ1dWlkIjoiYWIxOTk2YzctMGViOC00ZjAyLWI0NTUtZWI2MzU5NTljNTdmIiwiZW1wbG95ZWVJZCI6IjVhNTc5NDg1MDM5YzY5YmJkYTA3YWRhNyIsInJvbGVzIjpbIk1BTkFHRVIiLCJHUkFQSElRTCIsIkVNUExPWUVSIiwiQ0xJRU5UIl19.R2EeCjz2JbZyiHOdKKGv__xcBOTOTUXwm22TW7P9KGY"}`},
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgress(percentCompleted +"%" )
        }
      }

      axios.put(`${API_URL}upload`,data, config)
        .then(result => {
          console.log('file upload result ', result)
          input && input.onChange && input.onChange(result.data[0].id)
        })
        .catch(console.error)
    }
  }

  if (renderFileInput) {
    const MyFileInput = renderFileInput
    return <MyFileInput onChange={uploadDocumentRequest} />
  } else {

    return (
      <UploadContainer>
        <FileInput onChange={uploadDocumentRequest} prompt={prompt} />
        {isMobileDevice() && <CameraInput onChange={uploadDocumentRequest} />}
        {console.log('progress is ',progress)}
        <BarFillStripes progress={progress}/>
      </UploadContainer>
    )
  }
}

export default FileUpload
