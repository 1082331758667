import React, { Fragment } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { PaymentList } from '../../../components/payments'
import gql from 'graphql-tag'
import { ActivityIndicator, Button, Container } from '../../../components/generic'
import { Col, Row } from '../../../components/generic/grid'


const GET_SAVINGS_PAYMENTS = gql`{
  myClient {
    id
    savings {
      id
      payments {
        amount
        fromEmployer
        timestamp
      }
    }
  }
}`

function MyPayments({ history }) {
  const { data, loading, error } = useQuery(GET_SAVINGS_PAYMENTS)

  if (loading) { return <ActivityIndicator /> }
  if (error) { return "An error has occurred" }

  return <Fragment>
    <PaymentList payments={data.myClient.savings.payments.map(payment => ({
      amount: payment.amount,
      fromEmployer: payment.fromEmployer,
      date: payment.timestamp
    }))} />
    <Container noBackground>
    <Row>
    <Col centerHorizontal><Button action onClick={() => history.push('/')}>Home</Button></Col>
    <Col centerHorizontal><Button action onClick={() => history.push('/savings')}>My Savings</Button></Col>
    </Row>
    </Container>
  </Fragment>
}

export default MyPayments