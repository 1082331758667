import React from 'react'
import { CardContainer, CardTitle, CardBody } from './Card.style'

function Card({ title, children }) {
  return (
    <CardContainer>
      {title && <CardTitle>{title}</CardTitle>}
      <CardBody>
        {children}
      </CardBody>
    </CardContainer>
  )
}

export default Card