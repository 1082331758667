import styled from 'styled-components'
import { Col } from '../../grid'

const PaddedCol = styled(Col)`
  padding: 7px
`

export {
  PaddedCol
}
