import styled from 'styled-components'
import Icon from '../Icon'

const spinnerSizes = {
  small: "20px",
  normal: "35px",
  large: "50px"
}

const StyledSpinner = styled(Icon).attrs({
  icon: "spinner",
  spin: true
})`
  font-size: ${props => props.small ? spinnerSizes.small : props.large ? spinnerSizes.large : spinnerSizes.normal}
  color: ${props => props.color || "grey"}
`

const StyledSpinnerContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`

export {
  StyledSpinner,
  StyledSpinnerContainer
}
