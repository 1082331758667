import React from 'react';
import RRS from 'react-responsive-select';
import { CaretIcon, SelectContainer } from './Select.style'

const Select = (props) => {
  let { value, options, onChange, onBlur, defaultValue, noSelectionLabel = null, ...selectProps } = props
  console.log('Select props are', props, value, options[0])
  if (!value || value == "") { value = options[0].value }
  
  return (
    <SelectContainer>
      <RRS {...selectProps} selectedValue={value} onChange={v => onChange && onChange(v.value)} noSelectionLabel={noSelectionLabel} onBlur={v => onBlur && onBlur(v.value)} options={options} caretIcon={<CaretIcon />} />
    </SelectContainer>
  )
}

export default Select