import React, { useState } from 'react'
import { Moku, Center, ChatCards, LoginContainer } from '../../profile/RegisterForm/RegisterForm.style'
import { ChatFlow, Modal} from '../../generic'
import {getDOBFromID, forMemberTypes, isValidIdNumber, isValidEmail, isValidCellphoneNumber, isValidAge, isValidDay, doesDOBMatchIdNumber, doesAgeAtSignupMatchDOB, doesAgeAtSignupMatchId, isRequired} from '../../../lib/validators'
import schema from './schema'
import FuneralQuote from '../FuneralQuote'
import gql from 'graphql-tag'
import { TermsAndConditions } from '../../shared'
import { Mandate } from '../../payments'
import  { banks, idTypes, membertypes } from '../../../constants'
import moment from 'moment'

const MAX_CHILDREN = 5

const CREATE_POLICY = gql`
  mutation CreateSalesInterationPolicyWithMembers($funeral: CreateFuneralInput!, $bankAccount: CreateBankAccountInput, $members: [CreateMemberInput!], $salesInteraction: CreateSalesInteractionInput!) {
    createSalesInteractionPolicyWithMembers(funeral: $funeral, bankAccount: $bankAccount, members: $members, salesInteraction: $salesInteraction) {
      payAtNumber
      policyNumber
      paymentBankAccountId
      id
    }
  }
`

const salesInteractionMutation = gql`
  mutation CreateSalesInteraction($input: CreateSalesInteractionInput!) {
    createSalesInteraction(input: $input) {
      id
    }
  }
`

const sendTerms = gql`
  mutation SendTerms($input: CreateClientInput!) {
    sendTerms(input: $input)
  }
`

const sanitizeMembers = (members) => {
  console.log('sanitizeMembers ', members)
  const coveredMembers = members.filter(d => d.memberType).map(member => member.idNumber ? 
      ({ ...member, DOB: moment(getDOBFromID(member.idNumber)).valueOf() }) : 
      ({ ...member, DOB: member.DOB && moment(member.DOB).valueOf() }))
  
  console.log('sanitizeMembers after ', coveredMembers)

  coveredMembers.forEach(member => {
   
    if (member.relationship) {
      if (member.relationship.includes('MOTHER')) {
        member.gender = "FEMALE"
      }
      else {
        member.gender = "MALE"
      }
    }

    if (member.hasOwnProperty('isCovered')) {
      delete member.isCovered
    }
    if (member.hasOwnProperty('isActive')) {
      delete member.isActive
    }
  })

  console.log('sanitizeMembers after ', coveredMembers)
  return coveredMembers
}

// TODO: Put this in a separate folder
function SignDebitOrderMandate({ gotoNext, chatState }) {
  const [showModal, setShowModal] = useState(true)

  const saleCompleted = () => {
    setShowModal(false);
    gotoNext(["congratulations"])
  }

  if (chatState.bankAccount.ownAccount === "N" && showModal) {
    saleCompleted()
    return <div />
  }

  return (
    <Modal
      centered
      width='98%'
      backgroundColor= 'rgb(242, 246, 252)'
      visible={showModal}
      hideOk
      extraButtonsTop={true}
      hideCancel
    >
      <Mandate.ShowMandate id={chatState.bankAccountId} onMandateSign={saleCompleted} />
    </Modal>
  )
}

function stepsFromSchema() {
  const quizSchema = schema.quizSchema()
  console.log(quizSchema)
  const steps = quizSchema.map((step, n) => ({
    name: `step${n+1}`,
    text: step.question,
    fieldNames: step.elements.map(e => e.name),
    nextSteps: n+1 <= quizSchema.length && [`step${n+2}`],
    nextOnValue: step.elements[0].type === "yesno" || step.elements[0].type === "options",
    skipStep: (data) => step.showCondition && !(step.showCondition(data)),
    noNext: step.elements[0].type === "yesno" || step.elements[0].type === "options"
  })).concat([,
    {
      name: `step${quizSchema.length+1}`,
      fieldNames:["income"],
      nextSteps: ["doQuote"],
      skipStep: ({ income }) => income,
    },
    {
    name: "doQuote",
    component: FuneralQuote,
    noNext: true,
    },
    {
    name: "noThanks",
    text: "Thanks for talking to us about funeral insurance. Visit Moku anytime if you would like to take us up on this product",
    noNext: true
  },
  {
    name: "acceptTerms",
    component: TermsAndConditions,
    nextSteps: ["getIncome"],
    handleNext: ({ apolloClient, setSubmitting, gotoNext }) => {
      setSubmitting(true);
      //apolloClient.mutate({ })
      console.log('Handling Next in Terms');
      setTimeout(() => { setSubmitting(false); gotoNext() }, 2000)
    },
    skipStep: ({ hasAcceptedTerms,acceptedTerms }) => hasAcceptedTerms || acceptedTerms,
    noNext: true,
    noCard: true
  },
  {
    name: "getIncome",
    fieldNames:["income"],
    nextSteps: ["doIdCard"],
    skipStep: ({ income }) => income,
  },
  {
    name: "mainMember",
    beforeStep: ({ changeFieldValue, chatState }) => {
      changeFieldValue("members[0].memberType", "POLICY_HOLDER")
      changeFieldValue("members[0].idNumber", chatState.idNumber)
      changeFieldValue("members[0].idType", chatState.idType)
      changeFieldValue("members[0].email", chatState.email)
      changeFieldValue("members[0].ageAtSignup", chatState.age)
      changeFieldValue("members[0].firstNames", chatState.firstName)
      changeFieldValue("members[0].firstNames", chatState.surname)
      changeFieldValue("members[0].cellNumbers", chatState.cellNumber)
      changeFieldValue("members[0].gender", chatState.gender)
    },
    text: "Please can we get your details",
    fieldNames: ['members[0].firstNames', 'members[0].surname', 'members[0].ageAtSignup', 'members[0].cellNumbers', 'members[0].email', 'members[0].DOB', 'members[0].idType', 'members[0].idNumber'],
    labels: true,
    //skipStep: ({ loggedIn }) => loggedIn,
    nextSteps: ["spouse"]
  }, {
    name: "spouse",
    beforeStep: ({ changeFieldValue, chatState }) => {
      const {spouse} = chatState
    console.log('spouse is ',spouse)
      changeFieldValue("members[1].memberType", "SPOUSE")
      changeFieldValue("members[1].ageAtSignup", chatState.ageSpouse)
    },
    text: "Please can we get your spouse's details",
    fieldNames: ['members[1].firstNames', 'members[1].surname', 'members[1].ageAtSignup', 'members[1].cellNumbers', 'members[1].email', 'members[1].DOB','members[1].idType', 'members[1].idNumber'],
    skipStep: ({ spouse }) => spouse === "N",
    labels: true,
    nextSteps: ["addChild1"]
  },
  // Add in the steps for 5 children
  ...Array.from(Array(MAX_CHILDREN), (_, index) => ({
    name: `addChild${index + 1}`,
    beforeStep: ({ changeFieldValue }) => {
      changeFieldValue(`members[${index + 2}].memberType`, "CHILD")
    },
    labels: true,
    skipStep: ({ children }) => children === "N",
    text: index === 0 ? `Your policy allows up to 5 covered children. Please give the details of child ${index + 1}` : `Please give the details of child ${index + 1}`,
    fieldNames: [`members[${2+index}].firstNames`, `members[${2+index}].surname`, `members[${2+index}].ageAtSignup`, `members[${2+index}].DOB`, `members[${2+index}].idType`, `members[${2+index}].idNumber`],
    nextButtons: [{ label: "Add Another", nextSteps: [`addChild${index + 2}`] }, { label: "Done", nextSteps: ["ownAccount"] }],
    nextSteps: ["beneficiarySpouse"]
  })),
  {
    name: "beneficiarySpouse",
    skipStep: ({ spouse }) => spouse === "N",
    text: "Is your spouse the beneficiary?",
    fieldNames: ["beneficiary.isSpouse"],
    labels: false,
    noNext: true,
    nextSteps: ({ beneficiary }) => beneficiary && beneficiary.isSpouse === "Y" ? ["ownAccount"] : ["beneficiaryInfo"],
  },
  {
    name: "beneficiaryInfo",
    text: "Who is the beneficiary?",
    fieldNames: ["beneficiary.firstNames", "beneficiary.surname", "beneficiary.idNumber", "beneficiary.cellNumbers", "beneficiary.email", "beneficiary.bankName", "beneficiary.accountNo"],
    labels: true,
    nextSteps: ["ownAccount"]
  },
  {
    name: "ownAccount",
    text: "Will you be paying the account?",
    noNext: true,
    fieldNames: ["bankAccount.ownAccount"],
    nextOnValue: true,
    nextSteps: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? ["bankAccountInfo"] : ["accountHolderInfo"]
  },
  {
    name: "accountHolderInfo",
    text: "Please can we have the information for the account holder",
    fieldNames: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderEmail", "bankAccount.accountHolderIdNumber"],
    labels: true,
    nextSteps: ["bankAccountInfo"]
  },
  {
    name: "bankAccountInfo",
    text: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? "Your bank account info" : "The Bank Account of the Premium Payer",
    fieldNames: ["bankAccount.bankName", "bankAccount.accountNo", "funeral.paymentDueDay"],
    labels: true,
    nextSteps: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? ["completeSale"] : ["doMandateSent"],
  },

  {
    name: "completeSale",
    beforeStep: async ({ apolloClient, chatState, setChatState }) => {
      console.log('Completing sale ...', chatState.bankAccount )
      console.log('Completing sale members...', chatState.members )
      const { data } = await apolloClient.mutate({ mutation: CREATE_POLICY, variables: {
        funeral: { paymentMethod:"NAEDO", coverAmount: chatState.coverAmount, ...chatState.funeral },
        bankAccount: chatState.bankAccount ? { ...chatState.bankAccount, bankName: chatState.bankAccount.bankName ?chatState.bankAccount.bankName :"Capitec Bank", ownAccount: chatState.bankAccount.ownAccount === "Y" } : null,
        members: chatState.members && sanitizeMembers(chatState.members),
        salesInteraction: {}
      } });
      console.log(data)
      if (data) {
        setChatState({ policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber, bankAccountId: data.createSalesInteractionPolicyWithMembers.paymentBankAccountId })
      }
    },
    component: SignDebitOrderMandate,
    noCard: true,
    noNext: true,
    nextSteps: ["congratulations"],
    // DO Mandate
  }, {
    name: "doMandateSent",
    text: "We have sent the debit order mandate to the account holder to sign. Once that has been signed, your policy will be issued and cover will be active once the first payment has been received",
    nextSteps: ["completeSale"]
  },
  {
    name: "doIdCard",
    text: "Please can you upload a copy of your ID?",
    fieldNames: ["idCardId"],
    nextOnValue:true,
    nextTitle:"Later",
    skipStep: ({ idCardId }) => idCardId,
    nextSteps: ["doSelfie"]
  },
  {
    name: "doSelfie",
    text: 'Please can you upload a "selfie" of yourself',
    fieldNames: ["selfieId"],
    nextTitle:"Later",
    nextOnValue:true,
    skipStep: ({ selfieId }) => selfieId,
    nextSteps: ["mainMember"]
  },
   {
    name: "congratulations",
    text: ({ policyNumber }) => `Congratulations on your funeral insurance purchase. Your cover will be active as soon as the first payment in received. Your policy number is ${policyNumber}`,
    noNext: true
  }])
  console.log(steps)
  return steps
}


function fieldsFromSchema() {
  const quizSchema = schema.quizSchema()

  const fields = {
    coverAmount: {
      type: 'optionselector',
      options: FuneralQuote.QUOTE_AMOUNTS
    },
    members: {
      type: 'fieldarray',
      config:
      {
        firstNames: { type: 'text', label: "First Name",
          validate: [isRequired] },
        surname: { type: 'text', label: "Last Name",
          validate: [isRequired] },
        ageAtSignup: { type: 'text', label: "Age",
          validate: [ forMemberTypes(["PARENT"],isRequired), isValidAge, doesAgeAtSignupMatchId, doesAgeAtSignupMatchDOB] },
        email: { type: 'email', label: 'Email', validate: [forMemberTypes(["POLICY_HOLDER","SPOUSE"],isRequired), isValidEmail] },
        DOB: { type: 'text', label: 'Birth Date (YYYY-MM-DD)', assesor: row => moment(row.value).format('YYYY-MM-DD'),
          validate: [forMemberTypes(["CHILD"],isRequired), isValidAge, doesDOBMatchIdNumber] },
        idNumber: { type: 'number',label: "ID Number", maxLength: 13,
          validate:[forMemberTypes(["POLICY_HOLDER"],isRequired), isValidIdNumber] },
        idType: { type: "select",options: idTypes,label: "Identity Type",
          validate:[forMemberTypes(["POLICY_HOLDER"],isRequired)] },
        memberType: { type: "select",options: membertypes,label: "Member Type"},
        cellNumbers: { type: 'number', label: "Cell Number", maxLength: 10,
          validate: [forMemberTypes(["POLICY_HOLDER","SPOUSE"],isRequired), isValidCellphoneNumber] },
      }
    },
    beneficiary: {
      isSpouse:{type: 'yesno', label: "Is your spouse the beneficiary" },
      firstNames: { type: 'text', label: "Beneficiary First Name ", validate: [isRequired] },
      surname: { type: 'text', label: "Beneficiary Last Name *", validate: [isRequired] },
      cellNumbers: { type: 'number', label: "Beneficiary Cell Number *", maxLength: 10, validate: [isRequired, isValidCellphoneNumber] },
      email: { type: 'email', label: 'Beneficiary Email', validate: [isValidEmail] },
      idNumber: { type: 'text',label: "Beneficiary ID Number", maxLength: 13,validate:[isValidIdNumber] },
      idType: { type: "select",options: idTypes,label: "Beneficiary Identity Type"},
      bankName: { type: 'select', options: banks, label: 'Beneficiary Bank' },
      accountNo: { type: 'text', label: 'Beneficiary Account Number' }
    },
    bankAccount: {
      bankName: { type: 'select', options: banks, label: 'Bank' },
      accountNo: { type: 'text', label: 'Account Number' },
      ownAccount:{ type: 'yesno', label: "Policy holder's account" },
      accountHolderName: { type: 'text', label: 'Premium Payer Name' },
      accountHolderContactNumber: { type: 'text', label: 'Contact Number'},
      accountHolderEmail: { type: 'text', label: 'Contact Email' },
      accountHolderIdNumber: { type: 'text', label: 'Id Number' },
    },
    funeral: {
      paymentDueDay: {
        type: 'number',
        keyboard: 'numeric',
        maxLength: 2, label: 'Debit Order Day'
      },
    },
    idCardId: {
      type: "fileupload"
    },
    selfieId: {
      type: "fileupload"
    }
  }

  quizSchema.forEach((step) => {
    step.elements.forEach(e => {
      const { name, ...fieldConfig } = e
      fields[name] = fieldConfig
    })
  })
  return fields
}

const newFuneralSteps = stepsFromSchema(schema)
const fields = fieldsFromSchema(schema)

function NewFuneral() {
  // if logged in then get the client details
  console.log('new funeral')

  return (
      <Center>
        <Moku />
        <ChatCards>
          <ChatFlow
            name="newFuneral"
            steps={newFuneralSteps}
            fields={fields}
            initialValues={chatState => ({ loggedIn:true, firstName:chatState.firstName, idType:chatState.idType, cellNumber:chatState.cellNumber, gender:chatState.gender, ageSpouse:chatState.ageSpouse, age:chatState.age, income:chatState.income, acceptedTerms:chatState.acceptedTerms, hasAcceptedTerms:chatState.hasAcceptedTerms, "beneficiary.isSpouse":"","bankAccount.bankName":"Capitec Bank", coverAmount: 30000, members:chatState.members })}
          />
        </ChatCards>
      </Center>
  )
}

export default NewFuneral