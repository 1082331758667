import React, {Fragment} from 'react'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo-hooks'
import { withRouter } from 'react-router-dom'
import { ActivityIndicator } from '../../../components/generic'
import { DebtSummary } from '../../../components/debt'

const GET_DEBTS = gql`
  {
    myClient {
      id
      firstNames
      surname
      idNumber
      photo {
        id
        url
      }
      creditRecord {
        fetchedAt
        pdf {
          id
          url
        }
        riskType
        compuScore
        monthlyInstallment
        riskColour
        thinFile
      }    
      creditorObligations {
        id 
        clientCreditor {
          description
        }
        currentPayment {
          installment
          rate
        }
        currentBalance
        accountTypeDescription
        statusCodeDescription
        remainingTerm
      }
    }
  }
`

function Debt({ match, history }) {
    const { data , loading, error } = useQuery(GET_DEBTS)
    if (loading ) { return <ActivityIndicator large/> }
    if (error) { console.log(error); return "An error has occurred" }

    const { myClient } = data
    console.log('My debt client is ', myClient)

    return <DebtSummary creditReport={myClient.creditRecord} history={history} obligations={myClient.creditorObligations.filter(t => t.statusCodeDescription === "OPEN/CURRENT"  && t.currentBalance > 0)} gotoDetails={id => history.push(`/debt/${id}`)} getCreditReport={() => history.push('/credit')} />
}

export default Debt