import React from 'react'
import styled from 'styled-components'
import { MonthCircle, RowContainer } from '../../employer/EmployerContributionRow/EmployerContributionRow.style'
import theme from '../../../theme.style'
import { Icon } from '../../generic'

const ClientCircle = styled(MonthCircle)``

const colorChanges = [30, 70]

const getFillColor = (percent) => {
  if (percent < colorChanges[0]) {
    return theme.colors.status.bad
  }
  if (percent < colorChanges[1]) {
    return theme.colors.status.average
  }
  return theme.colors.status.good
}

const getSmiley = (percent) => {
  if (percent < colorChanges[0]) {
    return "sad-tear"
  }
  if (percent < colorChanges[1]) {
    return "meh"
  }
  return "smile"
}

const getSmileyProps = (percent) => {
  const  color = getFillColor(percent)
  const  icon = getSmiley(percent)
  return { color, icon }
}

const ProgressBarContainer = styled.div`
  display: flex
  border: 1px solid #a8a8a8;
  width: 100%;
  margin-right: 0px;
  flexGrow: 1;
  height: 16px;
  border-radius: 6px;
`

const BarFill = styled.div`
  height: 14px;
  background-color: ${props => getFillColor(props.percentageFull)};
  width: ${props => (props.percentageFull || 0)}%;
  margin: 1px;;
  cursor: pointer;
  border-radius: 5px;
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background-color: ${({ accountType }) => accountTypeConfig[accountType || ""].color}
`

const IconCircle = (props) => (
  <Circle {...props}>
    <Icon style={{ fontSize: "20px" }} {...getAccountTypeProps(props.accountType)} />
  </Circle>
)

const ProgressBar = ({ className, ...props }) => (
  <ProgressBarContainer className={className} {...props}>
    <BarFill {...props} />
  </ProgressBarContainer>
)

const DebtBalance = styled.span`
  font-size: ${props => props.large ? "16px" : props.small ? "10px" : "12px"}
  ${props => props.small && "font-weight: 600; padding-bottom: 3px"}
  margin-right: 7px;
  ${props => !props.large && "color: #787878;"}
`

const AccountTypeText = styled.span`
  color: #787878;
  font-size: 13px;
`

const InterestRateText = styled.span`
  margin-left: 7px;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 7px;
  padding-right: 7px;
  background: ${props => props.color || "red"}
  color: white;
  font-size: 10px;
  border-radius: 14px;
`

const getRateColor = (rate) => {
  if (rate < 10) {
    return theme.colors.status.good
  }
  if (rate < 14) {
    return theme.colors.status.average
  }
  return theme.colors.status.bad
}

const InterestRateBadge = ({ rate }) => rate ? (
  <InterestRateText color={getRateColor(rate)}>
    {Math.floor(rate * 100) / 100.0} %
  </InterestRateText>
) : <div />

const getAccountTypeProps = (accountType) => ({ ...accountTypeConfig[accountType || ""], color: "white" })

const accountTypeConfig = {
    "Revolving Credit Account" : { icon: "credit-card", color: theme.colors.status.bad },
    "Term Loan direct collection" : { icon: "university", color: theme.colors.status.bad },
    "Short Term Loan" : { icon: "university", color: theme.colors.status.bad },
    "Instalment" : { icon: "file-invoice-dollar", color: theme.colors.status.bad },
    "Short Term Insurance" : { icon: "umbrella", color: theme.colors.status.good },
    "Long Term Insurance" : { icon: "umbrella", color: theme.colors.status.good },
    "Personal Cash Loans" : { icon: "money-bill-alt", color: theme.colors.status.bad },
    "Credit Card Account" :  { icon: "credit-card", color: theme.colors.status.bad },
    "Student Loan" : { icon: "graduation-cap", color: theme.colors.status.average },
    "Service Related without limit" : { icon: "file-invoice-dollar", color: theme.colors.status.bad },
    "Debt Recovery Account" : { icon: "file-invoice-dollar", color: theme.colors.status.bad },
    "Home Loans" : { icon: "home", color: theme.colors.status.good },
    "" : { icon: "file-invoice-dollar", color: theme.colors.status.bad },
    "Unsecured Loan under R8000" : { icon: "university", color: theme.colors.status.bad },
    "Building Loan" : { icon: "building", color: theme.colors.status.good },
    "Open Acc without Cr limit" :{ icon: "file-invoice-dollar", color: theme.colors.status.bad },
    "Pension Backed Lending" : { icon: "university", color: theme.colors.status.bad },
    "Term Loan payroll based" : { icon: "university", color: theme.colors.status.bad },
    "Single Credit Facility" : { icon: "credit-card", color: theme.colors.status.bad },
    "Garage Card" : { icon: "credit-card", color: theme.colors.status.bad }
}

export {
  ClientCircle,
  RowContainer,
  ProgressBar,
  getFillColor,
  getSmileyProps,
  getAccountTypeProps,
  IconCircle,
  DebtBalance,
  AccountTypeText,
  InterestRateBadge
}
