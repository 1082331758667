import React, { Component } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator } from '../../../components/generic'
import { EmployerContributionList } from '../../../components/employer'

const GET_EMPLOYER_CONTRIBUTIONS = gql`
  query GetEmployeContributions($id: String!) {
    employer(id: $id) {
      contributions {
        id
        period
        totalAmount
        amountPaid
        employees
        description
      }
    }
  }
`

class EmployerContributions extends Component {
  render() {
    const { history, match } = this.props
    const { id } = match.params

    return (
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", marginTop: "7px" }}>
        <Query query={GET_EMPLOYER_CONTRIBUTIONS} variables={{ id }}>
          {
            ({ loading, error, data }) => {
              if (loading) {
                return <ActivityIndicator large="true"/>

              }
              if (error) {
                return "An error occurred"
              }
              return <EmployerContributionList contributions={data.employer.contributions || []} onRowPress={id => history.push(`/employer/contribution/${id}`)} />
            }
          }
        </Query>
      </div>
    )
  }
}

export default EmployerContributions