import React, {Fragment, useState } from 'react'
import { MyProfileContainer, MyProfileImage, ButtonContainer } from './MyProfile.style'

import { Container, Button, ActivityIndicator } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { GraphQLFormProvider, FormField, FieldValue, SubmitButton } from 'react-form-helper'
import { renderInput, renderDisplay, renderLabel } from '../../generic/form'
import gql from 'graphql-tag'
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';


const GET_MY_PROFILE = gql`{
  myClient {
    id
    title
    firstNames
    surname
    idNumber
    nickName
    gender
    photo {
      id
      url
    }
    selfieId
    selfie {
      id
      url
    }
    addresses {
      type
      street
      suburb
      city
      zip
    }
    email
    cellNumbers
  }
}`

const UPDATE_MY_PROFILE = gql`
  mutation UpdateProfile($input: UpdateClientInput) {
    updateMyProfile(input: $input) {
      id
      title
      firstNames
      surname
      idNumber
      nickName
      gender
      addresses {
        type
        street
        suburb
        city
        zip
      }
      selfieId
      selfie {
        id
        url
      }
      email
      cellNumbers
    }
  }
`

const fields = {
  selfie: {
    strip: true
  },
  photo: {
    strip: true
  },
  selfieId: {
    label: "Selfie",
    type: "fileupload"
  },
  firstNames: {
    label: "Name",
    type: "text"
  },
  surname: {
    label: "Surname",
    type: "text"
  },
  nickName: {
    label: "Nick Name",
    type: "text"
  },
  gender: {
    label: "Gender",
    type: "select",
    options: ["MALE", "FEMALE"]
  },
  idNumber: {
    label: "ID Number",
    type: "text",
    strip: true,
    displayOnly: true,
    noAdd: true
  },
  /*cellNumbers: {
    label: "Cell Numbers",
    type: "fieldarray",
    arrayOfType: "text"
  },
  addresses: {
    label: "Address Information",
    type: "fieldarray",
    config: {
      type: {
        label: "Address Type",
        type: "select",
        options: ["STREET", "POSTAL", "STREET_AND_POSTAL"]
      },
      street: {
        label: "Street",
        type: "text"
      },
      suburb: {
        label: "Suburb",
        type: "text"
      },
      city: {
        label: "City",
        type: "text"
      },
      zip: {
        label: "Postal Code",
        type: "text"
      }
    }
  },*/
  email: {
    label: "Email",
    type: "text",
  }
}

const buildRowsandColumns = (fieldNames, mode) => {
  // console.log('profile form fields are ', fieldNames)
  var rows = []
  var cols = []

  // NOTE: the value of the span * rowCount, must be 24
  var span = 4
  var rowCount = 1
  var fieldCount = 1

  fieldNames.forEach((field, index) => {

    // if field not on our ignore list then add to screen
    if (['firstPolicySold'].indexOf(field) < 0) {
      cols.push(
      <Col span={span} style={{ display: 'block' }}>
        <FormField mode={mode} key={index} name={field} />
      </Col>
    )

    if (((fieldCount) % rowCount) == 0 || index === fieldNames.length - 1) {
    rows.push(
      <Row gutter={8} > {cols}</Row>)
      cols = []
    }
      fieldCount++
    }
  })

  return rows

}

function Layout({mode, setMode, data}) {
  // console.log('profile.data',data.myClient)
  let url = (data.myClient.selfie && data.myClient.selfie.url)
  
  return (
    <MyProfileContainer>
    <MyProfileImage url={url} />
    
    
    <center>
    {!data.myClient.selfie && <SubmitFormButton renderSubmitButton={({ submit, submitting }) => {
      const [uploaded, setUploaded] = useState(false)
      if (uploaded && !submitting) {
        return "File Uploaded"
      }
      if (uploaded) {
        return <ActivityIndicator />
      }
      return <FormField mode="edit" name="selfieId" inputProps={{ onChange: (id) => { setTimeout(() =>  { submit(); setUploaded(true) }, 100) } }} />
    }} />}
    </center>
    <Container>
      <center>
        {buildRowsandColumns(["firstNames","surname","nickName","gender","email"], mode) }
        <ButtonContainer>
        {mode === 'edit' ? <SubmitFormButton renderSubmitButton={({ submit, submitting }) => (
          <Button action="true" submitting={submitting} onClick={() => submit()}>Save</Button>
        )} />
        : <Button action="true"onClick={() => setMode('edit')}>Edit</Button>
        }
        </ButtonContainer>
      </center>
      </Container>
  </MyProfileContainer>
  )
}

const MyProfile = ({history}) => (
  <GraphQLFormProvider
    name="profile"
    query={GET_MY_PROFILE}
    updateMutation={UPDATE_MY_PROFILE}
    mode="display"
    fields={fields}
    renderInput={renderInput}
    renderDisplay={renderDisplay}
    renderLabel={renderLabel}
    InputFormLayout={Layout}
    DisplayFormLayout={Layout}
    displayOnSave
    initialValuesFromData={data => data.myClient}
  />
)

export default MyProfile