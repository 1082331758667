import React, { Component } from 'react'
import TopBar from '../../components/layout/TopBar'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator } from '../../components/generic'


const START_REGISTRATION = gql`
  mutation {
    startClientRegistration
  }
`

// This gets us a token to complete the registration process securely
class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    this.props.mutate().then(({ data }) => {
      localStorage.setItem('accessToken', data.startClientRegistration);
      localStorage.setItem('registerToken', "true");
      this.setState({ loading: false })
    });
  }

  componentWillUnmount() {
    localStorage.setItem('registerToken', "false");
  }

  render() {
    const { loading } = this.state
    const { Content, match } = this.props

    if (loading) {
      return <ActivityIndicator />
    } else {
      return (
        <div>
          <TopBar leftIcon={null} />
          <Content {...this.props} />
        </div>
      )
    }
  }
}

export default graphql(START_REGISTRATION)(Register)
