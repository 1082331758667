import React, { Fragment } from 'react'
import { Row, Col } from '../../generic/grid'
import { DrawField } from '../../generic/form'
import { Container } from '../../generic'
import DebtPaymentHistory from '../DebtPaymentHistory'
import AmortizationGraph from '../AmortizationGraph'

const formatName = (text) => text && text.split(" ").map(t => t && t[0] && (t[0].toUpperCase() + t.substring(1).toLowerCase())).join(" ")

function DebtItemDetails({ obligation }) {
  const { clientCreditor, currentBalance, originalBalance, currentPayment, remainingTerm, terms, inceptionDate, lastPaymentDate, accountTypeDescription, fetchedAt, paymentHistory, paymentTypeDescription } = obligation || {}
  const { installment, rate } = currentPayment

  return (
    <Fragment>
      <Container title={formatName(clientCreditor.description)} centerTitle>
        <Col pad>
          <Row>
            <DrawField label="Opening Balance" value={originalBalance} type="currency" />
            <DrawField label="Inception Date" value={inceptionDate} type="date" />
          </Row>
          <Row>
            <DrawField label="Current Balance" value={currentBalance} type="currency" />
            <DrawField label="Interest Rate" value={rate} type="rate" />
          </Row>
          <Row>
            <DrawField label="Monthly Installment" value={installment} type="currency" />
            <DrawField label="Last Payment Date" value={lastPaymentDate} type="date" />
          </Row>
          <Row>
            <DrawField label="Remaining Months" value={remainingTerm} />
            <DrawField label="Terms" value={terms} />
          </Row>
          <Row>
            <DrawField label="Payment Method" value={formatName(paymentTypeDescription)} />
            <DrawField label="Data Date" value={fetchedAt} type="date" />
          </Row>
          <Row>
            <DrawField label="Description" value={accountTypeDescription} />
          </Row>
        </Col>
        <Col pad centerHorizontal>
          <AmortizationGraph obligation={obligation} />
        </Col>
        <Col pad>
          <DrawField label="Payment History">
            <DebtPaymentHistory paymentHistory={paymentHistory} />
          </DrawField>
        </Col>
      </Container>
    </Fragment>
  )
}

export default DebtItemDetails

