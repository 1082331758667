import React from 'react'
import { renderDisplay, renderLabel } from '../../form'
import { Row } from '../../grid'
import { PaddedCol } from './DrawField.style'


const DrawField = ({ label, value, type, children }) => {
  const DisplayField = renderDisplay(type || "text")
  const Label = renderLabel(type || "text")

  return (
    <PaddedCol>
      <Row><Label label={label}/></Row>
      <Row>{children ? children : <DisplayField value={value} />}</Row>
    </PaddedCol>
  )
}

export default DrawField
