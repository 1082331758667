import React, { Fragment } from 'react'
import { useQuery } from 'react-apollo-hooks'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { ActivityIndicator, Container, Icon, Button } from '../../../components/generic'
import { Row, Col } from '../../../components/generic/grid'
import { EmployerItemContainer, CompanyName } from './ListEmployers.style'


const GET_EMPLOYERS = gql`{
  myEmployers {
    id
    companyName
  }
}`

function EmployerItem({ id, companyName }) {
  return (
    <Link to={`/employer/${id}`}>
      <EmployerItemContainer>
        <Row centerVertical>
          <Col centerVertical><CompanyName>{companyName}</CompanyName></Col>
          <Col centerVertical width="20px"><Icon size="1x" color="black" icon="chevron-right" /></Col>
        </Row>
      </EmployerItemContainer>
    </Link>
  )
}

function ListEmployers() {
  const { data, error, loading } = useQuery(GET_EMPLOYERS);
  if (loading) {
    return <ActivityIndicator large="true"/>;
  };
  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const { myEmployers } = data

  return (
    <Fragment>
      <Container title="My Employers">
      {
        myEmployers.map(e => <EmployerItem {...e} />)
      }
      </Container>
      <Link to={`/add-employer`}><Button action>Register as an Employer</Button></Link>
    </Fragment>
  )
}

export default ListEmployers
