import styled from 'styled-components'
import { googleSignIn, facebookSignIn, moku } from '../../../assets/images'

const Moku = styled.img.attrs({
  src: moku
})`
 height: ${props => props.top ? "13vh" : "13vh"};
 margin: auto;
 margin-top: 7px;
 margin-bottom: 7px;
`

const Center = styled.div`
z-index: 1;
display:flex;
flex-direction:column;
justify-content:center;
margin-bottom:7px;
`

const Bottom = styled.div`
  position:absolute;
  bottom:1vh;
  margin:0 auto;
  width:400px;
`

const ChatCards = styled.div`
  margin-top: 7vh;
  flex-direction:row;
  width: 95%;
  max-width: 600px;
`

const FacebookLoginButton = styled.img.attrs({
  src: facebookSignIn
})`
  width: 218px;
`

const GoogleLoginButton = styled.img.attrs({
  src: googleSignIn
})`
  width: 200px;
`

const TermsLink = styled.a`
text-decoration: underline;
:active {
  color: blue;
}
:hover,
:focus {
  font-weight: bold;
}
:visited {
    color: green;
  }
`
const Footer = styled.div`
  position: absolute;
  text-align:center;
  min-height: 3vh;
  height: 3vh;
  padding-top:1vh;
  left: 0;
  bottom:0;
  opacity: 0.5;
  background-image:linear-gradient(to bottom,rgba(38,126,127,0.5),0,rgba(38,126,127,0.5),32vh,#f8f8f8);
}


  width: 100%;
  color:${props => props.white ? "#229490" : "#FFF"}
  font-size:12px;
`

export {
  Moku,
  Center,
  ChatCards,
  FacebookLoginButton,
  GoogleLoginButton,
  TermsLink,
  Bottom,
  Footer
}