import styled from 'styled-components'

const Row = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${props => props.width && !props.size ? '' : `flex: ${props.size || 1}`};
  ${props => props.width && `width: ${props.width}`};
  ${props => props.height && `height: ${props.height}`};
  ${props => props.centerHorizontal && `justify-content: center;flex-direction: row`};
  ${props => props.centerVertical && `align-items: center;`};
  ${props => props.leftVertical && `align-items: flex-start; flex-direction: column`};
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
`

export default Row