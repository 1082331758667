import React from 'react'
import gql from 'graphql-tag'
import { ActivityIndicator, ChatCard } from '../../../components/generic'
import { Moku, Center, ChatCards, LoginContainer } from '../../../components/profile/RegisterForm/RegisterForm.style'

import { ClaimsList, ClaimsTable } from '../../../components/claim'
import { useQuery } from 'react-apollo-hooks'

const GET_PROFILE = gql`
{
  myClient {
    id
    firstNames
    surname
    idNumber
    photo {
      id
      url
    }
  }
}
`

const MY_CLAIMS = gql`
  {
    myClaims {
      id
      client {
        firstNames
        surname
        idNumber
        products {
          policies {
            policyType
            policyNumber
          }
        }
      }
      effectiveDate
      claimType
      productClaimStatus{
        claimNumber
        status
        claimId
      }
    }
  }
`
function checkClient({ history }) {

  let { data: clientData, loading:load , error:err } = useQuery(GET_PROFILE)

  if (load) { return <ActivityIndicator large/> }
  if (err) { console.log(err); return "An error has occurred" }
  console.log('myClaims client ', clientData.myClient)

  if (clientData.myClient === null) {
    console.log('no client found ', clientData)
    return (
        <Center>
          <Moku />
          <ChatCards>
          <ChatCard text="I'm sorry but something went wrong. Please try again in a little while." nextTitle="Logout" handleNext={()=> history.push('/logout')} />
          </ChatCards>
    </Center>
  )
  }
  else return <div />
}

function MyClaims({ history }) {

  const sortClaims = (myClaims) => {
    let claims = []
    let allClaims = []
    myClaims.map( (claim) =>  {
      claims.push({"claimType": claim.claimType, "client": claim.client, "effectiveDate": claim.effectiveDate, "id": claim.id})
      claim.productClaimStatus.map( status => allClaims.push({"claimType": claim.claimType, "client": claim.client, "effectiveDate": claim.effectiveDate, "id": claim.id,...status}))
    } )
    return allClaims.sort((a, b) => (a.claimNumber > b.claimNumber) ? 1 : -1)
  }

  checkClient(history)

  let { data, loading, error } = useQuery(MY_CLAIMS)

  if (loading) { return <ActivityIndicator large="true"/> }
  if (error) { console.log(error); return "An error has occurred" }

  const { myClaims } = data

  const sortedClaims = sortClaims(myClaims)
  console.log('sorted claims are ', sortedClaims)

  const handleStartNewClaim = () => {
    history.push('/claim')
  }

  return (
    <ClaimsTable claims={sortedClaims} startNewClaim={handleStartNewClaim} history={history} />
  )
}

export default MyClaims
