import styled from 'styled-components'

const ClaimItemContainer = styled.div`
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
  ${props => props.topRow ? "" : "border-top: 1px solid grey;"}
  justify-content: center
`

const Container = styled.div`
  display: block;
  text-align: left;
  margin:0 auto;
  border: 1px solid grey;
  border-radius:14px;
  padding: 7px 0 7px 0;
  background: white
`
const MokuContainer = styled.div`
  position: absolute;
  left:0;
  right:0;
  margin:0 auto;
`

export {
  Container,
  MokuContainer,
  ClaimItemContainer
}