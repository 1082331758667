import React, { Fragment, useState, useRef } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { FormField } from 'react-form-helper'
import { ActivityIndicator, Button } from '../../generic'
import { printCurrency } from '../../../lib/utils'
import { Row, Col } from '../../generic/grid'
import { Quote } from './FuneralQuote.style'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'

const QUOTE_AMOUNTS = [10000, 20000, 30000, 40000, 50000, 60000]

const DEFAULT_COVER_AMOUNT = 30000

const GET_QUOTE = gql`
  query GetQuote($idNumber: String,$age : Int!, $ageSpouse: Int, $gender: Gender!, $spouse: Boolean, $children: Boolean, $coverAmount: Int!, $parents: [ParentQuoteInput], $income: Float)
  {
    getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, spouse: $spouse, children: $children, coverAmount: $coverAmount, parents: $parents, income: $income) {
      premium
      totalCover
    }
  }
`

const quoteVariablesFromChatState = ({ idNumber, ageSpouse, members, spouse, children, coverAmount, totalSpouseCover }) => ({
  age : members[0].ageAtSignup,
  idNumber,
  ageSpouse,
  gender: members[0].gender,
  spouse: spouse === "Y",
  children: children === "Y",
  coverAmount: coverAmount || DEFAULT_COVER_AMOUNT,
  income: members[0].income,
  totalSpouseCover: totalSpouseCover || 0
})

const genderText = {
  'MALE': 'man',
  'FEMALE': 'woman'
}

const spouseGenderText = {
    'MALE': 'woman',
    'FEMALE': 'man'
  }

function CoverDescription({ proposed, variables: { coverAmount, gender, age, spouse, children, ageSpouse, totalSpouseCover } }) {
  return (
    <Quote>
      { Numeral(proposed).format('$0,0')}{' '} cover for {genderText[gender]} ({age })
      { spouse && children ? " " + spouseGenderText[gender] + " (" + ageSpouse + ")" : spouse  && " and " + spouseGenderText[gender] + " (" + ageSpouse + ")" }
      { children && " and children" }
    </Quote>
  )
}

function FuneralQuote({ chatState, setChatState, gotoNext }) {
  console.log('chatstate ', chatState)
  const [showNext, setShowNext] = useState(true)
  const quoteCard = useRef(null)

  const autoScroll = () => {
    setTimeout(() => quoteCard.current && quoteCard.current.scrollIntoView(true), 100)
  }

  const variables = quoteVariablesFromChatState(chatState)
  console.log('Funeral quote ', variables, chatState, QUOTE_AMOUNTS)

  return (
    <Query query={GET_QUOTE} variables={variables}>
      {
        ({ loading, error, data }) => {
          if (loading) {
            return <ActivityIndicator large="true"/>
          }
          if (error) {
            console.log(error)
            return "An error has occured"
          }

          console.log('total cover & quote amounts ', QUOTE_AMOUNTS, data.getPolicyQuote)
          
          const deleteItems = []
          QUOTE_AMOUNTS.map( amount => {if ( (amount + data.getPolicyQuote.totalCover > 100000) ||  (amount + variables.totalSpouseCover > 100000)) {
            deleteItems.push(amount);
          }} )

          deleteItems.map( item => {
            const index = QUOTE_AMOUNTS.indexOf(item)
            if (index > -1) {
              QUOTE_AMOUNTS.splice(index, 1);
            }
          })

          if (QUOTE_AMOUNTS.length > 0) {
            let proposed = variables.coverAmount
            if (proposed > QUOTE_AMOUNTS[QUOTE_AMOUNTS.length - 1]) {
              let offer = Math.floor(QUOTE_AMOUNTS.length / 2) - 1
              if (offer < 0) offer = 0
              proposed = QUOTE_AMOUNTS[offer]
              console.log('proposes ', variables.coverAmount, proposed, QUOTE_AMOUNTS[QUOTE_AMOUNTS.length - 1], QUOTE_AMOUNTS)
              //setChatState({ coverAmount: proposed })
            }
            console.log('proposes 2', variables.coverAmount, proposed, QUOTE_AMOUNTS[QUOTE_AMOUNTS.length - 1], QUOTE_AMOUNTS)
            autoScroll()
            return (
                <div ref={quoteCard}>
                  <center>
                    <p><CoverDescription proposed={proposed} variables={variables} /></p>
                    <span style={{fontSize:28,fontWeight:700, borderBottom:"2px solid gray"}}>{printCurrency(data.getPolicyQuote.premium)}</span>
                  </center>
                  {showNext && (
                    <Col>
                    <span style={{fontSize:15,fontWeight:600,paddingBottom:7,paddingTop:14}}>Select the cover amount you would like below (limit of R100 000 across all policies)</span>
                      <Row>
                        {QUOTE_AMOUNTS.map(coverAmount => ( <Col centerHorizontal>
                          <Query query={GET_QUOTE} variables={{ ...variables, coverAmount }}>
                            {
                              ({ loading, error, data }) => {
                                if (loading) {
                                  return <ActivityIndicator small />
                                }
                                if (error) {
                                  console.log(error)
                                  return "Err"
                                }
                                return <span style={{fontSize:13, fontWeight: proposed === coverAmount ? '700':'300'  }} >{ Numeral(data.getPolicyQuote.premium).format('$0,0')}</span>
                              }
                            }
                          </Query>
                        </Col>))
                      }
                      </Row>
                      <FormField name="coverAmount" inputProps={{ onChange: (e, v) => setChatState({ coverAmount: v }) }} />
                      <br />
                      {variables.totalSpouseCover > 0 && <Row>
                        <Col centerHorizontal>{`* Your spouse already has cover for ${Numeral(variables.totalSpouseCover).format('$0,0')}`} </Col>
                      <br />
                      <br />
                      </Row>}
                      <Row>
                        <Col centerHorizontal><Button action="true"onClick={() => { setShowNext(false); gotoNext(["acceptTerms"]) }}>Apply</Button></Col>
                        <Col centerHorizontal><Button action="true"onClick={() => { setShowNext(false); gotoNext(["noThanks"]) }}>No Thanks</Button></Col>
                      </Row>
                    </Col>
                  )}
                </div>
                )
             }
          else return (
            <Fragment>
              <Row>I see that you already have R100 000 funeral cover. The law prevents us offering you any more.</Row>
              <Row>
                <Col centerHorizontal><Button action="true"onClick={() => { setShowNext(false); gotoNext(["noThanks"]) }}>Continue</Button></Col>
              </Row>
            </Fragment>)
        }
    }
    </Query>
  )
}

FuneralQuote.QUOTE_AMOUNTS = QUOTE_AMOUNTS

export default FuneralQuote