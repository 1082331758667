import styled from 'styled-components'

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const ModalOuter = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,0.5);
display: flex;
justify-content: center;
align-items: center;
${props => !props.visible  && `display:none`};
z-index:1001
`

const ModalContainer = styled.div`
  display: ${props => props.visible ? "block" : "none"};
  max-height: 99vh;
  min-width:50%;
  min-height:37%;
  overflow-y: auto;
  position: relative;
  text-align:${props => props.centered ? "center" : "left"};
  z-index: 901;
  overflow: auto;
  background: white;
  border-radius: 5px;
  padding: 15px;
  margin-top:14px;
  margin-bottom: 14px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.5;
`;

const ButtonBox = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  margin-top:7px;
  margin-bottom:7px;
`;


export {
  ModalContainer,
  ModalOuter,
  CloseButton,
  Background,
  ButtonBox
}
