const idTypes = [
  {value:"rsa_id",text:"SOUTH AFRICA"},
  {value:"bot_id",text:"BOTSWANA"},
  {value:"les_id",text:"LESOTHO"},
  {value:"nam_id",text:"NAMIBIA"},
  {value:"swz_id",text:"SWAZILAND"},
  {value:"zam_id",text:"ZAMBIA"},
  {value:"zim_id",text:"ZIMBABWE"},
  {value:"oth",text:"Other"}
]

export default idTypes