import React, { Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo-hooks'
import {Radio, Button, Icon} from '../index'
import { Row, Col } from '../grid'


const CHALLENGE = gql`
  query GetChallenge($idNumber: String!) {
    getChallenge(idNumber: $idNumber) {
      error
      telephones
      accounts
      employers
    }
  }
`

const VALID = gql`
  query DoChallenge($idNumber: String!, $telephone: String, $employer:String, $account:String) {
    doChallenge(idNumber: $idNumber, telephone: $telephone, employer:$employer, account:$account) {
      passedChallenge
    }
  }
`

const validChallenge = ({apolloClient, gotoNext, idNumber, telephone, employer, account, setShowQuestions,setTryAgain,setDisabled, setTelephone, setEmployer,setAccount, setPassed, setCount, count,setSubmitting, setNext}) => {
  apolloClient.query({ query: VALID, variables: { idNumber, telephone, employer, account } }).then(({ data }) => {
      console.log('calling doChallenge with ', telephone, employer, account + ' result is ',data.doChallenge )
      setSubmitting(false)
      if (data.doChallenge.passedChallenge) {
        setShowQuestions(false)
        setPassed(true)
        gotoNext(["sendReport"])
      }
      else  {
        setShowQuestions(false)
        if (count < 1) {
          setCount(count + 1)
          setTryAgain(true)
          setDisabled(false)
          setTelephone()
          setEmployer()
          setAccount()
        }
        else {
          setPassed(false)
          setNext(true)
        }
      }
  })
}


const Challenge = ({apolloClient,gotoNext,chatState, submitting, setSubmitting, isActive }) => {

  const {idNumber} = chatState

  const [telephone, setTelephone] = useState()
  const [employer, setEmployer] = useState()
  const [account, setAccount] = useState()
  const [disabled, setDisabled] = useState(false)
  const [showQuestions, setShowQuestions] = useState(true)
  const [tryAgain, setTryAgain] = useState(false)
  const [passed, setPassed] = useState()
  const [count, setCount] = useState(0)
  const [next, setNext] = useState(false)

  const {telephones, employers, accounts, hasNoCreditRecord } = chatState

  if (hasNoCreditRecord && !next) {
    setShowQuestions(false)
    setSubmitting(false)
    setNext(true)
    gotoNext(["noRecord"])
  }

  const onPhoneChange = (e) => {
    console.log('telephone radio checked', e.target.value);
    setTelephone(e.target.value);
  }

  const onAccountChange = (e) => {
    console.log('account radio checked', e.target.value);
    setAccount(e.target.value);
  }

  const onEmployerChange = (e) => {
    console.log('employer radio checked', e.target.value);
    setEmployer(e.target.value);
  }

  const onClick = () => {
    setDisabled(true)
    setSubmitting(true)
    validChallenge({apolloClient,gotoNext,idNumber,telephone,employer,account, setShowQuestions,setTryAgain,setDisabled,setTelephone, setEmployer,setAccount,setPassed, setCount, count, setSubmitting, setNext})
  }

  const canWeCall = (call) => {
    setNext(false)
    if (call) {
      gotoNext(["creditPicture"])
    }
    else gotoNext(["seeYouLater"])
  }

  if (hasNoCreditRecord) {
    return <div />
  }
else {
    return (
      <Fragment>
        {showQuestions && <Fragment>
          <br />Please answer the following questions so I can check that the report is for you<br />
          <Radio title="Select a phone which you recognise" group={"telphones"} values={telephones} labels={telephones} selectedValue={telephone} onChange={onPhoneChange}  />
          <Radio title="Select a company or occupation you have worked" group={"employers"} values={employers} labels={employers} selectedValue={employer} onChange={onEmployerChange}  />
          <Radio title="Select where you have (or had) an account" group={"accounts"} values={accounts} labels={accounts} selectedValue={account} onChange={onAccountChange}  />
          <center>
            <Button action="true"submitting={submitting} disabled={disabled || !telephone || !account || !employer} onClick={onClick} > Validate</Button>
          </center>
      </Fragment>}
      {tryAgain && <Fragment>
        Oh dear, your answers are incorrect. Would you like to try again?
            <Row centerHorizontal>
                <Button action="true"onClick={() => {setTryAgain(false);setShowQuestions(true)} } >Yes</Button>
                <Button action="true"onClick={() => {setTryAgain(false);setPassed(false);setNext(true)} }>No</Button>
            </Row>
      </Fragment>}
      {next === true && !hasNoCreditRecord &&
        <Fragment>
        You failed the verification but I can still show you a graph of your credit status?
          <Row centerHorizontal>
            <Button action="true"onClick={() => canWeCall(true)}  >Next</Button>
            <Button action="true"onClick={() => canWeCall(false) }>Quit</Button>
          </Row>
      </Fragment>}
    </Fragment>
    );
  }
}

export default Challenge