import React, { useState } from 'react'
import { SideMenuContainer } from './SideMenu.style';
import sideMenuConfig from './sideMenuConfig'
import SideMenuItem from '../SideMenuItem'
import { withRouter } from 'react-router-dom'
import { AuthConsumer } from 'react-auth-helper'

function SideMenu({ location, toggleSidebarOpen }) {
  const { pathname } = location;
  return (
    <SideMenuContainer>
      <AuthConsumer>
        {({ hasRole }) => (
          sideMenuConfig.filter(item => item.requiresRoles.reduce((show, role) => show || hasRole(role), false)).map(item => <SideMenuItem title={item.title} icon={item.icon} toggleSidebarOpen={toggleSidebarOpen} selected={item.route === pathname} route={item.route} />)
        )}
      </AuthConsumer>
    </SideMenuContainer>
  )
}

export default withRouter(SideMenu)
