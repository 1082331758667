import styled from 'styled-components'
import { Row, Col } from '../../generic/grid'

const PaddedCol = styled(Col)`
  padding: 7px;
`

const stateColor = {
  "PAID": "green",
  "PENDING": "orange",
  "OVERDUE": "red"
}

export {
  PaddedCol,
  stateColor
}