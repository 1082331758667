import styled from 'styled-components'

const SideMenuContainer = styled.div`
  margin-top: 50px;
  padding: ${props => props.theme.padding+"px"};
  width: 200px;
  background-color: ${props => props.theme.sideMenuColor};
  height: 100vh;
  z-index: 999;
`

export {
  SideMenuContainer
}