import React from 'react'
import gql from 'graphql-tag'
import { ActivityIndicator, ChatCard } from '../../../components/generic'
import { Moku, Center, ChatCards, LoginContainer, ChatWithMokuContainer, MokuContainer } from '../../../components/profile/RegisterForm/RegisterForm.style'
import CreditForm from '../../../components/profile/CreditForm'
import { useQuery } from 'react-apollo-hooks'

const GET_PROFILE = gql`
{
  myClient {
    id
    idType
    firstNames
    surname
    idNumber
    email
    cellNumbers
  }
}
`

function MyCredit({ history }) {

  let { data, loading, error } = useQuery(GET_PROFILE)

  if (loading) { return <ActivityIndicator large="true"/> }
  if (error) { console.log(error); return "An error has occurred" }

  const { id, idNumber, idType } = data.myClient
  console.log('MyCredit data is ', data)

  return (
    <CreditForm {...data.myClient} loggedIn={true} />
  )
}

export default MyCredit