import React from 'react'
import { useQuery  } from 'react-apollo-hooks'
import gql from 'graphql-tag'
import { SavingsNeedItem } from '../../../components/savings'
import { ActivityIndicator } from '../../../components/generic'

const GET_SAVINGS_ITEM_INFO = gql`
  query GetSavingsItemInfo($id: String) {
    myClient {
      id
      savings {
        id
        bucketInfoById(id: $id) {
          id
          bucketType
          description
          name
          amount
          targetAmount
          targetDate
          percentageFull
        }
      }
    }
  }
`

function MySavingsItem({ match, history }) {
  const { id } = match.params

  const { data, error, loading } = useQuery(GET_SAVINGS_ITEM_INFO, { variables: { id } })

  if (loading) {
    return <ActivityIndicator large="true"/>;
  };
  if (error) {
    return <div>Error! {error.message}</div>;
  };

  return <SavingsNeedItem {...data.myClient.savings.bucketInfoById} savingsId={data.myClient.savings.id} refetchQueries={[{ query: GET_SAVINGS_ITEM_INFO, variables: { id } }]} onBack={() => history.push('/savings')} />

}

export default MySavingsItem