export const meerkatWhite = require('./meerkat_white.png')
export const moku = require('./mokuSmall.png')
export const MokuButtonImage = require('./MokuHeadSmall.png')
export const GoogleButtonImage = require('./google.png')
export const FacebookButtonImage = require('./FacebookF.png')
export const mokuFavicon = require('./meerkat_favicon.png')
export const googleSignIn = require('./btn_google_signin_dark_normal_web@2x.png')
export const facebookSignIn = require('./loginwithfacebook.png')
export const loginGoogle = require('./signinGoogle.png')
export const loginFB = require('./continueFacebook.png')
export const OR = require('./OR.png')
export const register = require('./registerAccount.png')
export const logo = require('./logo.svg')