import React, { useState,Fragment } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormFieldArray, FormField, FieldValue } from 'react-form-helper'
import { banks } from '../../../constants'
import { renderLabel, renderDisplay, renderInput } from '../../generic/form'
import { Container, Icon, Button } from '../../generic'
import { Row, Col } from '../../generic/grid'

import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';

const UPDATE_BUCKET = gql`
  mutation SaveToBucket($id: String!, $amount: Float!) {
    saveToBucket(id: $id, amount: $amount) {
      id
      amount
  }
}
`

function Layout({closeModal}) {

  return (
    <Fragment>
      <Col>
        <Row centerHorizontal >
          <Col>
            <FormField name={"amount"}  />
          </Col>
        </Row>
        <br />

        <SubmitFormButton renderSubmitButton={({ invalid, submit, submitting }) => {

          const [submitted, setSubmitted] = useState(false)

          if (submitted && !submitting) {
                setSubmitted(false)
          }
          return (
              <Row centerHorizontal>
              <Col>
                <Button onClick={() => closeModal(false)}>Cancel</Button>
              </Col>
              {' '}
              <Col>
                <Button action="true" disabled={invalid} submitting={submitting} onClick={ () => { submit();  setTimeout(() => setSubmitted(true), 100);  }}>Confirm</Button>
              </Col>
            </Row>

          )}
        } />
      </Col>
    </Fragment>
  )
}

const fields = {
  "amount": {
    label: "Additional Savings Amount",
    type: "currency",
    required:true
  }
}


function SaveMoreForm({id, closeModal}) {

  const validate = values => {
    const {amount} = values

    const errors = {}

    if ( !amount) {
      errors.amount = `Amount is required`
    }

    if ( amount < 25) {
      errors.amount = `The mimimum amount is R25`
    }

    console.log('errors are ', errors)
    return errors
  }

  return (
    <GraphQLFormProvider
      name="withdraw"
      id={id}
      fields={fields}
      validate={validate}
      mode="add"
      displayOnSave
      InputFormLayout={props => <Layout {...props} closeModal={closeModal} />}
      updateMutation={UPDATE_BUCKET}
      renderLabel={renderLabel}
      renderInput={renderInput}
      afterSubmit={() => closeModal() }
    />
  )
}

export default SaveMoreForm
