import React, { Component} from 'react'
import { Switch, Route } from 'react-router-dom'
import MyDocuments from './MyDocuments'

// TODO: Should only be 1  FuneralForm
export default class Document extends Component {
  render() {
    return (
      <Switch>
        <Route path='/documents' component={MyDocuments} />
      </Switch>
    )
  }
}