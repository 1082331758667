import styled from 'styled-components'

const StyledFileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const StyledFileLabel = styled.label`
  color: white;
  padding: 7px;
  padding-right: 14px;
  padding-left: 14px;
  ${props => `background-image: linear-gradient(${props.theme.actionGradientFrom}, ${props.theme.actionGradientTo});`}
  background-color: black;
  display: inline-block;
  border-radius: 14px;
  cursor: pointer

  :hover {
    ${props => `background-image: linear-gradient(${props.theme.primaryHover}, ${props.theme.primaryHover});`}
  }

  span {
    display: inline-block;
    height: 100%;
    transition: all 0.3s;
    width: 100%;
  }

  &::before {
    height: 100%;
    left: 0;
    line-height: 2.6;
    position: absolute;
    top: -180%;
    transition: all 0.3s;
    width: 100%;
  }
  &:hover {
    background-color: darken(#ee6d9e, 30%);
    span {
      transform: translateY(300%);
         }
      &::before {
        top: 0;
      }
    }
  }
}
`

const StyledInput = styled.input`
  height: 0;
  overflow: hidden;
  width: 0;
`;

const StyledLabel = styled.label`
  display:${props => props.hide ? "none" : "flex"};
  border: none;
  justify-content:center;
  color: #fff;
  cursor: pointer;
	font-size: inherit;
  font-weight: 600;
  margin-bottom: 7px;
  margin-top: 7px;
  outline: none;
  padding: 7px 14px;
  ${props => `background-image: linear-gradient(${props.theme.actionGradientFrom}, ${props.theme.actionGradientTo});`}
  background-color: #99c793;
  border-radius: 50px;
  overflow: hidden;
  :hover{
    background-color: darken(#99c793, 50%);
    ${props => `background-image: linear-gradient(${props.theme.primaryHover}, ${props.theme.primaryHover});`}
  }
`

export {
  StyledLabel,
  StyledInput,
  StyledFileInput,
  StyledFileLabel
}