import React, { useState } from 'react'
import moment from 'moment'
import { printCurrency } from '../../../lib/utils'
import { ActivityIndicator, Button, Icon } from '../../generic'
import { Outer, SigContainer, SigInnerContainer} from './Mandate.style'
import { Row, Col } from '../../generic/grid'
import SignatureCanvas from 'react-signature-canvas';
import {MEERKAT_WEB_URL,API_URL} from '../../../config'
import styles from './mandate.css'
import {getLocation} from '../../../lib/utils'
import { useQuery  } from 'react-apollo-hooks'
import fileDownload from 'js-file-download'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'

const GET_TEMPLATE = gql`
  query getDebitOrderTemplate($debitOrderId:String!, $templateValues:String) {
    getDebitOrderTemplate(debitOrderId:$debitOrderId, templateValues:$templateValues)
}`

const hideAcountNumber = ( number ) => {
  if (number){
    const suffix = number.substr(number.length - 4, 4)
    let prefix = ""
    for (var i = 0; i < number.length-4 ; i++) {
      prefix += 'X'
    }
    return prefix + '-' + suffix
  }
  else return "-"
}

const savePdf = async (debitOrderId, clientId, accountHolderName, accountHolderIdNumber, city, country_name, signature, amount, bank, accountNo, policyNumber, startDate) => {
  const accessToken = localStorage.getItem('token');

  const doValues = {
    debitOrderId,
    accountHolderIdNumber,
    accountHolderName,
    city,
    country_name,
    signature,
    date: moment().format('Do MMMM YYYY'),
    amount,
    bank,
    accountNo,
    policyNumber,
    startDate,
    product: "funeral",
    returnPdf: false
  }
  
  console.log('fetchPdf values ', doValues)

  const response = await fetch(`${API_URL}client/${clientId}/pdf/do`, { method: 'post', body: JSON.stringify(doValues), headers: { 'Content-Type':'application/json', 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
  const data = await response.blob()
  //const file = await fileDownload(data, `DOMANDATE-${idNumber}.pdf`)
  //const file = await fileDownload(data, `DOMANDATE-${idNumber}.htm`)
}

function DoMandate (props) {
  const { debitOrderId, clientId, bankAccountId, policyNumber, accountHolderName, accountHolderIdNumber, amount, bank, accountNo, onMandateSign, client, nextDebitDate } = props
  console.log('doMandate props ', props)

  const [disableSave, setDisableSave] = useState(true)
  const [spin, setSpin] = useState(false)
  const [notDone, setNotDone] = useState(true)
  const [clicked, setClicked] = useState(false)
  const [fromDataURL, setFromDataURL] = useState()
  const [sigPad, setSigPad] = useState({})
  const [location, setLocation] = useState({city:"Knysna", country_name:"South Africa"})
  const [mandate, setMandate] = useState()
  const save = "Save"

  const loadMandate = (templateValues) => {
    client.query({ query: GET_TEMPLATE, variables: { debitOrderId, templateValues } }).then(({ data }) => {
      console.log('get_template ', data.getDebitOrderTemplate)
      setMandate(data.getDebitOrderTemplate)
    })
  }

  const myLocation = async () => {
    getLocation().then((obj) => {
      console.log('Browser location from getLocation() is ', obj)
      setLocation(obj)
      return obj
    })
  }

const saveSig = () => {
  setFromDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png') );
  if (disableSave) {
    setDisableSave(false)
  }
};

const saveButton = () => {
  if (sigPad && !clicked) {
    if (!sigPad.isEmpty()) {
      setDisableSave(true)
      if (!clicked) {
        setClicked(true)
        setSpin(true)
        // save signature
        let signature = sigPad.toDataURL('image/png');
        savePdf(debitOrderId, clientId, accountHolderName, accountHolderIdNumber, location && location.city || "", location && location.country_name || "", signature, printCurrency(amount), bank, accountNo, policyNumber, moment(nextDebitDate).format('D/MM/YYYY'))
          .then ((response) => {
            console.log('create doMandate  returned ', response);
            setNotDone(false)
            setSpin(false)
            onMandateSign();
            //history.push('/')
            })
      }
    }
  }
};

if (!location) {
  myLocation()
}

if (!mandate) { 
  
  const values = { debitOrderId, clientId, bankAccountId, accountHolderName, accountHolderIdNumber, city: location && location.city || "", country_name: location && location.country_name || "", signature: 'none', amount: printCurrency(amount), bank, accountNo, policyNumber, date: moment().format('D/MM/YYYY'), startDate: moment(nextDebitDate).format('D/MM/YYYY') }
    loadMandate(JSON.stringify(values))
  return <div /> 
}

else return (
  <Outer>
    <div dangerouslySetInnerHTML={{__html: mandate }} />
      <SigContainer>
        <SigInnerContainer>
          <SignatureCanvas clearOnResize={false} canvasProps={{width: 320, height: 200, className: 'sigPad'}} ref={(ref) => { setSigPad(ref) }} onEnd={saveSig} />
          <Row centerHorizontal>
              <Button action="true"onClick={() => sigPad.clear()}>Clear</Button>
              <Button action="true"disabled={disableSave || !location || spin} onClick={() => saveButton()}>
                {spin ? <Icon size="1x" color="white" spin icon="spinner" /> : `Save` }
              </Button>
          </Row>
        </SigInnerContainer>

      </SigContainer>

      Signed
      {1== 2 && location ?  ` in ${location.city}, ${location.country_name} `: ' '}
      on {' '}
      {moment().format('Do MMMM YYYY')}
  </Outer>
  )
}

const DO_MANDATE_INFO = gql`
query DoMandateInfo($id: String!) {
  doMandateInfo(id: $id) {
    debitOrderId
    clientId
    policyNumber
    accountHolderIdNumber
    accountHolderName
    bank
    accountNo
    amount
    nextDebitDate
    isActive
    debitOrderMandateUrl
  }
}
`

function ShowMandate({ id, client, onMandateSign }) {
  const { data, error, loading } = useQuery(DO_MANDATE_INFO, { variables: { id } })

  if (loading) {
    return <ActivityIndicator />
  };
  if (error) {
    return <div>Error! {error.message}</div>;
  };

  if (data) {
    const { debitOrderId, clientId, policyNumber, accountHolderIdNumber, accountHolderName, surname, bank, accountNo, amount, nextDebitDate, isActive, debitOrderMandateUrl } = data.doMandateInfo
  
    console.log('debitOrderMandateUrl ', debitOrderMandateUrl, data.doMandateInfo)

    if (debitOrderMandateUrl) {
      return <center> This debit order has already been signed <a href={debitOrderMandateUrl}>Click to download</a> </center>
    }
    else {
    return <div>
      {isActive ? <DoMandate {...data.doMandateInfo} bankAccountId={id} onMandateSign={onMandateSign} client={client} /> : <center>This debit order is not longer active. Please look for a newer SMS from us.</center>}
    </div>
    }
  }
else return <ActivityIndicator />
}

function Mandate(props) {
  const { match, history, client } = props
  const { id } = match.params
  console.log('doMandate redirect ', MEERKAT_WEB_URL)
  return <ShowMandate id={id} client={client} onMandateSign={() => window.location = `${MEERKAT_WEB_URL}` } />
}

Mandate.ShowMandate = ShowMandate

export default withApollo(Mandate)