import React from 'react'
import { PaymentHistoryContainer, PaymentBlock } from './DebtPaymentHistory.style'

function DebtPaymentHistory({ paymentHistory }) {
  return (
    <PaymentHistoryContainer>
      {paymentHistory && paymentHistory.map(p => <PaymentBlock {...p} />)}
    </PaymentHistoryContainer>
  )
}

export default DebtPaymentHistory
