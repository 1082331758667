import React, { Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FieldName, FormField, FieldValue } from 'react-form-helper'
import { renderLabel, renderDisplay, renderInput } from '../../generic/form'

import {ButtonBox} from '../../generic/Modal/Modal.style'

import { Row, Col, Break } from '../../generic/grid'
import { Button } from '../../generic'
import { SavingsNeedIcon, NeedProgressBar } from './SavingsNeedItem.style'
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';

const today = new Date()

const UPDATE_BUCKET = gql`
  mutation UpdateBucket($id: String!, $input: SavingsBucketInput!) {
    updateSavingsBucket(id: $id, input: $input) {
      id
      name
      targetAmount
      targetDate
  }
}
`

function Layout({mode, closeModal}) {

  return (
    <Fragment>
        <Col pad>
          <Row>
            <FormField name={"name"} />
          </Row>
          <Row>
            <FormField name={"targetAmount"} />
          </Row>
          <Row>
            <FormField name={"targetDate"} />
          </Row>
        </Col>
        <br />

        <ButtonBox>
          {mode == "edit" &&
            <SubmitFormButton renderSubmitButton={({ submit, submitting }) => {
              const [submitted, setSubmitted] = useState(false)
              if (submitted && !submitting) {
                setSubmitted(false)
              }
              return (
              <Fragment>
                <Button  onClick={() => closeModal(true) }>Cancel</Button>
                <Button action="true" submitting={submitting} onClick={ () => { submit(); setTimeout(() => setSubmitted(true), 100);  }}>Save</Button>
              </Fragment>)
            }} /> }
        </ButtonBox>
    </Fragment>
  )
}


const fields = {
  "name": {
    label: "Bucket Name",
    type: "text"
  },
  "targetAmount": {
    label: "Target Amount",
    type: "currency"
  },
  "targetDate": {
    label: "Target Date",
    type: "date",
    minDate:new Date(),
    required:true
  },
  "bucketType": {
    strip: true
  }
}

function SavingsNeedItemForm({id,refetchQueries,closeModal}) {
  return (
    <GraphQLFormProvider
      query={gql`query GetSavingsBucket($id: String!) {
        getSavingsBucket(id:$id) {
          id
          name
          bucketType
          targetAmount
          targetDate
        }
      }
      `}
      initialValuesFromData={data => data.getSavingsBucket}
      initialValues={() => ({ targetDate:today })}
      name="savings"
      id={id}
      fields={fields}
      mode="edit"
      InputFormLayout={props => <Layout {...props} closeModal={closeModal}  />}
      DisplayFormLayout={props => <Layout {...props}  />}
      updateMutation={UPDATE_BUCKET}
      renderLabel={renderLabel}
      renderDisplay={renderDisplay}
      renderInput={renderInput}
      refetchQueries={refetchQueries}
      afterSubmit={() => closeModal()}
    />
  )
}

export default SavingsNeedItemForm
