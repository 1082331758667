import React from 'react'
import styled from 'styled-components'
import { MonthCircle, RowContainer } from '../../employer/EmployerContributionRow/EmployerContributionRow.style'
import theme from '../../../theme.style'

const ClientCircle = styled(MonthCircle)``

const colorChanges = [30, 70]

const getFillColor = (percent) => {
  if (percent < colorChanges[0]) {
    return theme.colors.status.bad
  }
  if (percent < colorChanges[1]) {
    return theme.colors.status.average
  }
  return theme.colors.status.good
}

const getSmiley = (percent) => {
  if (percent < colorChanges[0]) {
    return "sad-tear"
  }
  if (percent < colorChanges[1]) {
    return "meh"
  }
  return "smile"
}

const getSmileyProps = (percent) => {
  const  color = getFillColor(percent)
  const  icon = getSmiley(percent)
  return { color, icon }
}

const ProgressBarContainer = styled.div`
  display: flex
  border: 1px solid #a8a8a8;
  width: 100%;
  margin-right: 0px;
  flexGrow: 1;
  height: 16px;
  border-radius: 6px;
`

const BarFill = styled.div`
  height: 14px;
  background-color: ${props => getFillColor(props.percentageFull)};
  width: ${props => (props.percentageFull || 0)}%;
  margin: 1px;;
  cursor: pointer;
  border-radius: 5px;
`

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  width: 56px;
  height: 56px;
  background-color: ${({ bucketType }) => bucketTypeConfig[bucketType].color}
`

const ProgressBar = ({ className, ...props }) => (
  <ProgressBarContainer className={className} {...props}>
    <BarFill {...props} />
  </ProgressBarContainer>
)

const bucketTypeConfig = {
  EMERGENCY: { icon: "life-ring", color: "red" },
  YEAR_END: { icon: "gifts", color: "orange" },
  EDUCATION: { icon: "graduation-cap", color: "blue" },
  HOLIDAY: { icon: "plane-departure", color: "blue" },
  CAR: { icon: "car", color: "cyan" },
  HOUSE: { icon: "home", color: "green" },
  RETIREMENT: { icon: "wallet", color: "grey" },
  FUNERAL: { icon: "monument", color: "brown" },
  HEALTH: { icon: "heartbeat", color: "red" }
}

Object.keys(bucketTypeConfig).forEach(bucketType => bucketTypeConfig[bucketType].color = theme.colors.buckets[bucketType])

const getBucketTypeProps = (bucketType) => ({ ...bucketTypeConfig[bucketType], color: "white" })

export {
  ClientCircle,
  RowContainer,
  ProgressBar,
  getFillColor,
  getSmileyProps,
  getBucketTypeProps,
  IconCircle
}
