import styled from 'styled-components'

const toFlexVertical = {
  top: "flex-start",
  bottom: "flex-end",
  center: "center"
}

const toFlexHorizontal = {
  left: "flex-start",
  right: "flex-end",
  center: "center"
}

const Col = styled.div`
display: flex;
flex-direction:column;
${props => props.width && !props.size ? '' : `flex: ${props.size || 1}`};
${props => props.width && `width: ${props.width}`};
${props => props.centerHorizontal && `align-items: center`};
${props => props.centerVertical && `justify-content: center`};
${props => props.alignVertical && `align-items: ${toFlexVertical[props.alignVertical]}`};
${props => props.alignHorizontal && `align-items: ${toFlexHorizontal[props.alignHorizontal]}`};
${props => props.direction && `flex-direction: ${props.direction}`};
${props => props.alignItems && `align-items: ${props.alignItems}`};
${props => props.justify && `justify-content: ${props.justify}`};
${props => props.pad && "padding: 7px"}
`

const Item = styled.div`
flex-grow: ${props => props.size ? props.size : "1"};
`
export { Col, Item}