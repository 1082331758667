import React, { Fragment } from 'react'
import {LoginButton} from '../LoginButton'
import {ButtonContainer} from './LoginButtons.style'

const LoginButtons = ({chatState}) => {

  const {authProvider} = chatState || {}

  return (
    <ButtonContainer>
      {(!authProvider || authProvider === "GOOGLE") && <LoginButton authProvider="google" /> }
      {(!authProvider || authProvider === "FACEBOOK") && <LoginButton authProvider="facebook" />}
    </ButtonContainer>
)}

export default LoginButtons
