import React, { Fragment } from 'react'
import TextInput from '../TextInput'
import Select from '../Select'
import DatePicker from '../DatePicker'
import AutoComplete from '../AutoComplete'
import FileUpload from '../FileUpload'
import Button from '../Button'
import OptionSelector from '../OptionSelector'
import Icon from '../Icon'
import { Row } from '../grid'

const Warning = ({ text }) => (
  <Row centerVertical>
    <Icon icon="exclamation-triangle" color="red" />
    <span style={{ marginLeft: "7px" }}>{text}</span>
  </Row>
)

const renderers = {
  text: props => <TextInput {...props} {...props.input} />,
  select: props => {
    // can be passed an array of objects or just an array. Make object if plain array
    const options = props.field.options
    const option = options.length > 0 ? options[0] : options
    console.log('select options are of type ', typeof option)
    return <Select {...props.field} {...props.input} options={typeof option === 'object' ? options : options.map(o => ({ text: o, value: o })) } />
  },
  date: props => {
    console.log('date renderer props ', props)
    //return <TextInput {...props} {...props.input} {...props.field}/>
    return <DatePicker {...props.field} {...props.input}  autoFocus={props.autoFocus} />
  },
  autocomplete: props => {
    return <AutoComplete {...props.input} options={props.field.options} />
  },
  fileupload: props => {
    return <FileUpload input={props.input} prompt={props.field.fileText} uploadResult={props.field.uploadResult} />
  },
  boolean: props => { 
    return <Select {...props.field} {...props.input} options={[{ text: 'Yes', value: 'YES' },{ text: 'No', value: 'NO'} ]} /> 
  },
  yesno: props => {
    return <Row fill centerHorizontal>
      <Button action="true" onClick={() => props.input.onChange("Y")}>Yes</Button>
      <Button action="true" onClick={() => props.input.onChange("N")}>No</Button>
    </Row>
  },
  options: ({ field, input }) => {
    return <Row fill centerHorizontal>
      {field.options.map(option => <Button action="true"onClick={() => input.onChange(option.value)}>{option.label}</Button>)}
    </Row>
  },
  optionselector: ({ input, field }) => {
    return <OptionSelector value={input.value} onChange={input.onChange} options={field.options} />
  }
}

const renderInput = (type) => props => {
const {input} = props

// dangerous, but if field name includes "date" set to date type
let MyInput = renderers[type && renderers[type] ? type : "text"]
//if(input.name && input.name.toLowerCase().indexOf("date") > -1 && (!type || type !== "select")) {
//  MyInput = renderers["date"]
//}
  console.log('renderInput props are type and myInput', type, MyInput)
  return (
    <Fragment>
      <MyInput {...props} />
      {props.meta && props.meta.error && <Warning text={props.meta.error} />}
      {props.meta && props.meta.warn}
    </Fragment>
  )
}

export default renderInput