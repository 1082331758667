import styled from 'styled-components'

const EmployerItemContainer = styled.div`
  display: flex;
  padding-top: 6px;
  padding-bottom: 8px;
  ${props => props.topRow ? "" : "border-top: 1px solid grey;"}
  justify-content: center
`

const CompanyName = styled.span`
  color: ${props => props.theme.color};
  padding-left: 7px;
`

export {
  EmployerItemContainer,
  CompanyName
}