/*
ChatFlow takes in a JSON chat definition and executes the chat flow with ChatCards
It also allows form submission to run a mutation
*/

// Disable Previous Step

import React, { useState, Fragment, useContext, useEffect } from 'react';
import { ChatCard } from '../../generic'
import { renderLabel, renderInput, renderDisplay } from '../../generic/form'
import renderFieldDisplay from './renderFieldDisplay'
import { GraphQLFormProvider, FormProvider } from 'react-form-helper';
import ActivityIndicator from '../ActivityIndicator';
import { withRouter } from 'react-router-dom'
import { ChatContext } from '../../../context'

const resolveNextSteps = (nextSteps, chatState) => {
  if (nextSteps instanceof Function) {
    return nextSteps(chatState)
  } else {
    if (nextSteps instanceof Array) {
      return nextSteps
    }
    return [nextSteps]
  }
}
// TODO: Wrap in ChatCards ?
function Layout({ apolloClient, changeFieldValue, steps, onlyShowCurrent, initialValues, history, submit }) {
  const { chatState, updateChatState, formSubmitDone, setFormSubmitDone } = useContext(ChatContext)
  let nextSteps = steps[0].nextSteps || []
  const [drawSteps, setDrawSteps] = useState([steps[0].name])
  const [currentSteps, setCurrentSteps] = useState([steps[0].name])
  const [submitting, setSubmitting] = useState(false)
  const [loadingCards, setLoadingCards] = useState({})
  const stepsByName = {}

  useEffect(() => {
    setChatState(initialValues)
  }, [])

  let nextChatState = { ...chatState }

  const setChatState = (values) => {
    if (values) {
      nextChatState = updateChatState(currentSteps, values)
    }
  }

  const updateChatFieldValue = (field, value) => {
    setChatState({ [field]: value })
    changeFieldValue(field, value)
  }

  const setLoading = (stepName, loading) => {
    setLoadingCards({ ...loadingCards, [stepName]: loading })
  }

  steps.forEach(step => {
    stepsByName[step.name] = step
  })

  const setNextSteps = (next) => {
    nextSteps = next
  }

  const gotoNext = (whichSteps) => {
    let addSteps = []
    const checkSteps = resolveNextSteps(whichSteps || nextSteps || [], nextChatState)
    let stepsSkipped = false

    checkSteps.forEach(stepName => {
      const step = stepsByName[stepName]
      if (step.skipStep && step.skipStep(nextChatState)) {
        stepsSkipped = true
        if (!step.handleNextIfSkipStep) {
          step.nextSteps && resolveNextSteps(step.nextSteps, nextChatState).forEach(s => addSteps.push(s))
        } else {
          step.handleNext({ apolloClient, history, changeFieldValue: updateChatFieldValue, chatState: nextChatState, setChatState, setNextSteps, setSubmitting, gotoNext: (s) => gotoNext(s || step.nextSteps) })
        }
      } else {
        addSteps.push(stepName)
      }
    })

    if (stepsSkipped && addSteps.length > 0) {
      console.log('stepname is ', addSteps)
      gotoNext(addSteps)
    } else {
      addSteps.forEach(stepName => {
        const step = stepsByName[stepName]
        if (step.beforeStep) {
          setLoading(stepName, true)
          const stepResult = step.beforeStep({ apolloClient, setChatState, changeFieldValue: updateChatFieldValue, chatState: nextChatState })
          if (stepResult instanceof Promise) {
            console.log('stepname is ', stepName)
            stepResult.then(() => setLoading(stepName, false))
          } else {
            setLoading(stepName, false)
          }
        }
      })
      if (onlyShowCurrent) {
        setDrawSteps([...addSteps])
      } else {
        setDrawSteps([...drawSteps, ...addSteps])
      }
      setCurrentSteps(addSteps)
    }
  }

  const renderSteps = drawSteps.map((s, index) => {
    const step = stepsByName[s]

    if (loadingCards[s]) {
      if (step && step.noCard) {
        return <center><ActivityIndicator large="true"/></center>
      } else {
        return <ChatCard noNext><center><ActivityIndicator large="true"/></center></ChatCard>
      }
    }

    const chatCardProps = {
      text: step.text instanceof Function ? step.text(chatState) : step.text,
      fieldNames: step.fieldNames,
      nextOnValue: step.nextOnValue,
      noNext: step.noNext || !currentSteps.includes(step.name),
      isActive: !!(currentSteps.includes(step.name) || (step.keepActive && step.keepActive(chatState))),
      focus: index === drawSteps.length - 1,
      labels: step.labels,
      submitting,
      setSubmitting,
      nextButtons: step.nextButtons,
      nextTitle: step.nextTitle,
      nextAfterDelay: step.nextAfterDelay,
      submitBeforeStep: step.submitBeforeStep,
      formSubmitDone,
      setFormSubmitDone,
      submit,
      handleNext: (values, nextStepsOveride) => {
        if (step.handleNext) {
          setChatState(values)
          step.handleNext({ apolloClient, history, changeFieldValue: updateChatFieldValue, values, chatState: nextChatState, setChatState, setNextSteps, setSubmitting, gotoNext: (s) => gotoNext(s || nextStepsOveride || step.nextSteps) });
        } else {
          setChatState(values);
          gotoNext(nextStepsOveride || step.nextSteps);
        }
      }
    }
    if (step.component) {
      const Contents = step.component
      chatCardProps.children = <Contents chatState={nextChatState} apolloClient={apolloClient} gotoNext={(s) => {
        if (step.handleNext) {
          step.handleNext({ apolloClient, submit, changeFieldValue: updateChatFieldValue, chatState: nextChatState, setChatState, setNextSteps, setSubmitting, gotoNext: (s2) => gotoNext(s2 || s || step.nextSteps) });
        } else {
          gotoNext(s || step.nextSteps);
        }}
      } history={history} setNextSteps={setNextSteps} setChatState={setChatState} submitting={submitting} setSubmitting={setSubmitting} step={step} changeFieldValue={updateChatFieldValue} />
    }

    if (step.noCard) {
      return chatCardProps.children
    }

    return <ChatCard key={step.text} {...chatCardProps} />
  })

  return <Fragment>{renderSteps}<div style={{ height: "13vh" }} /></Fragment>
}

function ChatFlow({ name, addMutation, steps, fields, afterSubmit, onlyShowCurrent, onSubmit, initialValues, history }) {
  const { chatState, formSubmitDone, setFormSubmitDone } = useContext(ChatContext)
  const [formInitialValues, setFormInitialValues] = useState()
  console.log('ChatFlow chatState', chatState)

  console.log(formSubmitDone)

  useEffect(() => {
    if (initialValues instanceof Function) {
      setFormInitialValues(initialValues(chatState))
    } else {
      setFormInitialValues(initialValues || {})
    }
  }, [])

  if (!formInitialValues) {
    return <div />
  }

  if (addMutation) {
    return (
      <GraphQLFormProvider
        name={name}
        mode="add"
        fields={fields}
        addMutation={addMutation}
        afterSubmit={(...args) => { console.log('afterSubmit', args); afterSubmit && afterSubmit(...args); setFormSubmitDone(true); }}
        renderInput={renderInput}
        renderDisplay={renderFieldDisplay}
        initialValues={formInitialValues}
        InputFormLayout={(props) => <Layout steps={steps} onlyShowCurrent={onlyShowCurrent} initialValues={formInitialValues} {...props} history={history} />}
        renderLabel={renderLabel}
      />
    )
  } else {
    return (
      <FormProvider
        name={name}
        mode="add"
        fields={fields}
        afterSubmit={(...args) => { afterSubmit && afterSubmit(...args); setFormSubmitDone(true); }}
        renderInput={renderInput}
        renderDisplay={renderFieldDisplay}
        initialValues={formInitialValues}
        InputFormLayout={(props) => <Layout steps={steps} onlyShowCurrent={onlyShowCurrent} initialValues={formInitialValues} {...props} history={history}  />}
        renderLabel={renderLabel}
      />
    )
  }
}

export default withRouter(ChatFlow)