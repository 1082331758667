import styled from 'styled-components';

const ProfileMenuContainer = styled.div`
  position: fixed;
  right: ${props => props.theme.padding}px;
  top: ${props => 50 + props.theme.padding}px;
  border: 1px solid black;
  width: 100px;
  height: 100px;
`

const ProfileMenuListItem = styled.li`
`

const ProfileMenuItemLink = styled.a`
`

const ProfileMenuList = styled.ul`
`

const ProfileMenuItemSelected = styled.div`
`

export {
  ProfileMenuContainer,
  ProfileMenuList,
  ProfileMenuListItem,
  ProfileMenuItemLink,
  ProfileMenuItemSelected
}