import React, {Component} from 'react'
import PropTypes from "prop-types";
import { Box, Item, RadioButton, RadioButtonLabel,Title} from './Radio.style'


const Radio = ({title, values, labels, group, selectedValue, onChange}) => {
  return (
    <Box>
    <Title>{title}</Title>
      {values.map((item, index) =>
        <Item>
          <RadioButton type="radio" name={group} key={item} value={item}  checked={item == selectedValue} onChange={onChange}  />
          <RadioButtonLabel />
          <div style={{ marginLeft: '14px' }}>{labels[index]}</div>
        </Item>
      )}
    </Box>
    )
  }

export default Radio
