import styled from 'styled-components'

const blockColor = (props) => {
  console.log('PROPS=', props)
  if (props.status === "missed") {
    return props.theme.colors.status.bad
  }
  if (props.status === "late") {
    return props.theme.colors.status.average
  }
  if (props.status === "paid") {
    return props.theme.colors.status.good
  }
  return props.theme.colors.status.unknown
}

const PaymentBlock = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => blockColor(props)}
  margin-right: 2px;
  margin-bottom: 2px
`

const PaymentHistoryContainer = styled.div`
  display: flex
  flex-direction: row
  flex-wrap: wrap
  width: 100%
  max-width: 264px
`

export {
  PaymentHistoryContainer,
  PaymentBlock
}