import React from 'react'
import {  ListContainer, Text, Title, ListHeader, ListTitle, stateColor, PaddedCol } from './MyFuneral.style'
import { CardLayout,Moku, LoginContainer, Center, ChatWithMokuContainer,BackgroundStrip,Footer} from '../../../components/profile/Login/Login.style'
import { Row, Col } from '../../generic/grid'
import { Container, MenuButton } from '../../generic'
import {policyTypes} from './MyFuneral'
import moment from 'moment'
const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)


// TODO - Should be in a separate file with a story
function Funerals({ id, policyNumber, policyType, createdAt, coverAmount, state, currentPremiumTotal, history}) {
  console.log('Funeral row is ', policyNumber)
  return (

      <ListContainer>
        <Row centerVertical onClick={() => {
          console.log('all my funeral row clicked ', policyNumber)
          history.push(`/funeral/${id}`)
          }
        }>
          <Col centerVertical size="2"><Text><b>{policyNumber}</b></Text></Col>
          <Col centerVertical size="4"><Text>{moment(createdAt).format('DD-MM-YYYY')}</Text></Col>
          {!isMobileDevice() &&  <Col centerVertical size="6"><Text>{policyTypes(policyType)}</Text></Col>}
          {!isMobileDevice() && <Col centerVertical size="4"><Text>{state}</Text></Col>}
        </Row>
      </ListContainer>

  )
}


function AllMyFunerals({ funeralPolicies, history }) {
  console.log('all my funerals are ', funeralPolicies)

  return (
    <LoginContainer>
    <br /><br />
    <CardLayout>
      <MenuButton color={"#fff"} label={"Buy Funeral Cover"} onClick={()=> history.push('/funeral/new')} />
    </CardLayout>
        <Container title={"Please select a policy"} >
            {funeralPolicies.sort((a, b) => (a.policyNumber > b.policyNumber) ? -1 : 1).map((funeral) => <Funerals {...funeral} history={history}/>)}
      </Container>
  </LoginContainer>
  )
}

export default AllMyFunerals
