import styled from 'styled-components'
import { moku } from '../../../assets/images'

const Moku = styled.img.attrs({
  src: moku
})`
 height: 18vh;
 margin: 14px;
`


const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 57px)
`

const Center = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  width:100%;
`

const CardLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center
`


const Footer = styled.div`
  position:absolute;
  bottom:0;
  left:0;
  text-align: center;
  padding-bottom: 7px;
  opacity: 0.5;
  //background-color: ${props => props.white ? "none" : "#229490"};
  width: 100%;
  color:${props => props.color ? props.color : props.theme.color ? props.theme.color : "#323232"}
  font-weight:600;
  font-size:12px;
`
const HrLine = styled.div`
  width: 100%;
  height: 12px;
  border-bottom: 1px solid black;
  text-align: center;
  margin:27px 0
  `

 const HrText = styled.span`
  font-size: 18px;
  background-color: #F3F5F6;
  padding: 0 10px;
  `

export {
  CardLayout,
  Moku,
  LoginContainer,
  Center,
  Footer,
  HrLine, HrText
}