import React, {Fragment} from 'react'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo-hooks'
import { withRouter } from 'react-router-dom'
import { ActivityIndicator } from '../../../components/generic'
import { DebtItemDetails } from '../../../components/debt'

const GET_DEBT_INFO = gql`
  query GetDebtInfo($id: String!) {
    clientobligation(id: $id) {
      id
      clientCreditor {
        description
      }
      currentPayment {
        installment
        rate
      }
      originalBalance
      terms
      inceptionDate
      lastPaymentDate
      fetchedAt
      paymentHistory {
        status
        arrears
      }
      currentBalance
      accountTypeDescription
      statusCodeDescription
      remainingTerm
      paymentTypeDescription
    }
  }
`

function DebtInfo({ match }) {
  const { id } = match.params
    const { data , loading, error } = useQuery(GET_DEBT_INFO, { variables: { id  } })
    if (loading ) { return <ActivityIndicator large/> }
    if (error) { console.log(error); return "An error has occurred" }

    return <DebtItemDetails obligation={data.clientobligation} />
}

export default DebtInfo