import React, { Fragment, useState } from 'react'
import { FieldValue } from 'react-form-helper'
import { ChatCard, Modal, Button, ActivityIndicator } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { terms } from '../../../constants'
import { TermsLink } from './TermsAndConditions.style'
import { Response } from '../../generic/ChatFlow/ChatFlow.style'


const TermsAndConditions = ({ chatState, setChatState, gotoNext, step, submitting }) => {
  const { acceptedTerms, tcFieldName } = chatState

  const [showModal, setShowModal] = useState(false)

  const setTCAccepted = (accepted) => {
    setChatState({ acceptedTerms: accepted })
    gotoNext(step.nextSteps)
  }

  console.log('terms and conditions accepted terms ', acceptedTerms)

  return (
    <Fragment>
      {acceptedTerms === false && <ChatCard text="We will not be able to help you unless you accept the terms and conditions" noNext/>}
      <ChatCard text={<Fragment>Do you accept <TermsLink onClick={() => setShowModal(true)}>our terms and conditions?</TermsLink></Fragment>} noNext>
        {submitting ? <ActivityIndicator large="true"/>
        : (
        <FieldValue name={tcFieldName || "acceptedTerms"} renderField={({ input: { onChange, value } }) => (
          (value !== true) ?
            <Fragment>
              <Fragment>
                {showModal && <Modal title="Meerkat Terms and Conditions September 2017"
                    centered
                    width='98%'
                    backgroundColor= 'rgb(242, 246, 252)'
                    visible={showModal}
                    extraButtonsTop={true}
                    onOk={() => { setTCAccepted(true); setShowModal(false); onChange(true); }}
                    okText={"Accept"}
                    onCancel={() => { setTCAccepted(false); setShowModal(false) }}
                    cancelText={"Cancel"}
                  >
                    <div style={{textAlign:"left"}} dangerouslySetInnerHTML={terms} />
                  </Modal>}
                  <Row centerHorizontal>
                      <Button onClick={() => { onChange(true); setTCAccepted(true) }} action>Yes</Button>
                      <Button action="true"onClick={() => { onChange(false); setTCAccepted(false) }}>No</Button>
                  </Row>
              </Fragment>
            </Fragment>
          : value === true ? <Response>Yes</Response> : <Response>No</Response>
        )} />
        )}
      </ChatCard>
    </Fragment>
  )
}

export default TermsAndConditions
