import React from 'react'
import { Button, ButtonIcon, ButtonLabel } from './MenuButton.style'
import { Icon } from '../../generic'

function MenuButton({ color, border, background, image, icon, label, onClick, disabled }) {
  return (
    <Button color={color} border={border} background={background} onClick={onClick} disabled={disabled}>
      <ButtonIcon >
        {image && <img src={image} height="20px" alt={label} />}
        {icon && <Icon color={color} icon={icon} size="1x" />}
      </ButtonIcon >
      <ButtonLabel color={color}>{label}</ButtonLabel>
    </Button>
  )
}

export default MenuButton
