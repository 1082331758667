import styled from 'styled-components'

const TermsLink = styled.a`
  text-decoration: underline;
  :active {
    color: blue;
  }
  :hover, :focus {
    font-weight: bold;
  }
  :visited {
    color: green;
  }
`

const TermsBox = styled.div`

`

export {
  TermsLink,
  TermsBox
}
