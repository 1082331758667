import styled from 'styled-components'

const TextInputContainer = styled.div`
`;

const StyledInput = styled.input`
  font-size: 16px;
  font-family: ${props => props.theme.fontFamily};
  border-radius: 4px;
  border: 1px solid grey;
  margin-bottom:7px;
  margin-top: 5px;
  padding: 3px;
  padding-left: 10px;
  :focus {
    border: 2px solid ${props => props.theme.colors.primary};
    background-color: none;
    outline: none;
    margin-bottom:6px;
    margin-top: 2px;
  }
`;

export {
  TextInputContainer,
  StyledInput
}