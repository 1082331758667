import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Card, Button, Icon } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { FormField, FieldValues, FieldValue, SubmitFormButton } from 'react-form-helper'
import { CardText, AnimatedCard, CardContent, CardNextButtons } from './ChatCard.style'
import ActivityIndicator from '../ActivityIndicator'

// TODO: Add a field layout
function ChatCard({ submitBeforeStep, submit, handleNext, nextAfterDelay, text, fieldNames, labels, noNext, children, focus, nextTitle, submitting, nextOnValue, nextOnBlur, isActive = true, nextButtons, formSubmitDone, setFormSubmitDone }) {
  let shownCard = useRef(null)
  const [formSubmit, setFormSubmit] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [nextPressed, setNextPressed] = useState(false)
  // TODO: Clear the timeouts if not necessary
  useEffect(() => {
    setTimeout(() =>  {
      shownCard && shownCard.current && shownCard.current.scrollIntoView({
        behaviour: "smooth",
        block: "center",
        inline: "center"
      });
      if (nextAfterDelay || nextAfterDelay === 0) {
        setTimeout(() => {
          handleNext(null)
        }, nextAfterDelay)
      }
    }, 100)
  }, [])

  let inputProps
  const [autoNext, setAutoNext] = useState(false)
  const [fieldValue, setFieldValue] = useState(false)

  if (nextOnValue) {
    inputProps= {
      onChange: (e, v) => { console.log('nextOnValue onchange e & v', e,v); setFieldValue(v); setAutoNext(true) }
    }
  }

  if (nextOnBlur) {
    inputProps= {
      onChange: (e, v) => { setAutoNext(true) }
    }
  }

  if (submitBeforeStep && !formSubmitted) {
    submit();
    setFormSubmitDone(false)
    setFormSubmit(true)
    setFormSubmitted(true)
    return <div />
  }

  if (formSubmit) {
    if (!formSubmitDone) {
      return <ActivityIndicator large="true"/>
    } else {
      setFormSubmit(false)
      return <div />
    }
  }

  const gotoNext = (values, nextSteps) => {
    //setNextPressed(true)
    handleNext(values, nextSteps)
  }

  return (
    <div ref={shownCard}>
    <AnimatedCard>
      <CardText>{text}</CardText>
      <CardContent empty={!fieldNames || fieldNames.length === 0}>
        {fieldNames && fieldNames.map(fieldName =>
            labels ?
              ( <FormField
              mode={isActive ? "edit" : "display"}
              key={fieldName}
              name={fieldName}
              inputProps={inputProps}
              autoFocus={focus && fieldNames[0] === fieldName}
              /> )
              :
              ( <FormField
                mode={isActive ? "edit" : "display"}
                noLabel
                key={fieldName}
                name={fieldName}
                inputProps={inputProps}
                autoFocus={focus && fieldNames[0] === fieldName}
                /> )
        )}

      {children}
      </CardContent>
      <CardNextButtons>
      {fieldNames ? (
        <FieldValues names={fieldNames} renderValues={({ values, errors }) => {
          const hasErrors = errors && Object.keys(errors).reduce((total, field) => errors[field] ? total + 1 : total, 0) > 0
          if (autoNext) {
            if (!hasErrors) {
              console.log('nextonvalue autoNext values are ', values)
              console.log('nextonvalue autoNext fieldValue are ', fieldValue)
              gotoNext(values)
            }
            setAutoNext(false)
          }
          return (
            <Fragment>
              {!noNext && !nextButtons && <center><Button action="true" submitting={submitting || nextPressed} disabled={hasErrors || nextPressed} onClick={() => gotoNext(values) }>{nextTitle || "Next"}</Button></center>}
              {!noNext && nextButtons && <Row>{nextButtons.map(b => <Col centerHorizontal><Button action="true" disabled={hasErrors || nextPressed} onClick={() => gotoNext(values, b.nextSteps)}>{b.label}</Button></Col>)}</Row>}
            </Fragment>
          )
        }} />
      ) : (
        <Fragment>
          {!noNext && !nextButtons && <center><Button action="true" submitting={nextPressed}  disabled={nextPressed} onClick={() => gotoNext(null)} >{nextTitle || "Next"}</Button></center>}
          {!noNext && nextButtons && <Row>{nextButtons.map(b => <Col centerHorizontal><Button action="true"  submitting={nextPressed} disabled={nextPressed} onClick={() => gotoNext(null, b.nextSteps)}>{b.label}</Button></Col>)}</Row>}
        </Fragment>
      )}
      </CardNextButtons>
    </AnimatedCard>
    </div>
  )
}

export default ChatCard