import styled from 'styled-components'

const StartHeading = styled.span`
  font-size:1.5em;
  margin-bottom:7px;
  font-weight:bold;
`
const LotteryText = styled.span`
  font-size:15px;
  margin:7px;
  margin-bottom:14px;
  text-align: center;
  ${props => props.underline &&  `text-decoration: underline;` };
  ${props => props.italic &&  `font-style: italic;` };
`

  export {
    LotteryText,
    StartHeading
  }