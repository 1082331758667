import React, { Fragment } from 'react'
import moment from 'moment'
import gql from 'graphql-tag'
import { Query, graphql } from 'react-apollo'
import { useQuery } from 'react-apollo-hooks'
import { Link } from 'react-router-dom'
import { Moku, Center, ChatCards, LoginContainer } from '../../profile/RegisterForm/RegisterForm.style'
import { ClaimItemContainer, Text, Title } from './ClaimsList.style'
import { Row, Col } from '../../generic/grid'
import { ActivityIndicator, ChatCard, Button } from '../../generic'

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const GET_PROFILE = gql`
{
  myClient {
    id
    firstNames
    surname
    idNumber
    photo {
      id
      url
    }
  }
}`


function ClaimHeader() {
  return (
    <ClaimItemContainer>
      <Row centerVertical>
        <Col centerVertical size="2"><Title>CLAIM</Title></Col>
        <Col centerVertical size="4"><Title>Initiated</Title></Col>
        {!isMobileDevice() &&  <Col centerVertical size="12"> <Title>Type</Title></Col>}
        <Col centerVertical size="4"><Title>FOR</Title></Col>
        {!isMobileDevice() && <Col centerVertical size="12"><Title>Id Number</Title></Col>}
        <Col centerVertical size="8"><Title>CLAIM STATUS</Title></Col>
      </Row>
    </ClaimItemContainer>
  )
}

// TODO - Should be in a separate file with a story
function ClaimItem({ client,  claimType, claimId, claimNumber, createdAt, status}) {
  console.log('client is ', client)
  const {firstNames, surname, idNumber} = client

  console.log('claim id is ', claimId, status)
  return (
    <Link to={`/claim/${claimId}`}>
      <ClaimItemContainer>
        <Row centerVertical>
          <Col centerVertical size="2"><Text><b>{claimNumber}</b></Text></Col>
          <Col centerVertical size="4"><Text>{moment(createdAt).format('DD-MM-YYYY')}</Text></Col>
          {!isMobileDevice() &&  <Col centerVertical size="6"><Text>{claimType.replace(/_/g,' ')}</Text></Col>}
          <Col centerVertical size="4"><Text>{firstNames} {surname}</Text></Col>
          {!isMobileDevice() && <Col centerVertical size="4"><Text>{idNumber}</Text></Col>}
          <Col centerVertical size="8"><Text>{status}</Text></Col>
        </Row>
      </ClaimItemContainer>
    </Link>
  )
}

function ClaimsList({ startNewClaim, claims }) {

  console.log('if no claims got to start a new Claim ', claims, claims.length)

  if (!claims || claims.length === 0)  {
    console.log('no claims, go to new')
    startNewClaim()
  }

  const { data , loading, error } = useQuery(GET_PROFILE)
  if (loading ) { return <ActivityIndicator large/> }

  return (
    <Center>
      <Moku />
      <div style={{width: "100%", maxWidth: 900, margin: 'auto'}}>
        {claims &&  <ChatCard text={`${data.myClient.firstNames}, these are the claims I have for you.`} noNext />}
        {claims &&  <ChatCard text="You can click a claim to look at it, or start a new claim by clicking the button below." noNext />}
        <center>
          <Button action="true"hide={false} onClick={() => startNewClaim()} >New Claim</Button>
        </center>
        {claims &&  <ChatCard text={
          <Fragment>
            {claims.sort((a, b) => (a.claimNumber > b.claimNumber) ? -1 : 1).map((claim, index) => <ClaimItem {...claim} /> )}
          </Fragment>} noNext/>}
      </div>
    </Center>
  )
}

export default ClaimsList
