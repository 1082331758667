
import Numeral from 'numeral'
import moment from 'moment'
import 'numeral/locales/en-za.js'

// switch between locales
Numeral.locale('en-za')

function printCurrency(amount, decimals = true) {
  return decimals ? Numeral(amount).format('$ 0,0.00') : Numeral(amount).format('$ 0,0')
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

const myApiKey = 'AIzaSyB1c9FS8FlxidcBMsndn7bhYhSRt4RPsHs'

const getLocation = async () => {

  return new Promise((resolve, reject) => {
    //resolve(ipStack())
     navigator.geolocation.getCurrentPosition((pos) => resolve(googleLocationApi(pos)), (err) => {console.log("Geolocation returned the following: ", err.message); resolve(ipStack())}, options)
    })
}

const ipStack = async () => {
  console.log('trying ipStack ');

  /*
  let ip = ''

  getIP().then((response) => {
    console.log('get ip returned ', response);
    ip = response.ip
    console.log('response.ip ', ip)
    ipLocate(ip).then((response) => console.log('response from iplocate is ', response))
  })
*/

  let result = await fetch("https://api.ipstack.com/check?access_key=56a90662652cae75f6a9152eec3e9a34")
  let loc = await result.json()
  console.log('result from ipstack is ', loc)

  if (loc) {
  return { "full": `${loc.city} ${loc.zip}, ${loc.region_name}, ${loc.country_name} `, "suburb": "", "country_name": loc.country_name, "city": loc.city, "region_name": loc.region_name, "zip": loc.zip, "geo": { "latitude": loc.latitude, "longitude": loc.longitude }, "ip": loc.ip }
  }
  else {
    console.log('location was not found at ipstack')
    return { "full": "unknown", "suburb": "", "country_name": "unknown", "city": "unknown", "region_name": "unknown", "zip": "unknown", "geo": { "latitude": "unknown", "longitude": "unknown" }, "ip": "unknown" }
  }

}

const googleLocationApi = async (pos) => {

  console.log('We have permission to access location ')

  // get IP info from ipify.org
  const ipResponse = await fetch("https://api.ipify.org/?format=json")
  const ip = await  ipResponse.json()
console.log('ipify ', ip)

  let loc = ""
  let crd = pos.coords
  let lat = crd.latitude
  let lng = crd.longitude
  console.log(`Your current position is: Latitude : ${crd.latitude}, Longitude: ${crd.longitude}. More or less ${crd.accuracy} meters.`);

  const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + lat + ',' + lng + '&key=' + myApiKey)
  const responseJson = await  response.json()
  
  if (responseJson.error_message) {
    return ipStack()
  }
  //console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));

  let formatted = responseJson && responseJson.results && responseJson.results[0] && responseJson.results[0].formatted_address
  let locationArray = formatted && formatted.split(",")
  let country = locationArray[4]
  let zip = locationArray[3]
  let city = locationArray[2]
  let suburb = locationArray[1]
  let street = locationArray[0]
  let region_name = ""

  console.log('location array is ', locationArray)

  let firstAddress = responseJson.results[1].address_components;
  console.log('firstAddress ', firstAddress)
  let full = responseJson.results[0].formatted_address
  console.log('Full Address ', full)
  let short = responseJson.results[1].formatted_address
  console.log('Short Address ', short)
  if (full.search(/unnamed/)) {
    full = short
  }

  /*
  let suburb = firstAddress[0].long_name
  console.log('street ', suburb)
  let city = firstAddress[1].long_name
  console.log('city ', city)
  let zip = firstAddress[3].long_name
  console.log('zip ', zip)
  let country = firstAddress[2].long_name
  console.log('country ', country)
  console.log('finding region ', responseJson.results[1].address_components)
  let region_name = responseJson.results[1].address_components[4].long_name
  console.log('region_name ', region_name)
*/
  return { "full": full, "suburb": suburb, "country_name": country, "city": city, "region_name": region_name, "zip": zip, "geo": { "latitude": crd.latitude, "longitude": crd.longitude }, "ip": ip.ip }
}


const getIP = async () => {
  let ipResponse = await fetch('https://jsonip.com/')
  let ip = await ipResponse.json()
  return ip
  console.log('ip is ', ip)
}

const ipLocate = async (ip) => {

  const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
  const  targetUrl = `https://www.iplocate.io/api/lookup/${ip}`

  fetch(proxyUrl + targetUrl)
    .then((response) => response.json())
    .then((loc) => {

      console.log('fetch iplocate worked  reponse is ', loc)
      return { "full": `${loc.city} ${loc.postal_code}, ${loc.subdivision}, ${loc.country_name} `, "suburb": "", "country_name": loc.country, "city": loc.city, "region_name": loc.subdivision, "zip": loc.postal_code, "geo": { "latitude": loc.latitude, "longitude": loc.longitude }, "ip": ip }
    })
    .catch((error) => console.log('damm, something happened ', error))
}

const toDecimalPlaces = (value, decimals) => Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)


function printDate(date) {
  return date ? moment(date).format('YYYY-MM-DD') : ""
}

export {
  printCurrency,
  getLocation,
  toDecimalPlaces,
  printDate
}