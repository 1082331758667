import React, { Fragment, useState } from 'react'
import { Container, Icon, Button, Modal } from '../../generic'
import { Row, Col, Break, Item } from '../../generic/grid'
import { printCurrency } from '../../../lib/utils'
import {ButtonBox} from '../../generic/Modal/Modal.style'
import moment from 'moment'
import { SavingsNeedIcon, NeedProgressBar } from './SavingsNeedItem.style'
import SavingsNeedItemForm from './SavingsNeedItemForm'
import WithdrawForm from './WithdrawForm'
import SaveMoreForm from './SaveMoreForm'

let SimpleModal = ({buttonText, modalTitle, disabled, showWithdrawal, showSaveMore, changeTarget, data}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    console.log('Button was clicked')
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button action disabled={disabled} onClick={handleOpen}>
        {buttonText}
      </Button>

      <Modal
       visible={open}
       onCancel={handleClose}
       hideOk={true}
       hideCancel={true}
  >
        <Col alignItems="center">
        <h2 id="simple-modal-title">{modalTitle}</h2>
          {changeTarget && <SavingsNeedItemForm {...data} closeModal={handleClose}  />}
          {showWithdrawal && <WithdrawForm {...data} availableAmount={Math.round((data.amount || 0) * 100) / 100}  refetchQueries={data.refetchQueries}  closeModal={handleClose}/>}
          {showSaveMore && <SaveMoreForm  {...data}  closeModal={handleClose}/>}
        </Col>
      </Modal>
    </div>
  );
}

function SavingsNeedItem(data) {
  const { bucketType = "EMERGENCY", name = "Emergency Fund", amount, availableAmount = 0, targetAmount, targetDate, description, percentageFull  = 0, onBack  } = data

  return (
    <Fragment>
     <Col>

      <Row centerHorizontal >
        <SavingsNeedIcon bucketType={bucketType} />
      </Row>
      
      <Row centerHorizontal>
        <NeedProgressBar percentageFull={percentageFull} />
      </Row>

      <Row centerHorizontal>
        <Container title={name} centerTitle>
          <Col centerHorizontal>
          
            <p style={{fontWeight:700}}>Saved: {printCurrency(amount)}</p>
            
            {targetAmount && targetAmount > 0 && <p>Target Amount: {printCurrency(targetAmount)}</p>}
            
            {targetDate && <p>Target Date: {moment(targetDate).format('Do MMMM YYYY')} </p>}
            
            <i>{description}</i>
            
            <br />
          
          <Row>
            <Button onClick={onBack}>Back</Button>
            <SimpleModal changeTarget="true" disabled={bucketType === "EMERGENCY"} changeTarget="true" data={data} buttonText="Edit" modalTitle="Edit Bucket Details" />
            <SimpleModal showWithdrawal="true" data={data}   buttonText="Withdraw" modalTitle="Withdraw from Savings"/>
            { 1 == 2 && <SimpleModal showSaveMore="true" data={data}  buttonText="Add Money" modalTitle="Add to Savings" />}
          </Row>

          <br />
          </Col>
        </Container>

      </Row>
    </Col>
    </Fragment>
  )
}

export default SavingsNeedItem