import styled from 'styled-components'

const CardContainer = styled.div`
  margin: ${props => props.theme.margin+"px"};
  padding: ${props => props.theme.padding+"px"};
  border:${props => `1px solid ${props.theme.border}`};
  border-radius: 14px;
  background:#fff
`

const StyledCardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-conent:center;
  max-width:650px;
  min-width:280px;
  padding:14px;
  width: 50%;
  margin: 14px;
  border-radius: 14px;
  border:${props => `1px solid ${props.theme.border}`};
  background-image: ${props => props.theme.backgroundImage};
  //linear-gradient(#3E4255,#55513E);
`

const CardTitle = styled.span`
  font-weight: bold;
  margin-botton: ${props => props.theme.padding+"px"}
`

const CardBody = styled.div`
`

export {
  StyledCardContainer,
  CardContainer,
  CardTitle,
  CardBody
}