import styled from 'styled-components'
import { Row } from '../../generic/grid'

const MonthCircle = styled.div`
  display: flex;
  background-color: #c8c8c8;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  border: 1px solid #a8a8a8;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`

const RowContainer = styled(Row)`
  padding-top: 7px;
  padding-bottom: 7px;
  ${props => props.topRow ? '' : `border-top: 1px solid #c8c8c8;`}
`

const stateColor = {
  "PAID": "green",
  "PENDING": "orange",
  "OVERDUE": "red"
}

export {
  MonthCircle,
  RowContainer,
  stateColor
}