import React from 'react'
import moment from 'moment'
import { printCurrency } from '../../../lib/utils'
import { Icon } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { ClientCircle, RowContainer } from './LifeCoveredRow.style'

function LifeCoveredRow({ idNumber, firstNames, surname, gender, DOB, relationship, coverAmount, topRow, showEdit }) {
  
  console.log('Funeral Policy Information lives covered Row', idNumber, firstNames, surname, gender, DOB, relationship, coverAmount )

  const clientLetters = firstNames || surname ? ((firstNames ? firstNames[0] : "") + (surname ? surname[0] : "")).toUpperCase() : ""
  
  const titleCase = (str) => {
    if (str) {
    return str.toLowerCase().split(/[\s_]+/).map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ')}

    else return (str)
  }

  return (
      <RowContainer topRow={topRow}>
        {console.log('Funeral Policy Information RowContainer life' )}

        <Col width="60px" centerHorizontal centerVertical>
          <Row>
            <ClientCircle>{clientLetters}</ClientCircle>
          </Row>
        </Col>
        <Col centerVertical>
          <div>
            <Row>{`${titleCase(firstNames)} ${titleCase(surname)}`}</Row>
            <Row>{idNumber ? idNumber : moment(DOB).format('YYYY-MM-DD')}</Row>
          </div>
        </Col>
        <Col width="100px" style={{ paddingRight: "14px" }} centerVertical alignHorizontal="right">{coverAmount && coverAmount > 0 && printCurrency(coverAmount)}</Col>
      </RowContainer>
  )
}

export default LifeCoveredRow
