import styled from 'styled-components'

const UploadContainer = styled.div`
  display: flex;
  flex-direction:column;
  padding-top: 3px;
  padding-bottom: 3px;
  //border: 3px solid lightgrey;
  justify-content: center;
  align-items:center;
`
const StripedBar = styled.div`
  width:${props => props.progress};
  height:14px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #315ad5;
  align-self: flex-start;
  background-image: linear-gradient(-45deg,rgba(255,255,255,.2) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.2) 50%,rgba(255,255,255,.2) 75%,transparent 75%,transparent);

  box-shadow:
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
`

const BarFillStripes = styled.div`
  height:14px;
  align-self: flex-start;
  background:#315ad5;
  width:${props => props.progress}
  border-radius:8px;
  background-image:linear-gradient(-45deg,rgba(255,255,255,.2) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.2) 50%,rgba(255,255,255,.2) 75%,transparent 75%,transparent);
  -webkit-transition:width .8s ease;
  -moz-transition:width .8s ease;
  transition:width .8s ease;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes progressbar{
  from {
      width:0
  }
  to {
      width:${props => props.progress}
  }
}
@keyframes progressbar {
from {
  width:0
}
to {
  width:${props => props.progress}
}
`

//(  center bottom, rgb(43,194,83) 37%, rgb(84,240,84) 69% )

export {StripedBar,UploadContainer, BarFillStripes}