import  {StyledLabel, StyledInput} from './FileInput.style'
import React, { Fragment, useState } from 'react'
import { Icon } from '../../generic'

function FileInput({ input,prompt,...props }) {
  const [display, setDisplay] = useState(true)

  const handleChange = (e) => {
      props && props.onChange && props.onChange(e)
      setDisplay(false)
  }

  return (
    <Fragment>
    {display ? <StyledLabel htmlFor="FInput"  >
       <StyledInput type="file" id="FInput" {...input} {...props}  onChange={handleChange}/>
          {prompt ? prompt : "Upload"}&nbsp;&nbsp; <Icon size="1x" icon="cloud-upload-alt" />
        </StyledLabel>
        : <Icon size="2x" spin icon="spinner" />}
    </Fragment>
  )
}

export default FileInput
