import React from 'react'
import { Row, Col } from '../../generic/grid'
import { ClientCircle, RowContainer } from './LifeCoveredRow.style'
import moment from 'moment'


function BeneficiaryRow({ payTo, bankAccount, percentage, topRow }) {
  const { idNumber, firstNames, cellNumbers, surname, DOB } = payTo

  const bankName = (bankAccount && bankAccount.bankName) || ''
  const accountNo = (bankAccount && bankAccount.accountNo) || ''
  
  console.log('Funeral Policy Information beneficiary row', idNumber, firstNames, surname, cellNumbers, bankName, accountNo, percentage, DOB )

  const beneficiaryLetters = firstNames || surname ? ((firstNames ? firstNames[0] : "") + (surname ? surname[0] : "")).toUpperCase() : ""
  
  const titleCase = (str) => {
    console.log('string is ', str)
    if (str) {
    return str.toLowerCase().split(/[\s_]+/).map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ')}

    else return (str)
  }
  
return (
      <RowContainer topRow={topRow}>
        {console.log('Funeral Policy Information RowContainer ' )}
        <Col width="60px" centerHorizontal centerVertical>
          <Row>
            <ClientCircle>{beneficiaryLetters}</ClientCircle>
          </Row>
        </Col>
        <Col centerVertical>
          <div>
            <Row>{`${titleCase(firstNames)} ${titleCase(surname)} ${idNumber ? idNumber : DOB ? moment(DOB).format('YYYY-MM-DD') : ' -'}`}</Row>
            <Row>{percentage}%</Row>
            <Row>{cellNumbers ? cellNumbers : '-'}</Row>
            <Row>{bankName}</Row>
            <Row>{accountNo}</Row>

          </div>
        </Col>
      </RowContainer>
  )
}

export default BeneficiaryRow
