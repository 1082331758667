import React, { Fragment, Component, useState } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormFieldArray, FormField, FieldValue, SubmitFormButton } from 'react-form-helper'
import { banks } from '../../../constants'
import { renderLabel, renderDisplay, renderInput } from '../../generic/form'
import { Button, ActivityIndicator, Modal } from '../../generic'
import { Col, Row } from '../../generic/grid'
import Select  from '../../generic/Select'
import { useMutation } from 'react-apollo-hooks'
import { isRequired } from '../../../lib/validators'

const getBankAccountOptions = (bankAccounts) => {
  let banks = []
  bankAccounts.map(b => {
    console.log('bank accounts found is ', b)
    if (b.bankName.indexOf('UNKNOWN') == -1 && b.accountNo.indexOf('UNKNOWN') == -1) {
    let bank = {text: b.bankName + ' ' + b.accountNo, value:b.id}
    banks.push(bank)}
    })

  const seen = new Set();
  const options = banks.filter(b => {
    const duplicate = Array.from(seen).find(s => s.text === b.text);
    seen.add({text: b.text, value:b.id});
    return !duplicate;
   }).sort((a, b) => (a.text > b.text) ? 1 : -1);

  options.push({text:'Add new bank account', value:"NEW"})
  return options
}

function Layout({ id: originalId, bankAccounts, productDescription, setShowModal, mode, setMode, showModal, onChange, history }) {
  const [saving, setSaving] = useState(false)
  const [changeBankId, setChangeBankId] = useState(originalId ? originalId : bankAccounts && bankAccounts.length > 0 ? bankAccounts[0].id: null)
  const showNew = bankAccounts && bankAccounts.length > 0 ? false : true

  console.log('show new flags ', showNew, changeBankId, originalId, bankAccounts, history)

  return (
    <Fragment>
    { !showNew && changeBankId !== "NEW" && <Fragment> 
      <h3>{`Select a bank account for ${productDescription} or add a new one`}</h3>
      <Select
        value={changeBankId}
        options={getBankAccountOptions(bankAccounts)}
        prefix="Account: "
        onChange={(v) => {console.log('account selected is ', v ); setChangeBankId(v)}}
      />  
    
      </Fragment>}
    
      { changeBankId !== "NEW" && !showNew && <Row centerHorizontal>
      {console.log('changeID ', changeBankId, originalId, saving)}
        <Button  color="secondary" size='large' type="danger" onClick={() => setShowModal(false)}>Cancel</Button>
        <Button action="true" disabled={!changeBankId || saving} submitting={saving} onClick={() => { if (changeBankId !== originalId) { setSaving(true); onChange(changeBankId).then(() => { setShowModal(false) }) } else { setShowModal(false) } } }>Save</Button>
      </Row>}

      { (changeBankId === "NEW" || showNew ) && (
        <>
        <h2>Please enter your bank details</h2>
        <Col >
          <FormField name="bankName" />
          <FormField name="accountNo" />
          <Row centerHorizontal>
            <Button  color="secondary" size='large' type="danger" onClick={() => { 
              if (originalId) {
                setShowModal(false)}
                else history.push(`/${productDescription.toLowerCase()}`)
            } 
              }>Back</Button>
            <SubmitFormButton renderSubmitButton={({ submit, submitting, invalid }) => (
            <Button action="true" disabled={submitting || invalid || saving} submitting={submitting || saving} onClick={() => { setSaving(true); submit(); console.log('Submitting Form'); }}>Save</Button>
            )} />
          </Row>
        </Col>
        </>
      )}
    </Fragment>
  )
}

const fields = {
  bankName: {
    label: "Bank Name",
    type: "select",
    noSelectionLabel:"  ",
    options: banks
  },
  accountNo: {
    label: "Account Number",
    type: "text",
    validate: isRequired
  }
}


function ChangeBankModal({ bankAccounts, id, clientId, productModel, productDescription, productId, showModal, setShowModal, refetchQueries, history }) {
  console.log('Change Bank id', productModel, bankAccounts, id, clientId)


  const changeProductAccount = useMutation(gql`
    mutation ChangeProductAccount($bankAccountId: ObjID) {
      update${productModel}(id: "${productId}", input: { paymentBankAccountId: $bankAccountId }) {
        paymentBankAccount {
          id
          accountNo
          bankName
          associatedWith {
            id
            type
            reference
          }
        }
      }
    }
  `)

  /*if (loading) {
    return <Modal><ActivityIndicator large /></Modal>
  }*/

  if (!showModal) {
    return <div />
  }


  const changeProductAccountId = (bankAccountId) => changeProductAccount({ variables: { bankAccountId }, awaitRefetchQueries: true, refetchQueries })

  return (
    <Modal visible={showModal} hideOk hideCancel>
      <GraphQLFormProvider
        name="changeBankAccount"
        mode="add"
        id={id}
        fields={fields}
        initialValues={{ clientId, bankName:"Capitec Bank "}}
        addMutation={gql`
          mutation CreateBankAccount($input: CreateBankAccountInput!) {
            createBankAccount(input: $input) {
              id
            }
          }
        `}
        renderLabel={renderLabel}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <Layout bankAccounts={bankAccounts} productDescription={productDescription} setShowModal={setShowModal} history={history} onChange={bankAccountId => changeProductAccountId(bankAccountId)} {...props} />}
        fields={fields}
        keepInitialValues
        refetchQueries={refetchQueries}
        afterSubmit={({ data }) => changeProductAccountId(data.createBankAccount.id).then(() => setShowModal(false))}
      />
    </Modal>
  )
}

export default ChangeBankModal
