import React, { Component} from 'react'
import { Route } from 'react-router-dom'
import Lottery from './Lottery'

// TODO: Should only be 1  FuneralForm
export default class Lotterys extends Component {
  render() {
    return (
      <Route path='/draw' component={Lottery} />
    )
  }
}