import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
// A great library for fuzzy filtering/sorting items
import matchSorter from 'match-sorter'
import {Button, TextInput} from '../../generic'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Moku, Center, ChatCards, LoginContainer } from '../../profile/RegisterForm/RegisterForm.style'

const Styles = styled.div`
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    border: none;
    margin:0 auto;
    tr {
      text-align: left;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      
      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }
      :last-child {
        border-right: 0;
      }

      input {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        -webkit-font-feature-settings: 'tnum';
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 0px 11px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:12px;
  }


  .right {
    text-align: right;
  }
`;
// Create an editable cell renderer
const ImageCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {

  console.log('editable cell called ', initialValue, index, id)

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    console.log('EditableCell onChange verified ', e.target.value)
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    console.log('EditableCell onBlur ', e)
    // run mutation to change description
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  
  return <input value={value} onChange={onChange} onBlur={onBlur} />;

}


// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {

  console.log('editable cell called ', initialValue, index, id)

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    console.log('EditableCell onChange verified ', e.target.value)
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    console.log('EditableCell onBlur ', e)
    // run mutation to change description
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  
  return <input value={value} onChange={onChange} onBlur={onBlur} />;

}


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-Select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}



function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data, updateMyData, skipPageReset, onRowPress}) {
  
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      autoResetPage: !skipPageReset,
      filterTypes,
      updateMyData
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
		useSortBy,
    usePagination
  )


  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageSize = (rows.length > 20) ? 20 : rows.length
  //const firstPageRows = rows.slice(0, firstPageSize)
  //console.log('table rows total ', rows)
  const [initialPageCount, setInitialPageCount] = useState(pageCount)
  return (
    <Styles>
      <div className="tableWrap">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps( {className: column.collapse ? 'collapse' : ''},column.getSortByToggleProps() )}>

                  {column.render('Header')}
                  <span> {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""} </span>
                  {/* Render the columns filter UI */}
                  {console.log('Render the columns filter UI ', column) }
                  {initialPageCount > 1 && <div> {column.canFilter ? column.render('Filter') : null}</div>}
                </th>
              ))}
            </tr>
          ))}

        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row) 
            return  (
                <tr {...row.getRowProps({onClick: () => onRowPress(row.original)  })}>

                {row.cells.map(cell => {
                   return <td {...cell.getCellProps({className: cell.column.className, className: cell.column.collapse ? 'collapse' : ''})}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps({className: column.className})}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      {initialPageCount > 1 && <div className="pagination">
        <Button width="50px" size="small" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>{' '}
        <Button width="50px"  size="small" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>{' '}
        <Button width="50px"  size="small" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>{' '}
        <Button  width="50px" size="small" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </Button>{' '}
        <span >
          Page{' '}
          <strong>
            {`${pageIndex + 1} of ${pageOptions.length} `}
          </strong>
          {`| Go to page: `}
        </span >
        <span >
         
          <input
            type="number"
            style={{width:50}}
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
          />
        </span>{' '}
        <select
        style={{width:75}}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>      
        </div>}
        </div>
    </Styles>
  )
}

const columns = [
  {
    Header: 'Claim Number',
    accessor: 'claimNumber',
    filter: 'fuzzyText',

  },
  {
    Header: 'Claim Type',
    accessor: 'claimType',
    Filter: SelectColumnFilter,
    filter: 'Select',
    //Cell: c => c && typeof c === 'string' && c.replace(/_/g,' ')
  },
  {
    Header: 'Created',
    assessor:'createdAt',
    Cell: ({value}) => moment(value).format('YYYY-MM-DD'),
    disableFilters:true,
    collapse: true,

  },
  {
    Header: 'Status',
    accessor:'status',
    Filter: SelectColumnFilter,
    filter:'Select',
    collapse: true,
  }
]

const rowClick = ({claimId}) => <Link to={`/claim/${claimId}`} />


let ClaimsTable = ({claims, startNewClaim, history}) => {

  console.log('ReactTable data', claims)
  
  let useColumns = React.useMemo( () => columns )

  const [data, setData] = useState(() => claims)
  const [skipPageReset, setSkipPageReset] = useState(false)


  if (claims.length < 1 ) startNewClaim()
  //if (claims.length === 1 ) history.push(`/claim/${claims[0].claimId}`)

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    console.log('Editable cell updateMyData ', value)
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  return (
    <Center>
      <Moku />
        <center>
          <Button action="true"hide={false} onClick={() => startNewClaim()} >New Claim</Button>
        
          <Table
            columns={useColumns} 
            data={data}
            onRowPress={ ({claimId})=>  history.push(`/claim/${claimId}`) }
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
          />
          </center>
    </Center>
  )
}

export default ClaimsTable