import React, { useState, useEffect, useRef, Fragment } from 'react'
import { usePdf } from 'react-pdf-js'
import fileDownload from 'js-file-download'
import { Button, Icon} from '../../generic/'
import leftPad from 'left-pad'

const saveFile = (fileUrl, spin, setSpin) => {
  if (spin === "") {
    setSpin(true)
    var link = document.createElement("a");
    link.download = "CreditReport.pdf";
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSpin(false)
  }
}
const Pdf = ({ fileUrl, showPdf, setShowPdf, file  }) => {

  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(null)
  const [spin, setSpin] = useState("")
  const [url, setURL] = useState(fileUrl)

  if (file) {
    setURL(file)
  }
  
  else if (!fileUrl ) {
    return "No file to display"
  }
  else {
/*
    if (process.env.NODE_ENV !== 'production') {
      console.log("Use CORS to get past google cors check")
      const cors = "https://cors-anywhere.herokuapp.com/"
      setURL(cors + fileUrl)
    }
    else { setURL(url) }
*/
  }

  console.log('url is ', url)
  console.log('Process.env.node is  ',process.env.NODE_ENV)

  const renderPagination = (page, pages) => {
    if (!pages) {
      return null
    }
    let previousButton = (
      <Button action="true"disabled={page === 1} onClick={() => setPage(page - 1)}>
      <Icon size="1x"   icon="arrow-left" />
      {' '} Previous Page
      </Button>
    )
    let nextButton = (
      <Button action="true"disabled={page === pages} onClick={() => setPage(page + 1)}>
        Next Page {' '}
        <Icon size="1x"  icon="arrow-right" />
      </Button>
    )

    return (
      <div style={{display:"flex",justifyContent:"center", flexDirection:"row", alignItems:"center"}}>
            {previousButton}{'  '}
            <Button action="true" disabled={spin || loading} onClick={() => saveFile(fileUrl,spin, setSpin) } >Download</Button>{'  '}
            {nextButton}
      </div>
    )
  }

    const canvasEl = useRef(null)
    const [loading, numPages] = usePdf({
      file: url,
      page,
      canvasEl
    })

    useEffect( () => { setPages(numPages) })

    return (
      <div style={{display:"flex",justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
        {loading && <span>Loading...</span>}
        {!loading && renderPagination(page, pages)}
        {!loading && <canvas ref={canvasEl} />}
      </div>
    )
  }

export default Pdf