import React from 'react'
import { LabelContainer, LabelText } from './Label.style'

function Label({ color, children, capitalize }) {
  return (
      <LabelContainer color={color}>
        <LabelText>{capitalize ? children.toUpperCase() : children}</LabelText>
      </LabelContainer>
  )
}

export default Label
