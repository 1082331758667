import moment from 'moment'
import isValid from 'date-fns/isValid'
import gql from 'graphql-tag'

const GET_COVER = gql`
  query getTotalCover($idNumber: String!) {
    getTotalCover(idNumber: $idNumber) 
  }
`

const isDate = (value) => {
  if (isValid(value)) {
    return undefined
  }
  return "Invalid Date"
}

const minAge = {
  POLICY_HOLDER: 18,
  SPOUSE: 18,
  CHILD: 1,
  PARENT: 18,
}

const maxAge = {
  POLICY_HOLDER: 60,
  SPOUSE: 60,
  CHILD: 17,
  PARENT: 75,
}

const getFieldName = (name) => {
  if (name) {
    var fieldName = name.split(".")
    console.log('the field name is ', fieldName[1])
    return fieldName[1]
  }
  return undefined
}

const getIndex = (name) => {
  if (name) {
    var openBracket= name.indexOf('[')
    var closeBracket = name.indexOf(']')
    var index = name.substr(openBracket + 1, (closeBracket - openBracket - 1) )
    console.log('the member index is ',index)
    return index
  }
    else return 0
}

const forMemberTypes = (memberTypes, validators) => (value, allFields, props, name) => {
  console.log('forMemberTypes ', name, memberTypes, allFields, validators)
  if (name && allFields.length > 0)  {
    const index = getIndex(name)
    const memberType = allFields.members[index] && allFields.members[index].memberType
    if (memberTypes.includes(memberType)) {
      return validators instanceof Array ? validators.map(validator => validator(value, allFields, props, name)) : validators(value, allFields, props, name)
    }
  }
    return undefined
}

const isRequired = (value, allFields, props, name) => {
    return (value ? undefined : 'This field is required')
}
const isRequiredIfNotSA = (value, allFields, props, name) => {
  console.log('isRequiredIfNotSA ', value, name, allFields )

  const index = getIndex(name)
  const idType = allFields.members[index].idType
  console.log('isRequiredIfNotSA ', idType )

  if ( idType === "rsa_id") {
   return (value ? undefined : "This is required for non-South African ID's")
  }
  else return undefined
}

const doesDOBMatchIdNumber = (value, allFields, props, name) => {
  
  console.log('doesDOBMatchIdNumber ', value, name, allFields )
  if (name && !isNaN(value) ) {
    // get the member's idType
    const index = getIndex(name)
    const idType = allFields.members[index].idType

    console.log('age error doesDOBMatchIdNumber ', idType, value )

    if (name && idType === "rsa_id") {
      let memberIndex = getIndex(name)
      let member = allFields.members[memberIndex]
      if (value && member && member.idNumber) {
        const DOBFromIdNumber = getDOBFromID(member.idNumber, member.idType)
        const DOB = moment(member.DOB)
        console.log('DOB is', DOB, DOBFromIdNumber)
        if (DOBFromIdNumber.diff(DOB) === 0) {
          return undefined
        } else {
          return '"Birth Date" does not match ID Number'
        }
      }
    }
  }
  return undefined
}

const getDOBFromID = (idNumber, idType) => {
  
  console.log('age error getDOBFromID ', idNumber, idType )
  
  if (idNumber && idType === "rsa_id") {
    var yy = idNumber.slice(0, 2)
    var dob = `19${idNumber.slice(0, 6)}`
    if (yy < 25 ) {
      dob = `20${idNumber.slice(0, 6)}`
    }
    var birthday = moment(dob, 'YYYY MM DD')
    console.log('getDOBFromID ', birthday)
    return birthday;
  }
  return undefined;
}

async function getCover (client, idNumber, coverAmount) {
  const response = await client.query({ query: GET_COVER, variables:{ idNumber } })
  const data = await response
  if ( data.data.getTotalCover + coverAmount > 100000) {
    console.log('isExistingOver100K: too much data is', data.data.getTotalCover)
    return "Total cover must be less than R100,000 (person already has R" + data.data.getTotalCover + ")"
  }
  else {
    console.log('isExistingOver100K: data is', data.data.getTotalCover)
    return undefined
  } 
}  

const isExistingOver100K = (value, allFields, props, name) => {
  
  return undefined

  if (!value ) return undefined
  
  const { coverAmount } = allFields
  const { client } = props
  const idNumber = value
  const tooMuch = getCover(client, idNumber, coverAmount)
  console.log(' isExistingOver100K:', tooMuch)
  return tooMuch
}

const doesAgeAtSignupMatchDOB = (value, allFields, props, name) => {
  let { idType } = allFields
  if (!idType) { idType = "rsa_id"}

  console.log('age error doesAgeAtSignupMatchDOB ', value, allFields, props, name)
  
  if (value && name ) {
    let memberIndex = getIndex(name)
    let member = allFields.members[memberIndex]
    console.log('age error doesAgeAtSignupMatchDOB member ', member)

    if (allFields.members && member  && (member.DOB || (member.idNumber && idType === "rsa_id")))  {
      const DOB = member.idNumber && idType === "rsa_id" ? getDOBFromID(member.idNumber, member.idType ) : moment(member.DOB)
      const currentAgeFromDOB = moment().diff(DOB, 'years')
      const ageAtSignup = member.ageAtSignup
      if ((member.memberType === "POLICY_HOLDER" || member.memberType === 'CHILD') && !DOB  && ! ageAtSignup ) return 'Enter either Age or ID or DOB'
      console.log('DOB is', ageAtSignup, currentAgeFromDOB )
      if (!ageAtSignup || currentAgeFromDOB === 0 || currentAgeFromDOB == ageAtSignup) {
        return undefined
      } else {
        return 'Age given does not match DOB'
      }
    }
  }
  return undefined
}

const doesDobMatchAge = (value, allFields, props, name) => {
  const { idType } = allFields

  console.log('doesDobMatchAge ', value, allFields, props, name)
  if (value && name ) {
    let memberIndex = getIndex(name)
    let member = allFields.members[memberIndex]
      const currentAgeFromDOB = moment().diff(value, 'years')
      const ageAtSignup = member.ageAtSignup
      console.log('doesDobMatchAge DOB age and age given are', currentAgeFromDOB, ageAtSignup,  )
      if (currentAgeFromDOB == ageAtSignup) {
        return undefined
      } else {
        return 'Your date of birth does not match age given'
      }
    }
  return undefined
}


const doesAgeAtSignupMatchId = (value, allFields, props, name) => {
  console.log('doesAgeAtSignupMatchId ', allFields, value)
  
  if (name && !isNaN(value) ) {
    // get the member's idType
    const index = getIndex(name)
    const idType = allFields.members[index].idType
  
    if (idType === "rsa_id") {
      
      let memberIndex = getIndex(name)
      let member = allFields.members[memberIndex]
      if (member && member.idNumber) {
        const DOB = getDOBFromID(member.idNumber, member.idType)
        const currentAgeFromDOB = moment().diff(DOB, 'years')
        const ageAtSignup = member.ageAtSignup
        if (member.memberType === "POLICY_HOLDER" && !DOB  && ! ageAtSignup ) {
          return 'Enter either Age or Birth Date'
          }
        if (currentAgeFromDOB == ageAtSignup) {
          return undefined
        } else {
          return 'Age given does not match ID number'
        }
      }
    }
  }
  return undefined
}

const getAgeFromDOB = (dob) => { 
  return moment().diff(moment(dob), 'years');}

  const getAgeFromID = (idNumber, idType) => {
  let idTypeUpper 

  console.log('age error getAgeFromID ', idNumber, idType )

  if (idType) idTypeUpper = idType.toUpperCase()

    if (idNumber && !idType && idType === "rsa_id") {
      const DOBFromIdNumber = moment(`19${idNumber.slice(0, 6)}`, 'YYYY MM DD');
      return getAgeFromDOB(DOBFromIdNumber);
    }
    return undefined;
  };

const isValidIdNumber = (value, allFields, props, name) => {

  console.log('isValidIdNumber for value, allFields, props, name', value, allFields, props, name)

  if (allFields) {
    
    let idType

    if (name) {
      const index = getIndex(name)
      if (index) idType = allFields.members[index].idType
    }
    
    console.log('isValidIdNumber idType', idType, allFields.idType)

    if (!idType) idType = allFields.idType
    console.log('isValidIdNumber for value, idType', value, idType)

    if (value && idType) {
      value = value.toString().replace(' ', '');
    
      if (idType === "rsa_id") {

        if (value == '0000000000000') return "The Id Number is not valid";

        if (!/^\d{10}[0-1]\d{2}$/.test(value)) return "This is not a valid SA ID Number";
        
        let n = value;
        let p1 =
          parseInt(n[0], 10) +
          parseInt(n[2], 10) +
          parseInt(n[4], 10) +
          parseInt(n[6], 10) +
          parseInt(n[8], 10) +
          parseInt(n[10], 10);
        let p2 = (
          parseInt(n[1] + n[3] + n[5] + n[7] + n[9] + n[11], 10) * 2
        ).toString();
        let p3 = 0;
        for (let i = 0; i < p2.length; i++) {
          p3 += parseInt(p2[i]);
        }
        let check = 10 - (p1 + p3).toString()[(p1 + p3).toString().length - 1];
        let check_char = check > 9 ? check.toString()[1] : check.toString();
        if (check_char != value[12]) return "This is not a valid ID Number";
        return undefined;
      }
    }
}
  return undefined
};

const isValidRsaIdNumber = (value)  => {
  console.log('isValidRsaId ', value)
  
  if (value) {
    value = value.toString().replace(' ', '');
  } else {
    return undefined
  }
  if (value == '0000000000000') return 'Invalid ID number';
  
  if (!/^\d{10}[0-1]\d{2}$/.test(value)) {
    console.log(value,' is not a valid SA ID #')
    return 'Invalid ID number'
  };
  
  const n = value;
  const p1 = parseInt(n[0], 10) + parseInt(n[2], 10) + parseInt(n[4], 10) + parseInt(n[6], 10) + parseInt(n[8], 10) + parseInt(n[10], 10);
  const p2 = (parseInt(n[1] + n[3] + n[5] + n[7] + n[9] + n[11], 10) * 2).toString();
  let p3 = 0;
  for (let i = 0; i < p2.length; i++) {
    p3 += parseInt(p2[i]);
  }
  const check = 10 - (p1 + p3).toString()[(p1 + p3).toString().length - 1];
  const check_char = check > 9 ? check.toString()[1] : check.toString();
  if (check_char != value[12]) {
    console.log(value,' is not a valid SA ID')
    return 'Invalid ID number';
  }

  return undefined;
}

const sex = (idNumber) => parseInt(idNumber.substr(6,4)) < 5000 ? "FEMALE" : "MALE"

const isValidEmail = (value, allFields, props, name) =>
  !value || value.length === 0 || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? undefined
  : 'Invalid email address';


const isInteger = value => value && !/^\d+$/.test(value) ? 'Whole numbers only' : undefined


const allowedPrefixes = ['06', '07', '08']

const isValidCellphoneNumber = (value, allFields, props, name) => {
  console.log('validate cell number ', value)
  return !value || value.length === 0 || allowedPrefixes.map(prefix => new RegExp('^' + prefix + '.{8}$')).some(rx => rx.test(value)) ? undefined : 'Invalid cellphone number'
}

const ageCheck = (value, allFields, props, name) => {
  if (name) {
    let memberIndex = getIndex(name)
    let member = allFields.members[memberIndex]
    const min = minAge[member.memberType]
    const max = maxAge[member.memberType]

    if (value < min || value > max) {
      return `Age must be between ${min} and ${max}, check age`
    }
  return undefined
  }
}

const isValidDay = (value) => {
if (value && value !== null && value !== undefined) {
  if (value < 1 || value > 32 ) return `Day must be between 1 and 31`
}
return undefined
}


const setYear = (date) => {
if (date.length === 6) {
  var yy = date.slice(0, 2)
  var fullDate = `19${date.slice(0, 6)}`
  if (yy < 25 ) {
    fullDate = `20${date.slice(0, 6)}`
  }
  return fullDate
}
else return date
}
 
const isMemberValidAge = (type) => (value)  => {
  const min = minAge[type]
  const max = maxAge[type]
  console.log('isMemberValidAge ', value, type, min, max)

  if (value && (value < min || value > max) ) {
    return `Age must be between ${min} and ${max}, check age`
  }
  else return undefined
}

const isValidAge = (value, allFields, props, name) => {
  console.log('isValidAge ', value, allFields, name, typeof value)
  
  if (name && value) {
    let memberIndex = getIndex(name)
    let member = allFields.members[memberIndex]
    let memberType = "SPOUSE"
    if (member && member.memberType) {memberType = member.memberType }
    const min = minAge[memberType]
    const max = maxAge[memberType]

    if (value && value !== null && value !== undefined) {
      // Date object
      if (typeof value === 'object') {
        return ageCheck(moment().diff(value, 'year'), minAge, maxAge)
      } else {
        let dob = value.toString().replace(/\//g, '').replace(/-/g, '')
        if (value.length > 2) {
          dob = (setYear(dob))
          let years = moment().diff(moment(dob, ['YYYYMMDD']), 'years')
          if (!years || years < 0 || years > 90) return "Birth date looks invalid"
          console.log('isValidAge years is ', dob, value, years )
          return ageCheck(years, minAge, maxAge)
        }
        else return ageCheck(value, minAge, maxAge)
      }
    }
  return undefined
  }
}



export { doesDobMatchAge,isRequiredIfNotSA, isExistingOver100K, isMemberValidAge, getAgeFromDOB, isDate, sex, forMemberTypes, getIndex, getAgeFromID, isValidIdNumber,isValidRsaIdNumber, isInteger, isValidEmail, getDOBFromID, isValidCellphoneNumber, isValidAge, isValidDay, doesDOBMatchIdNumber, doesAgeAtSignupMatchDOB, doesAgeAtSignupMatchId, isRequired
}
