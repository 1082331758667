import React from 'react'

import { OptionsContainer, OptionContainer, OptionText } from './OptionSelector.style'

function OptionSelector({ value, onChange, options }) {
  return (
    <OptionsContainer>
      {options.map((option, index) => <OptionContainer selected={option === value} first={index === 0} last={index === options.length - 1} onClick={() => onChange(option)}><OptionText selected={option === value}>{option}</OptionText></OptionContainer>)}
    </OptionsContainer>
  )
}

export default OptionSelector
