import React, { Component} from 'react'
import { Switch, Route } from 'react-router-dom'
import Funeral from './Funeral'
import NewFuneral from '../../components/funeral/NewFuneral'
import {FuneralForm} from '../../components/funeral/FuneralForm'

// TODO: Should only be 1  FuneralForm
export default class Funerals extends Component {
  render() {
    return (
      <Switch>
        <Route path='/funeral/new' component={FuneralForm} />
        <Route path='/funeral/:id' exact component={Funeral} />
        <Route path='/funeral' component={Funeral} />
      </Switch>
    )
  }
}