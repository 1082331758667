import React, {Fragment ,useState } from 'react'
import { ModalContainer, ModalOuter, ButtonBox } from './Modal.style'
import {Button} from '../index'

function Modal({ children, extraButtonsTop, ...props }) {
console.log('Modal props ', props)
  return (
    <ModalOuter {...props}>
      <ModalContainer {...props}>
        {extraButtonsTop && <ButtonBox>
          {!props.hideOk && <Button action="true" onClick={props.onOk}> {props.okText ? props.okText: "OK"}</Button>}
          {!props.hideCancel && <Button onClick={props.onCancel} >{props.cancelText ? props.cancelText: "Cancel"}</Button>}
        </ButtonBox>}
        {children}
        <ButtonBox>
          {!props.hideOk && <Button action="true" onClick={props.onOk}> {props.okText ? props.okText: "OK"}</Button>}
          {!props.hideCancel && <Button onClick={props.onCancel} >{props.cancelText ? props.cancelText: "Cancel"}</Button>}
        </ButtonBox>
      </ModalContainer>
    </ModalOuter>
  )
}

export default Modal