import React from 'react'
import styled from 'styled-components'
import { Icon } from '../../generic'

const SideMenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${props => (props.theme.padding/2)+"px"};
  background-color: ${props => props.selected ? '#787878c8' : 'none'};
  margin-bottom: ${props => (props.theme.padding/2)+"px"};
  border-radius: 10px;
  text-decoration: none;
`

const SideMenuItemText = styled.span`
  float: left;
  margin-left: 7px;
  color: white;
  line-height: 25px;
  text-decoration: none;
`

const SideMenuItemIconImage = styled(Icon)`
  color: white;
  height: 25px;
`

const SideMenuItemIconContainer = styled.div`
  width: 25px;
  height: 25px;
  text-align: center;
`

const SideMenuItemIcon = (props) => (
  <SideMenuItemIconContainer>
    <SideMenuItemIconImage {...props} />
  </SideMenuItemIconContainer>
) 

export {
  SideMenuItemContainer,
  SideMenuItemText,
  SideMenuItemIcon
}
