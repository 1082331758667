import React from 'react'
import { ProfileImageContainer, DefaultProfileImage, ShowProfileImage } from './ProfileImage.style';
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator } from '../../../components/generic'

const GET_PROFILE = gql`
{
  myClient {
    id
    firstNames
    surname
    photo {
      id
      url
    }
  }
}
`
const ProfileImage = () => (
  <ProfileImageContainer>
    <Query query={GET_PROFILE}>
    {({ loading, error, data }) => {
      if (loading) return <ActivityIndicator />;
      if (error) return `Error! ${error.message}`;
      if (data.myClient && data.myClient.photo && data.myClient.photo.url) {
        return (
          <ShowProfileImage src={data.myClient.photo.url || DefaultProfileImage } />
        );
      }
      return <ShowProfileImage src={DefaultProfileImage} />
    }}
    </Query>
  </ProfileImageContainer>
)



export default ProfileImage