import React, { Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormFieldArray, FormField, FieldValue } from 'react-form-helper'
import { banks } from '../../../constants'
import { renderLabel, renderDisplay, renderInput } from '../../generic/form'
import { Button, Container } from '../../generic'
import { Row, Col } from '../../generic/grid'
import ChangeBankModal from '../ChangeBankModal'

const BANK_ACCOUNT_DETAILS = gql`
  fragment BankAccountDetails on BankAccount {
    id
    accountNo
    bankName
    clientId
    associatedWith {
      type
      id
      reference
    }
  }
`

const GET_BANK_ACCOUNTS = gql`{
  myClient {
    id
    clientId: id
    bankAccounts {
      ...BankAccountDetails
    }
  }
}
${BANK_ACCOUNT_DETAILS}`

const UPDATE_BANK_ACCOUNTS = gql`
  mutation UpdateBankAccounts($input: [AddOrUpdateBankAccountInput!]) {
    addOrUpdateBankAccounts(input: $input) {
      ...BankAccountDetails
    }
  }
${BANK_ACCOUNT_DETAILS}`

const productModelTypes = {
  funeral: "Funeral",
  savings: "Savings"
}

const modelProductTypes = {
  "Funeral": "funeral",
  "Savings": "savings"
}

const productMatch = (model, product, productId) => {
  console.log('productMatch ',model, product, productId)
  if (product) {
    if (productId) {
      console.log('productMatch returns 1', product, productModelTypes[product.toLowerCase()], productId, model,  model.type === productModelTypes[product] && model.id === productId)
      return model.type === productModelTypes[product.toLowerCase()] && model.id === productId
    }
    return model.type === productModelTypes[product]
  }
  return ["Funeral", "Savings"].includes(model.type)
}

// Note that this should be a screen and not a component, routing put here temporarily - TODO: Refactor

const hasNoBankAccount = (myClient, productId) => {
  const {bankAccounts} = myClient
  let noBanks = true
  
  if (bankAccounts && bankAccounts.length > 0 ) {
    bankAccounts.map( ba => {
      ba.associatedWith.map(product => {
        console.log('associated bank ', product, productId)
        if (product.id === productId) {
          noBanks = false
        }
      })
    })
  } 
  console.log('has no bank account ', noBanks)
  return noBanks
  //data.myClient && data.myClient.bankAccounts && data.myClient.bankAccounts.length > 0 && data.myClient.bankAccounts[0].associatedWith.length > 0? false : true
}

const  capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Layout({ data, product, productId, gotoProduct, mode, history }) {
  
  console.log('FOR:', data, data.myClient.bankAccounts, product, productId)
  const [showModal, setShowModal] = useState(data.myClient ? hasNoBankAccount(data.myClient, productId) : false)

  if (!hasNoBankAccount(data.myClient, productId) ) {
    console.log('we have a bank account')
    return (
        <FormFieldArray name='bankAccounts' renderFieldArray={({ fields }) => (
            fields.map((field, index) => (
              <FieldValue name={`${field}.associatedWith`} renderField={({ input }) => {
                const associations = input.value.filter(model => productMatch(model, product, productId))
                const buttonInfo = associations.length === 1 && associations[0]
                return associations.length > 0 ? (
                  <Container pad>
                  <h3>{`Account used for ${buttonInfo.reference}`} </h3>
                    <Col pad>
                      <FormField name={`${field}.bankName`} />
                      <FormField name={`${field}.accountNo`} />
                      {mode === "display" && <Row centerHorizontal style={{ paddingTop: "7px" }}>{associations.map(a => <Button action onClick={() => gotoProduct(modelProductTypes[a.type], a.id)}>Goto {a.reference}</Button>)}</Row>}
                      {buttonInfo && product && (productId || product !== "funeral") && <Fragment>
                        <Row style={{ paddingTop: "14px" }} centerHorizontal><Button action onClick={() => setShowModal(true)}>Change Bank Account for {buttonInfo.reference}</Button></Row>
                        <FieldValue name={`${field}.id`} renderField={({ input }) => (
                          <ChangeBankModal id={input.value} clientId={data.myClient.id} bankAccounts={data.myClient.bankAccounts} productDescription={buttonInfo.reference} productModel={buttonInfo.type} productId={productId} showModal={showModal} setShowModal={setShowModal} refetchQueries={[{ query: GET_BANK_ACCOUNTS }]} />
                        )} />
                      </Fragment>}
                    </Col>
                  </Container>
                ) : <div />
              }} />
            ))
        )}/>
    )}
  else {
    // No bank accounts defined so go to Modal to add one
    console.log('new bank ',data.myClient, capitalizeFirstLetter(product))

    return ( 
      <ChangeBankModal id={null} clientId={data.myClient.id} bankAccounts={data.myClient.bankAccounts} productDescription={product} productModel={capitalizeFirstLetter(product)} productId={productId} showModal={showModal} history={history} setShowModal={setShowModal} refetchQueries={[{ query: GET_BANK_ACCOUNTS }]} />
    )}
}

const fields = {
  bankAccounts: {
    type: "fieldarray",
    config: {
      id: {
        type: "hidden",
      },
      bankName: {
        label: "Bank Name",
        type: "select",
        options: banks
      },
      accountNo: {
        label: "Account Number",
        type: "text"
      }
    }
  }
}

function MyBankAccounts({ match, history }) {
  const { product, productId } = match.params || {}

  console.log('MyBankAccounts parms ', product, productId)

  const gotoProduct = (p, id) => {
    console.log(p, id)
    if (["funeral"].includes(p)) {
      history.push(`/${p}/${id}`)
    }
    history.push(`/${p}`)
  }

  return (
    <GraphQLFormProvider
      name="bankAccounts"
      mode="display"
      initialValuesFromData={data => {console.log('MyBankAccounts data ', data.myClient); return data.myClient} }
      query={GET_BANK_ACCOUNTS}
      renderLabel={renderLabel}
      renderDisplay={renderDisplay}
      DisplayFormLayout={(props) => <Layout {...props} product={product} productId={productId} history={history} gotoProduct={gotoProduct} />}
      fields={fields}
      enableReinitialise
    />
  )
}

export default MyBankAccounts
