import React, { Component} from 'react'
import { Route } from 'react-router-dom'
import Start from './Start'

// TODO: Should only be 1 ClaimForm
export default class StartRoutes extends Component {
  render() {
    return (
      <Route path='/' exact component={Start} />
    )
  }
}