import React, { Fragment, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { ChatCard } from '../../generic'

const SEND_OTP = gql`
  mutation SendOTP($cellNumber: String, $useExisting: Boolean) {
    sendOTP(cellNumber: $cellNumber, useExisting: $useExisting)
  }
`

const CONFIRM_OTP = gql`
  mutation RegistrationOTP($OTP: String) {
    confirmOTP(OTP: $OTP)
  }
`




const OTP = ({ apolloClient, chatState, setChatState, setNextSteps, gotoNext, step }) => {
  const { hasExistingBankAccount, cellNumber, isVerified } = chatState
  const { nextSteps } = step
  const MAX_OTP_ATTEMPTS = 3
  const [numInvalidOTP, setNumInvalidOTP] = useState(0)
  const [otpsEntered, setOtpsEntered] = useState(0)

  useEffect(() => {
    setChatState({ isVerified: false, otpSent: false })
    apolloClient.mutate({ mutation: SEND_OTP, variables: { cellNumber } }).then(({ data }) => {
      setChatState({ otpSent: data.sendOTP })
    })
  }, [])

  const confirmOTP = (variables) => {
    const OTP = variables[Object.keys(variables)[0]]
    setOtpsEntered(otpsEntered + 1)
    apolloClient.mutate({ mutation: CONFIRM_OTP, variables: { OTP } }).then(({ data }) => {

      if (data.confirmOTP) {
        if (hasExistingBankAccount) {
          setNextSteps(["bankAccount"])
        } else {
          setNextSteps(nextSteps)
        }
        setChatState({ isVerified: true })
        gotoNext()
      } else {
        setNumInvalidOTP(numInvalidOTP + 1);
      }
    })
  }


  return (
    <Fragment>
      {<ChatCard text={`I have sent an OTP to your phone ${cellNumber ? `(${cellNumber})`: ""} to confirm your identity, please provide to continue`} focus fieldNames={["OTP[0]"]} handleNext={confirmOTP} noNext={(isVerified || numInvalidOTP > 0) && otpsEntered > 0} isActive={!((isVerified || numInvalidOTP > 0) && otpsEntered > 0)} />}
      {numInvalidOTP > 0 && [...Array(numInvalidOTP > MAX_OTP_ATTEMPTS - 1 ? MAX_OTP_ATTEMPTS - 1 : numInvalidOTP).keys()].map(index => <ChatCard text="The OTP you provided was invalid, please try again." fieldNames={[`OTP[${index + 1}]`]} handleNext={confirmOTP} noNext={isVerified || index < numInvalidOTP - 1} isActive={!isVerified && index === numInvalidOTP - 1} />)}
      {numInvalidOTP >= MAX_OTP_ATTEMPTS && <ChatCard text="You have not been able to provide a valid OTP after 3 attempts - we are unable to continue with registration" noNext />}
      {isVerified && otpsEntered > 0 && <ChatCard text="OTP verification received, thank you very much" noNext />}
    </Fragment>
  )
}

export default OTP


/*
const otpSteps = [
    {
      name: 'start',
      text: ({cellNumber}) => (`I have sent an OTP to your phone ${cellNumber? cellNumber : "" } to confirm your identity, please provide to continue`),
      nextSteps: ["idType"],
      nextAfterDelay:500,
      noNext:true
    },
    {
      name: "idNumber",
      text: "Please enter your ID Number",
      fieldNames: ["OTP[0]"],
      handleNext: confirmOTP,
      handleNextIfSkipStep: true,
      nextSteps: ["firstTime"],
    }
  ]

*/