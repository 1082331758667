import React, {Fragment} from 'react'
import gql from 'graphql-tag'
import { ActivityIndicator, MenuButton, Button } from '../../../components/generic'
import { StyledCardContainer } from '../../../components/generic/Card/Card.style'
import { LotteryText, StartHeading } from './Start.style'
import { CardLayout,Moku, LoginContainer, Center, Footer} from '../../../components/profile/Login/Login.style'
import { useQuery } from 'react-apollo-hooks'
import styled from 'styled-components'
import { ShareButtons } from '../../../components/shared'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { LINKS_API_URL } from '../../../config'

const GET_PROFILE = gql`
{
  myClient {
    id
    firstNames
    surname
    idNumber
    acceptedTerms
    savings {
      id
    }
    creditLife {
      id
    }
    funeralPolicy {
      id
    }
    claims {
      id
    }
    creditRecord {
      fetchedAt
      pdf {
        id
        url
        name
      }
    }
    nextLotteryDrawTickets
    referralCode
    photo {
      id
      url
    }
  }
}
`

function Start({ history }) {

  let { data, loading , error } = useQuery(GET_PROFILE)
  if (loading) { return <ActivityIndicator large/> }
  if (error) { console.log(error); return "An error has occurred" }

  const text = `${data.myClient.firstNames} ${data.myClient.surname} has invited you to start saving with Meerkat. Click to save and receive an entry into our monthly prize draw for every R100 you save (T’s & Cs apply)`
  const title = `${data.myClient.firstNames} ${data.myClient.surname} has sent you an invitiation`


  //if (!data.myClient){
  //  console.log('client not found, logout')
  //  history.push(`/logout`)
  //}

  if (data.myClient) {
    /*
    if (!acceptedTerms) {
      history.push(`/tc/${id}`)
      return <div />
    }
  */
   const {id, claim, savings, funeralPolicy, acceptedTerms, nextLotteryDrawTickets} = data.myClient
/*
  if (!acceptedTerms) {
    history.push(`/tc/${id}`)
    return <div />
  }
*/
  return (
    <LoginContainer>
            <Center>
            <Moku />
            <StartHeading>Welcome to Meerkat</StartHeading>
            <StyledCardContainer>
            <CardLayout>
              <MenuButton color={"#fff"} icon="chart-line" onClick={()=> history.push('/savings')} label={savings ? "My Savings Plan": "Start saving today"} /> 
              {1 == 2 && <MenuButton icon="umbrella" color={"#fff"} label={funeralPolicy ? "My Funeral cover ": "Buy funeral cover now!"}
                onClick={()=> { funeralPolicy ?history.push('/funeral') : history.push('/funeral/new')}} />}
              <MenuButton  color={"#fff"} icon="chart-pie" label={"Debt Management"} onClick={()=> history.push(`/debt`)} />
              <MenuButton color={"#fff"} icon="credit-card" onClick={()=> history.push('/credit')} label={"Free Credit Report"} />
              <MenuButton color={"#fff"} icon="dove" label={claim ? "View Insurance Claims" :"Insurance Claims"}  onClick={()=> history.push('/claims')} />
            </CardLayout>
              </StyledCardContainer>
              <LotteryText>You have {nextLotteryDrawTickets || 0} entries for next month's prize draw</LotteryText>
              <CardLayout>
                <MenuButton color={"#fff"} icon="coins" label={"Get Extra Entries"}  onClick={()=> history.push('/draw')} />
                {1 === 2 && <LotteryText underline="true" italic="true">Find out more about the prize draw</LotteryText>}
              </CardLayout>
              <LotteryText>Share with your friends and get additional tickets in the prize draw</LotteryText>
              <LotteryText><ShareButtons url={`${LINKS_API_URL}/${data.myClient.referralCode || "abc123"}`} text={text} title={title}/></LotteryText>
              <br />
              <br />
            </Center>


      { 1 == 2 && <Footer>
          © Copyright MyMeerkat (PTY) Ltd. All Rights Reserved.
      </Footer>}
      </LoginContainer>
  )
  }
  else {
   return (
     <div style={{fontWeight:"bold", display:"flex",flexDirection: "column", justifyContent:"center", alignItems: "center", height:"50vh"}}>Network error, please reload the page, or
      <br />
      <br />
      <Button action="true"onClick={() => history.push('/logout') }> Logout </Button>
    </div>
  )}
}

export default Start
