import React, { useState, useReducer, Fragment } from 'react'
import { Moku, Center, ChatCards, LoginContainer, GoogleLoginButton, TermsLink } from '../../profile/RegisterForm/RegisterForm.style'

import { Button, ChatCard, Modal } from '../../generic'
import { ButtonBox } from '../../generic/Modal/Modal.style'
import { renderLabel, renderInput } from '../../generic/form'
import { API_URL, API_AUTH_ID } from '../../../config'
import gql from 'graphql-tag'
import { GraphQLFormProvider, SubmitButton, FieldValue } from 'react-form-helper';
import { banks, terms, idTypes } from '../../../constants'

import { isValidRsaIdNumber, isInteger } from '../../../lib/validators'
import { GoogleButtonImage} from '../../../assets/images'
import {MenuButton} from '../../generic'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'

const GoogleButton = () => <MenuButton  background={"#fff"} border={"#4285F4"} image={GoogleButtonImage} label="Continue with Google" />
const FaceBookButton = () => <MenuButton background={"#4267B2"} color={"#fff"} icon={faFacebook} label="Continue with Facebook" />


const CLIENT_CHECK = gql`
  query ClientCheck($idNumber: String) {
    checkForClient(idNumber: $idNumber) {
      existingClient
      hasSavings
      employerContributions
      hasUser
      hasExistingCellNumber
      hasExistingBankAccount
      hasAcceptedTerms
      authProvider
    }
  }
`

const COMPLETE_REGISTRATION = gql`
  mutation RegisterClient($input: RegisterClientInput) {
    registerClientUser(input: $input)
  }
`

const SEND_OTP = gql`
  mutation SendOTP($cellNumber: String, $useExisting: Boolean) {
    sendOTP(cellNumber: $cellNumber, useExisting: $useExisting)
  }
`

const CONFIRM_OTP = gql`
  mutation RegistrationOTP($OTP: String) {
    confirmOTP(OTP: $OTP)
  }
`


// TODO: Remove inline styles and fix styling containers
function Layout({ apolloClient, changeFieldValue }) {
  const [clientInfo, setClientInfo] = useState({
    hasIDNumber: false,
    existingClient: null,
    hasSavings: false,
    employerContributions: false,
    hasUser: false,
    hasExistingCellNumber: false,
    hasExistingBankAccount: false,
    authProvider:"GOOGLE"
  })

  const [loginUrl, setLoginUrl] = useState("")
  const [hasCellNumber, setHasCellNumber] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [idValid, setIdValid] = useState(false)
  const [clientIdNumber, setClientIdNumber] = useState("")
  const [clientIdType, setClientIdType] = useState("")

  const [numInvalidOTP, setNumInvalidOTP] = useState(0)
  const [selfie, setSelfie] = useState(false)
  const [hasFirstNames, setHasFirstNames] = useState(false)
  const [hasSurname, setHasSurname] = useState(false)

  const [noNextButton, setNoNextButton] = useState(false)
  const [getCellNumber, setGetCellNumber] = useState(false)
  const [newClient, setNewClient] = useState(false)
  const [hasIdType, setHasIdType] = useState(false)
  const [hasBankDetails, setHasBankDetails] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const initialSteps = [
    { id:"start", display: true },
    { id:"idNumber", display: true },
    { id:"idType", display: false },
    { id:"cellNumber",  display: false },
    { id:"bankAccount",  display: false },
    { id:"otp",  display: false },
    { id:"login",  display: false },
    { id:"firstNames",  display: false },
    { id:"surname",  display: false },
    { id:"idError",  display: false },
    { id:"otpWrong",  display: false },
    { id:"newLogin",  display: false },
    { id:"terms",  display: false }
      ]

  const stepsReducer = (state, action) => {
    switch (action.type) {
      case 'ON':
        return state.map(on => {
          if (on.id === action.id) {
            return { ...on, display: true };
          } else {
            return on;
          }
        });
      case 'OFF':
        return state.map(off => {
          if (off.id === action.id) {
            return { ...off, display: false };
          } else {
            return off;
          }
        });
      default:
        return state;
    }
  };

const [steps, dispatch] = useReducer(stepsReducer, initialSteps);

const nextStep = (activeStep) => {
  console.log("set next step ", activeStep, steps)
  const allSteps = steps
  steps.map((off) => {
    dispatch({type: "OFF", id:off.id})
  })
  activeStep.map((on) => {
    dispatch({type: "ON", id:on})
  })
}

const doClientCheck = ({ idNumber }) => {
  setClientIdNumber(idNumber)

  apolloClient.query({ query: CLIENT_CHECK, variables: { idNumber } }).then(({ data }) => {
      setClientInfo({ hasIDNumber: true, ...data.checkForClient })
      if (clientInfo.authProvider) setLoginUrl (`${API_URL}auth/${clientInfo.authProvider}?clientId=${API_AUTH_ID}&redirect=${window.location.origin}`)

      if (data.checkForClient.existingClient && data.checkForClient.hasUser) {
        console.log('This is an existing client ', data.checkForClient)
        setNewClient(false)
        setAcceptedTerms(data.hasAcceptedTerms)
        nextStep(['login'])
      }
      else {
        setNewClient(true)
        if (!isValidRsaIdNumber(idNumber)) {
          console.log('id number is valid so go register')
          setIdValid(true)
          nextStep(['firstNames'])
        } else {
          console.log('id number invalid for sa, do get country')
          if (clientIdType.indexOf("SOU") > -1) {
            nextStep(['idError'])
          } else {
            setIdValid(false)
            nextStep(['idType'])}
        }
      }
    })
  }

  const doIdTypeCheck = ({ idType }) => {
    if (!idType) {idType = 'SOUTH AFRICA'}
    console.log('idType is ',idType)
    setClientIdType(idType)
    if (idType.indexOf("SOU") > -1) {
      if (idValid) {
        nextStep(['firstNames'])
        setHasIdType(true)
      } else {
        nextStep(['idError'])
      }
    } else if (idType && idType.length > 0) {
      nextStep(['firstNames'])
      setHasIdType(true)
    }
  }

  const doSelfie = ({ selfiePic }) => {
    console.log('selfie clicked')
    if (selfiePic && selfiePic.length > 0) {nextStep(['docHeader','newID'])}
  }

const sendOTP = (variables) => {
  apolloClient.mutate({ mutation: SEND_OTP, variables }).then(({ data }) => {
    setHasCellNumber(data.sendOTP);
    nextStep(['otp'])
  })
}

const confirmOTP = (variables) => {
  const OTP = variables[Object.keys(variables)[0]]
  console.log('otp is ', OTP, variables)

  apolloClient.mutate({ mutation: CONFIRM_OTP, variables: { OTP } }).then(({ data }) => {
    if (data.confirmOTP) {
      setIsVerified(true);
      nextStep(["bankAccount"])
      console.log('otp correct')
    } else {
      setNumInvalidOTP(numInvalidOTP + 1);
      console.log('otp not correct')
      nextStep(["otpWrong"])
    }
  })
}

const isActive = (active) => {
  const result = steps.filter( (item) => {
      return item.id == active
  })
  return result[0].display
}

  const {
    existingClient,
    hasSavings,
    hasIDNumber,
    hasUser,
    employerContributions,
    hasExistingCellNumber,
    hasExistingBankAccount
  } = clientInfo

  console.log('client info and window location origin' ,clientInfo, window.location.origin )

  return (
      <Center>
        <Moku />
          <ChatCards>
          { isActive("start") && <ChatCard text="Hi - I'm Moku. Let's see whether you are a registered user" noNext /> }
          { isActive("idNumber") && <ChatCard text="What is your ID Number" fieldNames={["idNumber"]} handleNext={doClientCheck} noNext={existingClient ? hasIDNumber : ''} /> }
          { isActive("idType") && <ChatCard text="From what country is your ID" fieldNames={["idType"]} handleNext={doIdTypeCheck}  />}
          { isActive("idError") && <ChatCard text="There is a problem with your ID number. Please try again" fieldNames={["idNumber"]} handleNext={doClientCheck} noNext={existingClient ? hasIDNumber : ''} /> }

          {isActive("login") && <ChatCard text="Great, I see that you are already registered with us. Please sign in with your Google or Facebook ID to continue." noNext>
              <center>
                <br /><br />
                <a href={loginUrl} onClick={() => localStorage.setItem('route', '/claims')}>
                {clientInfo.authProvider === "GOOGLE" ? <GoogleButton  /> : <FaceBookButton  /> }
            </a>
            </center>
          </ChatCard>}
          {isActive("firstNames") && <ChatCard text="Ok, I see that you haven't registered before, let's do that quickly." noNext  />}
          {isActive("firstNames") && <ChatCard text="What is your first name?" fieldNames={["firstNames"]} handleNext={() => {nextStep(['surname']);setHasFirstNames(true) } }noNext={hasFirstNames} />}
          {isActive("surname") && <ChatCard text="What is your last name" fieldNames={["surname"]} handleNext={() => {nextStep(['cellNumber']);setHasSurname(true) }} noNext={hasSurname} />}
          {isActive("cellNumber") && <ChatCard text="Thanks. What is your cell number?" fieldNames={["cellNumber"]} handleNext={sendOTP}  noNext={hasCellNumber} /> }
          {isActive("otp") && <ChatCard text={`I have sent an OTP to your phone (${hasCellNumber}) to confirm your identity, please provide to continue`} fieldNames={["OTP[0]"]} handleNext={confirmOTP} noNext={isVerified} />}
          {isActive("otpWrong") && numInvalidOTP > 0 && [...Array(numInvalidOTP > 3 ? 3 : numInvalidOTP).keys()].map(index => <ChatCard text="The OTP you provided was invalid, please try again. Check here to resend or here to change the number" fieldNames={[`OTP[${index + 1}]`]} handleNext={confirmOTP} />)}
          {isActive("otpWrong") && numInvalidOTP >= 3 && <ChatCard text="You have not been able to provide a valid OTP after 3 attempts - we are unable to continue with registration" />}
          {isActive("bankAccount") && <SubmitButton renderSubmitButton={({ submit }) => <ChatCard text="What is your bank account details for payments?" fieldNames={["bankAccount.bankName", "bankAccount.accountNo"]}  labels handleNext={() => {submit(); nextStep(['terms']);setHasBankDetails(true)}} noNext={hasBankDetails} />} />}

          {isActive("terms") && <ChatCard text={<Fragment>Do you accept <TermsLink onClick={() => setShowModal(true)}>our terms and conditions?</TermsLink></Fragment>} noNext>
              <FieldValue name="acceptedTerms" renderField={({ input: { onChange, value } }) => { console.log(value); return (
                (value !== true) ?
                  <Fragment>
                    <SubmitButton renderSubmitButton={({ submit }) => (
                      <Fragment>
                          {showModal && <Modal title="Meerkat Terms and Conditions September 2017"
                          extraButtonsTop={true}
                          width='98%'
                          backgroundColor= 'rgb(242, 246, 252)'
                          visible={showModal}
                          onOk={() => {nextStep(['newLogin']);setAcceptedTerms(true);setShowModal(false); onChange(true); submit()}}
                          okText={"I Accept"}
                          onCancel={() => {setAcceptedTerms(false);onChange(false); setShowModal(false)}}
                          cancelText={"Do Not Accept"}
                        >
                          <div dangerouslySetInnerHTML={terms} />
                        </Modal>}
                        <ButtonBox>
                          <Button onClick={() => { nextStep(['newLogin']); onChange(true); submit(); setAcceptedTerms(true) }} action>Yes</Button>
                          <Button action="true"onClick={() => { onChange(false); setAcceptedTerms(false) }}>No</Button>
                      </ButtonBox>
                      </Fragment>
                    )} />
                  </Fragment>
                : value === true ? " Yes" : " No"
              ) }} />
          </ChatCard>}

          {acceptedTerms === false && <ChatCard text="We will not be able to help you unless you accept the terms and conditions" noNext/>}

          {isActive("newLogin") && <SubmitButton renderSubmitButton={({ submit, submitting }) => (
            !submitting &&
              <ChatCard text="Thanks so much for your information. We are ready to finish setting up your account. Please sign in with your Google or Facebook account to complete registration." noNext>
                <br /><br />
                <center>
                  <a href={`${API_URL}auth/google?clientId=${API_AUTH_ID}&redirect=${window.location.origin}&registerId=${localStorage.getItem('registerId')}` } onClick={() => localStorage.setItem('route', '/claims')} >
                  {<GoogleButton  /> }
                  </a>
                  <br />
                  <a href={`${API_URL}auth/facebook?clientId=${API_AUTH_ID}&redirect=${window.location.origin}&registerId=${localStorage.getItem('registerId')}` } onClick={() => localStorage.setItem('route', '/claims')} >
                  {<FaceBookButton  /> }
                  </a>
                </center>
              </ChatCard>)} />}
          </ChatCards>
        </Center>
  )
}

//validate: isValidRsaIdNumber

const fields = {
  idNumber: {
    type: "text"
  },
  acceptedTerms: {
    type: "boolean"
  },
  idType: {
    type: "select",
    options: idTypes
  },
  selfiePic: {
    type: "text",
    strip: true,
    validate: value => value && value.length > 1 ? null : "We need a picture"
  },
  firstNames: {
    type: "text",
    strip: true,
    validate: value => value && value.length > 1 ? null : "I need your name"
  },
  lastName: {
    type: "text",
    strip: true,
    validate: value => value && value.length > 1 ? null : "I need your last name"
  },
  OTP: {
    type: "fieldarray",
    arrayOfType: "text",
    strip: true
  },
  "bankAccount.bankName": {
    label: "Bank",
    type: "select",
    options: banks,
    validate: value => value && value.length > 0 ? null : "Please select a bank"
  },
  "bankAccount.accountNo": {
    label: "Account Number",
    type: "text",
    validate: isInteger
  }
}

function RegisterClaimant() {
  console.log('idTypes ', idTypes)
  return (
    <GraphQLFormProvider
      name="claim"
      mode="add"
      fields={fields}
      addMutation={COMPLETE_REGISTRATION}
      initialValues={{ isClaim: true }}
      keepInitialValues
      afterSubmit={({ data }) => { localStorage.setItem('registerId', data.registerClientUser); localStorage.setItem('route', "/claim");} }
      renderInput={renderInput}
      InputFormLayout={Layout}
      renderLabel={renderLabel}
    />
  )
}

export default RegisterClaimant
//handleNext={sendOTP}