import React, { Component, Fragment } from 'react'
import { Query, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { SavingsNeedsList } from '../../../components/savings'
import { ActivityIndicator, Button, Container } from '../../../components/generic'
import { Row, Col } from '../../../components/generic/grid'

export const SAVINGS_INFO = gql`
  fragment SavingsInfo on Savings {
    id
    bucketInfo {
      id
      bucketType
      name
      amount
      targetAmount
      targetDate
      percentageFull
    }
  }
`

const GET_SAVINGS = gql`
  query GetSavingsInfo {
    myClient {
      id
      savings {
        ...SavingsInfo
      }
    }
  }
  ${SAVINGS_INFO}
`

const MOVE_BUCKETS = gql`
  mutation MoveBucket($savingsId: String!, $bucketId: String!, $currentIndex: Int!, $newIndex: Int!) {
    moveBucketPosition(savingsId: $savingsId, bucketId: $bucketId, currentIndex: $currentIndex, newIndex: $newIndex) {
      ...SavingsInfo
    }
  }
  ${SAVINGS_INFO}
`

class MySavings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bucketOrder: null,
      showButtons: false
    }
  }

  onDragEnd = (id) => async (result) => {
    const { source, destination, draggableId } = result
    const { moveBucketPosition } = this.props

    if (destination && destination.index >= 0) {
      this.optimisticallyChangePosition(draggableId, destination.index)

      const data = await moveBucketPosition({
        variables: {
          savingsId: id,
          bucketId: draggableId,
          currentIndex: source.index + 1,
          newIndex: destination.index + 1
        },
        refetchQueries: [
          { query: GET_SAVINGS }
        ]
      })
      console.log('mysavings data is',data)
      console.log({
        savingsId: id,
        bucketId: draggableId,
        currentIndex: source.index + 1,
        newIndex: destination.index + 1
      })
    }
  }

  getOrder = (bucketInfo) => {
    console.log(bucketInfo)

    const { bucketOrder } = this.state
    if (!bucketOrder) {
      this.setState({ bucketOrder: bucketInfo.map(b => b.id)})
      return bucketInfo
    } else {
      const bucketInfoById = {}
      bucketInfo.forEach(b => {
        bucketInfoById[b.id] = b
      })
      return bucketOrder.map(id => bucketInfoById[id])
    }
  }

  optimisticallyChangePosition = (bucketId, newIndex) => {
    const { bucketOrder } = this.state
    const newBucketOrder = bucketOrder.filter(id => id !== bucketId)
    newBucketOrder.splice(newIndex + 1, 0, bucketId)
    this.setState({ bucketOrder: newBucketOrder })
  }

  render() {
    const { history } = this.props

    return (
      <Query query={GET_SAVINGS}>
        {
          ({ loading, error, data }) => {
            if (loading) {
              return <ActivityIndicator large="true"/>
            }
            if (error) {
              return "An error occurred"
            }

            console.log('my savings ', data )
            const productId = data.myClient.savings ? data.myClient.savings.id : null
            const product = 'savings'

            return (
              data.myClient.savings ?
                <Col>
                  <SavingsNeedsList bucketInfo={data.myClient.savings && this.getOrder(data.myClient.savings.bucketInfo || []) } onDragEnd={this.onDragEnd(data.myClient.savings.id) } onRowPress={(id) => history.push(`/savings/${id}`)} />
                  <Container noBackground>
                    <Row centerHorizontal>
                      <Button action onClick={() => history.push('/')}>Home</Button>
                      <Button action onClick={() => history.push(`/${product}/${productId}/bank-accounts`)}>Bank Details</Button>
                      <Button action onClick={() => history.push('/payments')}>Payments</Button>
                    </Row>
                  </Container>
                </Col>
              : <div style={{marginTop:"45%", padding:"5%"}}><Row centerVertical>
                  <Col centerHorizontal> <b>The savings product has not been activated, press start to activate</b></Col>
                  <br />
                  <Col centerHorizontal><Button action onClick={() => history.push('/startSavings')}>Start</Button></Col>
               </Row>
               </div>
            )
          }
        }
      </Query>
    )
  }
}

export default graphql(MOVE_BUCKETS, { name: 'moveBucketPosition' })(MySavings)