import React, { Component, useState, useReducer, Fragment } from 'react'
import { Moku, Center, ChatCards, LoginContainer, ChatWithMokuContainer, MokuContainer } from '../../profile/RegisterForm/RegisterForm.style'
import { ActivityIndicator, Container, Icon, Button, ChatCard,  } from '../../generic'
import { renderLabel, renderInput } from '../../generic/form'
import gql from 'graphql-tag'
import { Query, graphql } from 'react-apollo'
import { useQuery } from 'react-apollo-hooks'
import { withRouter } from 'react-router-dom'
import { GraphQLFormProvider, FormFieldArray, FieldInput, FormField, FieldValue, FieldValues, SubmitButton } from 'react-form-helper';
import { isValidRsaIdNumber } from '../../../lib/validators'
import { Row, Col } from '../../generic/grid'
import { DocumentName } from './FuneralForm.style'
import FileInput from '../../generic/FileInput'
import CameraInput from '../../generic/CameraInput'
import { API_URL } from '../../../config'

import moment from 'moment'

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const clientFriendlyStatus = (state) => {
  if (state === "INITIATED") {
    // If not valid / document missing then "Requirements Outstanding"
    return "Needs More Information"
  }
  if (state === "VALIDATED") {
    return "Waiting For Manager Approval"
  }
  if (state === "CANCELLED") {
    return "Claim was Cancelled"
  }
  if (state === "COMPLETED") {
    return "Claim Was Paid"
  }
  if (state === "APPROVED_PENDING_PAYMENT") {
    return "Awaiting Payment"
  }
  if (state === "REJECTED") {
    return "Claim was rejected"
  }
  if (state === "PENDING_REJECTED") {
    return "Unikely To Be Approved"
  }
  return "Being Processed"
}

const claimInfo = gql`
  fragment ClaimInfo on Claim {
    id
    effectiveDate
    info {
      key
      value
    }
    documents {
      id
      description
      verified
      waived
      fileId
      documentId
    }
  }`

function ClaimHeader ({ claimInfo, editsAllowed}) {
  const {client : clientData, effectiveDate, createdAt, claimantId, claimType, claimNumber, state} = claimInfo
  const {idNumber, firstNames, surname} = clientData
  console.log('ClaimHeader ', clientData, effectiveDate, createdAt, claimantId, claimType, claimNumber, state)

  return  (
      <Container title=''>
      <Row centerVertical key={'claimStatus'}>
      <Col centerVertical size='8'  ><DocumentName >Status</DocumentName></Col>
      <Col centerVertical  ></Col>
      <Col centerVertical size='12'><DocumentName bold={true}>{clientFriendlyStatus(state)}</DocumentName></Col>
    </Row>
      {createdAt && (
        <Row centerVertical key={'Createddate'}>
          <Col centerVertical size='8'><DocumentName>Initiated</DocumentName></Col>
          <Col centerVertical  ></Col>
          <Col centerVertical size='12'><DocumentName bold={true} >{moment(createdAt).format("Do MMMM YYYY")}</DocumentName></Col>
        </Row>
      )}

      {claimantId && (
        <Row centerVertical key={'claimantID'}>
          <Col centerVertical size='8'><DocumentName> Claimant</DocumentName></Col>
          <Col centerVertical ></Col>
          <Col centerVertical size='12' ><DocumentName  bold={true}>{claimantId}</DocumentName></Col>
        </Row>
      )}
      {claimNumber && (
        <Row centerVertical key={'claimnumber'}>
          <Col centerVertical size='8'><DocumentName>Claim Number</DocumentName></Col>
          <Col centerVertical ></Col>
          <Col centerVertical size='12' ><DocumentName  bold={true}>{claimNumber}</DocumentName></Col>
        </Row>
      )}
      {idNumber && (
        <Fragment>
        <Row centerVertical key={'insuredID'}>
          <Col centerVertical size='8' ><DocumentName >Claim For</DocumentName></Col>
          <Col centerVertical  ></Col>
          <Col centerVertical size='12'><DocumentName bold={true}>{idNumber} {!isMobileDevice() && `  ${surname}, ${firstNames}` }</DocumentName></Col>
        </Row>

      </Fragment>
      )}
      {claimType && (
        <Row centerVertical key={'type'}>
          <Col centerVertical size='8'  > <DocumentName >Type</DocumentName></Col>
          <Col centerVertical  ></Col>
          <Col centerVertical size='12'> <DocumentName bold={true}>{claimType}</DocumentName></Col>
        </Row>
        )}
        {effectiveDate && (
          <Row centerVertical key={'EffectiveDate'}>
            <Col centerVertical size='8'><DocumentName>Occured </DocumentName></Col>
            <Col centerVertical ></Col>
            <Col centerVertical size='12'><FormField displayOnly={!editsAllowed} name="effectiveDate" noLabel/> </Col>
          </Row>
        )}

  </Container>
    )
  }

const GET_PROFILE = gql`
{
  myClient {
    id
    firstNames
    surname
    idNumber
    photo {
      id
      url
    }
  }
}`

const GET_REQUIREMENTS = gql`
  query GetClaimRequirements($claimType: ClaimType) {
    getClaimRequirements(claimType: $claimType, products: [CREDIT_LIFE]) {
      id
      documents {
        id
        requirementId
        documentType
        description
        required
        requireCertified
        maximumAgeDays
      }
      info {
        name
        key
        type
      }
    }
  }`

  function Layout({ apolloClient, changeFieldValue, history, data}) {
    const today = moment()
    const fileUploadSuccess = ""

    const [claimantID, setClaimantID] = useState(false)
    const [dateError, setDateError] = useState(false)
    const [infoFields, setInfoFields] = useState([])
    const [allDocuments, setAllDocuments] = useState([])
    const [activeDocument, setActiveDocument] = useState(null)
    const [doNothing, setDoNothing] = useState(null)
    const [displayOnly, setDisplayOnly] = useState()
    const [lastFileUploaded, setLastFileUploaded] = useState(false)
    const [editsAllowed, setEditsAllowed] = useState(true)

    const cancelReasons = [
      { value: 'missingInfo', label: 'the application is incomplete' },
      { value: 'timeExpired', label: "the Claimant didn't provide all the required information" },
      { value: 'fraud', label: 'the admin suspected fraud' },
      { value: 'noSupport', label: 'the submitted information does not support a claim.' }
    ]

    const loadInfo = (claimType) => {
    apolloClient.query({ query: GET_REQUIREMENTS, variables: { claimType } }).then(({ data }) => {
      console.log('infoFields requirements ', data)
      fields.info.config = {}
      setInfoFields(data.getClaimRequirements[0].info)
    })
  }

  const uploadFileRequest = (e, v) => {
    let data = new FormData();
    console.log('fileUpload clicked for data ', data)

    if (e.target.files[0]) {


      const { name } = e.target.files[0]

      data.append('name', name);
      data.append('file', e.target.files[0])

       fetch(`${API_URL}upload`, { method: "POST", headers: { 'Authorization': `JWT ${localStorage.getItem('accessToken') || "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI1OWQyNjUzNmMzNzBjM2E2YjIzMjc5YjQiLCJ1dWlkIjoiYWIxOTk2YzctMGViOC00ZjAyLWI0NTUtZWI2MzU5NTljNTdmIiwiZW1wbG95ZWVJZCI6IjVhNTc5NDg1MDM5YzY5YmJkYTA3YWRhNyIsInJvbGVzIjpbIk1BTkFHRVIiLCJHUkFQSElRTCIsIkVNUExPWUVSIiwiQ0xJRU5UIl19.R2EeCjz2JbZyiHOdKKGv__xcBOTOTUXwm22TW7P9KGY"}` }, body: data  })
        .then(result => {
            if (result.ok) {
              //changeFieldValue(fileId)
              return result.json();
          }
          else {
            console.log(`File upload failed with status ${result.status}`);
          }
        })
        .then(result => {
          console.log(`File upload succeeded. ${name} ${result[0].id} `);

         // input && input.onChange && input.onChange(result[0].id)
          //setLastDoc(input.name)
        })
        .catch(console.error)
      }

  }

  const fileInput = (doc, uploadSuccess) => {
    //changeFieldValue(fileId: fileId)
    console.log('fileUpload clicked for doc ', doc)
    return (
      <Fragment>
      <FileInput onChange={uploadFileRequest} prompt={"Load the " + doc.description} />
      isMobileDevice() && <CameraInput onChange={uploadFileRequest} />}
     </Fragment>
     )
  }

    const { data : myData, loading, error } = useQuery(GET_PROFILE)
    if (loading ) { return <ActivityIndicator large/> }

    console.log('data & claimant ', claimantID, data.claim, myData)

    if (!claimantID ) {
      console.log('state is ', data.claim.state )
      setEditsAllowed(data.claim.state === 'INITIATED')

      if (myData.myClient) {
        setClaimantID(myData.myClient.idNumber)
      }
      else {
        setClaimantID(data.claim.claimant.idNumber)
      }
      setAllDocuments(data.claim.documents)
      loadInfo(data.claim.claimType)
      setDisplayOnly(['cancelled', "rejected"].indexOf(data.claim.state.toLowerCase()) > -1 )
    }

    const cancelUserReason = data.claim.reason ? cancelReasons.filter((reason) => reason.value === data.claim.reason ) : null

     console.log('edits allowed', editsAllowed)


     if (displayOnly) {
       return (
          <Center>
          <Moku small />
          <ChatCards>
            {myData.myClient && <ChatCard text={`Welcome back ${myData.myClient.firstNames}. `} noNext />}
            <ChatCard text={`This claim has been ${data.claim.state} because ${cancelUserReason ? cancelUserReason[0].label :"unknown reasons"}.`} noNext />
            <ChatCard nextTitle="OK" handleNext={() => history.push('/claim')} />
        </ChatCards>
        </Center>
       )
     }

     else return (
          <Center>
            <Moku small />
            <ChatCards>

            {myData.myClient && <ChatCard text={`Welcome back ${myData.myClient.firstNames}.`} noNext />}

            {myData.myClient &&  <ChatCard text={<ClaimHeader claimInfo={data.claim} editsAllowed={editsAllowed}/>  } noNext />}

            {infoFields && infoFields.length > 0 &&
              <ChatCard displayOnly={!editsAllowed} text="" noNext>
            {
              infoFields.map((field, index) => <FormField displayOnly={!editsAllowed} key={"info"  + index} label={field.name} name={`info.${field.key}`}/> )
            }

            </ChatCard>}


              <FieldValue  name={`documents[${activeDocument}].fileId`} renderField={doc => {
                const { input } = doc

                if ((activeDocument || activeDocument === 0) && !input.value  ) {
                  return  (
                    <ChatCard
                    text={<Fragment>
                      <center><br/>Please upload: <b>{allDocuments[activeDocument].description}</b></center>
                      <br />
                    </Fragment>}
                  fieldNames={[`documents[${activeDocument}].fileId` ]} noNext={!input.value} focus nextTitle={"Later" } handleNext={ (() => setActiveDocument(null))} />
                ) }
                else {
                  return (
                    ((activeDocument || activeDocument === 0) && !input.value)
                    ? <ChatCard text={<Fragment><center><br/> <Icon size="4x" color="green" spin icon="spinner" /><br/>uploading</center></Fragment>} noNext />
                    : (activeDocument || activeDocument === 0 && input.value) ?
                    <ChatCard text={<Fragment> <center><br />  Upload complete, press Next to continue<br /><br /> </center> </Fragment>} focus nextTitle={"Next Document" } handleNext={(() =>  { setLastFileUploaded(input.value) ; setActiveDocument(null)})} />
                    : <div></div>
                    )}}}/>

              {activeDocument == null && editsAllowed &&
              <center>
                <br/>
                <ChatCard noNext text={<SubmitButton renderSubmitButton={({ submit }) => ( <Button action="true"onClick={() =>  submit() }> Submit Changes</Button> )} />}/>
              </center>}

            </ChatCards>
          </Center>
    )
}

Layout = withRouter(Layout)

function ClaimCompleted({ history }) {
  return (
        <Center>
          <Moku />
          <ChatCards>
            <ChatCard text={
              <center>Thanks so much for the update. Your claim changes have been logged and will be assessed. We will provide feedback using the given details.
              </center>}
            nextTitle="Done" handleNext={() => history.push('/claims')} />
          </ChatCards>
          </Center>
  )
}

ClaimCompleted = withRouter(ClaimCompleted)

const UPDATE_CLAIM = gql`
  mutation UpdateClaim($id: String, $input: UpdateClaimWithDocumentsInput) {
  updateClaimWithDocuments(id: $id, input: $input) {
    ...ClaimInfo
  }
}
${claimInfo}
`

const validate = values => {
  const {info, documents} = values

  const errors = {}

  console.log('errors are ', errors)
  return errors
}

const warn = values => {

  const {info, documents} = values

  const errors = {}

  if (!info || !info.causeOfDeath || info.causeOfDeath.length == 0 ) {
    if (!errors.info) { errors.info = {} }
    errors.info.causeOfDeath = 'Cause of death is required'
  }

  if (!info  ) {
    if (!errors.info) { errors.info = {} }
    errors.info = 'All fields are required'
  }

  errors.documents = documents && documents.map((doc, index) => {
    {console.log('values for warnings ', doc )}
    if (doc) {
      if (doc.waived) {
        return {}
      }
      if (doc.verified) {
        return {}
      }
      if (doc.verified === false) {
        return {documentId: 'The document is invalid' }
      }
      if (doc.documentId || doc.fileId) {
      return {documentId: 'Please check the document' }
      }

      return { documentId: 'This document is required ' }
    }
  })

  console.log('warnings are ', errors)

  return errors
}

const fields = {
  effectiveDate: {
    type: "date",
    required:true
  },
  info:  {
    type: "keyvalue",
    config: {}
  },
  documents: {
    label: 'Documents',
    type: 'fieldarray',
    config: {
      description: {
        label: 'Description',
        type: 'text'
      },
      documentId: {
        type: 'hidden'
      },
      file: {
        strip:true,
      },
      fileId: {
        type: 'fileupload'
      },
      requirementId: {
        type: 'hidden'
      },
      requirement: {
        strip: true
      },
      'requirement.documentType': {
        label: 'Type',
        type: 'text',
        displayOnly: true
      }
    }
  }
}

function FuneralFormEdit({ match}) {

  const [claimNumberSaved, setClaimNumberSaved] = useState("")
  const { id, claimNumber } = match.params

  console.log('Edit claim for match params and id and claimType ', match, claimNumber)

  if (claimNumberSaved !== "") {
    return <ClaimCompleted  />
  }

  return (
    <GraphQLFormProvider
      id={id}
      name="claim"
      mode="edit"
      fields={fields}
      validate={validate}
      warn={warn}
      initialValuesFromData={data => data.claim}
      renderInput={renderInput}
      InputFormLayout={Layout}
      renderLabel={renderLabel}
      query={gql`query Claim($id: String!) {
        claim(id: $id) {
        id
        state
        reason
        claimNumber
        claimType
        claimant {
          id
          idNumber
        }
        client {
          id
          idNumber
          firstNames
          surname
        }
        effectiveDate
        createdAt
        info{
          key
          value
        }
        documents {
          id
          description
          waived
          verified
          documentId
          fileId
          file {
            id
            url
            name
            contentType
          }
          requirementId
          requirement {
            id
            requirementId
            documentType
            description
            required
          }
        }
        }
        }`}
      updateMutation={UPDATE_CLAIM}
      afterSubmit={(data) => {setClaimNumberSaved("claim")} }
      keepDirtyOnReinitialize={true}
    />
  )
}


export default FuneralFormEdit