import React, { Fragment } from 'react'
import gql from 'graphql-tag';
import { Row, Col } from '../../generic/grid'
import { Container, Icon, Button } from '../../../components/generic'
import { DocumentContainer, DocumentName } from './RequiredDocuments.style'
import { FormFieldArray, FieldValue, FormField } from 'react-form-helper'


function List( {doc,  editDoc, bold, onClick, newClaim}) {
  console.log('RequiredDocuments list doc is ', doc, doc.verified)
  return (
  <DocumentContainer >
    <Row  width="98%" key={doc.key} >

        <Col alignHorizontal="left" width="65%" ><DocumentName bold={(!doc.fileId && !doc.documentId || (doc.verified === false)) && !doc.waived} strike={doc.waived}>{doc.requirement ? doc.requirement.description : doc.description}</DocumentName></Col>

      <Col alignHorizontal="left" width="21%" ><span style={{fontVariant: "small-caps"}}>{doc.verified === false && !doc.waived && <b>error</b>}{doc.waived && "(waived)"}</span></Col>

        {!newClaim && <Col alignHorizontal="right" width="14%">
        {doc.waived ? ""
        : doc.verified ?
          <Icon size="1x" color="green" icon="thumbs-up" />
        : <Fragment>
        {console.log("Required docs",doc )}
            { !doc.fileId || doc.verified === false ? <Button  size="small" action={true}  onClick={() => {doc.verified = null; doc.fileId = null; editDoc(doc)}} >  {doc.verified  === false ? "Re-Upload" :  "Upload"}  </Button>
            :  doc.fileId && doc.verified === null ? <Icon size="1x" color="green" icon="user-clock" /> : <div />
            }
          </Fragment>}
      </Col>}
    </Row>
  </DocumentContainer>
  )
}

function RequiredDocuments({ activeDocument, setActiveDocument, newClaim }) {
  return (
    <Fragment>
      <Container title="" >
        <FormFieldArray key={activeDocument} name='documents' renderFieldArray={({ fields }) => {
          console.log('RequiredDocuments list fields are ', activeDocument)
          return (
          fields && fields.map((field, index) => <FieldValue name={field} renderField={({ input }) => {
            console.log('field is ', field, input)
            return (
              <Fragment>
                <List key={index+'list"'} onClick={() => setActiveDocument(index)} newClaim={newClaim} key={input.id} doc={input.value} editDoc={() => setActiveDocument(index)}  bold={activeDocument === index} />
              </Fragment>
          )}} />)
        )}
      } />
      </Container>
    </Fragment>
  )
}

export
{
  RequiredDocuments, List
}