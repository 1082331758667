import React from 'react'
import EmployerContributionRow from '../EmployerContributionRow'
import { Col } from '../../generic/grid'
import { Container } from '../../generic'

const byPeriod = (a, b) => (a.period < b.period) ? 1 : -1

function EmployerContributionList({ contributions, onRowPress }) {
  return (
    <Container title="My Contributions">
      <Col>
        {contributions.sort(byPeriod).map((c, index) => (
          <EmployerContributionRow key={c.id} topRow={index === 0} {...c} onClick={onRowPress}/>
        ))}
      </Col>
    </Container>
  )
}

export default EmployerContributionList
