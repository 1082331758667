import React,{Fragment} from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { SavingsNeedItemContainer } from './SavingsNeedsList.style'
import SavingsNeedItemRow from '../SavingsNeedItemRow'
import { Col } from '../../generic/grid'
import { Container } from '../../generic'

// Row 0 is not draggable
function SavingsNeedsList({ bucketInfo, onRowPress, onDragEnd }) {
  return (
    <Fragment>
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <Droppable droppableId="all-rows">
        {provided => (
          <Col {...provided.droppableProps} ref={provided.innerRef}>
            {bucketInfo.map((c, index) => (
              index === 0 ? (
                <SavingsNeedItemContainer>
                  <SavingsNeedItemRow
                    topRow={true}
                    {...c}
                    onClick={onRowPress}
                  />
                </SavingsNeedItemContainer>
              ) : (
                <Draggable draggableId={c.id} index={index - 1}>
                  {(provided2, snapshot) => (
                    <SavingsNeedItemContainer
                      {...provided2.dragHandleProps}
                      {...provided2.draggableProps}
                      ref={provided2.innerRef}
                      isDragging={snapshot.isDragging}
                      key={c.id}
                    >
                      <SavingsNeedItemRow
                        topRow={index === 0}
                        {...c}
                        onClick={onRowPress}
                      />
                    </SavingsNeedItemContainer>
                  )}
                </Draggable>
              )
            ))}
            {provided.placeholder}
          </Col>
        )}
        </Droppable>
      </Container>
    </DragDropContext>
    <p style={{ textAlign: "center" }}><i>Drag to change savings priority</i></p>
    </Fragment>
  )
}

export default SavingsNeedsList
