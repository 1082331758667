import React, {Fragment} from 'react'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo-hooks'
import { withRouter } from 'react-router-dom'
import {Container, Button} from '../../../components/generic'
import { MyFuneral, AllMyFunerals } from '../../../components/funeral'
import { ActivityIndicator } from '../../../components/generic'

const GET_PROFILE = gql`
{
  myClient {
    id
    firstNames
    surname
    idNumber
    photo {
      id
      url
    }
    funeralPolicies {
      id
      policyNumber
      policyType
      createdAt
      coverAmount
      state
      currentPremiumTotal
    }
  }
}
`
const ComingSoon = ({history}) => (
  <Container title='Coming Soon'>
    <center>
    <h3>Please check back later this month.</h3>
      <Button action="true"onClick={()=> history.push(`/`)} > Back</Button>
      <br />
      <br />
    </center>
  </Container>
)


function Funeral({ match, history }) {

  const { id } = match.params
  console.log('funeral match params id is ', id)

  const New = () => history.push('/funeral/new')

  if (id) {
    return <MyFuneral id={id} />
  }

  else {
    const { data , loading, error } = useQuery(GET_PROFILE)
    if (loading ) { return <ActivityIndicator large/> }
    if (error) { console.log(error); return "An error has occurred" }

    const { myClient } = data

    console.log('myClient is ', myClient)

    if (myClient && myClient.funeralPolicies && myClient.funeralPolicies.length > 0) {
    return (
      <Fragment>
          {myClient.funeralPolicies.length > 1
            ? <AllMyFunerals funeralPolicies={myClient.funeralPolicies} history={history} />
            : <MyFuneral id={myClient.funeralPolicies[0].id} /> }
      </Fragment>
      )
    }
    else return <New />
  }
}

export default Funeral