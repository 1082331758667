import React from 'react'
import moment from 'moment'
import { printCurrency } from '../../../lib/utils'
import { Icon } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { RowContainer } from './PaymentItem.style'

const defaultDescription = (fromEmployer, amount) => amount < 0 ? 'Withdrawal' : fromEmployer ? 'Employer Contribution' : 'Contribution'
    
function PaymentItem({ amount, description, topRow, fromEmployer, date }) {
  return (
    <RowContainer topRow={topRow}>
      <Col width="60px" centerVertical centerHorizontal>
        <Icon size="2x" icon={amount < 0 ? "arrow-circle-left" : "arrow-circle-right"} />
      </Col>
      <Col centerVertical>
        <Row>{moment(date).format('YYYY-MM-DD')}</Row>
        <Row>{`${description || defaultDescription(fromEmployer, amount)}`}</Row>
      </Col>
      <Col width="100px" style={{ paddingRight: "14px" }} centerVertical alignHorizontal="right">{printCurrency(amount)}</Col>
    </RowContainer>
  )
}

export default PaymentItem