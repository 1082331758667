import React, { useState, Fragment } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormFieldArray, FormField, FieldValue } from 'react-form-helper'
import { banks } from '../../../constants'
import { renderLabel, renderDisplay, renderInput } from '../../generic/form'
import { Container, Icon, Button } from '../../generic'
import { Row, Col } from '../../generic/grid'

import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';

const UPDATE_BUCKET = gql`
  mutation WithdrawFromBucket($id: String!, $savingsId: String, $amount: Float!) {
    withdrawSavings(bucketId: $id, savingsId: $savingsId, amount: $amount)
  }
`

function Layout({closeModal}) {

  return (
    <Fragment>
      <Col>
        <Row centerHorizontal >
          <Col size="1"></Col>
          <Col size="9">
            <br />
            <FormField name={"amount"}  />   
          </Col>
        </Row>
        
        <Row>
          <br /> <br />
              * The first withdrawal each month is free. R5 is charged for each additional withdrawal.
          <br /><br />
        </Row>
        
        <Row direction="column" alignItems="flex-end">
        <SubmitFormButton renderSubmitButton={({ invalid, submit, submitting }) => {

          const [submitted, setSubmitted] = useState(false)

          if (submitted && !submitting) {
                setSubmitted(false)
          }
          return (
          <Row centerHorizontal>
            <Col>
              <Button onClick={() => closeModal(false)}>Cancel</Button>
            </Col>
            {' '}
            <Col>
              <Button action="true" disabled={invalid || submitting} submitting={submitting} onClick={ () => { submit();  setTimeout(() => setSubmitted(true), 100);  }}>Confirm</Button>
            </Col>
          </Row>
          )}
        } />
        </Row>
      </Col>
    </Fragment>
  )
}

function isInt(value) {
  var x;
  if (isNaN(value)) {
    return false;
  }
  x = parseFloat(value);
  return (x | 0) === x;
}

const fields = {
  "amount": {
    label: "Withdrawal Amount",
    type: "currency",
    required: true,
    validate: value => { 
      if (value) {
        return isInt(value) ? undefined : "Enter a valid number"
      }
      return undefined
    }
  }
}


function WithdrawForm({ id, availableAmount, closeModal, refetchQueries, savingsId }) {

  console.log('validate that amount is less than available ', availableAmount)

  const validate = values => {
    const {amount} = values

    const errors = {}
    if ( !amount) {
      errors.amount = `Withdrawal amount is required`
    }
    else if ( amount > availableAmount) {
      errors.amount = `The maximum withdrawal allowed is R${availableAmount}`
    }
    else if (amount < 25) {
      errors.amount = "The minimum withdrawal allowed is R25"
    }
    console.log('errors are ', errors)
    return errors
  }

  return (
    <GraphQLFormProvider
      name="withdraw"
      id={id}
      fields={fields}
      validate={validate}
      mode="add"
      mapInputVariables={({ amount }) => ({ id, savingsId, amount })}
      InputFormLayout={props => <Layout {...props} closeModal={closeModal} />}
      addMutation={UPDATE_BUCKET}
      renderLabel={renderLabel}
      renderInput={renderInput}
      refetchQueries={refetchQueries}
      afterSubmit={() => closeModal() }
    />
  )
}

export default WithdrawForm
