import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function AutoComplete({ getValue = (v => v), value = "", onChange, placeholder, options }) {
  const onValueChange = (event, { newValue, method }) => {
    onChange(newValue)
  }

  const inputProps = {
    placeholder,
    value,
    onChange: onValueChange
  };

  function getOptions({ value }) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp('^' + escapedValue, 'i');

    return options.filter(option => regex.test(getValue(option)));
  }

  const renderSuggestion = suggestion => {
    return (
      <div>
        {getValue(suggestion)}
      </div>
    )
  }

  const menuStyle ={
    borderRadius: '3px',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '3px',
    fontSize: '100%',
    fontWeight:'bold',
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
  }

  const [suggestions, setSuggestions] = useState(options)

  return (
    <Autosuggest
      suggestions={suggestions}
      autoHighlight={true}
      onSuggestionsFetchRequested={(v) => { setSuggestions(getOptions(v)) }}
      onSuggestionsClearRequested={() => { setSuggestions([]) }}
      getSuggestionValue={getValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      wrapperStyle={menuStyle}
      selectOnBlur={true}
    />
  )
}

export default AutoComplete
