import {API_URL, API_SUBSCRIPTION_URL, API_TOKEN, APP_VERSION} from './config'
import introspectionQueryResultData from './fragmentTypes.json'
import { ApolloClient } from 'apollo-client'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { setContext } from 'apollo-link-context'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'

const httpLink = createUploadLink({
  uri: `${API_URL}graphql`,
});

const middlewareLink = setContext(() => ({
  headers: {
    Authorization: `JWT ${localStorage.getItem('accessToken') || API_TOKEN}`,
    "X-App-Client": "portal",
    "X-App-Version":  APP_VERSION
  }
}));

// use with apollo-client
const httpLinkWithAuth = middlewareLink.concat(httpLink);

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: API_SUBSCRIPTION_URL,
  options: {
    reconnect: true,
    connectionParams: () => ({ authToken: localStorage.getItem('accessToken') || API_TOKEN })
  }
});
  
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLinkWithAuth
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;