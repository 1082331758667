import React from 'react'
import { Link } from 'react-router-dom'
import { SideMenuItemContainer, SideMenuItemIcon, SideMenuItemText } from './SideMenuItem.style'

function SideMenuItem({ icon, title, route, selected, toggleSidebarOpen }) {
  return (
    <Link to={route} onClick={toggleSidebarOpen}>
      <SideMenuItemContainer selected={selected}>
        <SideMenuItemIcon icon={icon} />
        <SideMenuItemText>{title}</SideMenuItemText>
      </SideMenuItemContainer>
    </Link>
  )
}

export default SideMenuItem
