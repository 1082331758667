import styled from 'styled-components' 

const GraphContainer = styled.div`
  width: 100%;
  max-width: 400px;
  text-align: center;
`

export {
  GraphContainer
}