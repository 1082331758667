import React, { Component, Fragment } from 'react'
import { ThemeProvider } from 'styled-components'
import theme from './theme.style'
import { TopBar, SideMenu } from './components/layout'
import Sidebar from 'react-sidebar'
import { GlobalStyle, ContentPane } from './App.style'
import { createBrowserHistory } from 'history';
import { Router,  Route, Switch, Redirect } from 'react-router-dom'
import {MEERKAT_WEB_URL} from './config'


import { Login, ProfileImage, ProfileMenu, MyProfile, MyBankAccounts, RegisterForm, RegisterEmployer, CreditForm } from './components/profile'
import { Register } from './containers'
import { RegisterClaimant } from './components/claim'
import { Mandate } from './components/payments'
import Terms  from './components/terms'

import client from './client'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import { Employer, Payments, Claims, Funerals, Debts, Start, Documents, Credit, Lottery } from './screens'
import { AuthProvider } from 'react-auth-helper'
import { Provider } from 'react-redux'
import store from './store'
import {mokuFavicon} from './assets/images'
import { ChatProvider } from './context'


const isUserToken = Login.isUserToken

const history = createBrowserHistory();

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI notify the user they can install the PWA
});

/*
For logging ....
window.addEventListener('load', () => {
  if (navigator.standalone) {
    console.log('Launched: Installed (iOS)');
  } else if (matchMedia('(display-mode: standalone)').matches) {
    console.log('Launched: Installed');
  } else {
    console.log('Launched: Browser Tab');
  }
});
*/

class TopBarWithProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  render() {
    return (
      <Fragment>
        <TopBar onLeftPress={this.props.toggleSidebarOpen} onRightPress={() => this.setState({ menuOpen: !this.state.menuOpen })} renderRight={() => <ProfileImage size={30} />} />
        {
          1 == 2 && this.state.menuOpen && <ProfileMenu open={this.state.menuOpen} />
        }
      </Fragment>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props)

    const accessToken = localStorage.getItem("accessToken")

    this.state = {
      sidebarOpen: false,
      loggedIn: accessToken && isUserToken(accessToken)
    }
  }
  
  componentDidMount() {
    if (this.state.loggedIn && deferredPrompt) {
      deferredPrompt.prompt()
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextState.loggedIn && deferredPrompt) {
      deferredPrompt.prompt()
    }
  }

  loggedIn = () => {
    console.log('Setting as logged in !')
    this.setState({ loggedIn: true })
  }

  logOut() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('registerToken');
    this.setState({ loggedIn: false })
  }

  toggleSidebarOpen = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  onSetOpen = (sidebarOpen) => {
    this.setState({ sidebarOpen });
  }

  render() {
    const accessToken = localStorage.getItem('accessToken');
    const registerToken = localStorage.getItem('registerToken');
    console.log('ACCESS TOKEN=', accessToken, registerToken, this.state.loggedIn)
    const { location } = history
    const { pathname} = location
    console.log('pathName is ', location)
    console.log('pathName is ', pathname)
    
    const spinner = document.getElementById('spinner');
    if (spinner && !spinner.hasAttribute('hidden')) {
      spinner.setAttribute('hidden', 'true');
      spinner.setAttribute('class', 'hidden');
    }

    if (!this.state.loggedIn) {
      return (
        <Provider store={store({})}>
          <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <ThemeProvider theme={theme}>
              <ChatProvider>
                <Fragment>
                  <GlobalStyle />
                  <Router history={history}>
                  <ContentPane>
                    <Switch>
                      <Route path="/do/:id" component={Mandate} />
                      <Route path="/tc/:id" component={Terms} />
                      <Route path="/claims" render={(routeProps) => <Register {...routeProps} Content={RegisterClaimant} />} />
                      <Route path="/credit" render={(routeProps) => <Register {...routeProps} Content={CreditForm} />} />
                      <Route path="/register"  exact render={(routeProps) => <Register {...routeProps} Content={RegisterForm} />} />
                      <Route path="/register/:id"  render={(routeProps) => <Register {...routeProps} Content={RegisterForm} />} />
                      <Route path="/register-employer" render={(routeProps) => <Register {...routeProps} Content={RegisterEmployer} />} />
                      <Route path="/login" render={props => <Login {...props} register={() => props.history.push('/register')} registerEmployer={() => props.history.push('/register-employer')} loggedIn={this.loggedIn} />} />
                          <Route path='/savings' component={() => window.location = `https://www.meerkat.co.za/savings`} />
                          <Route path="/" exact render={(routeProps) => <Register {...routeProps} Content={RegisterClaimant} />} />
                      <Route path='*'><Redirect to='/' /></Route>
                    </Switch>
                </ContentPane>
                </Router>
                </Fragment>
              </ChatProvider>
            </ThemeProvider>
          </ApolloHooksProvider>
          </ApolloProvider>
        </Provider>
      );
    }

    return (
      <Provider store={store({})}>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <ThemeProvider theme={theme}>
              <ChatProvider>
              <AuthProvider>
                <GlobalStyle />
                <Router history={history}>
                  <Sidebar
                    sidebar={<SideMenu toggleSidebarOpen={this.toggleSidebarOpen} />}
                    open={this.state.sidebarOpen}
                    onSetOpen={this.onSetOpen}
                    transitions
                  >
                    <ContentPane>
                      { (!history.location || !history.location.pathname || !(history.location.pathname.startsWith('/do/') || history.location.pathname.startsWith('/tc/') )) && <TopBarWithProfileMenu toggleSidebarOpen={this.toggleSidebarOpen} history={history} />}
                      <Switch>
                        <Route
                          path="/logout" exact
                          render={({ history }) => {
                            this.logOut();
                            history.push('/');
                            return <div />;
                          }}
                        />
                        <Route path="/do/:id" component={Mandate} />
                        <Route path="/tc/:id" component={Terms} />
                        <Route path="/profile" component={MyProfile} />
                        <Route path="/login" render={props => <Login {...props} loggedIn={this.loggedIn} />} />
                        <Route path="/:product?/:productId?/bank-accounts" component={MyBankAccounts} />
                        <Route path='/about-us' component={() => window.location = `${MEERKAT_WEB_URL}/about-us`  }/>
                        <Route path='/claims' component={Claims} />
                        <Route path='/claim' component={Claims} />
                          <Route path='/savings' component={() => window.location = `https://www.meerkat.co.za/savings`} />
                        <Route path='/credit' component={Credit} />
                        <Route path='/debt' component={Debts} />
                        <Route path='/documents' component={Documents} />
                        <Route path={'/employer'} component={Employer} />
                        <Route path={'/employers'} component={Employer} />
                        <Route path='/payments' component={Payments} />
                        <Route path='/draw' component={Lottery} />
                        <Route path='/' exact component={Start} />
                        <Route path='*'><Redirect to='/' /></Route>
                      </Switch>
                    </ContentPane>
                  </Sidebar>
                </Router>
              </AuthProvider>
              </ChatProvider>
            </ThemeProvider>
          </ApolloHooksProvider>
        </ApolloProvider>
      </Provider>
    );
  }
}

export default App;