import styled, { createGlobalStyle } from 'styled-components'

const ContentPane = styled.div`
  margin-top:57px;
  //background-image: linear-gradient(#3E4255, #55513E);
  background-image: ${props => props.theme.backgroundImage};
  min-height: calc(100vh - 57px);
`

const GlobalStyle = createGlobalStyle`
  ${props => props.theme.loadFontUrl ? `@import url('${props.theme.loadFontUrl}');` : ""}

  a {
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: bold;
    }
  }

  body {
    font-family: ${props => props.theme.fontFamily}, sans-serif;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.05);
    color:#323232
  }

  input[type="file" i] {
    width:0
  }

  .DateInput_input, .DateInput_input__focused, .SingleDatePickerInput__withBorder {
    outline: 0;
    background: #f2f2f2;
    border: 1px solid #e4e7e7!important;
    border-radius: 4px!important;
    line-height: 16px!important;
    font-size: 16px!important;
    font-weight: normal!important;
}

.react-date-picker__wrapper {
  border-radius: 4px!important;
}
`

export {
  GlobalStyle,
  ContentPane
}