import React from 'react'
import { Container } from '../../generic'
import PaymentItem from '../PaymentItem'

function PaymentList({ payments }) {
  return (
    <Container title="My Payments">
      {payments.map((payment, index) => <PaymentItem topRow={index === 0} key={payment.id} {...payment} />)}
    </Container>
  )
}

export default PaymentList
