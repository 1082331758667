import styled from 'styled-components'

import { meerkatWhite }  from '../../../assets/images'

const TopBarContainer = styled.header`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
  height: 50px;
  background-color: ${props => props.theme.topBarColor};
  text-align: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2)
`

const TopBarTitle = styled.img.attrs({
  src: meerkatWhite
})`
  display: inline-block;
  width: 150px
`

const TopBarLeft = styled.div`
  position: fixed;
  left: 5px;
  top: 5px;
`

const TopBarRight = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  right: 7px;
  top: 5px;
`

export {
  TopBarContainer,
  TopBarTitle,
  TopBarLeft,
  TopBarRight
}

