import React, { Component} from 'react'
import { Switch, Route } from 'react-router-dom'
import MyClaims from './MyClaims'
import { ClaimForm, ClaimFormEdit } from '../../components/claim'

// TODO: Should only be 1 ClaimForm
export default class Claims extends Component {
  render() {
    return (
      <Switch>
        <Route path='/claims' component={MyClaims} />
        <Route path='/claim' exact component={ClaimForm} />
        <Route path='/claim/:id' component={ClaimFormEdit} />
      </Switch>
    )
  }
}