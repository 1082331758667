export default [
  {
    icon: "home",
    title: "Home",
    route: "/",
    requiresRoles: ['CLIENT']
  },
  {
    icon: "piggy-bank",
    title: "My Savings",
    route: "/savings",
    requiresRoles: ['CLIENT']
  },
  {
    icon: "cash-register",
    title: "My Payments",
    route: '/payments',
    requiresRoles: ['CLIENT']
  },
  {
    icon: "file-invoice",
    title: "My Claims",
    route: '/claims',
    requiresRoles: ['CLIENT']
  },
  {
    icon: "file",
    title: "My Documents",
    route: '/documents',
    requiresRoles: ['CLIENT']
  },
  {
    icon: "user-circle",
    title: "My Profile",
    route: "/profile",
    requiresRoles: ['CLIENT']
  },
  {
    icon: "building",
    title: "Employer Portal",
    route: "/employers",
    requiresRoles: ['EMPLOYER']
  },
  {
    icon: "sign-out-alt",
    title: "Logout",
    route: "/logout",
    requiresRoles: ['CLIENT', 'EMPLOYER']
  }
]

/*{
    icon: "umbrella",
    title: "My Insurance",
    route: "/insurance",
    requiresRoles: ['CLIENT']
  },
  {
    icon: "coins",
    title: "My Bank Accounts",
    route: "/bank-accounts",
    requiresRoles: ['CLIENT']
  }
*/