import React from 'react'
import { ListContainer, ListHeader, ListTitle } from './Container.style'

function Container({ children, title, header, className, centerTitle, noBackground, pad }) {
  return (
    <ListContainer className={className} noBackground={noBackground} pad={pad}>
      {
        header ?
          header
        : title && (
          <ListHeader>
            <ListTitle centerTitle={centerTitle}>{title}</ListTitle>
          </ListHeader>
        )
      }
      {children}
    </ListContainer>
  )
}

export default Container
