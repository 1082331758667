import styled from 'styled-components'
import { Row, Col } from '../../generic/grid'

const PaddedCol = styled(Col)`
  padding: 7px;
`

const stateColor = {
  "ACTIVE": "green",
  "PAID_UP": "green",
  "INFO_MISSING": "orange",
  "NOT_TAKEN_UP": "orange",
  "ON_RISK_GRACE": "orange",
  "FROZEN": "red",
  "LAPSED": "red",
  "CANCELLED":"red"
}

const Container = styled.div`
  margin:auto;
  border: 1px solid grey;
  padding:7px,
`

const BoxContainer = styled.div`
  min-width:325px;
  min-height:100px;
  margin:auto;
  border: 1px solid grey;
  padding:7px,
`

const PageContainer = styled.div`
  display: flex;
  margin-top:5%;
  flex-direction:column;
  align-items: center;
  max-width: 600px;
`

const Title = styled.h1`
  font-size: 16px;
  line-height: 16px;
  padding-left: 7px;
`

const Header = styled.div`
  padding: 7px;
  border-bottom: 1px solid grey;
`

export {
  PaddedCol,
  stateColor,
  PageContainer,
  Container,
  Header,
  Title,
  BoxContainer
}
