import { createStore, applyMiddleware, compose  } from 'redux'
import reducer from '../reducers'

function configureStore(initialState) {
  return createStore(reducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
}

const store = (initialState = {}) => configureStore(initialState)

export default store
