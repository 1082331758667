import React from 'react'
import styled from 'styled-components'
import { Icon } from '../../generic'

const MyProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const MyProfileImageContainer = styled.div`
  width: 24vh;
  heigth: 24vh;
  border-radius: 12vh;
`

const ShowMyProfileImage = styled.img`
  width: 24vh;
  border-radius: 12vh;
`

const ButtonContainer = styled.div`
  align-text: center;
  margin: 14px
`

const DefaultProfileImage = () => <Icon icon="user-circle" style={{ width: "24vh", height: "24vh" }} />

const MyProfileImage = ({ url }) => (
  <MyProfileImageContainer>
    {url ? <ShowMyProfileImage src={url} /> : <DefaultProfileImage />}
  </MyProfileImageContainer>
)

export {
  MyProfileContainer,
  MyProfileImage,
  ButtonContainer
}