import styled from 'styled-components'

const Button = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
  z-index:1
  cursor:pointer;
  border-radius: 100px;
  height: auto;
  margin-bottom: 7px;
  min-height: 45px;
  min-width:280px;
  ${props => props.border && `border: 1px solid ${props.border}` };
  width:85%;
  background: ${props => props.background ? props.background : props.theme.buttons };
  &:hover {
    opacity:0.6
  }
`
const ButtonIcon = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
  margin-right:14px;
`
const ButtonLabel = styled.label`
  display: flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;
  font-size: 16px;
  font-weight: 400;
  height:20px
  color: ${props => props.color ? props.color : "#505050"};
`

export {
  Button, ButtonIcon, ButtonLabel,
}