import React, {Fragment, useState} from 'react'
import { withRouter } from 'react-router-dom'
import {ChatCards } from '../../profile/RegisterForm/RegisterForm.style'
import { Button, ChatFlow, Challenge,  Modal } from '../../generic'
import { Moku, Center } from './CreditForm.style'
import {CreditPicture, DrawChart} from './CreditPicture'
import {CreditLine, DrawLine} from './CreditLine'
import gql from 'graphql-tag'
import { isRequired, isValidIdNumber, isValidEmail, isInteger } from '../../../lib/validators'
import { TermsAndConditions, OTP, LoginButtons, RegisterButtons } from '../../shared'
import { SubmitButton} from 'react-form-helper'
import { idTypes } from '../../../constants'

const CLIENT_CHECK = gql`
  query ClientCheck($idNumber: String, $idType: String) {
    checkForClient(idNumber: $idNumber, idType: $idType) {
      existingClient
      hasSavings
      employerContributions
      hasUser
      hasExistingCellNumber
      hasExistingBankAccount
      hasAcceptedTerms
      hasCreditLife,
      hasFuneralPolicy,
      isFuneralPolicyMember
      authProvider
      hasCreditRecord
      hasCreditReport
    }
  }
`

const SENDREPORT = gql`
  query EmailCreditReport($idNumber:String!, $email: String) {
    emailCreditReport (idNumber: $idNumber, email: $email )
  }
`

const COMPUSCORE = gql`
  query GetCompuscoreByIdNumber($idNumber: String!) {
    getCompuscoreByIdNumber(idNumber: $idNumber) {
      compuScore
      riskType
      riskColour
      canAfford
      totalDebt
    }
  }
`
const AFFORD = gql`
  query GetAffordability($idNumber:String, $income:String, $expenses:String, $married:String, $dependants:String, $totalDebt:String) {
    getAffordability(idNumber:$idNumber,income:$income, expenses:$expenses, married:$married, dependants:$dependants, totalDebt:$totalDebt)
  }
`

const CHALLENGE = gql`
  query GetChallenge($idNumber: String!, $idType:String, $firstNames:String, $surname:String, $cellNumbers:[String], $email:String) {
    getChallenge(idNumber: $idNumber, idType: $idType, firstNames: $firstNames, surname:$surname, cellNumbers:$cellNumbers, email:$email) {
      error
      telephones
      accounts
      employers
    }
  }
`

const submitAndEnd = () => {
return (

<SubmitButton renderSubmitButton={({ submit }) => (
  <Fragment>
    <center>
      <Button onClick={() => { submit(); }} action>Home Page</Button>
    </center>
  </Fragment>
)} />

  )
}
const doClientCheck = ({ apolloClient, values, chatState, setChatState, gotoNext, setSubmitting }) => {
  const { idNumber, idType } = chatState
  console.log('check whether ' + idNumber + ' from ', idType + ' is a client')
  console.log('check whether check values are  ', values)
  let next = ["firstTime"]
  setSubmitting(true);
  if (isValidIdNumber(idNumber, idType) === undefined) {
    console.log(idNumber +'isValidIdNumber ')
      apolloClient.query({ query: CLIENT_CHECK, variables: { idNumber, idType } }).then(({ data }) => {
      setChatState({ ...data.checkForClient, idNumber })
      setSubmitting(false);
      console.log('data.checkForClient ', data.checkForClient)
      if (data.checkForClient.hasAcceptedTerms) {
        next = ["email"]
        if (data.checkForClient && data.checkForClient.hasCreditReport && !data.checkForClient.hasCreditRecord) {
          next = ["noReport"]
        }
        else next = ["email"]
      }
      gotoNext(next)
    })
  }
  gotoNext(next)
}

const creditCheckSteps = [
  {
    name: "intro",
    text: `Your credit profile is what tells lenders whether or not you are a good candidate for the credit you're applying for.`,
    noNext:true,
    nextAfterDelay:1000,
    nextSteps: ["intro2"]
  },
  {
    name: "intro2",
    text: "Your profile is based on current credit usage, defaults or judgements you may have. A low score is bad and a high score is good. ",
    noNext:true,
    nextAfterDelay:1000,
    nextSteps: ["idInfo"],
  },
  {
    name:"idInfo",
    text: 'I will need some information before I can run a credit check',
    labels: true,
    fieldNames: ["idType", "idNumber"],
    skipStep: ({ loggedIn, idNumber }) => loggedIn && idNumber,
    handleNextIfSkipStep: true,
    handleNext: doClientCheck
  },
  {
    name: "wantToLogin",
    text: "I see you're registered on our site. Do you want to login for more security",
    skipStep: ({ loggedIn }) => loggedIn,
    fieldNames: ["YesNo"],
    nextOnValue: true,
    nextSteps: ({ loggedIn, YesNo}) => { console.log('wantToLogin has yesNo value of ', YesNo); return loggedIn ? ["email"] : YesNo === 'Y' ? ["login"] : ["email"]},
    noNext: true
  },
  {
    name: "login",
    text: "Please login",
    component: LoginButtons,
    noNext: true,
    nextSteps: ["email"]
  },
  {
    name: "firstTime",
    text: "Before I can do anything I need you to accept our terms and conditions which allows me to run the check",
    nextSteps:["acceptedTerms"]
  },
  {
    name: "acceptedTerms",
    component: TermsAndConditions,
    skipStep: ({ hasAcceptedTerms }) => hasAcceptedTerms,
    noCard: true,
    nextSteps: ["names"]
  },
  {
    name: "names",
    text: "What is your name",
    labels: true,
    fieldNames: ["firstNames","surname"],
    nextSteps: ["cellPhone"],
  },
  {
    name: "cellPhone",
    text: "and your cell number?",
    fieldNames: ["cellNumber"],
    skipStep: ({ hasExistingCellNumber }) => hasExistingCellNumber,
    nextSteps: ["OTP"]
  },
  {
    name: "OTP",
    component: OTP,
    noCard: true,
    nextSteps: ["email"]
  },
  {
    name: "email",
    text: "Please give me an email address where I can send the report?",
    skipStep: ({ email }) => email,
    fieldNames: ["email"],
    nextSteps: ["getReport"]
  },
  {
    name: "getReport",
    text: "Please wait while I get the report. This can sometimes be quite slow.",
    noNext:true,
    nextAfterDelay:1000,
    nextSteps: ["challenge"]
  },
  {
    name: "challenge",
    text: "I am required to validate your identity to protect the data",
    skipStep: ({ existingClient,  hasCreditRecord }) => existingClient && !hasCreditRecord,
    beforeStep: async ({ apolloClient, chatState, setChatState }) => {
      const { idNumber, idType, firstNames, surname, cellNumber, email } = chatState
      console.log(' challenge state is', idNumber, idType, firstNames, surname, cellNumber, email)
      
        return apolloClient.query({ query: CHALLENGE, variables: { idNumber, idType, firstNames, surname, cellNumbers:cellNumber, email  } }).then(({ data }) => {
          const { error, ...challengeData } = data.getChallenge
          if (error) {
            setChatState({ hasCreditRecord: false })
          } else {
            console.log(' challenge results are ', data.getChallenge)
            setChatState({ hasCreditRecord: true, ...challengeData})
          }
      })},
    component: Challenge,
    nextSteps: ({hasCreditRecord}) => hasCreditRecord ? ["sendReport"] : ["noReport"],
    noNext:true,
  },
  {
    name: "sendReport",
    text:"Great, you passed so I've mailed you your credit report. Let me show you a display of your credit score",
    beforeStep: async ({ apolloClient, chatState }) => {
      const { idNumber, email } = chatState
      console.log('send report for ', idNumber +' to ', email )
      if (email) {
        apolloClient.query({ query: SENDREPORT, variables: { idNumber, email } }).then(({ data }) => {
      })}
    },
    nextAfterDelay:1000,
    nextSteps: ["creditLine"],
  },
  {
    name: "creditLine",
    text:"Your credit rating graph (higher is better).",
    beforeStep: async ({ apolloClient, chatState, setChatState }) => {
      const { idNumber } = chatState
      console.log('compuscore values for  ', idNumber, chatState )
      if (idNumber) {
        return apolloClient.query({ query: COMPUSCORE, variables: { idNumber } }).then(({ data }) => {
          console.log('compuscore values are  ', data )
          setChatState({...data.getCompuscoreByIdNumber})
      })}
    },
    component: CreditLine,
    nextSteps: ({compuScore}) => compuScore && compuScore > 450 ? ["doAnalysis"] : ["noReport"]
  },
  {
    name: "noReport",
    text: "I'm sorry but I can't find a credit record for you 🙁. The system is either off-line or they don't have one for your ID.",
    nextSteps: ["offline"],
    nextAfterDelay:2000,
    noNext: true
  },
  {
    name: "doAnalysis",
    text: "If I ask you a few questions I can prepare a full financial picture. Can I continue?",
    fieldNames: ["YesNo"],
    nextOnValue: true,
    nextSteps: (props) => { console.log('YesNo has yesNo value of ', props); const { YesNo } = props; return YesNo === 'Y' ? ["employed"] : ["seeYouLater"]},
    noNext: true
  },
 {
    name: "employed",
    text: "Are you employed?",
    fieldNames: ["employed"],
    nextSteps: ["married"],
    nextOnValue: true,
    noNext: true
  },
  {
    name: "married",
    text: "Are you are married or living with someone?",
    fieldNames: ["married"],
    nextSteps: ["income"],
    nextOnValue: true,
    noNext: true
  },
  {
    name: "income",
    text: "Ok, perfect. What is your income before tax each month?",
    fieldNames: ["income"],
    nextSteps: ["dependants"],
  },
  {
    name: "dependants",
    text: "And how many dependants (including your spouse and extended family) are you responsible for?",
    fieldNames: ["dependants"],
    nextSteps: ["expenses"]
  },
  {
    name: "expenses",
    text: "Could you let me know what your total monthly expenses are, on average?",
    fieldNames: ["expenses"],
    nextSteps: ["getAfford"]
  },
  {
    name: "getAfford",
    text: "Here's what your credit picture looks like",
    beforeStep: async ({ apolloClient, chatState, setChatState }) => {
      const { idNumber, income, expenses, married, dependants,totalDebt } = chatState
      return apolloClient.query({ query: AFFORD, variables: {idNumber, income, expenses, married, dependants,totalDebt} }).then(({ data }) => {
        setChatState({canAfford:data.getAffordability})
      })
    },
    component: CreditPicture,
    nextSteps: ["good"]
  },
  {
    name: "good",
    text: `You're profile's pretty good 😎 Well done!`,
    skipStep: ({ compuScore}) => compuScore < 620,
    nextSteps: ["afford"],
    nextAfterDelay:1000,
    noNext:true,
  },
  {
    name: "afford",
    text: `Your affordability to pay your debts is good.`,
    skipStep: ({ canAfford}) => !canAfford,
    nextSteps: ["badAfford"],
    nextAfterDelay:1000,
    noNext:true,
  },
  {
    name: "badAfford",
    text: `Your credit rating is low but your affordability is high. You might be able to improve this through Debt Counselling`,
    skipStep: ({ compuScore, canAfford}) => compuScore > 620 && canAfford,
    nextSteps: ["badBad"],
    nextAfterDelay:1000,
    noNext:true,
  },
  {
    name: "badBad",
    text: `Your affordability is not great 🙁 You might struggle to get credit. You might be able to improve this through Debt Counselling`,
    skipStep: ({ compuScore, canAfford}) => compuScore > 620 || canAfford,
    nextSteps: ({compuScore, canAfford, employed}) => canAfford  && compuScore > 620 ?["savings"] : employed ? ["dcCanHelp"] :["seeYouLater"],
    nextAfterDelay:1000,
    noNext:true,
  },
  {
    name: "savings",
    text: `You should have a look at our savings product. Can I tell you about it?`,
    fieldNames: ["gotoSavings"],
    nextOnValue: true,
    nextSteps: ({ gotoSavings}) => gotoSavings === 'Y' ? ["gotoSavings"] : ["seeYouLater"],
    noNext:true,
  },
  {
    name: "dcCanHelp",
    text: `Since the credit report is offline would you like me have someone call you to see if they can help?`,
    fieldNames: ["gotoDC"],
    nextOnValue: true,
    nextSteps: ({ gotoDC}) => gotoDC === 'Y' ? ["callMe"] : ["seeYouLater"],
    noNext:true,
  },
  {
    name: "offline",
    text: `Can I have someone call you to discuss Debt Counselling?`,
    fieldNames: ["gotoDC"],
    nextOnValue: true,
    nextSteps: ({ gotoDC}) => gotoDC === 'Y' ? ["callMe"] : ["seeYouLater"],
    noNext:true,
  },
  {
    name: "gotoSavings",
    text: "Great, let me redirect you to our savings product",
    handleNext:({ history, chatState  })  => {
      const {loggedIn}  = chatState
      console.log('logged in is ', loggedIn)
      return loggedIn ? history.push( '/savings') : history.push( '/register')},
    nextAfterDelay:1000,
    noNext:true,
  },
  {
    name: "callMe",
    text: "Ok, someone will call you shortly",
    nextAfterDelay:1000,
    noNext:true,
    nextSteps: ["seeYouLater"]
  },
  {
    name: "seeYouLater",
    text: "😀 It was very nice to e-meet you! Bye Bye.",
    handleNext:({ history })  => history.push('/'),
    nextTitle:"Done"
  },
  {
    name: "submit",
    handleNext:submitAndEnd,
    noNext: true
  },
  {
  name: "goHome",
  },
  {
    name: "signUp",
    handleNext:submitAndEnd,
    noNext: true
  }
];


const fields = {
  idNumber: {
    label: "ID Number",
    type: "text",
    validate: (value, { idType }) => isValidIdNumber(value,idType)
  },
  idType: {
    label: "ID Type",
    type: "select",
    options: idTypes,
    validate:isRequired
  },
  firstNames: {
    label: "First Name",
    type: "text"
  },
  surname: {
    label: "Surname",
    type: "text"
  },
  employed: {
    type: "yesno"
  },
  income: {
    type: "text",
    validate: isInteger
  },
  expenses: {
    type: "text",
    validate: isInteger
  },
  married: {
    type: "yesno"
  },
  dependants: {
    type: "text",
    validate: isInteger
  },
  email: {
    type: "text",
    validate: isValidEmail
  },
  cellNumber: {
    type: "text"
  },
  YesNo: {
    type: "yesno",
    strip:true
  },
  gotoSavings: {
    type: "yesno",
    strip:true
  },
  gotoDC: {
    type: "yesno",
    strip:true
  },
  OTP: {
    type: "fieldarray",
    arrayOfType: "text",
    strip: true
  },
  acceptedTerms: {
    type: "boolean"
  }
}

function CreditForm( {match, history, id, idNumber, idType, cellNumbers, email, loggedIn}) {

  console.log('Credit check form parms', match, id, idNumber, cellNumbers, email, idType, loggedIn)

  const [firstTime, setFirstTime] = useState(true)
  if (firstTime)  {
    setTimeout(() => { setFirstTime(false)}, 2000)
  }

  let clientId
  if (id) {
    clientId = id
  }
  else {
    clientId = match.params.id
  }

  console.log('Credit check form client id', id, clientId, history)

  return (
        <Center>
          <Moku top={firstTime}/>
          <ChatCards>
              <ChatFlow
              name="creditCheck"
              steps={creditCheckSteps}
              fields={fields}
              initialValues={chatState => ({ loggedIn: loggedIn || false, idNumber: chatState.idNumber || idNumber, idType: chatState.idType || "South African", cellNumber: chatState.cellNumber || cellNumbers, email:chatState.email || email, income:chatState.income, expenses:chatState.expenses, dependants:chatState.dependants  })}
              afterSubmit={() => history.push('/')}
            />
          </ChatCards>
        </Center>
  )
}

export default CreditForm