import React from 'react'
import { Route, Switch } from 'react-router-dom'
import MyPayments from './MyPayments'

function Payments() {
  return (
    <Switch>
      <Route path='/payments' exact component={MyPayments} />
    </Switch>
  )
}

export default Payments
