import styled from 'styled-components'

const Response = styled.div`
  display:flex;
  justify-content:flex-end;
  font-style: italic;
  font-size:15px;
  margin-left: 14px;
`

export { Response }

/*
float:right;
  margin-top: -19px;
  margin-right: 14px;
*/