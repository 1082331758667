import React, { Component} from 'react'
import { Switch, Route } from 'react-router-dom'
import Debt from './Debt'
import DebtInfo from './DebtInfo'

// TODO: Should only be 1  FuneralForm
export default class Debts extends Component {
  render() {
  console.log('debt router called')
    return (
      <Switch>
        <Route path='/debt' exact component={Debt} />
        <Route path='/debt/:id' component={DebtInfo} />
      </Switch>
    )
  }
}