import styled from 'styled-components'
import { Row } from '../../generic/grid'

const Outer = styled.div`
  height: 100vh;
  width:98%;
  max-width:800px;
  left:0;
  margin:0 auto;
  background-color:white;
`
const SigContainer = styled.div`
  height: 200px;
  margin: 0 auto;
  margin-top:1.5em;
  margin-bottom:5em;
  text-align:left;
`
const SigInnerContainer = styled.div`
  height: 200px;
  text-align:center;
  border-radius: 10px;
  background: #F2F6FC;
  border: 2px solid grey;
  max-width:330px;
`
export {
  Outer,
  SigContainer,
  SigInnerContainer
}