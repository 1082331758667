import React from 'react'
import moment from 'moment'
import { printCurrency } from '../../../lib/utils'
import { Icon } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { ClientCircle, RowContainer } from './ClientAmountRow.style'

function ClientAmountRow({ client, amount, topRow, showEdit }) {
  const { firstNames, surname, idNumber } = client
  const clientLetters = firstNames || surname ? ((firstNames ? firstNames[0] : "") + (surname ? surname[0] : "")).toUpperCase() : ""

  return (
      <RowContainer topRow={topRow}>
        <Col width="60px" centerHorizontal centerVertical>
          <Row>
            <ClientCircle>{clientLetters}</ClientCircle>
          </Row>
        </Col>
        <Col centerVertical>
          <div>
            <Row>{`${firstNames} ${surname}`}</Row>
            <Row>{idNumber}</Row>
          </div>
        </Col>
        <Col width="100px" style={{ paddingRight: "14px" }} centerVertical alignHorizontal="right">{printCurrency(amount)}</Col>
        {/*showEdit && <Col width="70px" centerVertical centerHorizontal><div><Icon icon="pencil-alt" size="1x" style={{ marginRight: "14px"}} /><Icon icon="trash" size="1x" /></div></Col>*/}
      </RowContainer>
  )
}

export default ClientAmountRow
