import React from 'react'
import {default as Picker} from 'react-date-picker'
import moment from 'moment'

function DatePicker({ onChange, value, minDate, maxDate, required }) {

console.log('Date picker called with props ', value, minDate, maxDate, required)

let myValue = new Date()
if (value) myValue = new Date(value)

const showDate = (date) => {
  console.log('date picked is ', date, moment(date).valueOf())
  onChange(moment(date).valueOf())
}

return (
    <Picker
      onChange={showDate}
      value={myValue}
      minDate={minDate}
      maxDate={maxDate}
      required={required}
      format={'yyyy-MM-dd'}
      clearIcon={null}
      className="datepicker"
    />
  )
}

export default DatePicker
