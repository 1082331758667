import styled from 'styled-components'
import { Row } from '../generic/grid'
import { logo } from '../../assets/images'

const Logo = styled.img.attrs({
  src: logo
})`
 height:80px;
 margin-left:10%;
 margin-bottom:10%;
 z-index: 999;
`

const Inner = styled.div`
  text-align:center;
  width: 95%;
  margin:0 auto;
  line-height:21px;
  `

const TermsLink = styled.a`
text-decoration: underline;
:active {
  color: blue;
}
:hover,
:focus {
  font-weight: bold;
}
:visited {
    color: green;
  }
`

export {
  Logo,
  TermsLink,
  Inner
}