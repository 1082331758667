import styled from 'styled-components'

const DocumentContainer = styled.div`
  display: flex;
  padding-top: 6px;
  padding-bottom: 8px;
  ${props => props.topRow ? "" : "border-top: 1px solid grey;"};
  justify-content: center;
  text-align:left;
`

const DocumentName = styled.span`
  color: ${props => props.error ? "red" : "black"};
  padding-left: 7px;
  text-decoration:${props => props.strike ? "line-through" : "none"};
  font-weight:${props => props.bold ? "bold" : "normal"}
`

export {
  DocumentContainer,
  DocumentName
}