import React, {useState, Fragment } from 'react'
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import gql from 'graphql-tag'
import { useQuery, useLazyQuery } from 'react-apollo-hooks'
// TODO: Combine the two
import { ListContainer, State, Title, stateColor, PaddedCol, Iframe } from './MyFuneral.style'
import { ActivityIndicator, Container, Button, Card , Label, Modal } from '../../generic'
import { withRouter } from 'react-router-dom'
import { ButtonBox } from '../../generic/Modal/Modal.style'
import { Row, Col } from '../../generic/grid'
import { renderLabel, renderDisplay } from '../../generic/form'
import { LifeCoveredRow, BeneficiaryRow } from '../LifeCoveredRow'
import { API_URL, API_TOKEN } from '../../../config'
//import terms  from '../../../constants/terms'
import policy  from '../../../constants/policy'
import moment from 'moment'

export const policyTypes = (policyType) => {
  switch (policyType) {
    case 'SINGLE':
      return 'Single';
      break;
    case 'SINGLE_WITH_CHILDREN':
      return 'Single with Children';
      break;
    case 'SINGLE_WITH_PARENTS':
      return 'Single with Parents';
      break;
    case 'SINGLE_WITH_CHILDREN_AND_PARENTS':
      return 'Single with Children and parents';
      break;
    case 'COUPLE':
      return 'Couple';
      break;
    case 'COUPLE_WITH_CHILDREN':
      return 'Couple with Children';
      break;
    case 'COUPLE_WITH_PARENTS':
      return 'Couple with Parents';
      break;
    case 'COUPLE_WITH_CHILDREN_AND_PARENTS':
      return 'Couple with Children and parents';
      break;
    default:
      return 'Unknown Policy Type';
      break;
  }
}

const config = {
  policyType: {
    label: value =>  policyTypes(value),
    type: "text"
  },
  coverAmount: {
    label: "Cover Amount",
    type: "currency"
  },
  currentPremiumTotal: {
    label: "Premium Amount",
    type: "currency"
  },
  state: {
    type: "label"
  },
  livesCovered: {
    type: "fieldarray",
    arrayOfType: "object"
  },
  beneficiaries: {
    type: "fieldarray",
    arrayOfType: "object"
  },
  firstActivated: {
    label: "First Activated",
    type: "date"
  }
}

const createMarkup = (data) => {
  return "data:text/html;charset=utf-8," + escape(data);
}

const fetchCAR = async (id, setSpin, setShowCar, setShowModal) => {
  setSpin(true)
  setShowCar(true)
  const response = await fetch(`${API_URL}funeral/${id}/carHTML`, { headers: { 'Authorization': `JWT ${localStorage.getItem('accessToken') || API_TOKEN } ` } })
  const data = await response.text()
  setSpin(false)
  setShowModal(true)
  return createMarkup(data)
}

const GET_TEMPLATE = gql`
  query ClientCheck($name: String!,$productType:String, $productId:String) {
    getTemplate(name: $name, productType:$productType, productId:$productId)
  }`

function Layout({data, apolloClient, history}) {

  const { firstNames, surname, acceptedTermsAt } = data.funeral.client

  console.log("my funeral data is ", data.funeral.client)
  console.log("my funeral data history is ", history)
  
  const { id } = data.funeral
  console.log('id is ',data.funeral, id)
  const [spin, setSpin] = useState(false)
  const [showDoc, setShowDoc] = useState("")
  const [carHTML, setCarHTML] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [template, setTemplate] = useState()

  const fetchTemplate = (name, productType, productId, format = 'html') => {
    setSpin(true)
    apolloClient.query({ query: GET_TEMPLATE, variables: { name, productType, productId, format } }).then(({ data }) => {
        console.log('get_template ', data.getTemplate)
        setTemplate(data.getFuneralTemplate)
        setSpin(false)
    })
  }
  return (
    <Fragment>
      <Container title={"Funeral Policy Information"}>
          <FieldValue name="state" renderField={({ input }) => {
            const { value: state } = input
              console.log('Funeral Policy Information state is',state )
              return <State><Label color={stateColor[state || "PENDING"]}>{state || "PENDING"}</Label> </State>
            }
          } />
          <Card>
          <PaddedCol><Title>Policy Type</Title>
            <Row>
              <PaddedCol size={2}><FieldValue name="policyType" renderField={ ({input}) => {
                const { value: policyType } = input
                  console.log('Funeral Policy Information value is ', policyType )
                  return ( <Fragment>{policyType ? policyTypes(policyType) : '-'}</Fragment>)} 
                  }/>
              </PaddedCol>
            </Row>
            <Row>
              <PaddedCol><FormField name="coverAmount" /></PaddedCol>
              <PaddedCol><FormField name="currentPremiumTotal" /></PaddedCol>
            </Row>
            <Row>
              <PaddedCol><FormField name="firstActivated" /></PaddedCol>
            </Row>
          </PaddedCol>
          </Card>

      <Container>
        <Card>
        <Col centerVertical>Lives Covered</Col>
          <FieldValue name="livesCovered" renderField={({input}) => {
            const { value: lives } = input
            if (lives) {
              return lives.map((c, index) => {
              console.log('Funeral Policy Information life c', c)
                return <LifeCoveredRow topRow={index === 0} {...c} />
              })}
              else return <span>None</span>
          }} />
        </Card>
      </Container>
      <Container>
        <Card>
        <Col centerVertical>Beneficiary</Col>
          <FieldValue name="beneficiaries" renderField={({ input }) => {
            const { value: beneficiary } = input
            if (beneficiary && beneficiary[0] && beneficiary[0].payTo) {
              return beneficiary && beneficiary.map((b, index) => {
              console.log('Funeral Policy Information beneficiary b', b)
              return <BeneficiaryRow topRow={index === 0} {...b} /> 
              })}
            else return <span>None</span>
          }
        } />
      </Card>

      </Container>
      <Container>
        <Card>
        <Col centerVertical>Documents</Col>
        
        <Row justify="center">    
         <div><Button action="true" disabled={spin} onClick={() => {fetchTemplate("CONST_TERMS"); setShowModal(true); setShowDoc("Terms")}} >T&C</Button></div>
          <div><Button action="true" disabled={spin} onClick={() => {fetchTemplate("CONST_FUNERAL_DOCUMENT", 'FUNERAL', id);setShowModal(true); setShowDoc("Policy") }} >Policy</Button></div>
          <div><Button action="true" disabled={spin} onClick={() => {fetchTemplate("CONST_FUNERAL_SCHEDULE", 'FUNERAL', id);setShowModal(true); setShowDoc("Schedule") }} >Schedule</Button></div>
        </Row>
        <Row justify="center"><PaddedCol centerHorizontal><Button action="true" onClick={() => history.push('/funeral') } >Back</Button> </PaddedCol></Row>

        <br /><br />
        </Card>
      </Container>
      {showModal && <Modal title="Meerkat CAR"
      extraButtonsTop={true}
      width='98%'
      hideCancel
      backgroundColor= 'rgb(242, 246, 252)'
      visible={showModal}
      onOk={() => setShowModal(false)}
      okText={"Close"}
      >
      {spin && <ActivityIndicator /> }
      <div dangerouslySetInnerHTML={{__html: template }} />
      {showDoc === "Terms" && <div style={{marginTop:14,fontWeight:"bold"}} >Accepted by {firstNames} {surname} on {moment(acceptedTermsAt).format("Do MMMM YYYY")}</div>}
    </Modal>}

    </Container>
    </Fragment>

  )
}

Layout = withRouter(Layout)


function MyFuneral({ id}) {

  console.log('funeral id is ', id)

  return (
    <GraphQLFormProvider
      query={gql`query Funeral($id: String!) {
        funeral(id: $id) {
          id
          policyNumber
          policyType
          state
          paymentMethod
          paymentDueDay
          firstActivated
          currentPremiumTotal
          coverAmount
          client {
            idNumber
            firstNames
            surname
            acceptedTerms
            acceptedTermsAt
          }
          beneficiaries {
            id
            payoutType
            percentage
            payTo {
                id
                firstNames
                surname
                DOB
                idType
                idNumber
                cellNumbers
              }
              bankAccount {
                id
                bankName
                accountNo
              }
            }
          livesCovered {
            idNumber
            firstNames
            surname
            gender
            idNumber
            DOB
            ageAtSignup
            cellNumbers
            email
            memberType
            relationship
            coverAmount
          }
        }
        }
      `}
      name="funeral"
      id={id}
      fields={config}
      mode="display"
      displayOnly
      DisplayFormLayout={Layout}
      renderLabel={renderLabel}
      renderDisplay={renderDisplay}
    />
  )
}
export default MyFuneral