import React, { useState, Fragment } from 'react'
import moment from 'moment'
import { ActivityIndicator, Button, Icon, Modal } from '../generic'
import { Inner, Logo,TermsLink } from './Terms.style'
import { Row, Col } from '../generic/grid'
import {MEERKAT_WEB_URL,API_URL} from '../../config'
import { withApollo } from 'react-apollo'

import {getLocation} from '../../lib/utils'
import { useQuery  } from 'react-apollo-hooks'
import gql from 'graphql-tag'

const CLIENT = gql`
query Client($id: String!) {
  getClient(id: $id) {
    firstNames
    surname
    idNumber
    acceptedTerms
  }
}
`

const GET_TEMPLATE = gql`
  query getTemplate($name: String!,$productType:String, $productId:String, $templateValues:String) {
    getTemplate(name: $name, productType:$productType, productId:$productId, templateValues:$templateValues)
}`

function Terms({ client, match, history }) {

  const submit = async (clientId) => {
    setSpin(true)
    const accessToken = localStorage.getItem('token');
    const steps = { clientId }
    const response = await fetch(`${API_URL}terms/${clientId}`, { method: 'post', body: JSON.stringify(steps), headers: { 'Content-Type':'application/json', 'Authorization':

    accessToken ? `JWT ${accessToken}` : null } })
    const data = await response
    console.log('response is ', data )
    setTCAccepted(true)
    window.location = `${MEERKAT_WEB_URL}` 
  }

  const [showModal, setShowModal] = useState(false)
  const [showAcceptButtons, setShowAcceptButtons] = useState(false)
  const [tcAccepted, setTCAccepted] = useState(null)
  const [spin, setSpin] = useState(false)
  const [terms, setTerms] = useState()
  const { id } = match.params
  console.log('Starting Terms with params ', match.params, id)

  const loadTerms = () => {
    client.query({ query: GET_TEMPLATE, variables: { name:"CONST_TERMS" } }).then(({ data }) => {
        console.log('get_template ', data.getTemplate)
        setTerms(data.getTemplate)
    })
  }

  const { data, error, loading } = useQuery(CLIENT, { variables: { id } })

  if (loading) {
    return <ActivityIndicator />
  };
  if (error) {
    return <div>Error! {error.message}</div>;
  };

  if (!terms) loadTerms()

  console.log('terms data is ', data)
  const web = API_URL.indexOf('test') < -1 && API_URL.indexOf('localhost') < -1 ? "https://meerkat.co.za/" : "https://beta.meerkat.co.za/"

  return (
    <>
    <Logo />

    <Inner>
      <center>
      {data.getClient && !data.getClient.acceptedTerms ?
        <>
            {`Hi ${data.getClient.firstNames} ${data.getClient.surname ? data.getClient.surname :''}, please accept `} <TermsLink onClick={() => {setShowModal(true); setShowAcceptButtons(false)}}>{` our terms and conditions?`}</TermsLink>
            <br/><br/><br/>
            <Button onClick={() => submit(id) } action>{spin ? <Icon size="1x" color="white" spin icon="spinner" /> : `OK` }</Button>
          </>
        : <>
        {`Hi ${data.getClient.firstNames} ${data.getClient.surname ? data.getClient.surname : ""}. All good, you have already accepted our `} <TermsLink onClick={() => {setShowModal(true);setShowAcceptButtons(false)}}>{`terms and conditions?`}</TermsLink>
        <br/><br/>
        <Button onClick={() => window.location = `${MEERKAT_WEB_URL}` } >Home Page</Button>
      </>}

      {showModal && <Modal title="Meerkat Terms and Conditions September 2017"
        width='98%'
        backgroundColor= 'rgb(242, 246, 252)'
        visible={showModal}
        extraButtonsTop={true}
        onOk={() => {setShowModal(false); showAcceptButtons && submit(id)  }}
        okText={showAcceptButtons ? "I Accept" : "OK"}
        hideCancel={true}
        >
          <div dangerouslySetInnerHTML={{__html: terms }}/>
      </Modal>}
      </center>
    </Inner>
  </>
  )
}

export default withApollo(Terms)
