import React, { Fragment, useState } from 'react'
import DebtItemRow from '../DebtItemRow'
import { PaddedCol, RiskText } from './DebtSummary.style'
import { Row, Col } from '../../generic/grid'
import { DrawField } from '../../generic/form'
import { Container, Button, Modal, Pdf } from '../../generic'

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const saveFile = (fileUrl,  spin, setSpin) => {
  if (!spin) {
    console.log('spin is ', spin)
    setSpin(true)
    var link = document.createElement("a");
    link.download = "CreditReport.pdf";
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function ShowCreditReport({ showModal, setShowModal, creditReport }) {
  return (
    showModal &&
      <Modal title="Credit Report"
        width='100%'
        hideCancel
        hideOk
        backgroundColor= 'rgb(242, 246, 252)'
        visible={showModal}
        extraButtonsTop={true}
        onOk={() => setShowModal(false)}
        okText={"Close"}
      >
        <Pdf fillHeight fileUrl={creditReport.pdf.url} showPdf={showModal} setShowPdf={setShowModal} />
        <center>
          <Button action="true" onClick={() => { setShowModal(false) }} >Close</Button>
        </center>
      </Modal>
  )
}


function DebtSummary({ obligations, creditReport, gotoDetails, getCreditReport, history }) {
  const { riskType, monthlyInstallment, compuScore, riskColour, fetchedAt } = creditReport || {}
  const totalDebt = obligations.reduce((total, o) => (total + o.currentBalance), 0)
  const [showModal, setShowModal] = useState(false)
  const [spin, setSpin] = useState(false)

  if (!creditReport) {
    return (
      <Fragment>
        <Container title="My Debt Summary">
          <PaddedCol centerHorizontal>
            <p>We do not have a credit record on file for you. You can get a free copy of your credit report here</p>
            <Button action disable={spin} onClick={() => { getCreditReport() }}>Get Credit Report</Button>
            <br />
          </PaddedCol>
        </Container>
      </Fragment>
    )
  }

  if (creditReport && creditReport.thinFile) {
    return (
      <Fragment>
        <Container title="My Debt Summary">
          <PaddedCol centerHorizontal>
            <p>We have fetched a credit report for you, however the credit bureau indicated that they do not have enough information to give you a report and therefore we do not have enough information to show you a picture of your debt</p>
          </PaddedCol>
        </Container>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Container title="My Debt Summary">
        <PaddedCol>
          <Row>
            <DrawField label="Total Debt" value={totalDebt} type="currency" />
            <DrawField label="Monthly Repayment" value={monthlyInstallment} type="currency" />
          </Row>
          <Row>
            <DrawField label="Credit Score" value={compuScore} />
            <DrawField label="Risk Type">
              <RiskText riskColour={riskColour}>{riskType}</RiskText>
            </DrawField>
          </Row>
          <Row>
            <DrawField label="Report Date" value={fetchedAt} type="date" />
          </Row>
        </PaddedCol>
      </Container>
      <Container>
        {(!isMobileDevice() || !spin) && <PaddedCol centerHorizontal>
          <p>Get a free copy of your credit report</p>
          <Button action disable={spin} onClick={() => {isMobileDevice() ? saveFile(creditReport.pdf.url, spin, setSpin) : setShowModal(true)}}>Show Credit Report</Button>
          <ShowCreditReport creditReport={creditReport} showModal={showModal} setShowModal={setShowModal} />
          <br />
        </PaddedCol>}
      </Container>

      <Container title="My Debts">
        <Col>
          {obligations.map((o, index) => {
            return (
            <DebtItemRow key={o.id} topRow={index === 0} obligation={o} onClick={(id) => gotoDetails(id)} />
          )})}
        </Col>
      </Container>
      
      <Container>
        <Row>
          <PaddedCol centerHorizontal>
            <Button action="true" onClick={() => history.push('/') } > Home </Button>
          </PaddedCol>
        </Row>
      </Container>

    </Fragment>
  )
}

export default DebtSummary