
const BANKS =
  [
    {
      "label": "Capitec Bank",
      "value": "470010"
    },
    {
      "label": "FNB",
      "value": "250655"
    },
    {
      "label": "Nedbank",
      "value": "198765"
    },
    {
      "label": "Standard Bank",
      "value": "51001"
    },
    {
      "label": "ABSA Bank",
      "value": "632005"
    },
    {
      "label": "African Bank",
      "value": "430000"
    },
    {
      "label": "Bidvest Bank",
      "value": "462005"
    },
    {
      "label": "Investec Bank",
      "value": "580105"
    },
    {
      "label": "Mercantile",
      "value": "450905"
    },
    {
      "label": "Ubank",
      "value": "431010"
    }
  ]


const banks = BANKS.map(b => b.label)

export  default banks