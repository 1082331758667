import React, { useState, useReducer,Fragment } from 'react'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo-hooks'
import { withRouter } from 'react-router-dom'
import { GraphQLFormProvider, FormField, FieldValue, SubmitButton } from 'react-form-helper';
import { isRequired } from '../../../lib/validators'


import { RequiredDocuments } from '../RequiredDocuments'
import { DocumentName } from '../RequiredDocuments/RequiredDocuments.style'
import { Container  } from './ClaimForm.style'
import { Moku, Center, ChatCards } from '../../profile/RegisterForm/RegisterForm.style'

import { ActivityIndicator, Icon, Button, ChatCard } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { renderLabel, renderInput } from '../../generic/form'
import { idTypes } from '../../../constants'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import moment from 'moment'
import { subMonths} from 'date-fns';

const today = new Date()
const earliestClaim = subMonths(today,24)

const CLIENT_CHECK = gql`
  query ClientCheck($idNumber: String) {
    checkForClient(idNumber: $idNumber) {
      existingClient
      hasCreditLife
      hasExGratia
      hasFuneralPolicy
      isFuneralPolicyMember
      hasSavings
      employerContributions
      hasUser
      hasExistingCellNumber
      hasExistingBankAccount
    }
  }
`

const GET_REQUIREMENTS = gql`
  query GetClaimRequirements($claimType: ClaimType) {
    getClaimRequirements(claimType: $claimType, products: [CREDIT_LIFE]) {
      id
      documents {
        id
        requirementId
        documentType
        description
        required
        requireCertified
        maximumAgeDays
      }
      info {
        name
        key
        type
      }
    }
  }
`

const GET_PROFILE = gql`
  {
    myClient {
      id
      firstNames
      surname
      idNumber
      idType
      photo {
        id
        url
      }
    }
  }
`

const claimBenefit = gql`
  fragment ClaimBenefit on Claim {
    id
    claimForIdType
    claimForIdNumber
    claimantId
    claimType
    claimReason
    relationship
    effectiveDate
    documents {
      id
      description
      requirementId
      documentId
      verified
      verifiedById
      waived
      waivedById
    }
    info {
      key
      value
    }
    requirementId
  }
`

const CLAIM_TYPES = [
  {id: "RETRENCHMENT", name: 'Retrenchment', icon:"user-alt-slash", newLine:false},
  {id: "COMPULSORY_UNPAID_LEAVE", name: 'Compulsory Unpaid Leave', icon:"business-time", newLine:false},
  { id: "DEATH", name: 'Death', icon: "ankh", newLine: false },
]


const benefits = {
  "20201001": {
    BASIC: {
      NORMAL: ['DEATH'],
    },
    CORE: {
      NORMAL: ['DEATH', 'RETRENCHMENT', 'PTD', 'TTD'],
      EXTRA: ['DEATH', 'RETRENCHMENT', 'SHORT_TIME', 'COMPULSORY_UNPAID_LEAVE', 'PTD', 'TTD']
    }
  },
  "ORIGINAL": {
    BASIC: {
      NORMAL: ['DEATH', 'CRITICAL_ILLNESS_NO_PTD'],
    },
    CORE: {
      NORMAL: ['DEATH', 'RETRENCHMENT', 'CRITICAL_ILLNESS_PTD', 'PTD', 'TTD'],
      EXTRA: ['DEATH', 'RETRENCHMENT', 'SHORT_TIME', 'COMPULSORY_UNPAID_LEAVE', 'CRITICAL_ILLNESS_PTD', 'PTD', 'TTD']
    }
  },
  "OPEN_MARKET_RGA": {
    CORE: {
      PLAN_A: ["DEATH", "PTD", "TTD", "RETRENCHMENT", "CRITICAL_ILLNESS", "OTHER"],
      PLAN_B: ["DEATH", "PTD", "TTD", "RETRENCHMENT", "CRITICAL_ILLNESS", "OTHER"],
      PLAN_C: ["DEATH", "PTD", "TTD", "RETRENCHMENT", "CRITICAL_ILLNESS", "OTHER"],
      PLAN_D: ["DEATH", "PTD", "TTD", "RETRENCHMENT", "CRITICAL_ILLNESS", "OTHER"]
    },
    BASIC: {
      PLAN_A: ["DEATH", "PTD", "TTD", "CRITICAL_ILLNESS", "OTHER"],
      PLAN_B: ["DEATH", "PTD", "TTD", "CRITICAL_ILLNESS", "OTHER"],
      PLAN_C: ["DEATH", "PTD", "TTD", "CRITICAL_ILLNESS", "OTHER"],
      PLAN_D: ["DEATH", "PTD", "TTD", "CRITICAL_ILLNESS", "OTHER"]
    }
  }
}


const OTHER_TYPES = [
  { id: "OTHER", name: 'Other', icon: "question", newLine: false }
]

const HeaderSummary = ({ claimantID, editing, effectiveDate }) => {
  console.log('HeaderSummary editing ', editing)
  return (
  <Container title=''>
  {claimantID && (
    <Row centerHorizontal key={'claimantID'}>
      <Col centerVertical alignHorizontal="right" width='50%'><DocumentName> Claimant:</DocumentName></Col>
      <Col centerVertical alignHorizontal="left" ><DocumentName  bold={true}>{claimantID}</DocumentName></Col>
    </Row>
  )}
    <Row centerHorizontal key={'insuredID'}>
      <Col centerVertical alignHorizontal="right" width='50%'><DocumentName >Insured:</DocumentName></Col>
      <Col centerVertical alignHorizontal="left"><FieldValue name="claimForIdNumber" renderField= { ({input}) => <DocumentName bold={true}>{input && input.value}</DocumentName>} /></Col>
    </Row>

    <Row centerHorizontal key={'type'}>
      <Col centerVertical alignHorizontal="right" width='50%'> <DocumentName >Type:</DocumentName></Col>
      <Col centerVertical alignHorizontal="left"><FieldValue name="claimType" renderField= { ({input}) => {
        console.log('claimType is ', input)
        return <DocumentName bold={true}>{input && input.value }</DocumentName>} }
      /></Col>
    </Row>

    <Row centerHorizontal key={'date'}>
      <Col centerVertical alignHorizontal="right" width='50%'><DocumentName>Occured:</DocumentName></Col>
      <Col centerVertical alignHorizontal="left">
        {editing ? <FormField name="effectiveDate" noLabel/> : <DocumentName bold={true}>{moment(effectiveDate).format("DD MMMM YYYY")} </DocumentName>}
        </Col>
    </Row>

</Container>
)}

const ClaimTypes = (props) => {
  const { buttonClicked, clientInfo } = props
  console.log('ClaimTypes props ', props, CLAIM_TYPES,)
  return (
    <FormControl component="fieldset" >
      <RadioGroup onChange={buttonClicked}>
        <br />
        {CLAIM_TYPES.map((type) => <FormControlLabel value={type.id} control={<Radio />} label={type.name} />)}
        {clientInfo.hasExGratia && OTHER_TYPES.map((type) => <FormControlLabel value={type.id} control={<Radio />} label={type.name} />)}
      </RadioGroup>
    </FormControl>
  )
}

function Layout({ apolloClient, changeFieldValue, history }) {
  const [clientInfo, setClientInfo] = useState({
    hasIDNumber: false,
    existingClient: null,
    hasCreditLife:false,
    hasExGratia: false,
    hasFuneralPolicy:false,
    isFuneralPolicyMember:false,
    hasSavings: false,
    employerContributions: false,
    hasUser: false,
    hasExistingCellNumber: false,
    hasExistingBankAccount: false
  })

  const falseFunc = ()=>false;


  const [validID, setValidID] = useState(false)
  const [noNextButton, setNoNextButton] = useState(false)
    const [docsDone, setDocsDone] = useState(false)
  const [claimType, setClaimType] = useState(null)
  const [claimantID, setClaimantID] = useState(false)
  const [displayHeader, setDisplayHeader] = useState(false)
  const [focus, setFocus] = useState(true)
  const [reRun, setReRun] = useState(false)
  const [editing, setEditing] = useState(false)
  const [infoFields, setInfoFields] = useState([])
  const [allDocuments, setAllDocuments] = useState([])
  const [activeDocument, setActiveDocument] = useState()
  const [activeInfoItem, setActiveInfoItem] = useState()
  const [totalDocuments, setTotalDocuments] = useState(0)
  const [forLoggedInUser, setForLoggedInUser] = useState(false)
  const [lastFileUploaded, setLastFileUploaded] = useState(false)
  const [effectiveDate, setEffectiveDate] = useState(new Date())


  const initialSteps = [
    { id:"claim", display: true },
    { id:"claimType",  display: false },
    { id:"claimantID",  display: false },
    { id:"claimReason",  display: false },
    { id:"relationship",  display: false },
    { id:"notRegistered",  display: false },
    { id:"info",  display: false },
    { id: "other", display: false },
    { id:"infoItem", display: false },
    { id:"allInfo",  display: false },
    { id:"allDocs",  display: false },
    { id:"docs",  display: false },
    { id:"aDoc", display: false },
    { id:"complete",  display: false },
    { id:"fo",  display: false },
    { id:"date",  display: false },
    { id:"noClaims",  display: false },
    { id:"claimIdNotFound",  display: false }
  ]

  const stepsReducer = (state, action) => {
    switch (action.type) {
      case 'ON':
        return state.map(on => {
          if (on.id === action.id) {
            return { ...on, display: true };
          } else {
            return on;
          }
        });
      case 'OFF':
        return state.map(off => {
          if (off.id === action.id) {
            return { ...off, display: false };
          } else {
            return off;
          }
        });
      default:
        return state;
    }
  };

const [steps, dispatch] = useReducer(stepsReducer, initialSteps);

const restartWizard = () => {
  nextStep(['claim'])
  setReRun(true)
  setFocus(true)
  setDocsDone(false)
  console.log('restarting wizard ...', steps)
}

const doClientCheck = ({ claimForIdType, claimForIdNumber }) => {

  let idNumber
  let idType

  console.log('My Client for logged in user ', data.myClient)

  if (!claimForIdNumber) {
    idNumber = data.myClient ? data.myClient.idNumber :"UNKNOWN"
    changeFieldValue('claimForIdNumber', idNumber)
    setForLoggedInUser(true)
    console.log('for logged in user ',data.myClient.idNumber)
  }
  else {
    console.log('Not for logged in user ', claimForIdType, claimForIdNumber)
    idNumber = claimForIdNumber
    idType = claimForIdType
    }

    apolloClient.query({ query: CLIENT_CHECK, variables: {idNumber, idType} }).then(({ data }) => {
      console.log('checkForClient returned the following ', data.checkForClient)
      setClientInfo({ hasIDNumber: true, ...data.checkForClient })
      console.log('data checkForClient and hasUser', data.checkForClient )

      if (data.checkForClient && data.checkForClient.existingClient) {
        setValidID(true)
        if (data.checkForClient.hasCreditLife || data.checkForClient.isFuneralPolicyMember ) {
          console.log('This is an existing client with a policy')
          changeFieldValue('claimForIdNumber', idNumber)
          
          if (data.checkForClient.isFuneralPolicyMember && !data.checkForClient.hasCreditLife) {
            setClaimType('DEATH')
            nextStep(["date"])
            getDocuments('DEATH')
            setDisplayHeader(true);
            changeFieldValue('claimType', 'DEATH');
          }
          else nextStep(['claimType'])
        }
        else {
          nextStep(['noClaims'])
        }
      }
      else {
        console.log('This is NOT an existing client')
        nextStep(['claimIdNotFound'])
        setValidID(false)
      }
    })
  }

// set a text display value equal to the document description
// Fred, how should this be done properly?


  const getDocuments = ( claimType ) => {
    console.log('claimType is ', claimType)

    apolloClient.query({ query: GET_REQUIREMENTS, variables: { claimType } }).then(({ data }) => {
      console.log('getClaimRequirements data is ', data)

      if (data.getClaimRequirements && data.getClaimRequirements[0] && data.getClaimRequirements[0].documents) {
        const documents = data.getClaimRequirements[0].documents.map(d => ({
          description: d.description,
          requirementId: d.requirementId,
          requirement: {
            requirementId: d.requirementId,
            documentType: d.documentType,
            required: d.required,
            description: d.description,
          },
        }))

        setTotalDocuments(documents.length)
        changeFieldValue('documents', documents)
        setAllDocuments(documents)
        setInfoFields(data.getClaimRequirements[0].info)

        fields.info.config = {}
        data.getClaimRequirements &&
          data.getClaimRequirements[0] &&
          data.getClaimRequirements[0].info.forEach(d => {
            fields.info.config[d.key] = {
              label: d.name,
              type: d.type
            }
          })
        console.log('info fields are ', fields, fields.info.config)
        console.log('documents are ', allDocuments)
      }
    })
  }

const setDocument = (index) => {
  setActiveDocument(index)
  if (!noNextButton) {
    setNoNextButton(true)
    if (!editing && !isActive('aDoc')) {
      nextStep(['aDoc'])
    }
  }
}

const nextInfoItem = () => {
  if (activeInfoItem >= infoFields.length - 1) {
    nextStep(["allDocs", "docs"])
  } else {
    setActiveInfoItem(activeInfoItem + 1)
  }
}

const nextDocument = () => {
  {console.log('nextDocument',reRun, activeDocument,totalDocuments)}
  if (!reRun) {
    nextStep(['aDoc'])
    if ((!activeDocument && activeDocument !== 0) || activeDocument < totalDocuments - 1) {
      if (!isActive('aDoc')) {
        nextStep(['aDoc'])
      }
      if (!activeDocument && activeDocument !== 0) {
        setDocument(0)
      } else {
        setDocument(activeDocument + 1)
      }
    }
    else {
      console.log('reset docs flags)')
      setNoNextButton(true)
      setDocument(null)
      setEditing(true)
      nextStep(['allInfo', 'docs', 'complete'])
    }
  }
  else {
    nextStep(['allInfo', 'docs', 'complete'])
    setDocsDone(true)
    setNoNextButton(true)
    setEditing(true)
  }
}

  const doSetExpectation = ({ idNumber }) => {
    console.log('no id on record')
  }

    const isActive = (active) => {

    const result = steps.filter( (item) => {
      return item.id == active
    })
    return result[0] && result[0].display
  }

  const nextStep = (activeStep) => {
    console.log("set next step ", activeStep, steps)
    const allSteps = steps
    steps.map((off) => {
      dispatch({type: "OFF", id:off.id})
    })
    activeStep.map((on) => {
      dispatch({type: "ON", id:on})
    })
  }

  const selectDate = ({ effectiveDate }) => {
    console.log('set effective date ',  effectiveDate )
    setEffectiveDate(effectiveDate)
    if (clientInfo.hasExGratia && claimType === "OTHER" || !allDocuments || allDocuments.length === 0) {
      nextStep(["complete"])
    }
    else if (!reRun) {
      if (infoFields.length > 0) {
        nextStep(["info", "infoItem"])
        setActiveInfoItem(0)
      } else {
        nextStep(["allDocs", "docs"])
      }
    } else {
      nextStep(["allInfo", "docs",'complete'])
    }
  }

  const buttonClicked = (event) => {
    const id = event.target.value
    console.log('claimType buttonClicked ', id)
    changeFieldValue('claimType', id);

    getDocuments(id)
    setClaimType(id)
  }

  const { data, loading, error } = useQuery(GET_PROFILE)

  if (loading) { return <ActivityIndicator /> }
  if (error) { console.log(error); return "An error has occurred" }

  return (
        <Center>
        <Moku />
          <ChatCards>

          {displayHeader && <HeaderSummary claimantID={claimantID} editing={editing} effectiveDate={effectiveDate} /> }

          { isActive("claim") && !reRun && <ChatCard text={<Fragment>Hi {data.myClient ? data.myClient.firstNames :"Guy" } - I'm Moku. This is where you make a claim</Fragment>} noNext />}

          { isActive("claim") && reRun && <ChatCard text={<Fragment>Ok {data.myClient ? data.myClient.firstNames :"Guy" }. Let's go through the questions again.</Fragment>} noNext />}

          { isActive("claim") &&
              <Fragment>
                <ChatCard text="Is this claim for you?" noNext mode="display">
                <Row centerHorizontal>
                  <Button action="true"hide={false} onClick={() => doClientCheck(false)} >Yes</Button>&nbsp;
                  <Button  action="true" onClick={() => { changeFieldValue('claimantId', data.myClient.id); setClaimantID(data.myClient.idNumber); nextStep(['relationship']) }}>No</Button>
                </Row>
                </ChatCard>
            </Fragment>
          }

          {isActive("noClaims") && <ChatCard text={<Fragment>I'm sorry 😔 but {forLoggedInUser ? "you" : "that ID number" } don't have any active insurance policies with Meerkat.</Fragment>}
            nextTitle="Done" handleNext={() => history.push('/claims')}  /> }
          
          {isActive("relationship") && <ChatCard text="What is our relationship to the person you're claiming for?" fieldNames={["relationship"]} focus  handleNext={() => nextStep(['claimReason']) } /> } 

          {isActive("claimReason") && <ChatCard text="What is the reason for the claim?" fieldNames={["claimReason"]} focus handleNext={() => nextStep(['claimantID']) } /> }


        {isActive("claimantID") && <ChatCard text="What is the Id number of the person you're claiming for?" fieldNames={["claimForIdType", "claimForIdNumber"]} focus handleNext={doClientCheck} noNext={validID} />}

          {isActive("claimIdNotFound") && <ChatCard text="I can't find an person with that ID, can you try again" fieldNames={["claimForIdType","claimForIdNumber"]} focus handleNext={doClientCheck} noNext={validID} /> }

          {isActive("notRegistered") && <ChatCard text="Oh dear, I can't find that id 😿, I'll have to add some code to fix that 😜" handleNext={doSetExpectation}  /> }

        {isActive("claimType") && <ChatCard text={`${data.myClient ? data.myClient.firstNames + ', w' : "W"}hat type of claim is this?`} handleNext={() => { setDisplayHeader(true); nextStep(["date"]) }}> <ClaimTypes buttonClicked={buttonClicked} clientInfo={clientInfo} /> </ChatCard>}

          {isActive('date') && <ChatCard text="What date did this happen?" fieldNames={["effectiveDate"]} focus handleNext={selectDate}  />}

          {isActive("info") && <ChatCard text={<Fragment>Ok, I'm going to need to get some information before we can process the claim. </Fragment>} noNext />}

          {isActive("infoItem") && (activeInfoItem || activeInfoItem === 0) && <ChatCard text={infoFields[activeInfoItem].name} fieldNames={[`info.${infoFields[activeInfoItem].key}`]} noLabel handleNext={nextInfoItem} />}

          {isActive("allInfo") && infoFields && infoFields.length > 0 && <ChatCard text="Information Provided" noNext>
          {
            infoFields.map(field => <FormField inputProps={{ validate: value => value ? undefined : 'Required' }}  label={field.name} name={`info.${field.key}`}  /> )
          }
          </ChatCard>}

        {isActive("other") && !reRun && <ChatCard text={<Fragment>We'll contact you for later the documents and information we need to process your claim</Fragment>} handleNext={() => nextStep(["complete"])} />}

          {isActive("allDocs") && !reRun && <ChatCard text={<Fragment>Ok, I'm going to need <b>all</b> of the following information before we can process the claim. </Fragment>} noNext /> }

          {isActive("docs") && <ChatCard noNext={noNextButton} handleNext={nextDocument}><RequiredDocuments newClaim={true} activeDocument={activeDocument} setActiveDocument= {setActiveDocument}/> </ChatCard>}

        <FieldValue name={`documents[${activeDocument}].fileId`} renderField={doc => {
            const { input } = doc

            if ((activeDocument || activeDocument === 0) && !input.value  ) {
              return  (
                <ChatCard
                text={<Fragment>
                  <center><br/>Please upload: <b>{allDocuments[activeDocument].description}</b></center>
                  <br />
                </Fragment>}
              fieldNames={[`documents[${activeDocument}].fileId` ]} nextOnValue={true} focus nextTitle={"Later" } handleNext={isActive("aDoc") ? nextDocument : (() => setDocument(null))} />
            ) }
            else {
              return (
                ((activeDocument || activeDocument === 0) && !input.value)
                ? <ChatCard text={<Fragment><center><br/> <Icon size="4x" color="green" spin icon="spinner" /><br/>uploading</center></Fragment>} noNext />
                : (activeDocument || activeDocument === 0 && input.value) ?
                <ChatCard text={<Fragment> <center><br />  Upload complete, press Next to continue<br /><br /> </center> </Fragment>} focus nextTitle={"Next Document" } handleNext={isActive("aDoc") ? (() =>  { setLastFileUploaded(input.value);  nextDocument(input.value) }) : (() =>  { setLastFileUploaded(input.value) ; setDocument(null)})} />
                : <div></div>
            )
          }
        }} />

          {isActive("complete") && activeDocument == null  &&
          <center>
          {1 === 2 && <ChatCard noNext text={<b>You can submit now, but you might need to come back to add any missing information.</b>} />}
          <ChatCard noNext text={<div>Click Submit and we'll start processing your claim.</div>}/>

          {1==2 && <Button  action="true" onClick={() => { restartWizard() }}>Make Changes</Button>}
          <br />
          <SubmitButton renderSubmitButton={({ invalid, submit, submitting }) => ( <Button disabled={submitting} action="true"onClick={() => { console.log('submit pressed, invalid is  ',invalid); submit()} }  >Submit Claim</Button> )} />
          </center>}

          </ChatCards>
        </Center>
  )
}

Layout = withRouter(Layout)


function ClaimCompleted({ claimNumber, history }) {
  return (
        <Center>
        <Moku />
          <ChatCards>
            <ChatCard text={
              <center>Thanks so much. Your claim has been logged and will be assessed. We will provide feedback using the given details.
                  <br /><br />
                  Your claim number is:<b> {claimNumber}</b>
                  <br /><br />
              </center>}
            nextTitle="Done" handleNext={() => history.push('/claims')} />
          </ChatCards>
          </Center>
  )
}

ClaimCompleted = withRouter(ClaimCompleted)

const fields = {
  effectiveDate: {
    type: "date",
    minDate: earliestClaim,
    maxDate: today,
    required:true
  },
  info:  {
    type: "keyvalue",
    config: {}
  },
  claimType: {
    type: "autocomplete",
    valuesFromData: CLAIM_TYPES => CLAIM_TYPES.name
  },
  claimReason: {
    type:"text",
    validate: [isRequired]
  },
  relationship: {
    type:"text",
    validate: [isRequired]
  },
  claimForIdType: {
    type: "select",
    options: idTypes,
    noSelectionLabel:"Select Country",
    //validate:(value) => { if (!value) { return "Please select a country"} }
  },
  claimForIdNumber: {
    type: "text"
    },
  claimantId: {
      type: "text"
  },
  documents: {
    label: 'Documents',
    type: 'fieldarray',
    config: {
      description: {
        label: 'Description',
        type: 'text'
      },
      documentId: {
        type: 'hidden'
      },
      file:{
        strip: true
      },
      fileId: {
        type: 'fileupload'
      },
      requirementId: {
        type: 'hidden'
      },
      requirement: {
        strip: true
      },
      'requirement.documentType': {
        label: 'Type',
        type: 'text',
        displayOnly: true
      }
    }
  }
}


function ClaimForm({ id }) {
  const [claimNumber, setClaimNumber] = useState("")

  if (claimNumber !== "") {
    return <ClaimCompleted claimNumber={claimNumber} />
  }

  return (
    <GraphQLFormProvider
      name="claim"
      mode={id === undefined ? "add" : "edit"}
      fields={fields}
      renderInput={renderInput}
      InputFormLayout={Layout}
      renderLabel={renderLabel}
      //initialValues={{claimForIdType:"South African"}}
      mapInputVariables={(input) =>  {
        let { createdAt, state, claimForIdType, ...rest } = input
        if (!claimForIdType) { claimForIdType = 'rsa_id' }
        let returnedInput = { ...rest, claimForIdType }
        console.log(' mapInputVariables', returnedInput)
        return { input: returnedInput }
      }}
      addMutation={ gql`
        mutation CreateClaim($input: CreateClaimForBenefit) {
          createBenefitClaim(input: $input)
          }
      `}
      afterSubmit={({ data }) => { setClaimNumber(data.createBenefitClaim) }}
      keepDirtyOnReinitialize={true}
    />
  )
}

export default ClaimForm