import React from 'react'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, FacebookMessengerShareButton, FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerIcon, LinkedinIcon} from 'react-share'
import { ShareButtonsContainer, ShareButtonContainer } from './ShareButtons.style'

const SIZE = 50

function ShareButtons({ url, text, title}) {
  return <ShareButtonsContainer width={(SIZE + 7) * 5}>
    <ShareButtonContainer><FacebookShareButton url={url} quote={text}><FacebookIcon size={SIZE} round /></FacebookShareButton></ShareButtonContainer>
    <ShareButtonContainer><TwitterShareButton  url={url} title={text}><TwitterIcon size={SIZE} round /></TwitterShareButton></ShareButtonContainer>
    <ShareButtonContainer><WhatsappShareButton separator=" " url={url} title={text}><WhatsappIcon size={SIZE} round /></WhatsappShareButton></ShareButtonContainer>
    <ShareButtonContainer><LinkedinShareButton url={url} title={title} summary={text}><LinkedinIcon size={SIZE} round /></LinkedinShareButton></ShareButtonContainer>
    <ShareButtonContainer><EmailShareButton url={url} separator=" " subject={title} body={text}><EmailIcon size={SIZE} round /></EmailShareButton></ShareButtonContainer>
  </ShareButtonsContainer>
}

export default ShareButtons
