import React, { useState } from 'react'
import { TextInputContainer, StyledInput } from './TextInput.style'
import moment from 'moment'

const TextInput = (props) => {

  const { onChange, defaultValue, value, type } = props

  const [myValue, updateValue] = useState(value || defaultValue ||  "")

  console.log('text input props are ',props, defaultValue, value, type, myValue )

  const onValueChange = (newValue) => {
    updateValue(newValue)
    if (onChange) {
      if (type==="date") {
        onChange(moment(newValue).valueOf())
      }
      else onChange(newValue)
    }
  }

  return (
    <TextInputContainer>
      <StyledInput {...props} value={myValue} onChange={e => onValueChange(e.target.value)} />
    </TextInputContainer>
  )
}

export default TextInput
