import React, { useState } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { Moku, Center, ChatCards} from '../../profile/RegisterForm/RegisterForm.style'
import { ChatFlow, Modal} from '../../generic'
import {doesDobMatchAge, isRequiredIfNotSA, getAgeFromDOB, getDOBFromID, isMemberValidAge, getAgeFromID, forMemberTypes, isValidIdNumber, isValidEmail, isValidCellphoneNumber, isValidAge, isValidDay, doesDOBMatchIdNumber, doesAgeAtSignupMatchDOB, doesAgeAtSignupMatchId, isRequired} from '../../../lib/validators'
import FuneralQuote from '../FuneralQuote'
import gql from 'graphql-tag'
import { TermsAndConditions } from '../../shared'
import { Mandate } from '../../payments'
import  { banks, idTypes, membertypes } from '../../../constants'
import Banks from '../../bank'
import { ActivityIndicator } from '../../../components/generic'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import moment from 'moment'

const MAX_CHILDREN = 5

const GET_PROFILE = gql`
  {
    myClient {
      id
      firstNames
      surname
      idNumber
      email
      idType
      birthDate
      cellNumbers
      gender
      acceptedTerms
      maritalStatus
      income
      smoker
      estimatedIncome
      documents {
        id
        type
      }
      bankAccounts {
        id
        bankName
        accountNo
      }
    }
  }
`

const CREATE_POLICY = gql`
  mutation CreateSalesInterationPolicyWithMembers($funeral: CreateFuneralInput!, $bankAccount: CreateBankAccountInput, $members: [CreateMemberInput!], $beneficiary: AddBeneficiaryInput, $salesInteraction: CreateSalesInteractionInput!, $selfieId: String, $idCardId: String) {
    createSalesInteractionPolicyWithMembers(funeral: $funeral, bankAccount: $bankAccount, members: $members, beneficiary: $beneficiary, salesInteraction: $salesInteraction, selfieId: $selfieId, idCardId: $idCardId) {
      id
      payAtNumber
      policyNumber
      paymentBankAccountId
    } 
  }
`

const CREATE_DOC = gql`
  mutation createClientDocument($input: CreateClientDocumentInput!) {
    createClientDocument(input: $input) {
      id
    }
  }
`

const GET_COVER = gql`
  query getTotalCover($idNumber: String!) {
    getTotalCover(idNumber: $idNumber) 
  }
`

const sanitizeMembers = (members, spouse, children, chatState) => {
  console.log('sanitizeMembers ', members, spouse, children)
  
  const coveredMembers = members.filter((d,index) => { console.log('sanitizeMembers ', d); 
    return ( (!d.memberType && index <= 1 ) || (d.memberType && d.firstNames && !(spouse === 'N' && d.memberType === 'SPOUSE' ) && !(children === 'N' && d.memberType === 'CHILD' )) )} ).map(m => m.idNumber && getDOBFromID(m.idNumber, m.idType) ? 
      ({ ...m, DOB: moment(getDOBFromID(m.idNumber, m.idType)).valueOf() }) : 
      ({ ...m, DOB: m.DOB && moment(m.DOB).valueOf() }))
  
  coveredMembers.forEach((m,index) => {

    if (!m.memberType) {
      if (index === 0 ) m.memberType = 'POLICY_HOLDER'
      if (index === 1 ) m.memberType = 'SPOUSE'
    }

    if (m.relationship) {
      if (m.relationship.includes('MOTHER')) {
        m.gender = "FEMALE"
      }
      else {
        m.gender = "MALE"
      }
    }
    if (m.hasOwnProperty('isCovered')) {
      delete m.isCovered
    }
    if (m.hasOwnProperty('isActive')) {
      delete m.isActive
    }

  })

  console.log('sanitizeMembers after ', coveredMembers)
  return coveredMembers
}

// TODO: Put this in a separate folder
function SignDebitOrderMandate({ gotoNext, chatState }) {

  console.log('SignDebitOrderMandate', chatState)
  const [showModal, setShowModal] = useState(true)
  const {funeral} = chatState
  
  const saleCompleted = () => {
    setShowModal(false);
    gotoNext(["congratulations"])
  }

  if (chatState.bankAccount.ownAccount === "N" && showModal) {
    saleCompleted()
    return <div />
  }

  return (
    <Modal
      centered
      width='98%'
      backgroundColor= 'rgb(242, 246, 252)'
      visible={showModal}
      hideOk
      extraButtonsTop={true}
      hideCancel
    >
      <Mandate.ShowMandate id={chatState.bankAccountId} onMandateSign={saleCompleted} paymentDueDay={funeral.paymentDueDay} />
    </Modal>
  )
}

const getPolicyType = (chatState) => {
return null
}

const steps = [
  {
    name: 'theSpouse',
    text: 'Do you want your spouse/partner covered?',
    fieldNames: ['spouse'],
    labels: false,
    noNext: true,
    nextOnValue: true,
    nextSteps: ({ spouse }) => spouse === "Y" ? ["ageSpouse"] : ["yourAge"],
  },
  {
    name: 'ageSpouse',
    text: 'What is their age?',
    fieldNames: ['ageSpouse'],
    labels: false,
    nextSteps: ["yourAge"],
  },
  {
    name: 'yourAge',
    text: 'How old are you?',
    skipStep: ({ members }) => members[0] && members[0].ageAtSignup,
    fieldNames: ['members[0].ageAtSignup'],
    labels: false,
    nextSteps: ["yourGender"],
  },
  {
    name: 'yourGender',
    text: 'What is your gender?',
    skipStep: ({ members }) => members[0] && members[0].gender,
    fieldNames: ["members[0].gender"],
    labels: false,
    nextSteps: ["theChildren"],
  },
  {
    name: 'theChildren',
    text: 'Do you have children?',
    fieldNames: ['children'],
    noNext: true,
    nextOnValue: true,
    nextSteps: ["income"],
  },
    {
    name: 'theParents',
    text: 'If affordable, would you like your parents covered?',
    fieldNames: ['parentsCover'],
    noNext: true,
    nextOnValue: true,
    nextSteps: ["yourAge"],
    },
    {
      name: "income",
      fieldNames:["members[0].income"],
      nextSteps: ["doQuote"],
      text: "What is your monthly income?",
      skipStep: ({ members }) => members[0] && members[0].income,
    },
  {
    name: "doQuote",
    component: FuneralQuote,
    noNext: true,
    },
    {
    name: "noThanks",
    text: "Thanks for talking to us about funeral insurance. Visit Moku anytime if you would like to take us up on this product",
    handleNext: ({history}) => history.push('/funeral'),
    nextTitle:"END"
  },
  {
    name: "acceptTerms",
    component: TermsAndConditions,
    nextSteps: ["mainMember"],
    handleNext: ({ apolloClient, setSubmitting, gotoNext }) => {
      setSubmitting(true);
      //apolloClient.mutate({ })
      console.log('Handling Next in Terms');
      setTimeout(() => { setSubmitting(false); gotoNext() }, 2000)
    },
    skipStep: ({ hasAcceptedTerms, acceptedTerms }) => hasAcceptedTerms || acceptedTerms,
    noNext: true,
    noCard: true
  },
  {
    name: "mainMember",
    beforeStep: ({ changeFieldValue, chatState }) => {
      console.log('set members[0] ', chatState)
      changeFieldValue("members[0].idNumber", chatState.idNumber)
      changeFieldValue("members[0].idType", chatState.idType)
      changeFieldValue("members[0].email", chatState.email)
      changeFieldValue("members[0].ageAtSignup", chatState.ageAtSignup)
      changeFieldValue("members[0].firstNames", chatState.firstName)
      changeFieldValue("members[0].surname", chatState.surname)
      changeFieldValue("members[0].cellNumbers", chatState.cellNumber)
      changeFieldValue("members[0].memberType", chatState.memberType)
      changeFieldValue("members[0].smoker", chatState.smoker)
    },
    text: "Please can we confirm your details",
    fieldNames: ['members[0].firstNames', 'members[0].surname','members[0].smoker', 'members[0].ageAtSignup', 'members[0].cellNumbers', 'members[0].email', 'members[0].DOB', 'members[0].idType', 'members[0].idNumber'],
    labels: true,
    // skipStep: ({ idNumber }) => idNumber,
    nextSteps: ["doIdCard"]
  }, 
  {
    name: "doIdCard",
    text: "Please can you upload a copy of your ID?",
    fieldNames: ["idCardId"],
    nextOnValue:true,
    nextTitle:"Later",
    skipStep: ({ idCardId }) => idCardId,
    nextSteps: ["doSelfie"]
  },
  {
    name: "doSelfie",
    text: 'Please can you upload a "selfie" of yourself',
    fieldNames: ["selfieId"],
    nextTitle:"Later",
    nextOnValue:true,
    skipStep: ({ selfieId }) => selfieId,
    nextSteps: ["spouse"]
  },
  {
    name: "spouse",
    skipStep: ({ spouse }) => spouse !== "Y",
    beforeStep: ({ changeFieldValue, chatState }) => {
      const { spouse } = chatState
      console.log('spouse is ', spouse)
      changeFieldValue("members[1].memberType", "SPOUSE")
      changeFieldValue("members[1].ageAtSignup", chatState.ageSpouse)
      changeFieldValue("members[1].idType","SOUTH AFRICAN")
    },
    text: "Please can we get your spouse's details",
    fieldNames: ['members[1].firstNames', 'members[1].surname', 'members[1].smoker','members[1].cellNumbers', 'members[1].email', 'members[1].DOB', 'members[1].ageAtSignup', 'members[1].idType', 'members[1].idNumber'],
    labels: true,
    nextSteps: ["addChild1"],
    handleNext: async ({ apolloClient, chatState, setChatState, gotoNext }) => {
      const coverRequested = chatState.coverAmount ? chatState.coverAmount : 0
      const idNumber = chatState.members[1] ? chatState.members[1].idNumber : 0
      if (chatState.totalSpouseCover) {
        if (chatState.totalSpouseCover + coverRequested <= 100000 ) {
          gotoNext(["addChild1"])
        }
        else { 
          gotoNext(["tooMuchCover"])
        }
      }
      else {
        if (idNumber) {
          const { data } = await apolloClient.query({ query: GET_COVER, variables: {idNumber} });
          console.log('check cover ', data)
          if (data && (data.getTotalCover + coverRequested <= 100000) ) {
            gotoNext(["addChild1"])
          }
          else { 
            setChatState({totalSpouseCover:data.getTotalCover})
            gotoNext(["tooMuchCover"])
          }
        }
        else {
          gotoNext(["addChild1"])
        }
      }
    },
  },
  {
    name: "tooMuchCover",
    text: ({ totalSpouseCover }) => `I'm sorry but we can't offer your spouse cover. }).`,
    //(existing cover is ${Numeral(totalSpouseCover).format('$0,0')//handleNext: ({history}) => history.push('/'),
    nextTitle:"BACK",
    nextSteps: ["noThanks"]
  },
  // Add in the steps for 5 children
  ...Array.from(Array(MAX_CHILDREN), (_, index) => ({
    name: `addChild${index + 1}`,
    beforeStep: ({ changeFieldValue }) => {
      changeFieldValue(`members[${index + 2}].memberType`, "CHILD")
    },
    labels: true,
    skipStep: ({ children }) => children !== "Y",
    text: index === 0 ? `Your policy allows up to 5 covered children. Please give the details of child ${index + 1}` : `Please give the details of child ${index + 1}`,
    fieldNames: [`members[${2+index}].firstNames`, `members[${2+index}].surname`, `members[${2+index}].ageAtSignup`, `members[${2+index}].DOB`, `members[${2+index}].idType`, `members[${2+index}].idNumber`],
    nextButtons: [{ label: "Add Another", nextSteps: [`addChild${index + 2}`] }, { label: "Done", nextSteps: ["beneficiarySpouse"] }],
    nextSteps: ["beneficiarySpouse"]
  })),
  {
    name: "beneficiarySpouse",
    skipStep: ({ spouse }) => spouse !== "Y",
    text: "Is your spouse the beneficiary?",
    fieldNames: ["beneficiary.isSpouse"],
    labels: false,
    noNext: true,
    nextOnValue: true,
    nextSteps: ({ beneficiary, spouse }) => spouse === 'Y' && beneficiary && beneficiary.isSpouse === "Y" ? ["ownAccount"] : ["beneficiaryInfo"],
  },
  {
    name: "beneficiaryInfo",
    text: "Who is the beneficiary?",
    fieldNames: ["beneficiary.firstNames", "beneficiary.surname", "beneficiary.idNumber","beneficiary.idType", "beneficiary.cellNumbers", "beneficiary.email", "beneficiary.bankName", "beneficiary.accountNo"],
    labels: true,
    nextSteps: ["ownAccount"]
  },
  {
    name: "ownAccount",
    text: "Will you be paying the account?",
    noNext: true,
    fieldNames: ["bankAccount.ownAccount"],
    nextOnValue: true,
    nextSteps: ({ bankAccounts, bankAccount  }) => bankAccount.ownAccount === "Y" ? bankAccounts && bankAccounts.length > 0 ? ["selectBank"] : [ "bankAccountInfo"] : ["accountHolderInfo"]
  },
  {
    name: "accountHolderInfo",
    text: "Please can we have the information for the account holder",
    fieldNames: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderEmail", "bankAccount.accountHolderIdNumber"],
    labels: true,
    nextSteps: ["bankAccountInfo"]
  },
  {
    name: "selectBank",
    component:Banks,
    noNext: true
  },
  {
    name: "bankAccountInfo",
    text: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? "Your bank account info" : "The Bank Account of the Premium Payer",
    fieldNames: ["bankAccount.bankName", "bankAccount.accountNo", "funeral.paymentDueDay"],
    labels: true,
    nextSteps: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? ["completeSale"] : ["doMandateSent"],
  },
  {
    name: "paymentDue",
    text: "What day should the debit order run?",
    fieldNames: ["funeral.paymentDueDay"],
    labels: false,
    nextSteps: ["completeSale"],
  },
  {
    name: "completeSale",
    beforeStep: async ({ apolloClient, chatState, setChatState }) => {
      console.log('Completing sale ...', chatState.bankAccount )
      console.log('Completing sale members...', chatState.members )
      console.log('Completing sale idCardId ...', chatState.idCardId )
      console.log('Completing sale funeral ...', chatState.funeral )
      const bankAccount = chatState.bankAccount ? {bankName:chatState.bankAccount.bankName, accountNo:chatState.bankAccount.accountNo} : null
      const { data } = await apolloClient.mutate({ mutation: CREATE_POLICY, variables: {
        funeral: { paymentMethod:"NAEDO", coverAmount: chatState.coverAmount, ...chatState.funeral },
        bankAccount,
        members: chatState.members && sanitizeMembers( chatState.members, chatState.spouse, chatState.children, chatState),
        beneficiary:chatState.beneficiary,
        salesInteraction: {},
        selfieId:chatState.selfieId,
        idCardId:chatState.idCardId
      } });
      console.log('complete sale data ',data)
      if (data) {
        setChatState({ policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber, bankAccountId: data.createSalesInteractionPolicyWithMembers.paymentBankAccountId })
        console.log('bankAccountId chatState ',chatState.bankAccountId)
      }
    },
    component: SignDebitOrderMandate,
    noCard: true,
    noNext: true,
    nextSteps: ["congratulations"],
    // DO Mandate
  }, {
    name: "doMandateSent",
    text: "We have sent the debit order mandate to the account holder to sign. Once that has been signed, your policy will be issued and cover will be active once the first payment has been received",
    nextSteps: ["completeSale"]
  },
   {
    name: "congratulations",
    text: ({ policyNumber }) => `Congratulations on your funeral insurance purchase. Your cover will be active as soon as the first payment is received. Your policy number is ${policyNumber}`,
    handleNext: ({history}) => history.push('/funeral'),
    nextTitle:"END"
  }]

  const fields = {
    idCardId: { type: "fileupload" },
    selfieId: { type: "fileupload" },
    ageAtSignup: { type: 'number' },
    spouse: { type: 'yesno' },
    ageSpouse: { type:'number', 
      label:"Age of Spouse", 
      validate: [isMemberValidAge('SPOUSE') ] 
    },
    children: { type: 'yesno' },
    parentsCover :{ type: 'yesno' },
    coverAmount: {
      type: 'optionselector',
      options: FuneralQuote.QUOTE_AMOUNTS
    },
    members: {
      type: 'fieldarray',
      config:
      {
        firstNames: { type: 'text', label: "First Name",
          validate: [isRequired] },
        surname: { type: 'text', label: "Last Name",
          validate: [isRequired] },
        memberType: { type: "text",label: "Member Type"},
        gender: { type: 'select', noSelectionLabel:"  ", label: "Gender *", options: ['MALE','FEMALE' ], validate: [isRequired] },
        smoker: { type: 'boolean', label: "Smoker", validate:[forMemberTypes(["POLICY_HOLDER","SPOUSE"],isRequired)] },
        ageAtSignup: { type: 'text', label: "Age",
          validate: [ forMemberTypes(["PARENT","SPOUSE"],isRequired), isValidAge, doesAgeAtSignupMatchId, doesAgeAtSignupMatchDOB] },
        email: { type: 'email', label: 'Email', validate: [forMemberTypes(["POLICY_HOLDER","SPOUSE"],isRequired), isValidEmail] },
        DOB: { type: 'text', label: 'Birth Date (YYYY-MM-DD)', assesor: row => moment(row.value).format('YYYY-MM-DD'),
          validate: [forMemberTypes(["CHILD"], isRequired), forMemberTypes(["POLICY_HOLDER","SPOUSE"], isRequiredIfNotSA),forMemberTypes(["POLICY_HOLDER","SPOUSE"], doesDobMatchAge),isValidAge, doesDOBMatchIdNumber] },
        idNumber: { type: 'number',label: "ID Number", maxLength: 13,
          validate:[forMemberTypes(["POLICY_HOLDER"], isRequired),forMemberTypes(["POLICY_HOLDER"], doesAgeAtSignupMatchId), isValidIdNumber] },
        idType: { type: "select", noSelectionLabel:"  ", options: idTypes,label: "Identity Type",
          validate:[forMemberTypes(["POLICY_HOLDER"],isRequired)] },
        cellNumbers: { type: 'number', label: "Cell Number", maxLength: 10,
          validate: [forMemberTypes(["POLICY_HOLDER","SPOUSE"],isRequired), isValidCellphoneNumber] },
        income: {type: 'number'}
      }
    },
    beneficiary: {
      isSpouse:{type: 'yesno', label: "Is your spouse the beneficiary" },
      firstNames: { type: 'text', label: "Beneficiary First Name ", validate: [isRequired] },
      surname: { type: 'text', label: "Beneficiary Last Name *", validate: [isRequired] },
      cellNumbers: { type: 'number', label: "Beneficiary Cell Number *", maxLength: 10, validate: [isRequired, isValidCellphoneNumber] },
      email: { type: 'email', label: 'Beneficiary Email', validate: [isValidEmail] },
      idType: { type: "select", noSelectionLabel:"  ", options: idTypes,label: "Beneficiary Identity Type"},
      idNumber: { type: 'text',label: "Beneficiary ID Number", maxLength: 13,validate:[isValidIdNumber] },
      bankName: { type: 'select', noSelectionLabel:"  ", options: banks, label: 'Beneficiary Bank' },
      accountNo: { type: 'text', noSelectionLabel:"  ", label: 'Beneficiary Account Number', }
    },
    bankAccount: {
      bankName: { type: 'select', noSelectionLabel:" ", options: banks, label: 'Bank' },
      accountNo: { type: 'text', label: 'Account Number',validate: [isRequired] },
      ownAccount:{ type: 'yesno', label: "Policy holder's account" },
      accountHolderName: { type: 'text', label: 'Premium Payer Name' },
      accountHolderContactNumber: { type: 'text', label: 'Contact Number'},
      accountHolderEmail: { type: 'text', label: 'Contact Email' },
      accountHolderIdNumber: { type: 'text', label: 'Id Number' },
    },
    funeral: {
      currentPremiumTotal: {type: 'number'},
      paymentDueDay: {
        type: 'number',
        keyboard: 'numeric',
        maxLength: 2, 
        label: 'Debit Order Day',
        validate: value => value && value > 0 && value <= 31 
          ? undefined
          : "Enter a valid day"
      },
    }
  }

function FuneralForm() {

  const { data, loading, error } = useQuery(GET_PROFILE)

  if (loading) { return <ActivityIndicator /> }
  
  if (error) { console.log(error); return "An error has occurred" }
  
  const { myClient } = data
  
  //console.log('MyClient ', myClient, data)

  let selfieDocument
  let idDocument
  if( !myClient ) return <ActivityIndicator />

  let selfieDocuments = data.myClient.documents && data.myClient.documents.filter( d => d.type && d.type.toUpperCase() === "SELFIE") || []
  selfieDocument = selfieDocuments.length > 0 ? selfieDocuments[selfieDocuments.length - 1].id : null

  let idDocuments = data.myClient.documents && data.myClient.documents.filter( d => d.type && (d.type.toUpperCase() === "IDDOCUMENT" || d.type.toUpperCase() === "ID" )) || []
  idDocument = idDocuments.length > 0 ? idDocuments[idDocuments.length - 1].id : null

  //console.log('MyClient documents idDoc', idDocument)
  //console.log('MyClient documents selfieDoc', selfieDocument)

  let bankAccount = {}
  //let bankAccount = {bankName:"Capitec Bank"}
  
  if (myClient && myClient.bankAccounts && myClient.bankAccounts.length > 0 ) bankAccount = myClient.bankAccounts[data.myClient.bankAccounts.length - 1]
  
  return (
      <Center>
        <Moku />
        <ChatCards>
          <ChatFlow
            name="newFuneral"
            steps={steps}
            fields={fields}
            initialValues={chatState => ({ 
              clientId:myClient.id,
              firstName: myClient.firstNames || chatState.firstName, 
              surname: myClient.surname || chatState.surname, 
              smoker: myClient.smoker || chatState.smoker || 'NO', 
              idType:myClient.idType, 
              idNumber:myClient.idNumber || chatState.idNumber, 
              memberType:chatState.memberType || "POLICY_HOLDER", 
              cellNumber:myClient.cellNumbers[0] || chatState.cellNumber, 
              ageSpouse:chatState.ageSpouse,
              members: [{
                idNumber: myClient.idNumber, 
                idType : myClient.idType, 
                email : myClient.email || chatState.email, 
                ageAtSignup: getAgeFromID(myClient.idNumber, myClient.idType ) || getAgeFromDOB(myClient.birthDate),
                DOB: myClient.birthDate ? moment(myClient.birthDate).format('YYYYMMDD') : null,
                smoker : myClient.smoker, 
                firstNames : myClient.firstNames, 
                surname : myClient.surname, 
                cellNumbers : myClient.cellNumber, 
                gender : myClient.gender && myClient.gender.toUpperCase(),
                memberType: "POLICY_HOLDER",
                income: myClient.income || chatState.income, 
              }],
              selfieId: selfieDocument || chatState.selfieId || null,
              idCardId: idDocument || chatState.idCardId,
              hasAcceptedTerms:myClient.acceptedTerms || chatState.hasAcceptedTerms, 
              bankAccount,
              coverAmount: 10000, 
              totalSpouseCover:null,
              spouse:null,
              funeral: {paymentDueDay:1}
            })}
          />
        </ChatCards>
      </Center>
  )
}

export default FuneralForm
//beforeStep: async ({ changeFieldValue }) => changeFieldValue("beneficiary.bankName", "Capitec Bank"),