import React from 'react'
import { printCurrency, printDate } from '../../../lib/utils'
import {renderDisplay} from "../form"
import { Response } from './ChatFlow.style'

const displayForType = (type, value, props) => {
  if (type === "currency") {
    return printCurrency(value)
  }
  if (type === "date") {
    return printDate(value)
  }
  if (type === "select" || type === "options") {
    console.log(props.field.options)
    if (props.field.options[0] instanceof Object) {
      let result = value
      props.field.options.forEach(option => {
        if (option.value === value) { result =  option.label }
      })
      return result
    }
    return value
  }
  if (type === "yesno") {
    if (value === "Y") return "Yes"
    if (value === "N") return "No"
  }
  return value
}

const renderFieldDisplay = (type) => ({ input, ...props }) => {
  // console.log('Render Display type',type)
  // console.log('Render Display props',props)
  // console.log('Render Display input',input)
  return (
    <Response>
    {displayForType(type, props.value || input.value, props)}
    </Response>
  )}

export default renderFieldDisplay