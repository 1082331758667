import React from 'react'

import styled from 'styled-components'

const LabelText = styled.div`
  font-size: 15px;
  color: #888888;
  line-height: 26px;
  font-weight: 600
`

const renderLabel = () => ({ label, field }) => (
  <LabelText>{label || field.label}</LabelText>
)

export default renderLabel
