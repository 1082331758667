import React from 'react'
import styled, { keyframes } from 'styled-components'
import Card from '../Card'

const CardText = styled.span`
  margin-bottom: 21px;
  margin-top: 14px;
`

const coolBoxKeyframes = keyframes`
  0% {
    transform: scale(0.5)
  }
  100% {
    transform: scale(1.0)
  }
`

const CardAnimation = styled.div`
  animation-name: ${coolBoxKeyframes};
  animation-duration: 0.1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
`

const AnimatedCard = (props) => <CardAnimation><Card {...props} /></CardAnimation>

const CardContent = styled.div`
  padding-top: ${props => props.empty ? "0px" : "7px"};
`

const CardNextButtons = styled.div`
  margin-top:7px
`

export {
  CardText,
  AnimatedCard,
  CardContent,
  CardNextButtons
}