import styled from 'styled-components'

const ButtonContainer = styled.div`
  position:relative;
  margin-right:7px;
  text-align:center;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  background-color: #e0e0e0;
  font-size: 0.875rem;
  min-width: ${props => props.width ? props.width : "100px"};
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 16px;
  width: fit-content;


  ${props => props.action && `background-image: linear-gradient(${props.theme.actionGradientFrom}, ${props.theme.actionGradientTo});`}
  ${props => props.warning && `background-image: linear-gradient(${props.theme.warningGradientFrom}, ${props.theme.warningGradientTo});`}
  ${props => props.disabled && `background-image: linear-gradient(grey, grey);`}

  ${props => props.disabled ? `cursor: not-allowed;`: `cursor: pointer;`}

  padding: ${props => props.size == "small" ? "2px 12px" : "5px 21px"};
  color: ${props => props.disabled || (!props.action && !props.warning) ? 'black' : 'white'};
  font-size: ${props => props.size == "small" ? "10px" : "0.875rem"};

  :hover {
    filter: brightness(80%);
    ${props => props.disabled && `background-image: linear-gradient(grey, grey);`}
    ${props => !props.action && `background-image: linear-gradient(grey, grey);`}
  }
}
`

export {
  ButtonContainer,
}

//${props => (!props.action && !props.warning) && `border:1px solid #267E7F; color:${props.theme.color}`}
