import React from 'react'
import { StyledSpinner, StyledSpinnerContainer } from './ActivityIndicator.style'

/*
function ActivityIndicator(props) {
  return (
    <StyledSpinnerContainer>
      <i className="gg-spinner-alt"></i>
    </StyledSpinnerContainer>
  )
}
*/
function ActivityIndicator(props) {
  return (
  <StyledSpinnerContainer>
    <StyledSpinner {...props} />
  </StyledSpinnerContainer>
  )
}

export default ActivityIndicator
