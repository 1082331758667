import React from 'react'
import { Icon, Button } from '../../generic'
import { Row, Col } from '../../generic/grid'
import { RowContainer, ProgressBar, getSmileyProps, getBucketTypeProps, IconCircle } from './SavingsNeedItemRow.style'

function SavingsNeedItemRow({ bucketType = "EMERGENCY", name = "Emergency Fund", description, percentageFull, targetAmount, id, topRow, onClick }) {
  // remove this when pushing
  //let percent = percentageFull ? percentageFull :  Math.random() * 100
  console.log('percentage full ', percentageFull, bucketType)
  let percent = percentageFull
  return (
      <RowContainer topRow={topRow} onClick={() => onClick(id)}>
        <Col width="72px" centerHorizontal centerVertical>
          <Row>
            <IconCircle bucketType={bucketType}>
              <Icon size="2x" {...getBucketTypeProps(bucketType)} />
            </IconCircle>
          </Row>
        </Col>
        <Col centerVertical style={{ width: "80%" }}>
          <Row centerVertical><Col alignHorizontal={"left"} centerVertical>{name}</Col></Row>
          {(percentageFull > 0 || targetAmount > 0) ? <Row><Col centerVertical><ProgressBar percentageFull={percentageFull || percent } /></Col></Row> : <Row />}
        </Col>
        <Col width={percentageFull > 0 || targetAmount > 0 ? "60px" : "100px"} style={{ paddingRight: "7px" }} centerVertical centerHorizontal> {(percentageFull > 0 || targetAmount > 0) ? <Icon size="2x" {...getSmileyProps(percentageFull || percent) } /> : bucketType != "EMERGENCY" && <Button action>START</Button>}</Col>
      </RowContainer>
  )
}

export default SavingsNeedItemRow
