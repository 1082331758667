// TODO: Expand Palette and standardise a bit - palette colors marked below
const colorPalette = {
  lightgreen: "#78e193", // FROM PALETTE
  green: "#55A168",
  darkgreen: "#229490", // FROM PALETTE
  yellow: "#ffbb42", // FROM PALETTE
  grey: "#3e4254", // FROM PALETTE
  pink: "#e22660", // FROM PALETTE
  purple: "#120ABF",
  red: "#B31F12",
  darkred: "#8C190E",
  orange: "#FF6940",
  brown: "#803C16",
  lightblue: "#1590B3",
  darkblue: "#0056B3",
  cyan: "#0ABFBD",
  lightgrey: "#c8c8c8"
}

const themeColors = {
  primary: "#046D80",
  buckets: {
    EMERGENCY: colorPalette.red,
    YEAR_END: colorPalette.yellow,
    EDUCATION: colorPalette.darkblue,
    HOLIDAY: colorPalette.purple,
    CAR: colorPalette.cyan,
    HOUSE: colorPalette.lightgreen,
    RETIREMENT: colorPalette.grey,
    HEALTH: colorPalette.darkred,
    FUNERAL: colorPalette.brown 
  },
  status: {
    good: colorPalette.green,
    average: colorPalette.orange,
    bad: colorPalette.red,
    unknown: colorPalette.lightgrey
  }
}

export default {
  topBarColor: colorPalette.grey,
  buttons: "#267E7F",
  border: "#b8b8b8",
  background: "#2F2F2F",
  sideMenuColor: "rgba(0, 0, 0, 0.5)",
  padding: 7,
  marginTop: 7,
  marginBottom: 7,
  margin: 3,
  primaryHover: "#046D80",
  actionGradientFrom: "#267E7F",
  actionGradientTo: "#04899F",
  warningGradientFrom: "rgba(255,80,0,1)",
  warningGradientTo: "rgba(233,134,0,1)",
  fontFamily: "Lato",
  loadFontUrl: "https://fonts.googleapis.com/css?family=Lato:300italic,300,700,700italic&display=swap",
  fontSize:14,
  color:"#323232",
  colors: themeColors,
  //backgroundImage: linear-gradient(#3E4255, #55513E);
}

//fontFamily: "Montserrat",
//
//loadFontUrl:"./assets/fonts/EuropaNuovaRegular.ttf",

