import styled from 'styled-components'

const OptionsContainer = styled.div`
  display: flex;
  border-left: 1px solid ${props => props.theme.colors.primary};
  border-top: 1px solid ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  flex-direction: row;
  border-radius: 20px;
`

const OptionContainer = styled.div`
  display: flex;
  flex: 1;
  border-right: 1px solid ${props => props.theme.colors.primary};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${props => props.selected ? props.theme.colors.primary : "none"};
  border-top-left-radius: ${props => props.first ? "20px" : "0px"};
  border-bottom-left-radius: ${props => props.first ? "20px" : "0px"};
  border-top-right-radius: ${props => props.last ? "20px" : "0px"};
  border-bottom-right-radius: ${props => props.last ? "20px" : "0px"};
  padding-left: ${props => props.first ? "5px" : "0px"};
  padding-right: ${props => props.last ? "5px" : "0px"};
`

const OptionText = styled.span`
  color: ${props => props.selected ? "white" : "black"};
`

export {
  OptionsContainer,
  OptionContainer,
  OptionText
}
