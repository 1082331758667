import styled from 'styled-components'
import { Col } from '../../generic/grid'

const PaddedCol = styled(Col)`
  padding: 7px
`

const RiskText = styled.span`
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  background: ${props => props.riskColour || "red"}
  color: white;
  font-weight: 600;
  font-size: 12px;
  border-radius: 14px;
`

export {
  PaddedCol,
  RiskText
}