import React from 'react'
import { VictoryChart, VictoryTheme, VictoryLine, VictoryAxis } from 'victory'
import { GraphContainer } from './AmortizationGraph.style'
import { printCurrency } from '../../../lib/utils'

const buildAmortizationTable = (balance, rate = 10, installment) => {
  let month = 0
  let newBalance = parseFloat(balance)
  let result = []
  while (newBalance > 0 && month <= 400) {
    result.push({ x: month, y: newBalance })
    newBalance = (newBalance * (1 + (rate / 1200))) - installment
    month += 1
  }
  result.push({ x: month, y: newBalance })
  return result
}

function AmortizationGraph({ obligation }) {
  const { currentPayment, currentBalance } = obligation
  const { rate, installment } = currentPayment

  const data = buildAmortizationTable(currentBalance, rate, installment)
  const extra = Math.floor((installment * 0.05) / 100) * 100
  const data2 = buildAmortizationTable(currentBalance, rate, installment + extra)

  return (
    <GraphContainer>
      <VictoryChart
        theme={VictoryTheme.material}
        width={400}
        height={250}
        padding={{ top: 0, bottom: 25, left: 50, right: 10 }}
      >
        <VictoryAxis dependentAxis
          tickFormat={(t) => `${Math.round(t / 1000)}k`}
        />
        <VictoryAxis crossAxis />
        <VictoryLine
          style={{
            data: { stroke: "#c43a31" },
            parent: { border: "1px solid #ccc"}
          }}
          data={data}
        />
        <VictoryLine
          style={{
            data: { stroke: "#3ac431" },
            parent: { border: "1px solid #ccc"}
          }}
          data={data2}
        />
      </VictoryChart>
      The green line shows if you pay an extra<br/>{printCurrency(extra)}. You will pay it off {data.length - data2.length} months faster.
    </GraphContainer>
  )
}

export default AmortizationGraph
