import React, {Fragment} from 'react'
import { MenuButton } from '../../../components/generic'
import { StyledCardContainer } from '../../../components/generic/Card/Card.style'
import { CardLayout,Moku, LoginContainer, Center, Footer} from '../../../components/profile/Login/Login.style'

import styled from 'styled-components'


function NewStart({ history}) {
  console.log('Login page called ')
  return (
    <div>
      <LoginContainer>
          <Center>
            <Moku />
            <h2>Welcome to Meerkat</h2>
            <StyledCardContainer>
            <CardLayout>
                <MenuButton color={"#fff"} icon="credit-card" onClick={()=> history.push('/credit')} label={"Free Credit Report"} />
                <MenuButton color={"#fff"} icon="chart-line" onClick={()=> history.push('/register')} label={"Start Saving"} />
                {1 == 2 && <MenuButton color={"#fff"} icon="umbrella" onClick={()=> history.push('/funeral/new')} label={"Buy Funeral Insurance"} />}
                <MenuButton color={"#fff"} icon="dove" onClick={()=> history.push('/claims')} label={"Register a Claim"} />
                <MenuButton color={"#fff"} icon="pencil-alt" onClick={()=> history.push('/login')} label={"Login"} />
              </CardLayout>
            </StyledCardContainer>
          </Center>
      <Footer>
          © Copyright MyMeerkat (PTY) Ltd. All Rights Reserved.
      </Footer>
      </LoginContainer>
    </div>
  )
}

export default NewStart
