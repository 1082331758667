import React, { Component} from 'react'
import { Switch, Route } from 'react-router-dom'
import MyCredit from './MyCredit'

// TODO: Should only be 1 ClaimForm
export default class Credit extends Component {
  render() {
    return (
      <Switch>
        <Route path='/credit' component={MyCredit} />
      </Switch>
    )
  }
}