import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDove, faAmbulance, faQuestion, faChartPie, faChartLine, faDiagnoses, faPlay, faExclamation, faDollarSign, faArrowLeft, faArrowRight, faThumbsUp, faThumbsDown, faSpinner, faClock, faUserClock, faQuestionCircle, faCloudUploadAlt, faCloudDownloadAlt, faExclamationTriangle, faExclamationCircle, faAnkh, faBars, faCheck, faTimes, faCoffee, faHome, faChevronRight, faPencilAlt, faTrash, faLifeRing, faGifts, faGraduationCap, faPlaneDeparture, faCar, faWallet, faMonument, faSmile, faSadTear, faMeh, faHeartbeat, faPiggyBank, faUserCircle, faCreditCard, faFileInvoice, faCoins, faSignOutAlt, faUmbrella, faBuilding, faInfoCircle, faFile, faCashRegister, faArrowCircleLeft, faArrowCircleRight, faStethoscope, faUserAltSlash, faUserInjured, faUserMd, faBusinessTime, faBriefcaseMedical, faUniversity, faFileInvoiceDollar, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faDove)
library.add(faChartPie)
library.add(faQuestion)
library.add(faChartLine)
library.add(faAmbulance)
library.add(faDiagnoses)
library.add(faPlay)
library.add(faDollarSign)
library.add(faExclamation)
library.add(faArrowLeft)
library.add(faArrowRight)
library.add(faThumbsUp)
library.add(faThumbsDown)
library.add(faCloudUploadAlt)
library.add(faCloudDownloadAlt)
library.add(faQuestionCircle)
library.add(faClock)
library.add(faSpinner)
library.add(faUserClock)
library.add(faExclamationTriangle)
library.add(faAnkh)
library.add(faBars)
library.add(faCheck)
library.add(faTimes)
library.add(faCoffee)
library.add(faHome)
library.add(faChevronRight)
library.add(faPencilAlt)
library.add(faTrash)
library.add(faLifeRing)
library.add(faGifts)
library.add(faGraduationCap)
library.add(faPlaneDeparture)
library.add(faCar)
library.add(faWallet)
library.add(faMonument)
library.add(faSmile)
library.add(faSadTear)
library.add(faMeh)
library.add(faHeartbeat)
library.add(faPiggyBank)
library.add(faUserCircle)
library.add(faCreditCard)
library.add(faFileInvoice)
library.add(faCoins)
library.add(faSignOutAlt)
library.add(faUmbrella)
library.add(faBuilding)
library.add(faInfoCircle)
library.add(faFile)
library.add(faCashRegister)
library.add(faArrowCircleLeft)
library.add(faArrowCircleRight)
library.add(faStethoscope)
library.add(faUserAltSlash)
library.add(faUserInjured)
library.add(faUserMd)
library.add(faBusinessTime)
library.add(faBriefcaseMedical)
library.add(faExclamationCircle)
library.add(faUniversity)
library.add(faFileInvoiceDollar)
library.add(faMoneyBillAlt)


function Icon(props) {
  return <FontAwesomeIcon {...props} />
}

export default Icon
