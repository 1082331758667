import React, { Component} from 'react'
import { Switch, Route } from 'react-router-dom'
import MySavings from './MySavings'
import MySavingsItem from './MySavingsItem'


export default class Savings extends Component {
  render() {
    return (
      <Switch>
        <Route path='/savings' exact component={MySavings} />
        <Route path='/savings/:id' component={MySavingsItem} />
      </Switch>
    )
  }
}