import React from 'react'
import styled from 'styled-components'
import { mokuFavicon } from '../../../assets/images'

import Icon from '../../generic/Icon'

const ProfileImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ShowProfileImage = styled.img`
  width: 40px;
  border-radius: 20px;
`

const DefaultProfileImage = () => mokuFavicon

export {
  ProfileImageContainer,
  ShowProfileImage,
  DefaultProfileImage
}